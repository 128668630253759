import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useQuery } from 'react-query';
import { enterpriseService } from 'services';
import { PopupController } from 'types/Common';
import { ProposalType } from 'types/EnterpriseProposal';

type PopupProps = PopupController & {
  proposal: ProposalType;
};

const EnterpriseProposalPopup = ({ proposal, onClose }: PopupProps) => {
  const { data } = useQuery(['enterpriseService.getProposalById', { id: proposal.id }], () =>
    enterpriseService.getProposalById({ id: proposal.id }),
  );

  return (
    <>
      <DialogTitle>
        <Trans>Proposal View</Trans>
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className='w-20'>
                  <Trans>No.</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Content</Trans>
                </TableCell>
                <TableCell className='max-w-xs'>
                  <Trans>CRM Department's Proposal</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>
                  <Trans>Total Enterprise Limit (VND)</Trans>
                </TableCell>
                <TableCell className='text-center'>{data?.totalLimit}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2</TableCell>
                <TableCell>
                  <Trans>Advance Term (Day)</Trans>
                </TableCell>
                <TableCell className='text-center'>
                  {data?.advanceTermFrom} - {data?.advanceTermTo}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3</TableCell>
                <TableCell>
                  <Trans>Advance Amount (VND)</Trans>
                </TableCell>
                <TableCell className='text-center'>
                  {data?.advanceAmountFrom} - {data?.advanceAmountTo}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>4</TableCell>
                <TableCell>
                  <Trans>Earned Discount Rate (APR)</Trans>
                </TableCell>
                <TableCell className='text-center'>{data?.earnedDiscountRate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5</TableCell>
                <TableCell>
                  <Trans>Future Discount Rate (APR)</Trans>
                </TableCell>
                <TableCell className='text-center'>{data?.futureDiscountRate}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Close</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EnterpriseProposalPopup;
