import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Select,
  MenuItem,
  Dialog,
  Pagination,
} from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { enterpriseSetupService, queryClient } from 'services';
import { useQuery, useMutation } from 'react-query';
import { DateTime } from 'luxon';
import { TableEmpty } from 'components';
import { GuaranteeStateType } from 'types/EnterpriseSetup';
import { GuaranteeConfirmationPopup } from 'views/ClientSetup/Enterprise/popups';
import { NoticeType } from 'types/EnterpriseSetup';
import { usePermission, useSearch } from 'hooks';
import { getGuaranteeStatusType } from 'constants/enterprise';
import SelectPageSize from 'components/SelectPageSize';

const EmployeeGuaranteeConfirmation = ({ enterpriseId }: { enterpriseId: string }) => {
  const { isCaRole, requreUserPermission, ENTERPRISE_LUU_XACNHANBAOLANH } = usePermission();
  const { enqueueSnackbar } = useSnackbar();
  const [dataSearch, onSearchChange] = useSearch();
  const { data: { notices } = {} } = useQuery(
    ['enterpriseSetupService.fetchGuarantee', { enterpriseId: enterpriseId, ...dataSearch }],
    () => enterpriseSetupService.fetchGuarantee({ enterpriseId: enterpriseId, ...dataSearch }),
    {
      onSuccess: ({ guarantee }) => {
        setGuaranteeStatus(guarantee?.state);
      },
    },
  );

  const { data: items = [], paginate } = notices ?? {};

  const [guaranteeStatus, setGuaranteeStatus] = useState('');
  const [stateCurrent, setStateCurrent] = useState('');

  const [openNotice, setOpenNotice] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState<NoticeType>();

  const { mutate: saveGuarantee, isLoading } = useMutation(enterpriseSetupService.saveGuarantee, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.fetchGuarantee');
    },
  });

  const handleSelectStatus = (e: any) => {
    setGuaranteeStatus(e.target.value);
  };

  const handleSaveStatus = () => {
    saveGuarantee({
      enterpriseId: enterpriseId,
      state: guaranteeStatus as GuaranteeStateType,
    });
  };

  return (
    <div>
      <Typography variant='h4'>
        <Trans>Guarantee Confirmation</Trans>
      </Typography>

      <div className='flex items-center mt-3'>
        <Typography variant='h6'>
          <Trans>Select Guarantee Status</Trans>
        </Typography>
        <Select
          className='mx-4'
          size='small'
          autoWidth
          value={guaranteeStatus}
          onChange={handleSelectStatus}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value='GUARANTEE'>
            <Trans>Guarantee</Trans>
          </MenuItem>
          <MenuItem value='NON_GUARANTEE'>
            <Trans>Non-Guarantee</Trans>
          </MenuItem>
        </Select>
        {requreUserPermission([ENTERPRISE_LUU_XACNHANBAOLANH]) && items.filter(function(item){
          return item.status === 'COMPLETED' || item.status === 'REJECTED'
        }).length == items.length && (<LoadingButton variant='contained' onClick={handleSaveStatus} loading={isLoading}>
          <Trans>Save</Trans>
        </LoadingButton>)}
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Creation Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>View</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell className='text-center'>{DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')}</TableCell>
                <TableCell className='text-center'>{getGuaranteeStatusType(item.status)}</TableCell>
                <TableCell className='text-center'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpenNotice(true);
                      setSelectedNotice(item);
                      setStateCurrent(item.status);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='md' open={openNotice} onClose={() => setOpenNotice(false)}>
        <GuaranteeConfirmationPopup
          stateCurrent={stateCurrent}
          notice={selectedNotice!}
          onClose={() => setOpenNotice(false)}
        />
      </Dialog>
    </div>
  );
};

export default EmployeeGuaranteeConfirmation;
