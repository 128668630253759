import { Trans } from '@lingui/macro';
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import { formatNumber } from 'utils/common';
import { useQuery } from 'react-query';
import { enterpriseService } from 'services';
import { useState } from 'react';
import { WorkingPaginateType, WorkingParamsType } from 'types/EmployeeWorking';
import { useSearch } from 'hooks';
import { useParams, Link } from 'react-router-dom';
import { getEmployeeType } from 'constants/employee';
import SelectPageSize from 'components/SelectPageSize';
const UsingLimit = () => {
  const { id: _id } = useParams() as { id: string };
  const [dataSearch, onSearchChange] = useSearch({ _id });
  const { data } = useQuery(
    ['enterpriseService.situationOfUsingLimit', dataSearch],
    () => enterpriseService.situationOfUsingLimit(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};


  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h4'>
          <Trans>TÌNH HÌNH SỬ DỤNG HẠN MỨC</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell>
                <Trans>Company Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Labour Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Advance Limit (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Available Limit (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Max Labor of Advance</Trans>
              </TableCell>
              <TableCell>
                <Trans>Request</Trans>
              </TableCell>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.enterprise.name}</TableCell>
                  <TableCell>{getEmployeeType(item.employeeType)}</TableCell>
                  <TableCell>{formatNumber(item.advanceLimit)}</TableCell>
                  <TableCell>{formatNumber(item.availableLimit)}</TableCell>
                  <TableCell>{item.numbOfAvailableLabour}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Link to={"/payroll?id=" + item.id}>
                      <Button size='small' color='info'>
                        <Trans>Request</Trans>
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>
      </div>
    </Paper>
  );
};

export default UsingLimit;
