import { PromotionEmployeePaginateType } from 'types/Employee';
import {
  EnterprisePaginateType,
  EnterpriseParamsType,
  PromotionPaginateType,
  PromotionParamsType,
  PromotionType,
  PromotionUpdateType,
} from 'types/Promotion';
import { client } from './axios';

const getPromotionById = ({ id }: { id: string }): Promise<PromotionType> =>
  client.get(`/api/v1/general-setup/promotions/${id}`);
const fetchPromotions = (params: PromotionParamsType): Promise<PromotionPaginateType> =>
  client.get(`/api/v1/general-setup/promotions`, { params });
const createPromotion = ({ id, ...body }: PromotionUpdateType): Promise<PromotionType> =>
  client.post(`/api/v1/general-setup/promotions`, body);
const updatePromotion = ({ id, ...body }: PromotionUpdateType): Promise<PromotionType> =>
  client.put(`/api/v1/general-setup/promotions/${id}`, body);
const removePromotion = ({ id }: { id: number }) => client.delete(`/api/v1/general-setup/promotions/${id}`);
const removePromotionEnterprises = ({ id, ...body }: { id: string, enterpriseIds: number[] }) => client.post(`/api/v1/general-setup/promotions/${id}/enterprises/delete`, body);

const fetchPromotionEnterprises = ({ id, ...params }: EnterpriseParamsType): Promise<EnterprisePaginateType> =>
  client.get(`/api/v1/general-setup/promotions/${id}/enterprises`, { params });
const fetchPromotionEmployees = ({ id, enId, ...params }: EnterpriseParamsType): Promise<PromotionEmployeePaginateType> =>
  client.get(`/api/v1/general-setup/promotions/${id}/enterprise/${enId}/employees`, { params });
const fetchPromotionAllEmployees = ({ id, enId }: EnterpriseParamsType): Promise<number[]> =>
  client.get(`/api/v1/general-setup/promotions/${id}/enterprise/${enId}/getallemployees`);
export default {
  getPromotionById,
  fetchPromotions,
  createPromotion,
  updatePromotion,
  removePromotion,
  removePromotionEnterprises,

  fetchPromotionEnterprises,
  fetchPromotionEmployees,
  fetchPromotionAllEmployees,
};
