import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatNumber } from 'utils/common';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { transactionOverdueService, queryClient } from 'services';
import {
  TransOverdueRecordDocPopup,
  TransOverdueUpdateRecordPopup,
} from 'views/Transaction/TransOverdue/TransOverdueView/popups';
import { LoadingButton } from '@mui/lab';
import { TableEmpty } from 'components';
import { TransOverdueHistoryType } from 'types/TransactionOverdue';

const TransOverdueRecordHis = ({ overdueRecords }: { overdueRecords?: TransOverdueHistoryType[] }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenRecord, setOpenRecord] = useState(false);
  const [isOpenDoc, setOpenDoc] = useState(false);
  const [record, setRecord] = useState<TransOverdueHistoryType>();

  const { mutate: approveRecord, isLoading } = useMutation(transactionOverdueService.approveOverdueRecordTransaction, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('transactionOverdueService.getOverdueTransaction');
      queryClient.invalidateQueries('transactionService.getTransactionById');
    },
  });

  const handleApproveOverdueClick = (item: TransOverdueHistoryType) => {
    approveRecord({
      id: item.transactionId!,
      recordId: item?.id!,
    });
  };

  const renderConfirm = (item: TransOverdueHistoryType) => {
    if (item.confirmer) {
      return item.confirmer;
    }
    return (
      <div className='flex items-center space-x-3'>
        <LoadingButton
          variant='contained'
          size='small'
          loading={isLoading}
          onClick={() => handleApproveOverdueClick(item)}
        >
          <Trans>Approve</Trans>
        </LoadingButton>
        <Button
          variant='outlined'
          size='small'
          onClick={() => {
            setRecord(item);
            setOpenRecord(true);
          }}
        >
          <Trans>Edit</Trans>
        </Button>
      </div>
    );
  };

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Overdue History</Trans>
          </Typography>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Collection Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Collected Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Collected Principal Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Collected Cost (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Collected Penalty (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Requester</Trans>
              </TableCell>
              <TableCell>
                <Trans>Confirmer</Trans>
              </TableCell>
              <TableCell>
                <Trans>Document</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {overdueRecords?.map((item) => (
              <TableRow key={item.id}>
                <TableCell align='center'>
                  {item.collectionDate ? DateTime.fromISO(item.collectionDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell className='text-right'>{formatNumber(item.collectedAmount)}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.collectedPrincipalAmount)}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.collectedCost)}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.collectedPenalty)}</TableCell>
                <TableCell>{item.requester}</TableCell>
                <TableCell>{renderConfirm(item)}</TableCell>
                <TableCell align='center'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setRecord(item);
                      setOpenDoc(true);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={overdueRecords} />
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog fullWidth maxWidth='md' open={isOpenDoc} onClose={() => setOpenDoc(false)}>
        <TransOverdueRecordDocPopup
          transactionId={record?.transactionId!}
          recordId={record?.id!}
          removable={record?.confirmer === undefined || record?.confirmer === null || record?.confirmer === ''}
          onClose={() => setOpenDoc(false)}
        />
      </Dialog>

      <Dialog fullWidth maxWidth='lg' open={isOpenRecord} onClose={() => setOpenRecord(false)}>
        <TransOverdueUpdateRecordPopup record={record!} onClose={() => setOpenRecord(false)} />
      </Dialog>
    </div>
  );
};

export default TransOverdueRecordHis;
