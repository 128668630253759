import { Trans } from '@lingui/macro';
import { Paper, Typography, Grid } from '@mui/material';
import { CompanyListWorking, AttendanceRetail } from 'views/Epayz/Dashboard/Employee/components';

const DashboardEmployee = () => {
  return (
    <Paper>
      <div className='p-3'>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Typography variant='h4'>
              <Trans>LIST OF WORKING COMPANY</Trans>
            </Typography>
            <CompanyListWorking />
          </Grid>
          <Grid item sm={12}>
            <Typography variant='h4'>
              <Trans>ATTENDANCE RETAIL</Trans>
            </Typography>
            <AttendanceRetail />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default DashboardEmployee;
