import { t, Trans } from '@lingui/macro';
import { Paper, Tab, Tabs, Typography } from '@mui/material';
import { useTabs } from 'hooks';
import { useQuery } from 'react-query';
import { employeeService } from 'services';
import { EmployeeAll, EmployeeOnBoard } from 'views/Employee/EmployeeList/components';

const Employee = () => {
  const { data: all } = useQuery(['employeeService.fetchEmployees'], () => employeeService.fetchEmployees());
  const { data: onboard } = useQuery(
    ['employeeService.fetchEmployees', { statuses: 'ONBOARDED,BLACKLISTED', size: 1 }],
    () => employeeService.fetchEmployees({ statuses: 'ONBOARDED,BLACKLISTED', size: 1 }),
  );

  const tabs = [
    { code: 'all', label: t`ALL (${all?.paginate.totalRecord ?? 0})`, component: <EmployeeAll /> },
    { code: 'onboard', label: t`ONBOARDED (${onboard?.paginate.totalRecord ?? 0})`, component: <EmployeeOnBoard /> },
  ];
  const [activeTab, onChangeTab] = useTabs(tabs);

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Employee Manager</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Tabs value={activeTab} onChange={onChangeTab}>
          {tabs.map((tab) => (
            <Tab key={tab.code} label={tab.label} value={tab.code} />
          ))}
        </Tabs>
        {tabs.map((tab) => (
          <div key={tab.code} hidden={tab.code !== activeTab} className='mt-4'>
            {tab.component}
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default Employee;
