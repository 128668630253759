import { Trans } from '@lingui/macro';
import { DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import PdfViewer from 'components/PdfViewer';
import { EnterpriseServiceFeeNoticeType } from 'types/EnterpriseSetup';

type PopupProps = {
  onClose: () => void;
  noticeCreate: EnterpriseServiceFeeNoticeType;
};

const FeeNoticeView = ({ noticeCreate, onClose }: PopupProps) => {
  return (
    <>
      <DialogTitle>
        <Trans>NOTICE OF SINGLE FEE PAYMENT</Trans>
      </DialogTitle>
      <DialogContent>
        <PdfViewer objectKey={noticeCreate?.object?.key} />
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </>
  );
};

export default FeeNoticeView;
