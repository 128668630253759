import { Grid, Paper, Typography, TextField, Button, ButtonGroup } from '@mui/material';
import { formatNumber } from 'utils/common';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Trans, t } from '@lingui/macro';
import { DesktopDatePicker } from '@mui/lab';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { transactionService } from 'services';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { useState } from 'react';

const BusinessResult = () => {
  const curYear = DateTime.now().year;
  const [year, setYear] = useState(curYear);
  const [fromDate, setFromDate] = useState<DateTime | null>(DateTime.now().plus({ days: 1 - DateTime.now().day }));
  const [toDate, setToDate] = useState<DateTime | null>(DateTime.now());
  const { data: statistic } = useQuery(
    ['transactionService.statisticTransYear', { year }],
    () => transactionService.statisticTransYear({ year }),
    { keepPreviousData: true },
  );
  const data = statistic?.months.map((item) => ({ ...item, name: item.month + '/' + item.year }));

  const { data: trans } = useQuery(
    ['transactionService.statisticTrans', { dateFrom: fromDate ? fromDate.toISO() : '', dateTo: toDate ? toDate.toISO() : '' }],
    () => transactionService.statisticTrans({ dateFrom: fromDate ? fromDate.toISO() : '', dateTo: toDate ? toDate.toISO() : '' }),
    { keepPreviousData: true },
  );

  const { data: allTrans } = useQuery(
    ['transactionService.statisticTrans', { dateFrom: '', dateTo: '' }],
    () => transactionService.statisticTrans({ dateFrom: '', dateTo: '' }),
    { keepPreviousData: false },
  );
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h4'>
          <Trans>KẾT QUẢ HOẠT ĐỘNG KINH DOANH</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <ButtonGroup variant='contained' size='small'>
              <Button onClick={() => setYear((v) => v - 1)}>
                <NavigateBefore />
              </Button>
              <Button disabled={year === curYear} onClick={() => setYear(curYear)}>
                {curYear}
              </Button>
              <Button onClick={() => setYear((v) => v + 1)}>
                <NavigateNext />
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item sm={9}>
            <Paper elevation={0} className='w-full' style={{ height: '400px' }}>
              <ResponsiveContainer>
                <ComposedChart
                  width={500}
                  height={400}
                  data={data}
                  margin={{
                    top: 20,
                    right: 0,
                    bottom: 20,
                    left: 40,
                  }}
                >
                  <CartesianGrid stroke='#f5f5f5' />
                  <XAxis dataKey='name' scale='band' />
                  <YAxis yAxisId='left' tickFormatter={formatNumber} />
                  <YAxis yAxisId='right' tickFormatter={formatNumber} orientation='right' />
                  <Tooltip formatter={formatNumber} />
                  <Legend />
                  <Bar yAxisId='left' dataKey='totalRequestAmount' name={t`Giá trị Giao dịch`} barSize={20} fill='#354155' />
                  <Bar yAxisId='left' dataKey='averageAmount' name={t`Số dư Bình quân`} barSize={20} fill='#0070c0' />
                  <Line type='monotone' yAxisId='right' dataKey='numOfTrans' name={t`Số Giao dịch`} stroke='#25bec3' />
                </ComposedChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item sm={3} className='text-center p-0'>
            <Typography variant='h4'>
              <Trans>TỔNG KẾT</Trans>
            </Typography>
            <div className='flex justify-between my-4'>
              <Typography variant='body2'><Trans>Số Giao dịch</Trans></Typography>
              <Typography variant='subtitle1'>{formatNumber(allTrans?.numOfTrans)}</Typography>
            </div>
            <div className='flex justify-between my-4'>
              <Typography variant='body2'><Trans>Giá trị Giao dịch (VND)</Trans></Typography>
              <Typography variant='subtitle1'>{formatNumber(allTrans?.totalRequestAmount)}</Typography>
            </div>
            <div className='flex justify-between my-4'>
              <Typography variant='body2'><Trans>Số dư Bình quân (VND)</Trans></Typography>
              <Typography variant='subtitle1'>{formatNumber(allTrans?.averageAmount)}</Typography>
            </div>
            <div className='border p-2'>
              <Typography variant='h5' className='text-left'>
                Tìm kiếm
              </Typography>
              <div className='mt-4 space-x-4 flex items-center'>
                <DesktopDatePicker
                  value={fromDate}
                  onChange={(value) => {
                    setFromDate(value);
                  }}
                  renderInput={(props) => <TextField {...props} />}
                  label={t`From`}
                  inputFormat='dd/MM/yyyy'
                />
                <DesktopDatePicker
                  value={toDate}
                  onChange={(value) => {
                    setToDate(value);
                  }}
                  renderInput={(props) => <TextField {...props} />}
                  label={t`To`}
                  inputFormat='dd/MM/yyyy'
                />
              </div>
              <div className='flex justify-between my-4'>
                <Typography variant='body2'><Trans>Số Giao dịch</Trans></Typography>
                <Typography variant='subtitle1'>{formatNumber(trans?.numOfTrans)}</Typography>
              </div>
              <div className='flex justify-between my-4'>
                <Typography variant='body2'><Trans>Giá trị Giao dịch (VND)</Trans></Typography>
                <Typography variant='subtitle1'>{formatNumber(trans?.totalRequestAmount)}</Typography>
              </div>
              <div className='flex justify-between my-4'>
                <Typography variant='body2'><Trans>Số dư Bình quân (VND)</Trans></Typography>
                <Typography variant='subtitle1'>{formatNumber(trans?.averageAmount)}</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default BusinessResult;
