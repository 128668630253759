import React from 'react';
import { useQuery } from 'react-query';
import { Trans } from '@lingui/macro';
import { Typography, Paper } from '@mui/material';
import TransHisList from './components/TransHisList';
import { transactionService } from 'services';
import TransSummary from 'views/Transaction/Employee/components/TransSummary';

const EmployeeTransHis = () => {
  const { data } = useQuery(
    ['transactionService.fetchTransactions', { size: 1, sum: 1 }],
    () => transactionService.fetchTransactions({ size: 1, sum: 1 }),
    { keepPreviousData: true },
  );

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Transaction History</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <div className='flex justify-between'>
          <div className='flex items-center space-x-3'>
            <Typography variant='h4'>
              <Trans>Summary Information</Trans>
            </Typography>
          </div>
        </div>
        <div className='mt-6'>
          <TransSummary summary={data?.summary} />
        </div>
        <div className='mt-6'>
          <div className='flex justify-between'>
            <div className='flex items-center space-x-3'>
              <Typography variant='h4'>
                <Trans>Transaction List</Trans>
              </Typography>
            </div>
          </div>
          <div className='mt-6'>
            <TransHisList />
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default EmployeeTransHis;
