import { t, Trans } from '@lingui/macro';
import { ArrowRight } from '@mui/icons-material';
import { Button, Dialog, Paper, Typography } from '@mui/material';
import { getContractState } from 'constants/contract';
import { usePermission } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { employeeService } from 'services';
import { ContractType } from 'types/EmployeeContract';
import {
  ContractApprovePopup,
  ContractCommitCreatePopup,
  ContractCreatePopup,
  ContractHistory,
  ContractPartnerSignPopup,
  ContractSignPopup,
  ContractUploadPopup,
  ContractViewPopup,
} from 'views/Employee/EmployeeContract/components';

const EmployeeContract = () => {
  const { requireRole, requreUserPermission, EMPLOYEE_CONTRACT, EMPLOYEE_CONTRACT_ADD, EMPLOYEE_CONTRACT_APPROVE, EMPLOYEE_CONTRACT_SIGN } = usePermission();
  const { id: _id } = useParams() as { id: string };

  const { data, isSuccess } = useQuery(['employeeService.getEmployeeLatest', { _id }], () =>
    employeeService.getEmployeeLatest({ _id }),
  );

  const [isOpen, setOpen] = useState(false);
  const [isOpenCreate, setOpenCreate] = useState(false);
  const [chosenContract, setChosenContract] = useState<ContractType>();
  const { state = 'MISSING' } = chosenContract ?? {};

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Employee Contract</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        {[
          { label: t`Master Contract`, contract: data?.mASTERCONTRACT, type: 'MASTER_CONTRACT' },
          { label: t`Commitment Agreement`, contract: data?.cOMMITMENTAGREEMENT, type: 'COMMITMENT_AGREEMENT' },
          { label: "Thỏa thuận giao dịch tự động", contract: data?.aGREEMENTAUTOMATICTRANSACTION, type: 'AGREEMENT_AUTOMATIC_TRANSACTION' },
        ].map(({ label, contract, type }, index) => (
          <div key={index} className='flex items-center justify-between mb-6'>
            <div className='flex items-center space-x-2'>
              <Typography variant='h4'>
                {index + 1}. {label}
              </Typography>
              <ArrowRight />
              <Typography variant='h5'>{getContractState(contract?.state ?? 'MISSING', false)}</Typography>
            </div>

            <div className='flex space-x-2'>
              {[
                { key: 'view', label: t`View`, disabled: !contract },
                {
                  key: 'create',
                  label: t`Create`,
                  disabled:
                    (contract?.state !== 'COMPLETED' && contract?.state !== 'OUTDATED' && !!contract) ||
                    (!requireRole('CRM') && !requireRole('CRM_SENIOR')) ||
                    (type === 'COMMITMENT_AGREEMENT' && !data?.mASTERCONTRACT?.ready) || 
                    (type === 'AGREEMENT_AUTOMATIC_TRANSACTION' && !data?.mASTERCONTRACT?.ready),
                },
              ].map((item, index) => (
                <Button
                  key={index}
                  variant='outlined'
                  size='small'
                  disabled={item.disabled}
                  onClick={() => {
                    if (item.key === 'create' && (contract?.state === 'COMPLETED' || contract?.state === 'OUTDATED')) {
                      setOpenCreate(true);
                      setOpen(false);
                    } else {
                      setOpen(true);
                      setOpenCreate(false);
                    }
                    setChosenContract({ ...contract, type } as ContractType | undefined);
                  }}
                >
                  {item.label}
                </Button>
              ))}
            </div>
          </div>
        ))}

        {isSuccess && (
          <Dialog fullWidth maxWidth='lg' open={isOpen}>
            {['MISSING'].includes(state) && requreUserPermission([EMPLOYEE_CONTRACT_ADD]) &&
              (chosenContract?.type === 'COMMITMENT_AGREEMENT' ? (
                <ContractCommitCreatePopup contract={chosenContract!} employeeId={_id} onClose={() => setOpen(false)} />
              ) : (
                <ContractCreatePopup contract={chosenContract!} employeeId={_id} onClose={() => setOpen(false)} />
              ))}
            {['WAITING_DOC'].includes(state) && requreUserPermission([EMPLOYEE_CONTRACT_ADD]) && (
              <ContractUploadPopup contract={chosenContract!} employeeId={_id} onClose={() => setOpen(false)} />
            )}
            {['REVIEWING'].includes(state) && requreUserPermission([EMPLOYEE_CONTRACT_APPROVE]) && (
              <ContractApprovePopup contract={chosenContract!} employeeId={_id} onClose={() => setOpen(false)} />
            )}
            {['WAITING_SIGN'].includes(state) && requreUserPermission([EMPLOYEE_CONTRACT_SIGN]) && (
              <ContractSignPopup contract={chosenContract!} employeeId={_id} onClose={() => setOpen(false)} />
            )}
            {['WAITING_PARTNER'].includes(state) && requreUserPermission([EMPLOYEE_CONTRACT_SIGN]) && (
              <ContractPartnerSignPopup contract={chosenContract!} employeeId={_id} onClose={() => setOpen(false)} />
            )}
            {['COMPLETED'].includes(state) && requreUserPermission([EMPLOYEE_CONTRACT]) && (
              <ContractViewPopup contract={chosenContract!} employeeId={_id} onClose={() => setOpen(false)} />
            )}
          </Dialog>
        )}
        {isSuccess && (
          <Dialog fullWidth maxWidth='lg' open={isOpenCreate}>
            {['COMPLETED', 'OUTDATED'].includes(state) && requreUserPermission([EMPLOYEE_CONTRACT_ADD]) &&
              (chosenContract?.type === 'COMMITMENT_AGREEMENT' ? (
                <ContractCommitCreatePopup
                  contract={chosenContract!}
                  employeeId={_id}
                  onClose={() => setOpenCreate(false)}
                />
              ) : (
                <ContractCreatePopup contract={chosenContract!} employeeId={_id} onClose={() => setOpenCreate(false)} />
              ))}
          </Dialog>
        )}

        <ContractHistory />
      </div>
    </Paper>
  );
};

export default EmployeeContract;
