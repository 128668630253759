import { t, Trans } from '@lingui/macro';
import { EmailRounded, Lock, Person, PhoneRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { InputAdornment, Link as MuiLink, Paper, TextField, Typography } from '@mui/material';
import { KeyboardEvent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { signIn, signOut } from 'reducers/profile';
import { store } from 'reducers';
import { openAlert } from 'reducers/notification';
import { staticRoute } from 'routes';
import { authService, userService } from 'services';
import { sha256 } from 'utils/common';

const Login = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const { mutate: login, isLoading } = useMutation(authService.login, {
    onSuccess: ({ data }) => {
      dispatch(signIn(data));
      userService.getProfile().then((profile) => {
        if (!profile) {
          store.dispatch(
            openAlert({
              message: t`Username or password is incorrect`,
              code: 400,
              variant: 'error',
            }),
          );
          dispatch(signOut());
          return;
        }
        dispatch(signIn(profile));
      });
    },
    onError: () => {
      store.dispatch(
        openAlert({
          message: t`Username or password is incorrect`,
          code: 400,
          variant: 'error',
        }),
      );
    },
  });

  const handleClickLogin = () => {
    handleSubmit(({ username, password }) => {
      login({
        username,
        password: sha256(password),
      });
    })();
  };

  const onKeyPressPassword = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleClickLogin();
    }
  };

  return (
    <Paper className='bg-white/90 w-[440px] rounded-lg p-10'>
      <Typography variant='h2' align='center'>
        <Trans>PLATFORM LOGIN</Trans>
      </Typography>
      <div className='space-y-8 my-8'>
        <Controller
          name='username'
          control={control}
          rules={{ required: t`Username is required` }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              fullWidth
              size='medium'
              placeholder={t`Username`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Person />
                  </InputAdornment>
                ),
                className: 'bg-white',
              }}
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name='password'
          control={control}
          rules={{ required: t`Password is required` }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              fullWidth
              size='medium'
              placeholder={t`Password`}
              type='password'
              onKeyPress={onKeyPressPassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Lock />
                  </InputAdornment>
                ),
                className: 'bg-white',
              }}
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
      </div>

      <div className='flex justify-between items-center'>
        <Link to={staticRoute.forgotPassword.path}>
          <span className='italic hover:underline'>
            <Trans>Forgot password?</Trans>
          </span>
        </Link>
        <LoadingButton variant='contained' color='info' className='w-40' loading={isLoading} onClick={handleClickLogin}>
          <Trans>LOGIN</Trans>
        </LoadingButton>
      </div>

      <div className='flex justify-between mt-10'>
        <div className='flex items-center gap-1'>
          <PhoneRounded />
          <MuiLink href='tel:0869121232'>0869 121 232</MuiLink>
        </div>
        <div className='flex items-center gap-1'>
          <EmailRounded />
          <MuiLink href='mailto:support@scp.com.vn'>support@scp.com.vn</MuiLink>
        </div>
      </div>
    </Paper>
  );
};

export default Login;
