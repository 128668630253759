import { Trans } from '@lingui/macro';
import {
  Button,
  Checkbox,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import { useSearch, usePermission } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { creditScoreService } from 'services';
import { CreditScoreType } from 'types/CreditScore';
import { CreditScoreAssignmentPopup, CreditScoreCreatePopup } from './popups';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { DateTime } from 'luxon';
import { formatNumber } from 'utils/common';
import SelectPageSize from 'components/SelectPageSize';

const CreditScore = () => {
  const { requireUserPCManager, requreUserPermission, DIEMTINDUNG_THEMNGANH, DIEMTINDUNG_PHANQUYEN, DIEMTINDUNG_DANHSACH, DIEMTINDUNG_XEM } = usePermission();
  const userLocale = useActiveLocale();
  const [dataSearch, onSearchChange] = useSearch();
  const [dataSelect, setDataSelect] = useState<CreditScoreType[]>([]);

  const [isOpenAssign, setOpenAssign] = useState(false);
  const [isOpenCreate, setOpenCreate] = useState(false);

  const { data } = useQuery(
    ['creditScoreService.fetchCreditScores', dataSearch],
    () => creditScoreService.fetchCreditScores(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Credit Scoring System</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        {requireUserPCManager() && (
          <div className='flex justify-between'>
            <div className='flex items-center space-x-3'>
              {requreUserPermission([DIEMTINDUNG_THEMNGANH]) && (<Button variant='contained' onClick={() => setOpenCreate(true)}>
                <Trans>Add Industry</Trans>
              </Button>)}
              {requreUserPermission([DIEMTINDUNG_PHANQUYEN]) && (<Button variant='contained' disabled={dataSelect.length === 0} onClick={() => setOpenAssign(true)}>
                <Trans>Assignment</Trans>
              </Button>)}
            </div>
          </div>
        )}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}></TableCell>
                <TableCell rowSpan={2}>
                  <Trans>Setup Date</Trans>
                </TableCell>
                <TableCell rowSpan={2}>
                  <Trans>Industry Name</Trans>
                </TableCell>
                <TableCell colSpan={2}>
                  <Trans>Assessment Proportion</Trans>
                </TableCell>
                <TableCell rowSpan={2}>
                  <Trans>No. of Applied Enterprise</Trans>
                </TableCell>
                <TableCell rowSpan={2}>
                  <Trans>Status</Trans>
                </TableCell>
                <TableCell rowSpan={2}>
                  <Trans>Executor</Trans>
                </TableCell>
                <TableCell rowSpan={2}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Trans>Quantitative Criteria</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Qualitative Criteria</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            {requreUserPermission([DIEMTINDUNG_DANHSACH]) && (<TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='p-1'>
                    <Checkbox
                      checked={dataSelect.some((next) => next.id === item.id)}
                      onChange={(event, checked) => {
                        if (checked) setDataSelect((nexts) => nexts.concat(item));
                        else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                      }}
                    />
                  </TableCell>
                  <TableCell align='center'>
                    {item.createdAt ? DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy') : ''}
                  </TableCell>
                  <TableCell>{userLocale === 'vi-VN' ? item.industryVi : item.industryEn}</TableCell>
                  <TableCell align='right'>{formatNumber(item.quantitativeProportion)}</TableCell>
                  <TableCell align='right'>{formatNumber(item.qualitativeProportion)}</TableCell>
                  <TableCell align='right'>{formatNumber(item.numOfEnterprise)}</TableCell>
                  <TableCell className='text-center'>
                    {item.status === 'unfinished' ? (
                      <Trans>Unfinished</Trans>
                    ) : item.status === 'out-of-date' ? (
                      <Trans>Expired</Trans>
                    ) : (
                      <Trans>Finished</Trans>
                    )}
                  </TableCell>
                  <TableCell>{item.executor?.fullname}</TableCell>
                  <TableCell className='text-center'>
                    {requreUserPermission([DIEMTINDUNG_XEM]) && (<Link to={privateRoute.creditScoreView.url?.(item)!}>
                      <Button size='small' color='info'>
                        <Trans>View</Trans>
                      </Button>
                    </Link>)}
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>)}
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>

        <Dialog fullWidth maxWidth='md' open={isOpenAssign} onClose={() => setOpenAssign(false)}>
          <CreditScoreAssignmentPopup
            creditScoreIds={dataSelect.map((item) => item.id)}
            onSuccess={() => setDataSelect([])}
            onClose={() => setOpenAssign(false)}
          />
        </Dialog>

        <Dialog fullWidth maxWidth='xs' open={isOpenCreate} onClose={() => setOpenCreate(false)}>
          <CreditScoreCreatePopup onClose={() => setOpenCreate(false)} />
        </Dialog>
      </div>
    </Paper>
  );
};

export default CreditScore;
