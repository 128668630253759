import { t, Trans } from '@lingui/macro';
import { Grid, TextField, Typography } from '@mui/material';
import { getGender } from 'constants/common';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { EmployeeType } from 'types/Employee';

const EmployeeViewInfo = ({ employee }: { employee?: EmployeeType }) => {
  const { control } = useForm({ mode: 'onChange' });

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Employee Information</Trans>
          </Typography>
        </div>
      </div>

      <Grid container columnSpacing={4} rowSpacing={3} className='mt-3'>
        <Grid item sm={3}>
          <Controller
            name='name'
            defaultValue={employee?.name}
            control={control}
            rules={{ required: t`Employee Name is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Employee Name`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='birthday'
            defaultValue={employee?.birthday ? DateTime.fromISO(employee?.birthday).toFormat('dd/MM/yyyy') : ''}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Birthday`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='gender'
            defaultValue={getGender(employee?.gender)}
            control={control}
            rules={{ required: t`Gender is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth required label={t`Gender`} error={invalid} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item sm={3}></Grid>

        <Grid item sm={3}>
          <Controller
            name='ercNumber'
            defaultValue={employee?.ercNumber}
            control={control}
            rules={{ required: t`ID Card / Passport is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`ID Card / Passport`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='firstGrantedDate'
            defaultValue={
              employee?.firstGrantedDate ? DateTime.fromISO(employee?.firstGrantedDate).toFormat('dd/MM/yyyy') : ''
            }
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Issuance Date`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='issuanceAgency'
            defaultValue={employee?.issuanceAgency}
            control={control}
            rules={{ required: t`Issuance Agency is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Issuance Agency`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}></Grid>

        <Grid item sm={6}>
          <Controller
            name='address'
            defaultValue={employee?.address}
            control={control}
            rules={{ required: t`Address is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Address`}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='phone'
            defaultValue={employee?.phone}
            control={control}
            rules={{ required: t`Phone is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Phone`}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='email'
            defaultValue={employee?.email}
            control={control}
            rules={{ required: t`Email is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                InputProps={{ readOnly: true }}
                label='Email'
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name='contactAddress'
            defaultValue={employee?.contactAddress}
            control={control}
            rules={{ required: t`Contact Address is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Contact Address`}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='registrationNo'
            defaultValue={employee?.registrationNo}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Registration Book No`}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='socialInsuranceNo'
            defaultValue={employee?.socialInsuranceNo}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Social Insurance Book No`}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EmployeeViewInfo;
