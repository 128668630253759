import { t, Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { InputNumber, TableEmpty, TooltipLight, InputNumberNegative } from 'components';
import { Fragment, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { creditScoreService, enterpriseSetupService, queryClient } from 'services';
import { CriteriaScoreViewTable } from 'views/GeneralSetup/CreditScore/popups';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { IS_EPAYZ } from 'env';
type PopupProps = {
  id: number;
  onSelect: (args: any) => void;
  onCreate: (args: any) => void;
  onBack: () => void;
  onNext: () => void;
};

const BusinessCreditScoreStep1 = ({ id, onSelect, onCreate, onBack, onNext }: PopupProps) => {
  const userLocale = useActiveLocale();
  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });
  const values = watch();
  const [idsave, changeidsave] = useState(0)
  const [dataSearch] = useState({ status: 'finished', size: 100 });
  const [creditScoreId, setCreditScoreId] = useState('');
  console.log(creditScoreId);
  const { data } = useQuery(['creditScoreService.fetchCreditScores', { dataSearch }], () =>
    creditScoreService.fetchCreditScores(dataSearch),
  );
  const { data: businessSectors = [] } = data ?? {};

  // const [creditScore, changecreditScore] = useState({} as CreditScoreType);

  const { data: creditScore } = useQuery(
    ['creditScoreService.getCreditScoreById', { id: creditScoreId }],
    () => creditScoreService.getCreditScoreById({ id: creditScoreId }),
    {
      enabled: !!creditScoreId,
      onSuccess: (data) => {
        console.log(data);
        onSelect(data);
      },
    },
  );

  const newChangeCombobox = () =>{
    queryClient.invalidateQueries('creditScoreService.getCreditScoreById');
  }

  // useEffect(() => {

  //   const { data: creditScore } = useQuery(
  //   ['creditScoreService.getCreditScoreById', { id: creditScoreId }],
  //   () => creditScoreService.getCreditScoreById({ id: creditScoreId }),
  //   {
  //     enabled: !!creditScoreId,
  //     onSuccess: (data) => {
  //       console.log(data);
  //       changecreditScore(data);
  //       onSelect(data);
  //     },
  //   },
  // );

  // }, [creditScoreId]);

  const {
    mutate: createCreditScore,
    isLoading,
    isSuccess: isCreated,
  } = useMutation(enterpriseSetupService.createCreditScore, {
    onSuccess: (data) => {
      changeidsave(data.id);
      onCreate(data);
      onNext();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      createCreditScore({
        id,
        idsave,
        creditScoringId: creditScoreId,
        qualitatives: creditScore?.qualitative?.map((parameter) => ({
          parameterId: parameter.id,
          criteriaRatios: parameter.criterias?.map((criteria) => ({
            criteriaRatioId: criteria.id,
            score: +values[`qualitative-parameter-${parameter.id}-criteria-${criteria.id}`],
          })),
        })),
        quantitatives: creditScore?.quantitative?.map((parameter) => ({
          parameterId: parameter.id,
          criteriaRatios: parameter.ratios?.map((criteria) => {
            const ratio = values[`quantitative-parameter-${parameter.id}-criteria-${criteria.id}`];
            const score =
              criteria.quantitativeScorecards?.find(
                (card) => Number(ratio) >= card.minValue && Number(ratio) <= card.maxValue,
              )?.score || 0;
            return {
              criteriaRatioId: criteria.id,
              ratio,
              score,
            };
          }),
        })),
      });
    })();
  };

  return (
    <>
      <DialogContent>
        <div className='flex mb-4'>
          <TextField
            select
            label={t`Business Sector`}
            value={creditScoreId}
            onChange={(event) => {setCreditScoreId(event.target.value); newChangeCombobox()}}
            className='w-[240px]'
          >
            {businessSectors?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {userLocale === 'vi-VN' ? item.industryVi : item.industryEn}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <Stepper activeStep={1} className='mb-6'>
          <Step>
            <StepLabel>
              <Trans>Credit Scoring</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Input Assessment Information</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Assessment Result</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Approval Decision By Investment Committee</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        <Grid container columnSpacing={4}>
          <Grid item md={6}>
            <Typography variant='h4' className='text-center'>
              <Trans>Qualitative Criteria</Trans>:{' '}
              <span className='text-red-700'>{creditScore?.qualitativeProportion}%</span>
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className='w-10'>
                      <Trans>No</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Parameter</Trans>
                    </TableCell>
                    <TableCell className='w-28'>
                      <Trans>Score</Trans>
                    </TableCell>
                    <TableCell className='w-10'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creditScore?.qualitative?.map((parameter) => (
                    <Fragment key={parameter.id}>
                      <TableRow className='h-12'>
                        <TableCell colSpan={2} className='font-bold'>
                          {userLocale === 'vi-VN' ? parameter.nameVi : parameter.nameEn}
                        </TableCell>
                        <TableCell className='font-bold text-center'>{parameter.proportion}%</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {parameter.criterias?.map((criteria, index) => (
                        <TableRow key={criteria.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{userLocale === 'vi-VN' ? criteria.nameVi : criteria.nameEn}</TableCell>
                          <TableCell>
                            <Controller
                              name={`qualitative-parameter-${parameter.id}-criteria-${criteria.id}`}
                              defaultValue=''
                              control={control}
                              rules={{ required: true, min: criteria.minScore, max: criteria.maxScore }}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <TextField
                                  {...field}
                                  error={invalid}
                                  InputProps={{
                                    inputComponent: InputNumberNegative as any,
                                    inputProps: { className: 'text-center' },
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell className='text-right'>
                            <TooltipLight
                              className={`max-w-[800px] min-w-[300px] ${IS_EPAYZ ? 'epayz' : 'admin'}`}
                              title={<CriteriaScoreViewTable criteriaScore={criteria} />}
                            >
                              <Button size='small' color='info'>
                                <Trans>View</Trans>
                              </Button>
                            </TooltipLight>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Fragment>
                  ))}
                  <TableEmpty data={creditScore?.qualitative} />
                </TableBody>
                <TableFooter className='bg-gray-200'>
                  <TableRow>
                    <TableCell colSpan={2} className='font-bold text-sm'>
                      <Trans>Total score by Qualitative Criteria</Trans>
                    </TableCell>
                    <TableCell className='font-bold text-sm text-center'>
                      {creditScore?.qualitative
                        ?.reduce(
                          (average, parameter) =>
                            average +
                            (parameter.proportion / 100) *
                              (parameter.criterias?.reduce(
                                (sum, criteria) =>
                                  sum + (values[`qualitative-parameter-${parameter.id}-criteria-${criteria.id}`] || 0),
                                0,
                              ) ?? 0),
                          0,
                        )
                        .toFixed(2)}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={6}>
            <Typography variant='h4' className='text-center'>
              <Trans>Quantitative Criteria</Trans>:{' '}
              <span className='text-red-700'>{creditScore?.quantitativeProportion}%</span>
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className='w-10'>
                      <Trans>No</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Parameter</Trans>
                    </TableCell>
                    <TableCell className='w-28'>
                      <Trans>Ratio</Trans>
                    </TableCell>
                    <TableCell className='w-10'>
                      <Trans>Score</Trans>
                    </TableCell>
                    <TableCell className='w-10'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creditScore?.quantitative?.map((parameter) => (
                    <Fragment key={parameter.id}>
                      <TableRow className='h-12'>
                        <TableCell colSpan={2} className='font-bold'>
                          {userLocale === 'vi-VN' ? parameter.nameVi : parameter.nameEn}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell className='font-bold text-center'>{parameter.proportion}%</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {parameter.ratios?.map((criteria, index) => (
                        <TableRow key={criteria.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{userLocale === 'vi-VN' ? criteria.nameVi : criteria.nameEn}</TableCell>
                          <TableCell>
                            <Controller
                              name={`quantitative-parameter-${parameter.id}-criteria-${criteria.id}`}
                              defaultValue=''
                              control={control}
                              rules={{
                                required: true,
                                validate: {
                                  inRange: (value) =>
                                    criteria.quantitativeScorecards?.some(
                                      (card) => Number(value) >= card.minValue && Number(value) <= card.maxValue,
                                    ),
                                },
                              }}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <TextField
                                  {...field}
                                  error={invalid}
                                  InputProps={{
                                    inputComponent: InputNumberNegative as any,
                                    inputProps: { className: 'text-center' },
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                          <TableCell className='text-center'>
                            {criteria.quantitativeScorecards?.find((card) => {
                              const ratio = values[`quantitative-parameter-${parameter.id}-criteria-${criteria.id}`];
                              return Number(ratio) >= card.minValue && Number(ratio) <= card.maxValue;
                            })?.score || '-'}
                          </TableCell>
                          <TableCell className='text-right'>
                            <TooltipLight
                              className={`max-w-[800px] min-w-[300px] ${IS_EPAYZ ? 'epayz' : 'admin'}`}
                              title={<CriteriaScoreViewTable criteriaScore={criteria} />}
                            >
                              <Button size='small' color='info'>
                                <Trans>View</Trans>
                              </Button>
                            </TooltipLight>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Fragment>
                  ))}
                  <TableEmpty data={creditScore?.quantitative} />
                </TableBody>
                <TableFooter className='bg-gray-200'>
                  <TableRow>
                    <TableCell colSpan={2} className='font-bold text-sm'>
                      <Trans>Total score by Quantitative Criteria</Trans>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell className='font-bold text-sm text-center'>
                      {creditScore?.quantitative
                        ?.reduce(
                          (average, parameter) =>
                            average +
                            (parameter.proportion / 100) *
                              (parameter.ratios?.reduce((sum, criteria) => {
                                const ratio = values[`quantitative-parameter-${parameter.id}-criteria-${criteria.id}`];
                                const score =
                                  criteria.quantitativeScorecards?.find(
                                    (card) => Number(ratio) >= card.minValue && Number(ratio) <= card.maxValue,
                                  )?.score || 0;
                                return sum + score;
                              }, 0) ?? 0),
                          0,
                        )
                        .toFixed(2)}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className='relative'>
        <LoadingButton variant='outlined' onClick={() => onBack()} className='absolute left-4 w-28'>
          <Trans>Cancel</Trans>
        </LoadingButton>

        {/* {isCreated ? (
          <LoadingButton variant='contained' className='w-28' loading={isLoading} onClick={() => onNext()}>
            <Trans>Next</Trans>
          </LoadingButton>
        ) : (
          <LoadingButton variant='contained' className='w-28' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Create</Trans>
          </LoadingButton>
        )} */}
         <LoadingButton variant='contained' className='w-28' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Create</Trans>
          </LoadingButton>
      </DialogActions>
    </>
  );
};

export default BusinessCreditScoreStep1;
