import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { creditScoreService, queryClient } from 'services';
import { PopupController } from 'types/Common';

type PopupProps = PopupController & {
  csId: number;
  prId: number;
  crId: number;
  id: number;
  type: number;
};

const CriteriaScoreRemovePopup = ({ csId, prId, crId, id, type, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const isQualitative = type === 1;

  const { mutate: removeScore, isLoading } = useMutation(
    isQualitative ? creditScoreService.removeScoreQualitative : creditScoreService.removeScoreQuantitative,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('creditScoreService.getParameterById');
        onClose();
      },
    },
  );

  const handleClickSubmit = () => {
    removeScore({ csId, prId, crId, id });
  };

  return (
    <>
      <DialogTitle>
        <Trans>Remove Score</Trans>
      </DialogTitle>

      <DialogContent>
        <Typography>
          <Trans>Are your sure you want to remove this score?</Trans>
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' color='error' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Remove</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CriteriaScoreRemovePopup;
