import { Trans } from '@lingui/macro';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { getDepartmentName } from 'constants/common';
import { EnterpriseType } from 'types/Enterprise';

const EnterpriseOriginatorTable = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const { createdUser } = enterprise ?? {};

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <Typography variant='h4'>
          <Trans>Information of Enterprise Originator</Trans>
        </Typography>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Department</Trans>
              </TableCell>
              <TableCell>
                <Trans>Role</Trans>
              </TableCell>
              <TableCell>
                <Trans>Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Position</Trans>
              </TableCell>
              <TableCell>
                <Trans>Email</Trans>
              </TableCell>
              <TableCell>
                <Trans>Phone</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{getDepartmentName(createdUser?.department)}</TableCell>
              <TableCell>{createdUser?.roles && createdUser?.roles.length > 0 ? createdUser?.roles[0].key : ''}</TableCell>
              <TableCell>{createdUser?.fullname}</TableCell>
              <TableCell>{createdUser?.position}</TableCell>
              <TableCell>{createdUser?.email}</TableCell>
              <TableCell>{createdUser?.phone}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EnterpriseOriginatorTable;
