import { Trans, t } from '@lingui/macro';

import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';
import { useQuery } from 'react-query';
import { PopupController } from 'types/Common';
import { useStyles } from 'views/Transaction/TransView/components/TransInfo';
import { employeeSetupService } from 'services';
import { formatNumber } from 'utils/common';

type PopupProps = PopupController & {
  employeeId: number;
  enterpriseEmployeeId: number;
  programId: number;
};

const ViewEmployeeProgramPopup = ({ employeeId, enterpriseEmployeeId, programId, onClose }: PopupProps) => {
  const classes = useStyles();

  const { data: program } = useQuery(
    [
      'employeeSetupService.getEmployeeSupportProgram',
      { employeeId: employeeId, enterpriseEmployeeId: enterpriseEmployeeId, programId: programId },
    ],
    () =>
      employeeSetupService.getEmployeeSupportProgram({
        employeeId: employeeId,
        enterpriseEmployeeId: enterpriseEmployeeId,
        programId: programId,
      }),
    {
      keepPreviousData: true,
    },
  );

  return (
    <>
      <DialogTitle>
        <Trans>EMPLOYEE SUPPORT PROGRAM</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cellTitleOne}>
                      <Trans>Enterprise</Trans>
                    </TableCell>
                    <TableCell>{program?.enterpriseName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitleOne}>
                      <Trans>Type</Trans>
                    </TableCell>
                    <TableCell>{program?.isKey === 1 ? t`Key Employee` : t`Regular Employee`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitleOne}>
                      <Trans>Support Limit from Enterprise</Trans>
                    </TableCell>
                    <TableCell>{formatNumber(program?.defaultSupportLimit)} VND</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitleOne}>
                      <Trans>% of Enterprise Support</Trans>
                    </TableCell>
                    <TableCell>{formatNumber(program?.supportRate)} %</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Close</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ViewEmployeeProgramPopup;
