import { Trans } from '@lingui/macro';
import { MenuItem, Select, Button } from '@mui/material';
import { useState } from 'react';
import { PopupController } from 'types/Common';

type PopupProps = PopupController & {
  onNext: (bankCode: string) => void;
};

const ContactPopup = ({ onNext, onClose }: PopupProps) => {

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)
  return (
    <div>
      {/* <DialogTitle>
        <Trans>SELECT BANK TO TRANSFER</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Trans>Please select bank to transfer payment</Trans>
          </Grid>
          <Grid item sm={3} className='mt-1'>
            <Trans>Bank</Trans>
          </Grid>
          <Grid item sm={9}>
            <TextField select fullWidth onChange={(event) => setBankCode(event.target.value)}>
              {banks.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className='relative'>
        <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
          <Trans>Close</Trans>
        </LoadingButton>
        <LoadingButton disabled={bankCode === ''} variant='contained' onClick={handleNext}>
          <Trans>Next</Trans>
        </LoadingButton>
      </DialogActions> */}
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <img src={require('assets/images/ct.jpg')} />
        </div>
        <div style={{ width: '50%', margin: 30, marginLeft: 80 }}>
          <div style={{ fontSize: 35, color: '#000000' }}><Trans>CONTACT US</Trans></div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <div style={{ width: '20%', fontSize: 18 }}><Trans>Name</Trans><span style={{ color: 'red' }}>*</span></div>
            <div style={{ width: '80%' }}><input onFocus={handleFocus} onBlur={handleBlur} type={"text"} style={{ width: '100%', height: "40px", borderWidth: 1, borderColor: "rgb(196 196 196 / 1)", padding: "10px", boxShadow: '0px 0px 4px 4px #DADADA', borderRadius: 5 }} /></div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <div style={{ width: '20%', fontSize: 18 }}><Trans>Email</Trans><span style={{ color: 'red' }}>*</span></div>
            <div style={{ width: '80%' }}><input onFocus={handleFocus} onBlur={handleBlur} type={"text"} style={{ width: '100%', height: "40px", borderWidth: 1, borderColor: "rgb(196 196 196 / 1)", padding: "10px", boxShadow: '0px 0px 4px 4px #DADADA', borderRadius: 5 }} /></div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <div style={{ width: '20%', fontSize: 18 }}><Trans>Phone</Trans><span style={{ color: 'red' }}>*</span></div>
            <div style={{ width: '80%' }}><input onFocus={handleFocus} onBlur={handleBlur} type={"text"} style={{ width: '100%', height: "40px", borderWidth: 1, borderColor: "rgb(196 196 196 / 1)", padding: "10px", boxShadow: '0px 0px 4px 4px #DADADA', borderRadius: 5 }} /></div>
          </div>
          <div style={{ fontSize: 18, marginTop: 20 }}><Trans>What service would you like to concern?</Trans></div>
          <div>
            <Select className='my-4' size='small' style={{ boxShadow: '0px 0px 4px 4px #DADADA' }} fullWidth displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem>
                <Trans>Salary Advance</Trans>
              </MenuItem>
            </Select>
          </div>
          <div style={{ fontSize: 18, marginTop: 15 }}><Trans>You can enter your question below, we will send an answer via email or call for advice as soon as possible.</Trans></div>
          <div style={{ marginTop: 15 }}><textarea style={{ width: '100%', borderWidth: 1, borderColor: "rgb(196 196 196 / 1)", padding: "10px", boxShadow: '0px 0px 4px 4px #DADADA', borderRadius: 5 }} rows={4}></textarea></div>
          <div style={{marginTop:15,textAlign:'end'}}>
            <Button variant='contained' size='small' style={{height:35}} color='info' className='rounded-lg mx-auto'>
              <Trans>Send</Trans>
            </Button>
          </div>
        </div>
      </div>
      <div style={{display:'flex', position:'absolute', bottom: 10, left:'30%'}}>
          <div style={{width:'220px'}}>Hotline: <b>1900 5656 16</b></div>
          <div style={{width:'200px'}}>Zalo: 0888 20 17 17</div>
          <div style={{width:'260px'}}>Email: support.ul@scp.com.vn</div>
        </div>
    </div>
  );
};

export default ContactPopup;
