import { Trans, t } from '@lingui/macro';
import {
  Pagination,
  Grid,
  MenuItem,
  Select,
  Typography,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/lab';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { enterpriseService } from 'services';
import { WorkingPaginateType, AttendanceRetailByIdOutput } from 'types/EmployeeWorking';
import { useSearch } from 'hooks';
import { useParams } from 'react-router-dom';
import { getEmployeeType } from 'constants/employee';
import { useQuery, useMutation } from 'react-query';
import { Calendar } from 'react-calendar';
import 'views/Dashboard/Employee/components/Calendar.css';
import 'views/Dashboard/Employee/components/AttendanceRetail.css';
import { useActiveLocale } from 'hooks/useActiveLocale';
import $ from "jquery";
import { formatNumber } from 'utils/common';
const AttendanceRetail = () => {
  const [value, onChange] = useState([] as Date[]);
  const [valueInfo, onChangeInfo] = useState({} as AttendanceRetailByIdOutput);
  const [date, onChangeDate]= useState(new Date());
  // const { id: _id } = useParams() as { id: string };
  // const [dataSearch, onSearchChange] = useSearch({ _id });
  const userLocale = useActiveLocale(); 
  const [ent, changeEnt] = useState(0)
  const { data } = useQuery(
    ['enterpriseService.getAllSituationOfUsingLimit'],
    () => enterpriseService.getAllSituationOfUsingLimit(),
    { keepPreviousData: false,
      onSuccess: (data) => {
          if(data.data.length > 0) {
            let check = data.data.filter(function(x){
              return x.id === ent
            }).length;
            changeEnt(check === 0 ? data.data[0].id : ent)
            changeValueInfo({ id: (check === 0 ? data.data[0].id : ent), month: date.getMonth() + 1, year: date.getFullYear() });
          }
      },
    },
  );
  const { data: items = [], paginate = { size: 1000, page: 1 }} = data ?? {};

  const { mutate: changeValueInfo, isLoading } = useMutation(enterpriseService.attendanceretail, {
    onSuccess: (data) => {
      $(".react-calendar")
      .find(`.react-calendar__month-view__days__day--weekend`)
      .removeClass("react-calendar__month-view__days__day--weekend")
      onChangeInfo(data);
      if(data.ngayBatDau && data.ngayKetThuc) {
        var lsta = [] as Date[];
        for(var i = 0; i < data.ngayHienTai.length; i++) {
          lsta.push(new Date(data.ngayHienTai[i]));
        }
        onChange(lsta);
      }else{
        onChange([]);
      }
    },
  });

  setTimeout(() => {
    $.each($(".react-calendar").find(`.react-calendar__month-view__weekdays__weekday`),function(i,val){
      var datatitle = $(this).find("abbr").attr("title")
      $(this).find("abbr").text(datatitle ? datatitle : '');
    })
    //handleRefresh();
  })

  const handleRefresh = (e:any) =>{
    if(e.action == 'next' || e.action == 'prev' || (e.action == 'drillDown' && e.view == 'month')){
      onChangeDate(e.activeStartDate);
      changeValueInfo({ id: ent, month: e.activeStartDate.getMonth() + 1, year: e.activeStartDate.getFullYear() });
    }
    // if($(".react-calendar").find(".react-calendar-first-day-tinhcong").length == 0){
    //   $(".react-calendar")
    //   .find(`button[class="react-calendar__tile react-calendar__tile--now react-calendar__tile--active react-calendar__tile--range react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day"]`)
    //   .attr("class", "react-calendar__tile react-calendar__month-view__days__day")
    //   $(".react-calendar").find(`.react-calendar__month-view__days__day--weekend`).removeClass("react-calendar__month-view__days__day--weekend");
    //   var length = $(".react-calendar").find(`button[class="react-calendar__tile react-calendar__month-view__days__day"]`).length;
    //   $(".react-calendar").find(`button[class="react-calendar__tile react-calendar__month-view__days__day"]`).eq(length - 1).addClass("react-calendar-first-day-tinhcong");
    //   $(".react-calendar").find(`button[class="react-calendar__tile react-calendar__month-view__days__day"]`).eq(0).addClass("react-calendar-first-day-tinhcong");  
    //   $.each($(".react-calendar").find(`button[class="react-calendar__tile react-calendar__month-view__days__day"]`), function(i,val){
    //     $(this).addClass("react-calendar-first-day-tinhcong-child");
    //   })  
    // }
  }

  const handleChangeMonth = () => {
    setTimeout(() => {
      $.each($(".react-calendar").find(`.react-calendar__month-view__weekdays__weekday`),function(i,val){
        var datatitle = $(this).find("abbr").attr("title")
        $(this).find("abbr").text(datatitle ? datatitle : '');
      })
    })
  }

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h4'>
          <Trans>CHI TIẾT BẢNG CÔNG</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Grid container columnSpacing={4} rowSpacing={3}>
          <Grid item sm={9}>
            <Calendar onActiveStartDateChange={handleRefresh} onClickMonth={handleChangeMonth} prevLabel={"▶"} nextLabel={"▶"} navigationLabel={({ date, label, locale, view }) => (userLocale == 'vi-VN' ? `Tháng ${date.getMonth() + 1},${date.getFullYear()}` : `Month ${date.getMonth() + 1},${date.getFullYear()}`) } locale={userLocale} value={value} />
            <p className='calendarInfoUpdate'>{ (userLocale == 'vi-VN' ? `Dữ liệu được cập nhật lần cuối ngày ` : `The data was last updated on `) + `${valueInfo.ngayCapNhat ? DateTime.fromISO(valueInfo.ngayCapNhat).toFormat('dd/MM/yyyy') : ''}` }</p>
          </Grid>
          <Grid item sm={3}>
            <Typography variant='subtitle1'>
              <Trans>Doanh nghiệp hiện tại</Trans>
            </Typography>
            <Select className='my-4' size='small' fullWidth displayEmpty inputProps={{ 'aria-label': 'Without label' }}
                value={ent}
                onChange={(e:any)=>{ changeEnt(e.target.value); changeValueInfo({ id: e.target.value, month: date.getMonth() + 1, year: date.getFullYear() }); }}
            >
              {items.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <Trans>{item.enterprise.name}</Trans>
                </MenuItem>
              ))}
            </Select>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                    <TableRow key={"tongcong"}>
                      <TableCell className='bg-primary-dark text-white' style={{ fontSize: 13 }}>{userLocale == 'vi-VN' ? "TỔNG CÔNG DỰ KIẾN" : "TOTAL PROJECTS"}</TableCell>
                      <TableCell className='text-center' style={{ fontSize: 13,fontWeight: 600 }}>{formatNumber(valueInfo.tongCongDuKien)}</TableCell>
                    </TableRow>
                    <TableRow key={"tongcong1"}>
                      <TableCell className='bg-primary-dark text-white' style={{ fontSize: 13 }}>{userLocale == 'vi-VN' ? "TỔNG CÔNG ĐÃ THỰC HIỆN" : "TOTAL TO PERFORMED"}</TableCell>
                      <TableCell className='text-center' style={{ fontSize: 13,fontWeight: 600,color: '#0f5099' }}>{formatNumber(valueInfo.tongCongDaThucHien)}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default AttendanceRetail;
