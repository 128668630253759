import { useState } from 'react';
import { PopupController } from 'types/Common';
import {
  AttendanceUpdateStep1,
  AttendanceUpdateEmployee,
  AttendanceUpdatePlatform,
  AttendanceUpdateEnterprise,
} from 'views/Attendance/popups';

type PopupProps = PopupController & {};

const AttendanceUpdatePopup = ({ onClose }: PopupProps) => {
  const [step, setStep] = useState(1);
  const [value, setValue] = useState('');

  return (
    <>
      {step === 1 && (
        <AttendanceUpdateStep1 value={value} setValue={setValue} onBack={() => onClose()} onNext={() => setStep(2)} />
      )}
      {step === 2 && value === 'single' && (
        <AttendanceUpdateEmployee setValue={setValue} onBack={() => setStep(1)} onNext={() => onClose()} />
      )}
      {step === 2 && value === 'platform' && (
        <AttendanceUpdatePlatform
          value={value}
          setValue={setValue}
          onBack={() => setStep(1)}
          onNext={() => onClose()}
        />
      )}
      {step === 2 && value === 'enterprise' && (
        <AttendanceUpdateEnterprise value={value} setValue={setValue} onBack={() => setStep(1)} onNext={() => {}} />
      )}
    </>
  );
};

export default AttendanceUpdatePopup;
