import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { InputNumber } from 'components';
import { formatNumber } from 'utils/common';
import { useQuery, useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { DesktopDatePicker } from '@mui/lab';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { AppendixPayloadType } from 'types/EnterpriseSetup';
import { useEffect, useState } from 'react';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseId: string;
  setObjectData: (args: any) => void;
  appendixPayload: AppendixPayloadType;
  setAppendixPayload: (args: any) => void;
};

const FixedServicePopupStep2 = ({
  enterpriseId,
  appendixPayload,
  setAppendixPayload,
  setObjectData,
  onBack,
  onNext,
}: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, watch, setValue } = useForm({ mode: 'onChange' });

  const times = [
    { num: 1, label: t`1 time` },
    { num: 2, label: t`2 times` },
    { num: 4, label: t`4 times` },
  ];

  const { discountRate, fixedFee, startValidityTime, numbOfPayment } = watch();

  const [tempAppendix, setTempAppendix] = useState<AppendixPayloadType>();

  const { data: { serviceTax, banks } = {} } = useQuery(
    ['enterpriseSetupService.getEnterpriseServiceFeeCategoryInfo', { id: enterpriseId }],
    () =>
      enterpriseSetupService.getEnterpriseServiceFeeCategoryInfo({
        id: enterpriseId,
        appliedFee: 'FIXED_SERVICE_FEE',
      }),
  );

  const { mutate: generateFileAppendix, isLoading } = useMutation(enterpriseSetupService.generateFileAppendix, {
    onSuccess: (data) => {
      enqueueSnackbar(t`Successful`);
      setAppendixPayload(tempAppendix);
      setObjectData(data);
      onNext();
      queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseServiceFeeHistories');
    },
  });

  useEffect(() => {
    Object.entries(appendixPayload ?? {}).forEach(([key, value]) => {
      if (value && ((key === 'startValidityTime') || (key === 'endValidityTime') || key.includes('-payment-date-')) && (typeof value === 'string')) {
        setValue(key, DateTime.fromISO(value));
      } else {
        setValue(key, value);
      }
    });
  }, [setValue, appendixPayload]);

  const handleClickSubmit = () => {
    handleSubmit(async (values) => {
      const { discountRate, fixedFee, startValidityTime, pcBankId, endValidityTime, numbOfPayment } = values;
      const sum = [...Array(numbOfPayment)].reduce(
        (acc, _, index) => acc + +values[`numb-of-payment-${index + 1}-payment-amount-${index + 1}`],
        0,
      );
      if (sum < totalFeeAfterTax) {
        enqueueSnackbar(t`Total Payment Amount is less than Total Fee After Tax`, { variant: 'error' });
        return;
      }

      const arr = [...Array(numbOfPayment)].map(
        (_, index) => values[`numb-of-payment-${index + 1}-payment-date-${index + 1}`],
      );

      //arr[arr.length] = endValidityTime;
      const checkYearAsc = DateTime.fromISO(endValidityTime)
        .diff(DateTime.fromISO(arr[arr.length - 1]), ['days'])
        .toObject();
      const checkDateAsc = arr.every((x, i) => {
        const diff = DateTime.fromISO(x)
          .diff(DateTime.fromISO(arr[i - 1]), ['days'])
          .toObject();
        return i === 0 || Math.floor(diff.days!) > 0;
      });
      if (!checkDateAsc) {
        enqueueSnackbar(t`Next Payment Date is after than Before Payment Date`, { variant: 'error' });
        return;
      }
      if (Math.floor(checkYearAsc.days!) < -1) {
        enqueueSnackbar(t`Next Payment Date is after than Before Payment Date`, { variant: 'error' });
        return;
      }
      setTempAppendix({
        ...values,
        id: enterpriseId,
        appliedType: 'FIXED_SERVICE_FEE',
        startValidityTime: startValidityTime.toISO(),
        endValidityTime: endValidityTime.toISO(),
        paymentTerms: [...Array(numbOfPayment)].map((_, index) => ({
          numbOfPayment: index + 1,
          paymentDate: values[`numb-of-payment-${index + 1}-payment-date-${index + 1}`].toISO(),
          paymentAmount: +values[`numb-of-payment-${index + 1}-payment-amount-${index + 1}`],
        })),
      } as AppendixPayloadType);
      generateFileAppendix({
        id: enterpriseId,
        appliedType: 'FIXED_SERVICE_FEE',
        discountRate,
        appendixType: 'DIGITAL',
        fixedFee,
        pcBankId,
        numbOfPayment,
        startValidityTime: startValidityTime.toISO(),
        endValidityTime: endValidityTime.toISO(),
        paymentTerms: [...Array(numbOfPayment)].map((_, index) => ({
          numbOfPayment: index + 1,
          paymentDate: values[`numb-of-payment-${index + 1}-payment-date-${index + 1}`].toISO(),
          paymentAmount: +values[`numb-of-payment-${index + 1}-payment-amount-${index + 1}`],
        })),
      } as AppendixPayloadType);
    })();
  };

  const totalFeeBeforeTax = Math.round((fixedFee * (100 - discountRate)) / 100);

  const vat = Math.round((totalFeeBeforeTax * serviceTax!) / 100);

  const totalFeeAfterTax = Math.round(totalFeeBeforeTax + vat);

  return (
    <>
      <DialogTitle>
        <Trans>ENTERPRISE'S SERVICE FEE SETTING</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Applied Fee</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Fixed Service Fee</Trans>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Fixed Fee</Trans>
                    </TableCell>
                    <TableCell className='border-r'>
                      <Controller
                        name='fixedFee'
                        defaultValue=''
                        control={control}
                        rules={{ required: t`Fixed Fee is required`, min: 0 }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            required
                            error={invalid}
                            helperText={error?.message}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: { thousandSeparator: '.', decimalSeparator: '#$%' },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Trans>VND/year</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>No. of payments</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='numbOfPayment'
                        defaultValue={1}
                        control={control}
                        rules={{ required: t`Numb Of Payment is required`, min: 1 }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <Select {...field} fullWidth size='small' error={invalid}>
                            {times.map((item) => (
                              <MenuItem key={item.num} value={item.num}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Discount Rate</Trans>
                    </TableCell>
                    <TableCell className='border-r'>
                      <Controller
                        name='discountRate'
                        defaultValue={1}
                        control={control}
                        rules={{
                          validate: (e) => {
                            if (!e) {
                              return false;
                            }
                            if (e < 0 || e > 100) {
                              return "Chỉ cho phép nhập >=0 và <= 100";
                            }
                            return true;
                          }
                        }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}

                            fullWidth
                            required
                            error={invalid}
                            helperText={error?.message}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                            }}
                            onBlur={(e) => {
                              if (parseFloat(e.target.value.replaceAll(".", "").replaceAll(",", ".")) >= 0 && parseFloat(e.target.value.replaceAll(".", "").replaceAll(",", ".")) <= 100)
                                setValue("discountRate", (Math.round((parseFloat(e.target.value.replaceAll(".", "").replaceAll(",", ".")) + Number.EPSILON) * 100) / 100));

                            }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Total Fee before tax</Trans>
                    </TableCell>
                    <TableCell className='border-r'>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(totalFeeBeforeTax)} />
                    </TableCell>
                    <TableCell>
                      <Trans>VND/year</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>VAT</Trans>
                    </TableCell>
                    <TableCell className='border-r'>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(vat)} />
                    </TableCell>
                    <TableCell>
                      <Trans>VND/year</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Total Fee after tax</Trans>
                    </TableCell>
                    <TableCell className='border-r'>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(totalFeeAfterTax)} />
                    </TableCell>
                    <TableCell>
                      <Trans>VND/year</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Validity Time</Trans>
                    </TableCell>
                    <TableCell className='flex items-center space-x-2'>
                      <Controller
                        name='startValidityTime'
                        defaultValue={DateTime.now()}
                        control={control}
                        rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                          <DesktopDatePicker
                            disablePast={true}
                            value={value}
                            onChange={onChange}
                            renderInput={(props) => <TextField {...props} fullWidth error={invalid} />}
                            inputFormat='dd/MM/yyyy'
                          />
                        )}
                      />
                      <div>-</div>
                      <Controller
                        name='endValidityTime'
                        defaultValue={DateTime.now().plus({ years: 1 })}
                        control={control}
                        rules={{
                          validate: {
                            isValid: (value: DateTime) => value && value.isValid,
                            isAfter: (value: DateTime) =>
                              value.toMillis() > startValidityTime.toMillis() ||
                              'End Validity Time should be after the Start Validity Time',
                          },
                        }}
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                          <DesktopDatePicker
                            disablePast={true}
                            value={value}
                            onChange={onChange}
                            renderInput={(props) => (
                              <TextField {...props} fullWidth error={invalid} helperText={error?.message} />
                            )}
                            inputFormat='dd/MM/yyyy'
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>PC's Bank Account</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='pcBankId'
                        defaultValue=''
                        control={control}
                        rules={{ required: t`PC's Bank Account is required` }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <Select {...field} fullWidth size='small' error={invalid}>
                            {banks?.map((bank) => (
                              <MenuItem key={bank.id} value={bank.id}>
                                {`${bank.accountNumber} - ${bank.name} - ${bank.branch}`}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Typography variant='h6'>
          <Trans>Payment Term</Trans>
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Payment times</Trans>
                    </TableCell>
                    {[1, 2, 3, 4].map((x) => (
                      <TableCell
                        className={`border-r ${x > numbOfPayment ? 'bg-[#ccc]' : ''}`}
                        key={`payment-time-${x}`}
                      >
                        <Trans>Payment {x}</Trans>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Payment Date</Trans>
                    </TableCell>
                    {[1, 2, 3, 4].map((x) => (
                      <TableCell
                        className={`border-r ${x > numbOfPayment ? 'bg-[#ccc]' : ''}`}
                        key={`payment-date-${x}`}
                      >
                        {x <= numbOfPayment && (
                          <Controller
                            name={`numb-of-payment-${x}-payment-date-${x}`}
                            defaultValue={DateTime.now()}
                            control={control}
                            rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
                            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                              <DesktopDatePicker
                                disablePast={true}
                                value={value}
                                onChange={onChange}
                                renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
                                inputFormat='dd/MM/yyyy'
                              />
                            )}
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Payment Amount</Trans>
                    </TableCell>
                    {[1, 2, 3, 4].map((x) => (
                      <TableCell className={`border-r ${x > numbOfPayment ? 'bg-[#ccc]' : ''}`} key={x}>
                        {x <= numbOfPayment && (
                          <Controller
                            name={`numb-of-payment-${x}-payment-amount-${x}`}
                            defaultValue=''
                            control={control}
                            rules={{ required: t`Payment Amount is required`, min: 0 }}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                required
                                error={invalid}
                                helperText={error?.message}
                                InputProps={{
                                  inputComponent: InputNumber as any,
                                  inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                                }}
                              />
                            )}
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button variant='outlined' onClick={onBack}>
            <Trans>Back</Trans>
          </Button>
          <LoadingButton
            variant='contained'
            // onClick={!!appendixPayload ? onNext : handleClickSubmit}
            onClick={handleClickSubmit}
            loading={isLoading}
          >
            {/* {!!appendixPayload ? <Trans>Next</Trans> : <Trans>Create Appendix</Trans>} */}
            {<Trans>Create Appendix</Trans>}
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default FixedServicePopupStep2;
