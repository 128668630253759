import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useActiveLocale } from 'hooks/useActiveLocale';
type SelectPageSizeType = {
    size: number;
    onChange: (size: number) => void;
};
const SelectPageSize = ({ size, onChange }: SelectPageSizeType) => {
    const handleChange = (event: SelectChangeEvent) => {
        let size = event.target.value;
        onChange(Number(size));
    };
    const userLocale = useActiveLocale();
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={size.toString()}
                label="pageSize"
                onChange={handleChange}
            >
                <MenuItem value={10}>10 {userLocale === 'vi-VN' ? "dòng" : 'line'}</MenuItem>
                <MenuItem value={20}>20 {userLocale === 'vi-VN' ? "dòng" : 'line'}</MenuItem>
                <MenuItem value={50}>50 {userLocale === 'vi-VN' ? "dòng" : 'line'}</MenuItem>
                <MenuItem value={100}>100 {userLocale === 'vi-VN' ? "dòng" : 'line'}</MenuItem>
                <MenuItem value={200}>200 {userLocale === 'vi-VN' ? "dòng" : 'line'}</MenuItem>
                <MenuItem value={500}>500 {userLocale === 'vi-VN' ? "dòng" : 'line'}</MenuItem>
            </Select>
        </FormControl>

    )
};

export default SelectPageSize;
