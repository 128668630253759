import { Trans } from '@lingui/macro';
import { Paper, Typography, TableContainer, Table, TableBody, TableCell, TableRow, Dialog } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { TransOverdueDetailType } from 'types/TransactionOverdue';
import { formatNumber } from 'utils/common';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { TransOverdueAddRecordPopup } from '../popups';
import { TransType } from 'types/Transaction';
import { OverdueStatus } from 'constants/transaction';
import { getGuaranteeStatusName } from 'constants/enterprise';
export const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  cellTitle: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '30%',
  },
  cellValue: {
    width: '20%',
  },
  cellTitleBold: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '30%',
    fontWeight: 600,
  },
  cellValueBold: {
    width: '20%',
    fontWeight: 600,
  },
});
const TransOverdueDetail = ({
  overdueDetail,
  transaction,
}: {
  overdueDetail?: TransOverdueDetailType;
  transaction?: TransType;
}) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Overdue Detail</Trans>
          </Typography>
          {transaction?.status === OverdueStatus && (
            <LoadingButton variant='contained' onClick={() => setOpen(true)}>
              <Trans>Collection Tracking</Trans>
            </LoadingButton>
          )}
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                <Trans>No. of Overdue Date (days)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{formatNumber(overdueDetail?.numbOfOverdueDate)}</TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Guarantee Status</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {getGuaranteeStatusName(overdueDetail?.guaranteeStatus)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitleBold}>
                <Trans>Total Unpaid Amount (VND)</Trans>
              </TableCell>
              <TableCell className={classes.cellValueBold}>{formatNumber(overdueDetail?.totalUnpaidAmount)}</TableCell>
              <TableCell className={classes.cellTitleBold}>
                <Trans>Total Collected Amount (VND)</Trans>
              </TableCell>
              <TableCell className={classes.cellValueBold}>
                {formatNumber(overdueDetail?.totalCollectedAmount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                (1) <Trans>Unpaid Principal Amount (VND)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{formatNumber(overdueDetail?.unpaidPrincipalAmount)}</TableCell>
              <TableCell className={classes.cellTitle}>
                (1) <Trans>Total Collected Principal Amount (VND)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {formatNumber(overdueDetail?.totalCollectedPrincipalAmount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                (2) <Trans>Unpaid Collection Cost (VND)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{formatNumber(overdueDetail?.unpaidCollectionCost)}</TableCell>
              <TableCell className={classes.cellTitle}>
                (2) <Trans>Total Collected Cost (VND)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{formatNumber(overdueDetail?.totalCollectedCost)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                (3) <Trans>Unpaid Penalty (VND)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{formatNumber(overdueDetail?.unpaidPenalty)}</TableCell>
              <TableCell className={classes.cellTitle}>
                (3) <Trans>Total Collected Penalty (VND)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{formatNumber(overdueDetail?.totalCollectedPenalty)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog fullWidth maxWidth='lg' open={isOpen} onClose={() => setOpen(false)}>
        <TransOverdueAddRecordPopup
          transactionId={overdueDetail?.transactionId!}
          totalUnpaidAmount={overdueDetail?.totalUnpaidAmount!}
          onClose={() => setOpen(false)}
        />
      </Dialog>
    </div>
  );
};

export default TransOverdueDetail;
