import { t, Trans } from '@lingui/macro';
import { CheckCircle, ErrorOutlineOutlined } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  debounce,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { TableEmpty, TooltipLight } from 'components';
import { usePermission, useSearch } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { employeeSetupService } from 'services';
import { EmployeeSetupType } from 'types/EmployeeSetup';
import { EmployeeAssignmentPopup } from 'views/ClientSetup/Employee/popups';
import { getDocumentStatusType } from 'constants/employee';
import { DateTime } from 'luxon';
import { formatNumber } from 'utils/common';
import SelectPageSize from 'components/SelectPageSize';
const EmployeeListSetup = () => {
  const [dataSearch, onSearchChange] = useSearch();
  const [searchType, setSearchType] = useState('employeeName');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const [dataSelect, setDataSelect] = useState<EmployeeSetupType[]>([]);
  const { data } = useQuery(['employeeSetupService.fetchEmployeeSetup', dataSearch], () =>
    employeeSetupService.fetchEmployeeSetup(dataSearch),
  );

  const [isOpenAssign, setOpenAssign] = useState(false);

  const { requireRole, requreUserPermission, EMPLOYEE_ASSIGN } = usePermission();

  const { data: employees = [], paginate } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Employee Credit Setup</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <div className='flex justify-between'>
          <div className='flex items-center space-x-3'>
            <Typography variant='h4'>
              <Trans>List of Employee</Trans>
            </Typography>
            {requreUserPermission([EMPLOYEE_ASSIGN]) && (
              <Button variant='contained' disabled={dataSelect.length === 0} onClick={() => setOpenAssign(true)}>
                <Trans>Assignment</Trans>
              </Button>
            )}
          </div>
          <div className='space-x-3'>
            <TextField
              select
              value={searchType}
              onChange={(event) => {
                setSearchText('');
                onSearchChange({});
                setSearchType(event.target.value);
                debounceChangeValue({ [searchType]: '' });
              }}
            >
              <MenuItem value='employeeName'>
                <Trans>Name</Trans>
              </MenuItem>
              <MenuItem value='enterpriseName'>
                <Trans>Enterprise Name</Trans>
              </MenuItem>
              <MenuItem value='employeeCode'>
                <Trans>ID</Trans>
              </MenuItem>
              <MenuItem value='enterpriseName'>
                <Trans>Enterprise Name</Trans>
              </MenuItem>
              <MenuItem value='documentStatus'>
                <Trans>Document Status</Trans>
              </MenuItem>
              <MenuItem value='creditOfficer'>
                <Trans>Credit Officer</Trans>
              </MenuItem>
              <MenuItem value='autoTransaction'>
                <Trans>Automatic Transaction</Trans>
              </MenuItem>
            </TextField>
            {['employeeName', 'employeeCode', 'creditOfficer', 'enterpriseName'].includes(searchType) && (

              <TextField
                placeholder={t`Search...`}
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                  debounceChangeValue({ [searchType]: event.target.value });
                }}
              />
            )}
            {['documentStatus'].includes(searchType) && (
              <TextField
                select
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                  debounceChangeValue({ [searchType]: event.target.value });
                }}
              >
                <MenuItem value='M'>
                  <Trans>Missing</Trans>
                </MenuItem>
                <MenuItem value='E'>
                  <Trans>Complete</Trans>
                </MenuItem>
              </TextField>
            )}
            {['autoTransaction'].includes(searchType) && <TextField
              select
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
            >
              <MenuItem value=''>
                <Trans>All</Trans>
              </MenuItem>
              <MenuItem value='1'>
                <Trans>YES</Trans>
              </MenuItem>
              <MenuItem value='0'>
                <Trans>NO</Trans>
              </MenuItem>
            </TextField>}
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={(event, checked) => {
                      if (checked) setDataSelect(employees);
                      else setDataSelect([]);
                    }}
                    checked={dataSelect.length === employees.length}
                    indeterminate={dataSelect.length > 0 && dataSelect.length < employees.length}
                  />
                </TableCell>
                <TableCell>
                  <Trans>Employee Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Automatic Transaction</Trans>
                </TableCell>
                <TableCell>
                  <Trans>ID</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Enterprise Name</Trans>

                </TableCell>
                <TableCell>
                  <Trans>No. of working Enterprise</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Max Labours of Advance in current term</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Total Advanced Labour (Day)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Total Advance Limit (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Total Advanced Amount (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Document Status</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Credit Officer</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Detail</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align='center' className='p-1'>
                    <Checkbox
                      checked={dataSelect.some((next) => next.id === item.id)}
                      onChange={(event, checked) => {
                        if (checked) setDataSelect((nexts) => nexts.concat(item));
                        else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                      }}
                    />
                  </TableCell>
                  <TableCell>{item.employeeName}</TableCell>
                  <TableCell className='text-center'>{item.autoTransaction ? <CheckCircle color='primary' /> : ""}</TableCell>
                  <TableCell>{item.employeeCode}</TableCell>
                  <TableCell>{item.enterpriseName}</TableCell>

                  <TableCell align='right'>{formatNumber(item.numbOfWorkingEnterprise)}</TableCell>

                  <TableCell align='right'>{formatNumber(item.maxAdvanceLabours)}</TableCell>
                  <TableCell align='right'>{formatNumber(item.totalAdvancedLabour)}</TableCell>
                  <TableCell align='right'>{formatNumber(item.totalAdvanceLimit)}</TableCell>
                  <TableCell align='right'>{formatNumber(item.totalAdvancedAmount)}</TableCell>
                  <TableCell>
                    <div className='flex items-center justify-between'>
                      <Typography>{getDocumentStatusType(item.documentStatus)}</Typography>
                      {item.documentStatus !== '2' && (
                        <TooltipLight
                          title={
                            <Grid container className='w-72'>
                              <Grid item sm={4} className='border p-2'>
                                <Trans>Note</Trans>
                              </Grid>
                              <Grid item sm={8} className='border p-2'>
                                {item.documentInfo}
                              </Grid>
                              <Grid item sm={4} className='border p-2'>
                                <Trans>Deadline</Trans>
                              </Grid>
                              <Grid item sm={8} className='border p-2'>
                                {item.documentSubmissionDate
                                  ? DateTime.fromISO(item.documentSubmissionDate).toFormat('dd/MM/yyyy')
                                  : ''}
                              </Grid>
                            </Grid>
                          }
                        >
                          <IconButton>
                            <ErrorOutlineOutlined />
                          </IconButton>
                        </TooltipLight>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{item.creditOfficer}</TableCell>
                  <TableCell>
                    <Link to={privateRoute.employeeSetupView.url?.(item)!}>
                      <Button size='small' color='info'>
                        <Trans>View</Trans>
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={employees} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ size: size });
              setDataSelect([]);
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => {
              onSearchChange({ page: value });
              setDataSelect([]);
            }}
          />
        </div>

        <Dialog fullWidth maxWidth='md' open={isOpenAssign} onClose={() => setOpenAssign(false)}>
          <EmployeeAssignmentPopup
            forCa={true}
            onClose={() => setOpenAssign(false)}
            employeeIds={dataSelect.map((item) => item.id)}
            onSuccess={() => setDataSelect([])}
          />
        </Dialog>
      </div>
    </Paper>
  );
};

export default EmployeeListSetup;
