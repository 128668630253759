import { useQuery } from 'react-query';
import { fileService } from 'services';

const PdfViewer = ({ objectKey }: { objectKey: string }) => {
  const { data: url, isSuccess } = useQuery(['fileService.downloadFile', { key: objectKey }], () =>
    fileService
      .downloadFile(objectKey)
      .then(async (data) => {
        var url = "";
        url = await base64ToBlob(data).then((blob) => {
          var file = new Blob([blob], { type: "application/pdf" });
          return window.URL.createObjectURL(new Blob([file], { type: 'application/pdf' }))
        });
        return url;
      }),
  );

  return (
    <div className='border' style={{ height: 'calc(-180px + 80vh)' }}>
      {isSuccess && <embed className='w-full h-full' src={url} />}
    </div>
  );
};

async function base64ToBlob(base64Data: any, contentType = "application/json") {
  const b64Res = await fetch(`data:${contentType};base64,${base64Data}`);
  return await b64Res.blob();
}
export default PdfViewer;
