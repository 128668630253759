import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { categoryService, employeeService, queryClient } from 'services';
import { BankAccountCreateType, BankAccountType } from 'types/BankAccount';
import { PopupController } from 'types/Common';
import { EmployeeType } from 'types/Employee';

type PopupProps = PopupController & {
  item: BankAccountType;
  employee: EmployeeType;
};

const BankAccountCreatePopup = ({ item, employee, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue, watch } = useForm({ mode: 'onChange' });
  const { bankName } = watch();

  const { data: banks } = useQuery(['categoryService.fetchBanks'], () =>
    categoryService.fetchBanks().then((items) => items.map((item) => ({ ...item, label: item.name }))),
  );
  const { data: branches } = useQuery(['categoryService.fetchBankBranches'], () =>
    categoryService.fetchBankBranches().then((items) => items.map((item) => ({ ...item, label: item.name }))),
  );

  useEffect(() => {
    Object.entries(item ?? {}).forEach(([key, value]) => {
      setValue(key, value ? value : '');
    });
  }, [item, setValue]);

  const { mutate: updateBank, isLoading } = useMutation(employeeService.updateEmployeeBankAccountWorking, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeService.getEmployeeBankAccountWorking');
      onClose();
    },
  });
  const { mutate: updateBankMain, isLoading: isLoadingMain } = useMutation(employeeService.updateEmployeeBankAccount, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeService.getEmployeeBankAccount');
      onClose();
    },
  });
  // const validate = (data:any) => {
  //   var strValid = "";
  //   if(data.accountNumber){
  //     if(data.accountNumber.trim() == ''){
  //       strValid += strValid ? ("\n" + t`Account Holder is required`) : t`Account Holder is required`;
  //     }
  //   }
  //   if(data.ercNumber){
  //     if(data.ercNumber.trim() == ''){
  //       strValid += strValid ? ("\n" + t`Account Number is required`) : t`Account Number is required`;
  //     }
  //   }
  //   if(strValid){
  //     enqueueSnackbar(strValid, { variant: 'warning', style: { whiteSpace: 'pre-line' } })
  //     return false;
  //   }
  //   else{
  //     return true;
  //   }
  // }
  const handleClickSubmit = () => {
    handleSubmit((values: BankAccountCreateType) => {      
      if (item.enterpriseId)
        updateBank({
          ...values,
          _id: employee.id,
          enterpriseId: item.enterpriseId,
        });
      else {
        updateBankMain({
          ...values,
          _id: employee.id,
        });
      }
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Update Account</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Controller
              name='bankName'
              defaultValue={null}
              control={control}
              rules={{ required: t`Field is required` }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <Autocomplete
                  value={value}
                  onChange={(event, item) => {
                    onChange({ target: { value: item.name } });
                    setValue('branchName', null);
                  }}
                  options={banks ?? []}
                  disableClearable
                  isOptionEqualToValue={(option, value) => option === value || option.label === value}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      fullWidth
                      required
                      label={t`Bank`}
                      error={invalid}
                      helperText={error?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='branchName'
              defaultValue={null}
              control={control}
              rules={{ required: t`Field is required` }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <Autocomplete
                  value={value}
                  onChange={(event, item) => onChange({ target: { value: item.name } })}
                  options={branches?.filter((item) => item.bankCode === bankName) ?? []}
                  disableClearable
                  isOptionEqualToValue={(option, value) => option === value || option.label === value}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      fullWidth
                      required
                      label={t`Branch`}
                      error={invalid}
                      helperText={error?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {item.enterpriseId && (
            <Grid item sm={12}>
              <Controller
                name='accountHolder'
                defaultValue=''
                control={control}
                // rules={{ required: t`Field is required` }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label={t`Account Holder`}
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
                rules={{
                  validate: (e) => {
                    if(!e){
                      return t`Account Holder is required`;
                    }else if(e.trim().length == 0){
                      return t`Account Holder is required`;
                    }
                    return true;
                  }
                }}
              />
            </Grid>
          )}
          <Grid item sm={12}>
            <Controller
              name='accountNumber'
              defaultValue=''
              control={control}
              rules={{ required: t`Account Number is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Account Number`}
                  error={invalid}
                  helperText={error?.message}
                  onChange={(e)=>{
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setValue("accountNumber", e.target.value)
                    }
                  }}
                />
              )}
              
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton
          variant='contained'
          className='w-20'
          loading={isLoading || isLoadingMain}
          onClick={handleClickSubmit}
        >
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default BankAccountCreatePopup;
