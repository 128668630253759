import { t, Trans } from '@lingui/macro';

import { Paper, Tab, Tabs, Typography } from '@mui/material';
import { useTabs } from 'hooks';
import { useQuery } from 'react-query';
import { enterpriseService, transactionService } from 'services';
import { EnterpriseAll, EnterpriseOnBoard } from 'views/Enterprise/EnterpriseList/components';
import { TransactionNoticeList, GuaranteeNoticeList, FeePaymentNoticeList  } from 'views/Transaction/TransPaying/TransNotify';
const TransNotifyList = () => {
    const { data: all } = useQuery(['transactionService.listTransactionNotice'], () => transactionService.listTransactionNotice({ size: -1, page: -1, type: '', search: '' }));
    const { data: guarantee } = useQuery(
      ['transactionService.listGuaranteeNotice', { size: -1, page: -1 }],
      () => transactionService.listGuaranteeNotice({ size: -1, page: -1 }),
    );
    const { data: feepayment } = useQuery(
      ['transactionService.listFeePaymentNotice', { size: -1, page: -1 }],
      () => transactionService.listFeePaymentNotice({ size: -1, page: -1 }),
    );
  
    const tabs = [
      { code: 'transaction', label: t`TRANSACTION NOTICE (${all?.paginate.totalRecord ?? 0})`, component: <TransactionNoticeList /> },
      { code: 'guarantee', label: t`GUARANTEE NOTICE (${guarantee?.paginate.totalRecord ?? 0})`, component: <GuaranteeNoticeList /> },
      { code: 'feepayment', label: t`FEE PAYMENT NOTICE (${feepayment?.paginate.totalRecord ?? 0})`, component: <FeePaymentNoticeList /> },
    ];
    const [activeTab, onChangeTab] = useTabs(tabs);
  
    return (
      <Paper>
        <div className='bg-primary-main text-white rounded-t px-3 py-2'>
          <Typography variant='h3'>
            <Trans>NOTICE MANAGEMENT</Trans>
          </Typography>
        </div>
        <div className='p-3'>
          <Tabs value={activeTab} onChange={onChangeTab}>
            {tabs.map((tab) => (
              <Tab key={tab.code} label={tab.label} value={tab.code} />
            ))}
          </Tabs>
          {tabs.map((tab) => (
            <div key={tab.code} hidden={tab.code !== activeTab} className='mt-4'>
              {tab.component}
            </div>
          ))}
        </div>
      </Paper>
    );
  };
  
  export default TransNotifyList;