import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { creditScoreService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { ParameterCreateType } from 'types/CreditScore';

type PopupProps = PopupController & {
  csId: number;
  type: 1 | 2;
};

const ParameterCreatePopup = ({ csId, type, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: createParameter, isLoading } = useMutation(creditScoreService.createParameter, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('creditScoreService.getCreditScoreById');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: ParameterCreateType) => {
      createParameter({ ...values, csId, type });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Add Parameter</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Controller
              name='nameVi'
              defaultValue=''
              control={control}
              rules={{ required: t`Vietnamese Name is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Vietnamese Name`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='nameEn'
              defaultValue=''
              control={control}
              rules={{ required: t`English Name is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`English Name`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ParameterCreatePopup;
