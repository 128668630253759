import { t, Trans } from '@lingui/macro';

import { LoadingButton } from '@mui/lab';
import {
  Button, DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Typography
} from '@mui/material';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  setValue: (args: any) => void;
  value: string;
};

const AttendanceUpdateStep1 = ({ onBack, onNext, value, setValue }: PopupProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const listTypeEmployee = [
    {
      title: t`By single employee`,
      value: 'single',
    },
    {
      title: t`By Platform's template`,
      value: 'platform',
    },
    {
      title: t`By Enterprise's template`,
      value: 'enterprise',
    },
  ];

  return (
    <>
      <DialogTitle>
        <Trans>UPDATE EMPLOYEE'S LABOUR</Trans>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h6'>
          <Trans>Please select one of the following way to update Employee's Labour</Trans>
        </Typography>
        <RadioGroup name='employee-type' onChange={handleChange}>
          <Grid container columnSpacing={2} className='my-6'>
            {listTypeEmployee.map((item, index) => {
              return (
                <Grid item md={12} lg={4} xl={4} key={index}>
                  <div className='border flex justify-center items-center py-4 rounded-lg'>
                    <FormControlLabel value={item.value} control={<Radio />} label={item.title} />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </RadioGroup>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button variant='outlined' onClick={onBack}>
            <Trans>Cancel</Trans>
          </Button>
          {value !== '' && (
            <LoadingButton variant='contained' onClick={onNext}>
              <Trans>Next</Trans>
            </LoadingButton>
          )}
        </div>
      </DialogActions>
    </>
  );
};

export default AttendanceUpdateStep1;
