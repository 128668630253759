import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { Lock, EmailRounded, PhoneRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { InputAdornment, Link as MuiLink, Paper, TextField, Typography } from '@mui/material';
import { InputOTP } from 'components';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { staticRoute } from 'routes';
import { passwordService } from 'services';
import { sha256 } from 'utils/common';

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState(1);
  const [accessToken, setToken] = useState('');
  const [otp, setOtp] = useState('');

  const { control, handleSubmit } = useForm();

  const { mutate: forgotPassword, isLoading: isLoadingSend } = useMutation(passwordService.forgotPassword, {
    onSuccess: ({ token }) => {
      setToken(token);
      setStep((step) => step + 1);
    },
  });

  const { mutate: resendOtp } = useMutation(passwordService.forgotPassword, {
    onSuccess: ({ token }) => {
      setToken(token);
    },
  });

  const { mutate: confirmOtp, isLoading: isLoadingOtp } = useMutation(passwordService.confirmOtp, {
    onSuccess: () => {
      setStep((step) => step + 1);
    },
  });

  const { mutate: resetPassword, isLoading: isLoadingPassword } = useMutation(passwordService.resetPassword, {
    onSuccess: () => {
      enqueueSnackbar('Update password successfully');
      navigate(staticRoute.login.path);
    },
  });

  const handleClickSendEmail = () => {
    handleSubmit(({ email }) => {
      forgotPassword({ email });
    })();
  };

  const handleResendOtp = () => {
    handleSubmit(({ email }) => {
      resendOtp({ email });
    })();
  };

  const handleClickSendOtp = () => {
    confirmOtp({
      params: { otp },
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };

  const handleClickSendPassword = () => {
    handleSubmit(({ password, confirmPassword }) => {
      resetPassword({
        params: { password: sha256(password), confirmPassword: sha256(confirmPassword) },
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    })();
  };

  return (
    <Paper className='bg-white/90 w-[440px] rounded-lg p-10'>
      <Typography variant='h2' align='center'>
        <Trans>FORGOT PASSWORD</Trans>
      </Typography>
      {step === 1 && <Typography className='text-center mt-8 mb-12'>Nhập địa chỉ email của bạn</Typography>}
      {step === 2 && (
        <Typography className='text-center mt-8 mb-12'>Nhập OTP được gửi đến số điện thoại đăng ký của bạn</Typography>
      )}
      {step === 3 && <Typography className='text-center mt-8 mb-12'>Nhập mật khẩu mới</Typography>}

      <div className='space-y-8'>
        {step === 1 && (
          <Controller
            name='email'
            defaultValue=''
            control={control}
            rules={{ required: t`Email is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                size='medium'
                placeholder={t`Email`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <EmailRounded />
                    </InputAdornment>
                  ),
                  className: 'bg-white',
                }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        )}

        {step === 2 && (
          <div className='-ml-5'>
            <InputOTP
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputStyle={{
                marginLeft: '16px',
                border: '1px solid rgba(0, 0, 0, 0.3)',
                width: '48px',
                height: '48px',
                borderRadius: 4,
              }}
            />
          </div>
        )}

        {step === 3 && (
          <>
            <Controller
              name='password'
              defaultValue=''
              control={control}
              rules={{ required: t`Password is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  size='medium'
                  placeholder={t`Password`}
                  type='password'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name='confirmPassword'
              defaultValue=''
              control={control}
              rules={{ required: t`Confirm Password is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  size='medium'
                  placeholder={t`Confirm Password`}
                  type='password'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </>
        )}
      </div>

      {step === 2 && (
        <div className='flex justify-end mt-6'>
          <span className='italic hover:underline cursor-pointer' onClick={handleResendOtp}>
            <Trans>Resend OTP</Trans>
          </span>
        </div>
      )}

      <div className='flex mt-10'>
        {step === 1 && (
          <LoadingButton
            fullWidth
            variant='contained'
            color='info'
            loading={isLoadingSend}
            onClick={handleClickSendEmail}
          >
            <Trans>SEND</Trans>
          </LoadingButton>
        )}
        {step === 2 && (
          <LoadingButton fullWidth variant='contained' color='info' loading={isLoadingOtp} onClick={handleClickSendOtp}>
            <Trans>NEXT</Trans>
          </LoadingButton>
        )}
        {step === 3 && (
          <LoadingButton
            fullWidth
            variant='contained'
            color='info'
            loading={isLoadingPassword}
            onClick={handleClickSendPassword}
          >
            <Trans>CONFIRM</Trans>
          </LoadingButton>
        )}
      </div>

      <div className='flex justify-between mt-10'>
        <div className='flex items-center gap-1'>
          <PhoneRounded />
          <MuiLink href='tel:0869121232'>0869 121 232</MuiLink>
        </div>
        <div className='flex items-center gap-1'>
          <EmailRounded />
          <MuiLink href='mailto:support@scp.com.vn'>support@scp.com.vn</MuiLink>
        </div>
      </div>
    </Paper>
  );
};

export default ForgotPasswordForm;
