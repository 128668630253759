import { t, Trans } from '@lingui/macro';
import { usePermission } from 'hooks';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { InputNumber } from 'components';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { employeeSetupService, queryClient } from 'services';
import { formatNumber } from 'utils/common';
import { RejectPopup } from 'views/ClientSetup/Employee/popups';
import { useActiveLocale } from 'hooks/useActiveLocale';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseEmployeeId: number;
  employeeId: string;
  pasId: number;
};

const FullTimePopupStep3 = ({ pasId, enterpriseEmployeeId, employeeId, onBack, onNext }: PopupProps) => {
  const [isOpenReject, setOpenReject] = useState(false);
  const { requreUserPermission, EMPLOYEE_DUYET_TUCHOI_DKUL } = usePermission();
  const {
    data: {
      typeOfLaborContractEn,
      typeOfLaborContractVi,
      contractAdvanceRatio,
      seniority,
      seniorityAdvanceRatio,
      limitByLaborContractSeniority,
      approvalRatio,
      levelEn,
      levelVi,
      maximumLimit,
      minimumLimit,
      appliedEnterpriseFeeEn,
      employeeSupportDiscountRate,
      advanceLimit,
      earnedDiscountRate,
      futureDiscountRate,
      futureAdvance,
      currentTermNumbOfDefaultLabour,
      currentTermNumbOfEarnedLabour,
      limitEarnedLabor
    } = {},
  } = useQuery(
    ['employeeSetupService.getPayrollAdvanceSettingEachEnterprise', { employeeId, enterpriseEmployeeId, pasId }],
    () => employeeSetupService.getPayrollAdvanceSettingEachEnterprise({ employeeId, enterpriseEmployeeId, pasId }),
    {
      onSuccess: (data) => {
        Object.entries(data ?? {}).forEach(([key, value]) => {
          setValue(key, value);
        });
      },
    },
  );
  const userLocale = useActiveLocale();
  const moneyToNumber = (currency: any) => {
    currency = currency ? currency.toString() : "";
    return Number(currency.replace(/[^0-9.-]+/g, ""));
  }
  const { control, handleSubmit, setValue, watch } = useForm({ mode: 'onChange' });

  const { monthlyAverageSalary, maxLimitPc } = watch();

  const { mutate: updatePayrollAdvanceSettingEachEnterprise, isLoading } = useMutation(
    employeeSetupService.updatePayrollAdvanceSettingEachEnterprise,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employeeSetupService.getSalaryCondition');
        onNext();
      },
    },
  );
  // const {
  //   data: {
  //     currentTermNumbOfEarnedLabour,
  //     currentTermNumbOfDefaultLabour,
  //   } = {},
  // } = useQuery(['employeeSetupService.getLabourDetail', { employeeId, enterpriseEmployeeId }], () =>
  //   employeeSetupService.getLabourDetail({ employeeId, enterpriseEmployeeId }),
  // );
  const handleApprove = () => {
    handleSubmit(() => {
      updatePayrollAdvanceSettingEachEnterprise({
        employeeId: employeeId,
        enterpriseEmployeeId: enterpriseEmployeeId,
        pasId,
        monthlyAverageSalary,
        status: 'PAS_STATE_APPROVE',
        maxLimitPc: maxLimitPc
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>CREDIT SETUP FOR FULL-TIME EMPLOYEE</Trans>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h4'>
          <Trans>Payroll Advance Setting</Trans>
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12} lg={8} xl={8}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Monthly Average Salary (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='monthlyAverageSalary'
                        defaultValue=''
                        control={control}
                        rules={{ required: t`Monthly Average Salary is required`, min: 0 }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            required
                            label={t`Monthly Average Salary`}
                            error={invalid}
                            helperText={error?.message}
                            InputProps={{ inputComponent: InputNumber as any, readOnly: true, inputProps: { thousandSeparator: '.', decimalSeparator: ',' }, }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Type of Labor Contract</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={(userLocale === "vi-VN" ? typeOfLaborContractVi : typeOfLaborContractEn) ?? ''} />
                    </TableCell>
                    <TableCell>
                      <Trans>Advance Ratio</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={contractAdvanceRatio ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Seniority (Year)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={seniority ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>Advance Ratio</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={seniorityAdvanceRatio ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Limit by Labor Contract & Seniority (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{ readOnly: true }}
                        value={formatNumber(limitByLaborContractSeniority) ?? 0}
                      />
                    </TableCell>
                    <TableCell>
                      <Trans>Approval Ratio</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={approvalRatio ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Level</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={(userLocale === "vi-VN" ? levelVi : levelEn) ?? ''} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Minimum Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(minimumLimit) ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>Maximum Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(maximumLimit) ?? 0} />
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell>
                      <Trans>Applied Enterprise's Fee</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={appliedEnterpriseFeeEn ?? ''} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow> */}
                  {/* <TableRow>
                    <TableCell>
                      <Trans>Employee Support Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={employeeSupportDiscountRate ?? 0} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={12} lg={4} xl={4}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Trans>APPROVAL DECISION</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Advance Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>{formatNumber(advanceLimit)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Earned Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>{earnedDiscountRate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Future Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>{futureDiscountRate}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Typography variant='h4'>
          <Trans>Điều kiện ứng lương theo ngày công</Trans>
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12} lg={8} xl={8}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Employee has been eligible for future advance</Trans>
                    </TableCell>
                    <TableCell>
                      {/* <TextField InputProps={{ readOnly: true }} value={appliedEnterpriseFeeEn ?? ''} /> */}
                      {futureAdvance ? t`YES` : t`NO`}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Expected Labours in term</Trans>
                    </TableCell>
                    <TableCell>
                      {currentTermNumbOfDefaultLabour}
                    </TableCell>
                    <TableCell>
                      <Trans>Earned Labours in term</Trans>
                    </TableCell>
                    <TableCell>
                      {currentTermNumbOfEarnedLabour}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Limit as earned labours (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(Math.round((moneyToNumber(monthlyAverageSalary!) * currentTermNumbOfEarnedLabour!) / currentTermNumbOfDefaultLabour!) ?? 0)} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

        </Grid>
        <Typography variant='h4'>
          <Trans>Maximum limit setting by PC</Trans>
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12} lg={8} xl={8}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Maximum limit</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='maxLimitPc'
                        defaultValue=''
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label={t`Maximum limit`}
                            error={invalid}
                            helperText={error?.message}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: { readOnly: true, thousandSeparator: '.', decimalSeparator: ',' },
                            }}
                          />
                        )}
                      />
                      {/* <TextField value={formatNumber(Math.round(maxLimitPc ?? 0))} /> */}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          {requreUserPermission([EMPLOYEE_DUYET_TUCHOI_DKUL]) &&
            <Button variant='outlined' color='error' onClick={() => setOpenReject(true)}>
              <Trans>Reject</Trans>
            </Button>
          }
          {requreUserPermission([EMPLOYEE_DUYET_TUCHOI_DKUL]) &&
            <LoadingButton variant='contained' loading={isLoading} onClick={handleApprove}>
              <Trans>Approve</Trans>
            </LoadingButton>
          }
          <Button variant='outlined' onClick={onBack}>
            <Trans>Cancel</Trans>
          </Button>
        </div>
      </DialogActions>

      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <RejectPopup
          pasId={pasId}
          employeeId={employeeId}
          enterpriseEmployeeId={enterpriseEmployeeId}
          onBack={() => {
            setOpenReject(false);
          }}
          onNext={() => {
            setOpenReject(false);
            onBack();
          }}
        />
      </Dialog>
    </>
  );
};

export default FullTimePopupStep3;