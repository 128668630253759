import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { transactionService } from 'services';
import { TransInfo, TransDetail, TransPayment, TransAssignedUser } from 'views/Transaction/TransView/components';
import { TransWaitSettlement, TransReviseHis, TransSettlementRecordHis } from './components';

const TransWaitSettlementView = () => {
  const { id: _id } = useParams() as { id: string };

  const { data: transaction, isSuccess } = useQuery(['transactionService.getTransactionById', { id: _id }], () =>
    transactionService.getTransactionById({ id: _id }),
  );

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Transaction Detail</Trans>
        </Typography>
      </div>
      {isSuccess && (
        <div className='p-3'>
          <TransInfo transaction={transaction} />
          <TransDetail transaction={transaction} />
          <TransPayment transaction={transaction} />
          <TransWaitSettlement transaction={transaction} />
          <TransSettlementRecordHis transaction={transaction} />
          <TransReviseHis transaction={transaction} />
          <TransAssignedUser transaction={transaction} />
        </div>
      )}
    </Paper>
  );
};

export default TransWaitSettlementView;
