import { useState } from 'react';
import { PopupController } from 'types/Common';
import {
  ConditionPartTimePopupStep1,
  ConditionPartTimePopupStep2,
  ConditionPartTimePopupStep3,
  ConditionPartTimePopupStep4,
} from 'views/ClientSetup/Employee/popups';

type PopupProps = PopupController & {
  enterpriseEmployeeId: number;
  employeeId: string;
  stepCurrent?: number;
  pasId: number;
};

const PartTimePopup = ({ pasId, stepCurrent, enterpriseEmployeeId, employeeId, onClose }: PopupProps) => {
  const [step, setStep] = useState(stepCurrent || 1);

  return (
    <>
      {step === 1 && (
        <ConditionPartTimePopupStep1
          employeeId={employeeId}
          enterpriseEmployeeId={enterpriseEmployeeId}
          pasId={pasId}
          onBack={() => onClose()}
          onNext={() => setStep(2)}
        />
      )}
      {step === 2 && (
        <ConditionPartTimePopupStep2
          employeeId={employeeId}
          enterpriseEmployeeId={enterpriseEmployeeId}
          pasId={pasId}
          onBack={() => onClose()}
          onNext={() => onClose()}
        />
      )}
      {step === 3 && (
        <ConditionPartTimePopupStep3
          employeeId={employeeId}
          enterpriseEmployeeId={enterpriseEmployeeId}
          pasId={pasId}
          onBack={() => onClose()}
          onNext={() => onClose()}
        />
      )}
      {step === 4 && (
        <ConditionPartTimePopupStep4
          employeeId={employeeId}
          enterpriseEmployeeId={enterpriseEmployeeId}
          pasId={pasId}
          onBack={() => onClose()}
        />
      )}
    </>
  );
};

export default PartTimePopup;
