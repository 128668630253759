import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { Check, CloseOutlined, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { InputNumber, TableEmpty } from 'components';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { queryClient, transactionService } from 'services';
import { PopupController } from 'types/Common';
import { downloadBase64, formatNumber } from 'utils/common';
import { Controller, useForm } from 'react-hook-form';
import { useActiveLocale } from 'hooks/useActiveLocale';

type PopupProps = PopupController & {
  bankCode: string;
};

const TransVcb = ({ bankCode, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();
  const [loadingExport, setLoadingExport] = useState(false);
  const [transId, setTransId] = useState(0);
  const { data } = useQuery(
    ['transactionService.listTransBankReport', { bankCode }],
    () => transactionService.listTransBankReport({ bankCode }),
    {
      keepPreviousData: true,
    },
  );
  const { list: items = [] } = data ?? {};
  const userLocale = useActiveLocale();
  const { mutate: updateTransBankReport } = useMutation(transactionService.updateTransBankReport, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('transactionService.listTransBankReport');
      setTransId(0);
    },
  });

  const exportReport = () => {
    setLoadingExport(true);
    transactionService
      .exportTransBankReport({
        bankCode,
      })
      .then(downloadBase64)
      .finally(() => {
        setLoadingExport(false);
      });
  };

  const handleSave = () => {
    if (transId <= 0) {
      return;
    }
    handleSubmit((values: { amount: number; content: string }) => {
      updateTransBankReport({
        ...values,
        txId: transId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>EXPORT TRANSACTION DATA</Trans>
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>No.</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Beneficiary Account</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Beneficiary Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Bank Code</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Amount (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Currency</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Content</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Edit</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, idx) => (
                <TableRow key={item.transId}>
                  <TableCell align='center'>{idx + 1}</TableCell>
                  <TableCell>{item.bankAccount}</TableCell>
                  <TableCell>{item.employeeName}</TableCell>
                  <TableCell>{item.beneficiaryBankBrandCode}</TableCell>
                  {transId === item.transId ? (
                    <TableCell align='center'>
                      <Controller
                        name='amount'
                        defaultValue={item.amount}
                        control={control}
                        rules={{ required: t`Field is required` }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            label={t`Amount`}
                            required
                            fullWidth
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  ) : (
                    <TableCell className='text-right'>{formatNumber(item.amount)}</TableCell>
                  )}
                  <TableCell>{item.currency}</TableCell>
                  {transId === item.transId ? (
                    <TableCell align='center'>
                      <Controller
                        name='content'
                        defaultValue={item.content}
                        control={control}
                        rules={{ required: t`Field is required` }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            label={t`Content`}
                            required
                            fullWidth
                            error={invalid}
                            multiline={true}
                            minRows={2}
                          />
                        )}
                      />
                    </TableCell>
                  ) : (
                    <TableCell>{item.content}</TableCell>
                  )}
                  <TableCell align='center'>
                    {transId === item.transId ? (
                      <>
                        <IconButton size='small' color='primary' onClick={handleSave}>
                          <Check fontSize='small' />
                        </IconButton>
                        <IconButton size='small' color='warning' onClick={() => setTransId(0)}>
                          <CloseOutlined fontSize='small' />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        color='primary'
                        size='small'
                        onClick={() => {
                          setTransId(item.transId);
                        }}
                      >
                        <Edit fontSize='small' />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table>
            <TableBody>
              <TableCell style={{border: "1px solid #D9D9D9",background:"#D9D9D9",width:"50%"}}>{userLocale === 'vi-VN' ? <div style={{fontSize: "16px",fontWeight:500,textAlign:"center"}}>Tổng tiền thanh toán (VND)</div> : <div style={{fontSize: "16px",fontWeight:500,textAlign:"center"}}>Total Payment Amount (VND)</div>}</TableCell>
              <TableCell style={{border: "1px solid #D9D9D9"}}><div style={{fontSize: "16px",fontWeight:500,textAlign:"center"}}>{formatNumber((items.reduce((a,v) =>  a = a + v.amount , 0 )))}</div></TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions className='relative'>
        <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
          <Trans>Close</Trans>
        </LoadingButton>
        <LoadingButton disabled={transId > 0} variant='contained' loading={loadingExport} onClick={exportReport}>
          <Trans>Export</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default TransVcb;
