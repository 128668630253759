import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { employeeService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { ChecklistStatusType } from 'types/EmployeeChecklist';
import { getLegalStatus } from 'constants/enterprise';
import { CHECKLIST_STATUS_MAP } from '../components/EmployeeChecklistLegal';
import { useState } from 'react';
type PopupProps = PopupController & { employeeId: string };

const ChecklistStatusPopup = ({ employeeId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const [showDT, setShowDT] = useState(false);
  const { mutate: createEnterpriseChecklistStatus, isLoading } = useMutation(
    employeeService.createEnterpriseChecklistStatus,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('employeeService.getEmployeeChecklistStatus');
        onClose();
      },
    },
  );
  const onClickNut = (event:any) =>{
    setShowDT(event.currentTarget.dataset.value == "1" ? true : false);
  }
  const handleClickSubmit = () => {
    handleSubmit((values) => {
      type ValuesType = ChecklistStatusType & { deadline: DateTime };
      const { status, deadline, ...others } = values as ValuesType;

      createEnterpriseChecklistStatus({
        ...others,
        status: Number(status),
        deadline: deadline ? deadline.toISO() : deadline,
        _id: employeeId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Review Legal</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={6}>
            <Controller
              name='status'
              defaultValue=''
              control={control}
              rules={{ required: 'Status is required' }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  select
                  required
                  label={t`Status`}
                  error={invalid}
                  helperText={error?.message}
                >
                  {Object.entries(CHECKLIST_STATUS_MAP).map(([key, value]) => (
                    <MenuItem key={key} value={value} onClick={onClickNut}>
                      {getLegalStatus(value)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={6}>
          { showDT &&
            <Controller
              name='deadline'
              defaultValue={DateTime.now()}
              control={control}
              rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDatePicker
                  value={value}
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} required error={invalid} />}
                  label={t`Deadline`}
                  inputFormat='dd/MM/yyyy'
                />
              )}
            />
            }
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='note'
              defaultValue=''
              control={control}
              rules={{ required: showDT ? t`Note is required` : false }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows={2}
                  required
                  label={t`Note`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ChecklistStatusPopup;
