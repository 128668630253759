import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { TextField, Typography } from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { EnterpriseSettingType, EnterpriseSettingUpdateType } from 'types/EnterpriseSetup';
import { usePermission } from 'hooks';

const EnterpriseOverdueDays = ({ setting }: { setting?: EnterpriseSettingType }) => {
  const { isCaRole } = usePermission();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm();

  const { mutate: createOverdueDays, isLoading } = useMutation(enterpriseSetupService.createOverdueDays, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.getGeneralSettings');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: EnterpriseSettingUpdateType) => {
      createOverdueDays({ ...values, id: setting?.enterpriseId! });
    })();
  };

  return (
    <div>
      <Typography variant='h4' className='my-4'>
        <Trans>Type of Overdue Changeover Time</Trans>
      </Typography>

      <div className='flex items-center gap-4'>
        <Controller
          name='overdueDay'
          defaultValue={setting?.overDueDay}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              fullWidth
              label={t`Type of Overdue Changeover Time`}
              error={invalid}
              InputProps={{
                inputComponent: InputNumber as any,
                endAdornment: t`Day`,
              }}
              onBlur={(e) => {
                if (e.target.value)
                  setValue("overdueDay", Math.round(parseFloat(e.target.value)));
                }}
            />
          )}
        />
        {isCaRole() && (<LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit} className='w-32'>
          <Trans>Apply</Trans>
        </LoadingButton>)}
      </div>
    </div>
  );
};

export default EnterpriseOverdueDays;
