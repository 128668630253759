import { Trans } from '@lingui/macro';
import { ArrowRight, InfoOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { TableEmpty, TooltipLight } from 'components';
import { getLegalStatus } from 'constants/enterprise';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseService } from 'services';
import { ChecklistLegalType } from 'types/EnterpriseChecklist';
import { ChecklistLegalPopup, ChecklistStatusPopup } from 'views/Enterprise/EnterpriseChecklist/popups';
import { usePermission } from 'hooks';
export const CHECKLIST_STATUS_MAP = [1, 2];

const AssignmentChecklistLegal = () => {
  const userLocale = useActiveLocale();
  const { id: _id } = useParams() as { id: string };

  const { data: info } = useQuery(['enterpriseService.getEnterpriseChecklistStatus', { _id }], () =>
    enterpriseService.getEnterpriseChecklistStatus({ _id }),
  );
  const { data } = useQuery(['enterpriseService.fetchEnterpriseChecklist', { _id }], () =>
    enterpriseService.fetchEnterpriseChecklist({ _id }),
  );
  const { data: items = [] } = data ?? {};
  const { canActionAssignCheckList } = usePermission();
  const [isOpenStatus, setOpenStatus] = useState(false);
  const [isOpenLegal, setOpenLegal] = useState(false);
  const [chosenLegal, setChosenLegal] = useState<ChecklistLegalType>();

  return (
    <>
      <div className='flex items-center justify-between'>
        <div className='flex items-center space-x-2'>
          <Typography variant='h4'>
            <Trans>Legal Documents</Trans>
          </Typography>
          <ArrowRight />
          <Typography variant='h5'>{getLegalStatus(info?.status as 1 | 2)}</Typography>
          <TooltipLight
            title={
              <Grid container className='w-72'>
                <Grid item sm={4} className='border p-2'>
                  <Trans>Note</Trans>
                </Grid>
                <Grid item sm={8} className='border p-2'>
                  {info?.note}
                </Grid>
                <Grid item sm={4} className='border p-2'>
                  <Trans>Deadline</Trans>
                </Grid>
                <Grid item sm={8} className='border p-2'>
                  {info?.status === 1 && DateTime.fromISO(info?.deadline!).toFormat('dd/MM/yyyy')}
                </Grid>
              </Grid>
            }
          >
            <InfoOutlined fontSize='small' className='cursor-pointer' />
          </TooltipLight>
          {canActionAssignCheckList() && <Button variant='outlined' size='small' onClick={() => setOpenStatus(true)}>
            <Trans>Review</Trans>
          </Button>}
        </div>

        <Dialog fullWidth maxWidth='xs' open={isOpenStatus}>
          <ChecklistStatusPopup enterpriseId={_id} onClose={() => setOpenStatus(false)} />
        </Dialog>
      </div>

      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className='w-20'>
                  <Trans>No</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Document</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Total</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.groupNumber}</TableCell>
                  <TableCell>{userLocale === 'vi-VN' ? item.viContent : item.enContent}</TableCell>
                  <TableCell>{item.total}</TableCell>
                  <TableCell className='text-right'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpenLegal(true);
                        setChosenLegal(item);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog fullWidth maxWidth='sm' open={isOpenLegal} onClose={() => setOpenLegal(false)}>
          <ChecklistLegalPopup legal={chosenLegal!} enterpriseId={_id} onClose={() => setOpenLegal(false)} />
        </Dialog>
      </div>
    </>
  );
};

export default AssignmentChecklistLegal;
