import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { PopupController } from 'types/Common';
import { Controller, useForm } from 'react-hook-form';
import { employeeSetupService, queryClient } from 'services';
import { UpdateOrRemoveBlacklistType } from 'types/EmployeeSetup';
import { useMutation } from 'react-query';

type PopupProps = PopupController & {
  notInBlacklisted: boolean;
  employeeId: string;
};

const BlacklistPopup = ({ employeeId, notInBlacklisted, onSuccess, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: updateOrRemoveBlacklist, isLoading } = useMutation(employeeSetupService.updateOrRemoveBlacklist, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeSetupService.fetchEmployeeBasicInfo');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit(({ reason }: UpdateOrRemoveBlacklistType) => {
      updateOrRemoveBlacklist({
        employeeId,
        reason,
        state: notInBlacklisted ? 'ENABLE_BLACKLIST' : 'DISABLE_BLACKLIST',
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        {notInBlacklisted ? <Trans>Add To Blacklist</Trans> : <Trans>Remove from Blacklist</Trans>}
      </DialogTitle>

      <DialogContent>
        <Controller
          name='reason'
          defaultValue=''
          control={control}
          rules={{ required: t`Reason is required` }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              fullWidth
              required
              label={t`Reason`}
              error={invalid}
              helperText={error?.message}
            ></TextField>
          )}
        />
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <LoadingButton variant='outlined' onClick={onClose}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default BlacklistPopup;
