import { Trans, t } from '@lingui/macro';
import { usePermission } from 'hooks';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseService, queryClient } from 'services';
import { FrequencyUpdateType, FrequencyType } from 'types/EnterpriseFrequency';
import { useState } from 'react';

const EnterpriseFrequency = () => {
  const { id: _id } = useParams() as { id: string };
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm();
  const [frequency, setFrequency] = useState<FrequencyType>();
  const { requireUserPCManagerOrCRMLeader, requreUserPermission, ENTERPRISE_CAPNHAT_TANSUATCAPNHAT_THONGBAO } = usePermission();

  useQuery(
    ['enterpriseService.getEnterpriseFrequency', { _id }],
    () => enterpriseService.getEnterpriseFrequency({ _id: _id ?? '0' }),
    {
      onSuccess: (data) => {
        setFrequency(data);
        Object.entries(data ?? {}).forEach(([key, value]) => {
          setValue(key, value ?? '');
        });
      },
    },
  );

  const { mutate: updateEnterpriseFrequency, isLoading } = useMutation(enterpriseService.updateEnterpriseFrequency, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseService.getEnterpriseFrequency');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: FrequencyUpdateType) => {
      updateEnterpriseFrequency({ ...values, _id: _id ?? '0' });
    })();
  };

  return (
    <div>
      <Typography variant='h4'>
        <Trans>Frequency of Update & Notice</Trans>
      </Typography>
  
      <TableContainer component={Paper} variant='outlined'>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Trans>Update Employee Information</Trans>
              </TableCell>
              <TableCell>
                <Controller
                  name='updateEmployeeInfo'
                  defaultValue=''
                  control={control}
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </TableCell>
              <TableCell>
                <Trans>Update Eligible Employee Notice</Trans>
              </TableCell>
              <TableCell>
                <Controller
                  name='updateEligibilityEmployeeNotice'
                  defaultValue=''
                  control={control}
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Trans>Update Working Day</Trans>
              </TableCell>
              <TableCell>
                <Controller
                  name='updateEmployeeTimekeeping'
                  defaultValue=''
                  control={control}
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </TableCell>
              <TableCell>
                <Trans>Update Retired Employee</Trans>
              </TableCell>
              <TableCell>
                <Controller
                  name='updateEmployeeQuit'
                  defaultValue=''
                  control={control}
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Controller
                  name='additionLabel1'
                  defaultValue=''
                  control={control}
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </TableCell>
              <TableCell>
                <Controller
                  name='additionValue1'
                  defaultValue=''
                  control={control}
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </TableCell>
              <TableCell>
                <Controller
                  name='additionLabel2'
                  defaultValue=''
                  control={control}
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </TableCell>
              <TableCell>
                <Controller
                  name='additionValue2'
                  defaultValue=''
                  control={control}
                  render={({ field }) => <TextField {...field} fullWidth />}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {(requreUserPermission([ENTERPRISE_CAPNHAT_TANSUATCAPNHAT_THONGBAO]) && frequency?.isWaitingConfirm === 1) && (
        <div className='flex justify-end'>
          <LoadingButton variant='contained' className='w-32' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
      )}
    </div>
  );
};

export default EnterpriseFrequency;
