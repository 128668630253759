import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import {
  EmployeeChecklistAppraisal,
  EmployeeChecklistHistory,
  EmployeeChecklistLegal
} from 'views/Employee/EmployeeChecklist/components';
import { usePermission } from 'hooks';
const EmployeeChecklist = () => {
  const { requreUserPermission,EMPLOYEE_LEGAL,EMPLOYEE_APPRAISAL } = usePermission();
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Assignment Checklist</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        {requreUserPermission([EMPLOYEE_LEGAL]) && <EmployeeChecklistLegal />}
        {requreUserPermission([EMPLOYEE_APPRAISAL]) &&<EmployeeChecklistAppraisal />}
        {requreUserPermission([EMPLOYEE_APPRAISAL]) &&<EmployeeChecklistHistory />}
      </div>
    </Paper>
  );
};

export default EmployeeChecklist;
