import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { PopupController } from 'types/Common';

type PopupProps = PopupController & {
  isLoading: boolean;
  handleAction: () => void;
};

const ActionConfirmPopup = ({ isLoading, handleAction, onClose }: PopupProps) => {
  return (
    <>
      <DialogTitle>CONFIRMATION</DialogTitle>

      <DialogContent>Are you sure to do this?</DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Cancel
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleAction}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ActionConfirmPopup;
