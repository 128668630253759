import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PdfViewer from 'components/PdfViewer';
import { useQuery } from 'react-query';
import { transactionOverdueService } from 'services';
import { PopupController } from 'types/Common';

type PopupProps = PopupController & {
  noticeId: number;
};

const ViewOverdueNoticePopup = ({ noticeId, onClose }: PopupProps) => {
  const { data: notice, isSuccess } = useQuery(
    ['transactionOverdueService.getOverdueNotice'],
    () => transactionOverdueService.getOverdueNotice({ id: noticeId }),
    { keepPreviousData: true },
  );

  return (
    <>
      <DialogTitle>
        <Trans>Overdue Notice For Employee</Trans>
      </DialogTitle>
      <DialogContent>{isSuccess && <PdfViewer objectKey={notice?.objectKey!} />}</DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ViewOverdueNoticePopup;
