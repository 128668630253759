import { Trans } from '@lingui/macro';
import { Container, Typography } from '@mui/material';
import { EpayzLayout } from 'layouts';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { Fragment, useState } from 'react';

const SalaryAdvance = () => {
  const userLocale = useActiveLocale();
  const [checkDoanhNghiep, setCheckDoanhNghiep] = useState(true);
  const [checkLaoDong, setCheckLaoDong] = useState(false);
  const [cauHoi1, setCauHoi1] = useState(true);
  const [cauHoi2, setCauHoi2] = useState(false);
  const [cauHoi3, setCauHoi3] = useState(false);
  const [cauHoi4, setCauHoi4] = useState(false);
  const [cauHoi5, setCauHoi5] = useState(false);

  return (
    <EpayzLayout>
      <div data-aos="fade-up" style={{ backgroundColor: 'white', position: 'relative' }}>
        <Container className='py-20' style={{ display: "flex" }}>
          <div style={{ width: "40%" }}>
            <div style={{ fontSize: "20px", marginBottom: "20px", marginTop: "30px" }}>{userLocale === 'vi-VN' ? <Trans>Với EPZ, ngày nào cũng là</Trans> : <Trans>With EPZ,</Trans>}</div>
            <div style={{ fontSize: "45px", fontWeight: 600, color: "rgb(192,0,0)", marginBottom: "20px" }}>{userLocale === 'vi-VN' ? <Trans>Ngày Nhận Lương</Trans> : <Trans>Everyday is Pay day!</Trans>}</div>
            <div style={{ fontSize: "14px", marginBottom: "50px" }}>{userLocale === 'vi-VN' ? <Trans>Chúng tôi giúp bạn hoàn toàn chủ động về tài chính cho nhu cầu tiêu dùng <br /> cá nhân của mình</Trans> : <Trans>We help you to be totally positive in your finance and personal <br /> consumption needs,</Trans>}</div>
            <div><Trans>Download EPZ now</Trans></div>
            <div style={{ display: "flex", marginTop: "15px" }}>
              <a style={{ marginRight: "20px" }} href='https://apps.apple.com/vn/app/epayz/id1627999955' target='_blank' rel='noreferrer'>
                <img
                  src={require('assets/icons/btn-app-store-white.jpg')}
                  className='cursor-pointer hover:opacity-70 ' style={{ width: "100px" }}
                />
              </a>
              <img
                src={require('assets/icons/btn-google-play-white.jpg')}
                className='cursor-pointer hover:opacity-70' style={{ width: "100px" }}
              />
            </div>
          </div>
          <div style={{ width: "60%" }}>
            <img style={{ width: "100%" }} src={require('assets/epayz/2868886.jpg')} />
          </div>
        </Container>
        {/* <div style={{ position: "absolute", width: "40%", height: "300px", left: 0, bottom: "-200px", background: "black", borderRadius: "0 300px 0 0" }}></div> */}
      </div>
      <div style={{ backgroundColor: 'white', position: 'relative' }}>
        <Container className='py-20' style={{ display: "flex" }}>
          <div style={{ width: "40%", alignSelf: 'center', background: "black", height: '400px', marginRight: '30px', borderRadius: '0 200px 200px 0', padding: 'inherit' }}>
            {/* <div style={{ position: "absolute", width: "40%", height: "400px", left: 0, bottom: 110, background: "black", borderRadius: "0 300px 300px 0" }}></div> */}
            <div style={{ fontSize: '40px', fontWeight: 600, marginBottom: "30px", color: 'white' }}><Trans>WHY</Trans></div>
            <div style={{ fontSize: '40px', fontWeight: 600, marginBottom: '25px', color: 'white' }}><Trans>THE EMPLOYEES</Trans></div>
            <div style={{ display: 'flex', fontSize: '40px', fontWeight: 600, alignItems: 'end', color: 'white' }}><Trans>LIKE</Trans> <img style={{ width: "150px", marginLeft: '15px' }} src={require('assets/epayz/bo nhan dien thuong hieu EPZ sua-12.png')} /> ?</div>
          </div>
          <div style={{ width: "60%" }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '40%', display: 'flex', justifyContent: 'center' }}><img style={{ width: "210px" }} src={require('assets/epayz/istockphoto-1327114327-612x612.jpg')} /></div>
              <div style={{ width: '60%', alignSelf: 'center' }}><Trans><span style={{ color: 'red' }}>42%</span> of employees run out the money before the monthly pay day.</Trans></div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '60%', alignSelf: 'center' }}><Trans>Enterprises lose average <span style={{ color: 'red' }}>27</span> talents every year due to stress related to salary and benefit.</Trans></div>
              <div style={{ width: '40%', display: 'flex', justifyContent: 'center' }}><img style={{ width: "220px" }} src={require('assets/epayz/epzpaint1.png')} /></div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '40%', display: 'flex', justifyContent: 'center' }}><img style={{ width: "210px" }} src={require('assets/epayz/epzpaint2.png')} /></div>
              <div style={{ width: '60%', alignSelf: 'center' }}><Trans><span style={{ color: 'red' }}>67%</span> of employees hope companies to support more financial health in work.</Trans></div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ backgroundColor: 'white', position: 'relative' }}>
        <Container className='py-20' style={{ display: "flex" }}>
          <div style={{ width: '50%' }}>
            <div style={{ display: 'flex' }}>
              <div onClick={() => { setCheckDoanhNghiep(true); setCheckLaoDong(false); }} style={checkDoanhNghiep == true ? { padding: "10px 20px", marginLeft: '15px', background: '#F6DBDB', color: '#C00000', borderRadius: '10px 10px 0 0', cursor: 'pointer' } : { padding: "10px 20px", marginLeft: '15px', cursor: 'pointer' }}><Trans>What employers get?</Trans></div>
              <div onClick={() => { setCheckDoanhNghiep(false); setCheckLaoDong(true); }} style={checkLaoDong == true ? { padding: "10px 20px", background: '#F6DBDB', color: '#C00000', borderRadius: '10px 10px 0 0', cursor: 'pointer' } : { padding: "10px 20px", cursor: 'pointer' }}><Trans>What employees get?</Trans></div>
            </div>
            <div style={{ width: '100%', height: '90%', border: "3px solid #F6DBDB", borderRadius: '10px' }}>
              {
                checkDoanhNghiep == true ?
                  <div style={{ width: '100%', height: '96%', display: 'inline-grid' }}>
                    <div>
                      <div style={{ display: 'flex', margin: '30px', marginBottom: '0px' }}>
                        <div style={{ backgroundImage: `url(/istockphoto-1326617351-170667a.jpg)`, width: '78px', height: '78px', backgroundPosition: '207px 263px' }}></div>
                        <div style={{ alignSelf: 'center' }}>
                          <div style={{ fontSize: '30px', fontWeight: 600, }}><Trans>Attract new talents</Trans></div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', margin: '0px 30px' }}>
                        <div style={{ width: '78px' }}></div>
                        <div style={{ fontSize: '15px' }}><Trans>Retaining the top talents in an increasingly competitive employee marketplace.</Trans></div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', margin: '30px', marginBottom: '0px' }}>
                        <div style={{ backgroundImage: `url(/istockphoto-1326617351-170667a.jpg)`, width: '78px', height: '78px', backgroundPosition: '207px 263px' }}></div>
                        <div style={{ alignSelf: 'center' }}>
                          <div style={{ fontSize: '30px', fontWeight: 600, }}><Trans>Improve team productivity</Trans></div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', margin: '0px 30px' }}>
                        <div style={{ width: '78px' }}></div>
                        <div style={{ paddingLeft: '19px', fontSize: '15px' }}><Trans>Reducing or even eliminating financial stress helps employees work comfortably and achieve optimal work performance.</Trans></div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', margin: '30px', marginBottom: '0px' }}>
                        <div style={{ backgroundImage: `url(/istockphoto-1326617351-170667a.jpg)`, width: '78px', height: '78px', backgroundPosition: '207px 263px' }}></div>
                        <div style={{ alignSelf: 'center' }}>
                          <div style={{ fontSize: '30px', fontWeight: 600, }}><Trans>ZERO cost</Trans></div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', margin: '0px 30px' }}>
                        <div style={{ width: '78px' }}></div>
                        <div style={{ paddingLeft: '19px', fontSize: '15px' }}><Trans>EPZ provide the free service to your company to help saving your money and resources spent on salary advances and loan grants.</Trans></div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', margin: '30px', marginBottom: '0px' }}>
                        <div style={{ backgroundImage: `url(/istockphoto-1326617351-170667a.jpg)`, width: '78px', height: '78px', backgroundPosition: '207px 263px' }}></div>
                        <div style={{ alignSelf: 'center' }}>
                          <div style={{ fontSize: '30px', fontWeight: 600, }}><Trans>Flexible process</Trans></div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', margin: '0px 30px' }}>
                        <div style={{ width: '78px' }}></div>
                        <div style={{ paddingLeft: '19px', fontSize: '15px' }}><Trans>EPZ’s services are extremely flexible and able to integrate with most of your departments and payroll processes.</Trans></div>
                      </div>
                    </div>
                  </div> :
                  <div style={{ width: '100%', height: '96%', display: 'inline-grid' }}>
                    <div>
                      <div style={{ display: 'flex', margin: '30px', marginBottom: '0px' }}>
                        <div style={{ backgroundImage: `url(/istockphoto-1326617351-170667a.jpg)`, width: '66px', height: '78px', backgroundPosition: '206px 95px' }}></div>
                        <div style={{ alignSelf: 'center' }}>
                          <div style={{ fontSize: '30px', fontWeight: 600, }}><Trans>Gain the initiative</Trans></div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', margin: '0px 30px' }}>
                        <div style={{ width: '78px' }}></div>
                        <div style={{ fontSize: '15px' }}><Trans>Responsible access to your income at any time and reduce your reliance on payday loans and debt cycles</Trans></div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', margin: '30px', marginBottom: '0px' }}>
                        <div style={{ backgroundImage: `url(/istockphoto-1326617351-170667a.jpg)`, width: '66px', height: '78px', backgroundPosition: '206px 95px' }}></div>
                        <div style={{ alignSelf: 'center' }}>
                          <div style={{ fontSize: '30px', fontWeight: 600, }}><Trans>Control your plans</Trans></div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', margin: '0px 30px' }}>
                        <div style={{ width: '78px' }}></div>
                        <div style={{ fontSize: '15px' }}><Trans>Once you are financially proactive, you can budget, track, save and make better financial choices</Trans></div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', margin: '30px', marginBottom: '0px' }}>
                        <div style={{ backgroundImage: `url(/istockphoto-1326617351-170667a.jpg)`, width: '66px', height: '78px', backgroundPosition: '206px 95px' }}></div>
                        <div style={{ alignSelf: 'center' }}>
                          <div style={{ fontSize: '30px', fontWeight: 600, }}><Trans>Low Cost</Trans></div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', margin: '0px 30px' }}>
                        <div style={{ width: '78px' }}></div>
                        <div style={{ fontSize: '15px' }}><Trans>You only pay a small fee based on the number of days you applied an advance before company’s pay day.</Trans></div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', margin: '30px', marginBottom: '0px' }}>
                        <div style={{ backgroundImage: `url(/istockphoto-1326617351-170667a.jpg)`, width: '66px', height: '78px', backgroundPosition: '206px 95px' }}></div>
                        <div style={{ alignSelf: 'center' }}>
                          <div style={{ fontSize: '30px', fontWeight: 600, }}><Trans>Easy access</Trans></div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', margin: '0px 30px' }}>
                        <div style={{ width: '78px' }}></div>
                        <div style={{ fontSize: '15px' }}><Trans>After just a few minutes, you will receive your money directly to your personal account to spend freely.</Trans></div>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
          <div style={{ width: '50%' }}>
            {checkDoanhNghiep == true ? <img style={{ width: "100%" }} src={require('assets/epayz/4669613.jpg')} /> :
              <img style={{ width: "100%" }} src={require('assets/epayz/image11.jpeg')} />}
          </div>
        </Container>
      </div>
      <div style={{ backgroundColor: 'black', position: 'relative' }}>
        <Container className='py-20'>
          <div style={{ textAlign: 'center', fontSize: '30px', color: 'white', fontWeight: 600 }}>{userLocale === 'vi-VN' ? <Trans>Các bước ứng lương trên E<span style={{ color: '#C00000' }}>P</span>Z</Trans> : <Trans>Steps to advance salary on E<span style={{ color: '#C00000' }}>P</span>Z</Trans>}</div>
          <div style={{ display: 'flex', marginTop: '35px' }}>
            <div style={{ width: '25%', color: 'white', textAlign: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: '75%' }} src={require('assets/epayz/Picture1.png')} /></div>
              <div style={{ fontSize: '30px', fontWeight: 600, margin: '20px' }}>01</div>
              <div style={{ fontSize: '15px' }}><Trans>Get account via email & message.<br /> Login & change your password.</Trans></div>
            </div>
            <div style={{ width: '25%', color: 'white', textAlign: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: '75%' }} src={require('assets/epayz/Picture2.png')} /></div>
              <div style={{ fontSize: '30px', fontWeight: 600, margin: '20px' }}>02</div>
              <div style={{ fontSize: '15px' }}><Trans>Verify electronic identity eKYC<br /> and confirm salary account</Trans></div>
            </div>
            <div style={{ width: '25%', color: 'white', textAlign: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: '75%' }} src={require('assets/epayz/Picture3.png')} /></div>
              <div style={{ fontSize: '30px', fontWeight: 600, margin: '20px' }}>03</div>
              <div style={{ fontSize: '15px' }}><Trans>Enter the advance amount and<br /> confirm transaction on app</Trans></div>
            </div>
            <div style={{ width: '25%', color: 'white', textAlign: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: '75%' }} src={require('assets/epayz/Picture4.png')} /></div>
              <div style={{ fontSize: '30px', fontWeight: 600, margin: '20px' }}>04</div>
              <div style={{ fontSize: '15px' }}><Trans>Enter the OTP sent to your phone<br /> to complete the transaction</Trans></div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ backgroundColor: 'white', position: 'relative' }}>
        <Container className='py-20'>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '30%' }}><img style={{ width: '100%' }} src={require('assets/epayz/13197716_SL-052720-31240-14.jpg')} /></div>
            <div style={{ width: '70%' }}>
              <div style={{ fontSize: '30px', fontWeight: 600, marginBottom: '30px' }}><Trans>FAQs</Trans></div>
              <div style={{ margin: '20px' }}>
                <div onClick={() => { setCauHoi1(true); setCauHoi2(false); setCauHoi3(false); setCauHoi4(false); setCauHoi5(false); }} style={{ fontSize: '20px', fontWeight: 600, cursor: 'pointer', color: cauHoi1 === true ? '#C00000' : 'black' }}>{cauHoi1 === false ? <span style={{ paddingRight: '15px' }}>+</span> : <span style={{ paddingRight: '15px' }}>-</span>}<Trans>Who can apply the salary advance on EPZ?</Trans></div>
                {cauHoi1 === true ? <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>Once you are an employee working for a company that cooperates with EPZ and having salary tranfered via bank by VND, you can apply for EPZ's Salary Advance Service.</Trans></div> : <div></div>}
              </div>
              <div style={{ margin: '20px' }}>
                <div onClick={() => { setCauHoi1(false); setCauHoi2(true); setCauHoi3(false); setCauHoi4(false); setCauHoi5(false); }} style={{ fontSize: '20px', fontWeight: 600, cursor: 'pointer', color: cauHoi2 === true ? '#C00000' : 'black' }}>{cauHoi2 === false ? <span style={{ paddingRight: '15px' }}>+</span> : <span style={{ paddingRight: '15px' }}>-</span>}<Trans>How can I know my advance amount?</Trans></div>
                {cauHoi2 === true ? <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>When you login EPZ app, it will show your maximum advance amount and appropriate labours (earned labours or even future labours). This amount has been based on the cooperation conditions and information that Enterprise provides to EPZ.</Trans></div> : <div></div>}
              </div>
              <div style={{ margin: '20px' }}>
                <div onClick={() => { setCauHoi1(false); setCauHoi2(false); setCauHoi3(true); setCauHoi4(false); setCauHoi5(false); }} style={{ fontSize: '20px', fontWeight: 600, cursor: 'pointer', color: cauHoi3 === true ? '#C00000' : 'black' }}>{cauHoi3 === false ? <span style={{ paddingRight: '15px' }}>+</span> : <span style={{ paddingRight: '15px' }}>-</span>}<Trans>What should my company do when cooperate with EPZ?</Trans></div>
                {cauHoi3 === true ?
                  <div>
                    <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>After signing cooperation with EPZ, your company only need to support EPZ to:</Trans></div>
                    <ul style={{ listStyleType: 'square', marginLeft: '15px' }}>
                      <li style={{ fontSize: '15px' }}><Trans>Update information & attendance of employees by free account granted by EPZ on website epz.com.vn</Trans></li>
                      <li style={{ fontSize: '15px' }}><Trans>Hold a meeting so that all Employees can know and use this welfare program.</Trans></li>
                    </ul>
                  </div> : <div></div>}
              </div>
              <div style={{ margin: '20px' }}>
                <div onClick={() => { setCauHoi1(false); setCauHoi2(false); setCauHoi3(false); setCauHoi4(true); setCauHoi5(false); }} style={{ fontSize: '20px', fontWeight: 600, cursor: 'pointer', color: cauHoi4 === true ? '#C00000' : 'black' }}>{cauHoi4 === false ? <span style={{ paddingRight: '15px' }}>+</span> : <span style={{ paddingRight: '15px' }}>-</span>}<Trans>How long will it take to get my advance payment?</Trans></div>
                {cauHoi4 === true ? <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>It will only take several seconds to place an order and your requested amount will be transferred to your personal account IMMEDIATELY after your order was placed successfully. If you don’t get money within 5 minutes from that time, please contact us for support.</Trans></div> : <div></div>}
              </div>
              <div style={{ margin: '20px' }}>
                <div onClick={() => { setCauHoi1(false); setCauHoi2(false); setCauHoi3(false); setCauHoi4(false); setCauHoi5(true); }} style={{ fontSize: '20px', fontWeight: 600, cursor: 'pointer', color: cauHoi5 === true ? '#C00000' : 'black' }}>{cauHoi5 === false ? <span style={{ paddingRight: '15px' }}>+</span> : <span style={{ paddingRight: '15px' }}>-</span>}<Trans>What makes EPZ's services stand out from others?</Trans></div>
                {cauHoi5 === true ?
                  <div>
                    <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>With Enterprise, EPZ’s service does not affect the attendance or salary payment process, all actions and contracts are digital signed 100%. It helps the HR and Accounting Department reduce the huge workload when managing salary advanced employees.</Trans></div>
                    <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>With Employee, EPZ’s service does not affect the customer’s CIC, does not require the credit assessment or collateral when trading too. Once you are an employee working at cooperated enterprise, you can apply salary advance at any time.</Trans></div>
                    <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>With EPZ, everyday is pay day!</Trans></div>
                  </div> : <div></div>}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </EpayzLayout>
  );
};

export default SalaryAdvance;

