import { Trans, t } from '@lingui/macro';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { AdvanceLevelCreateType, AdvanceLevelType } from 'types/EnterpriseSetupAdvance';

type PopupProps = PopupController & {
  id: number;
  advanceLevel: AdvanceLevelType;
};

const AdvanceLevelCreatePopup = ({ id, advanceLevel, onClose }: PopupProps) => {
  const userLocale = useActiveLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: createAdvanceLevel, isLoading } = useMutation(enterpriseSetupService.createAdvanceLevel, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.fetchAdvanceLevels');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: AdvanceLevelCreateType) => {
      createAdvanceLevel({
        ...values,
        id,
        employeeLevelId: advanceLevel.employeeLevelId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Advance Level</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TextField
              fullWidth
              label={t`Employee Level`}
              InputProps={{ readOnly: true }}
              value={
                userLocale === 'vi-VN' ? advanceLevel.employeeLevel.viContent : advanceLevel.employeeLevel.enContent
              }
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='minLimit'
              defaultValue={advanceLevel.minLimit ?? ''}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t`Minimun Limit`}
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                    endAdornment: 'VND',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='maxLimit'
              defaultValue={advanceLevel.maxLimit ?? ''}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t`Maximum Limit`}
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                    endAdornment: 'VND',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AdvanceLevelCreatePopup;
