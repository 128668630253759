import { Trans } from '@lingui/macro';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';

type PopupProps = {
  enterpriseId: string;
  onBack: () => void;
};

const SingleServicePopupStep4 = ({ enterpriseId, onBack }: PopupProps) => {
  const { data: { serviceTax } = {} } = useQuery(
    ['enterpriseSetupService.getEnterpriseServiceFeeCategoryInfo', { id: enterpriseId }],
    () =>
      enterpriseSetupService.getEnterpriseServiceFeeCategoryInfo({
        id: enterpriseId,
        appliedFee: 'SINGLE_SERVICE_FEE',
      }),
  );

  return (
    <>
      <DialogTitle>
        <Trans>ENTERPRISE'S SERVICE FEE SETTING</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Applied Fee</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Single Service Fee</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>VAT</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={serviceTax ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Payment Term</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>within 03 Working Days from receiving Notice of Single Fee Payment on Platform</Trans>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={onBack}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </>
  );
};

export default SingleServicePopupStep4;
