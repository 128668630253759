import { Trans } from '@lingui/macro';
import { ForgotPassword } from 'views/Epayz/ForgotPassword';
import { HomeEpayz } from 'views/Epayz/Home';
import { LoginEpayz } from 'views/Epayz/Login';
import { AboutEpayz, BrokerageAdvance, LimitedOffer, Partners, SalaryAdvance } from 'views/Epayz/Static';

const epayzRoute = {
  home: {
    path: '/',
    name: <Trans>Home</Trans>,
    element: <HomeEpayz />,
  },
  login: {
    path: '/login',
    name: <Trans>Login</Trans>,
    element: <LoginEpayz />,
  },
  forgotPassword: {
    path: '/forgot-password',
    name: <Trans>Forgot Password</Trans>,
    element: <ForgotPassword />,
  },
  offer: {
    path: '/offer',
    name: <Trans>Limited Offer</Trans>,
    element: <LimitedOffer />,
  },
  salaryAdvance: {
    path: '/salary-advance',
    name: <Trans>Salary Advance</Trans>,
    element: <SalaryAdvance />,
  },
  brokerageAdvance: {
    path: '/brokerage-advance',
    name: <Trans>Brokerage Advance</Trans>,
    element: <BrokerageAdvance />,
  },
  // forBusiness: {
  //   path: '/business',
  //   name: <Trans>For Business</Trans>,
  //   element: <ForBusiness />,
  // },
  partners: {
    path: '/partners',
    name: <Trans>Partners</Trans>,
    element: <Partners />,
  },
  aboutUs: {
    path: '/about-us',
    name: <Trans>About IFZ</Trans>,
    element: <AboutEpayz />,
  },
};

export default epayzRoute;
