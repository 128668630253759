import { Trans } from '@lingui/macro';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { useQuery } from 'react-query';
import { fileService } from 'services';
import { PopupController } from 'types/Common';
import { download } from 'utils/common';

type PopupProps = PopupController & {
  objectKey: string;
  showFooter: Boolean;
};

const ImageViewer = ({ showFooter, objectKey, onClose }: PopupProps) => {
  const { data: url, isSuccess } = useQuery(['fileService.downloadFile', { key: objectKey }], () =>
    fileService
      .downloadFile(objectKey)
      .then(async (data) => {
        var url = "";
        url = await base64ToBlob(data).then((blob) => {
          var file = new Blob([blob], { type: 'image/jpeg' });
          return window.URL.createObjectURL(new Blob([file], { type: 'image/jpeg' }))
        });
        return url;
      }),
  );

  const handleClickDownloadFile = () => {
    if (objectKey) {
      fileService.downloadFile(objectKey).then(async (data) => {
        var url = "";
        url = await base64ToBlob(data).then((blob) => {
          var file = new Blob([blob], { type: 'image/jpeg' });
          return window.URL.createObjectURL(new Blob([file], { type: 'image/jpeg' }))
        });
        let a = document.createElement('a');
        a.href = url;
        a.download = objectKey + '.jpg';
        a.click();
        // download(url, objectKey + '.jpg', 'image/jpeg');
      });
    }
  };

  return (
    <>
      <DialogContent>
        <div className='border'>{isSuccess && <img className='max-w-full max-h-full' src={url} />}</div>
      </DialogContent>
      {showFooter &&
        <DialogActions>
          <Button variant='contained' onClick={handleClickDownloadFile} className='m-2 text-right'>
            <Trans>Download</Trans>
          </Button>
          <Button variant='outlined' onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
        </DialogActions>
      }
    </>
  );
};
async function base64ToBlob(base64Data: any, contentType = "application/json") {
  const b64Res = await fetch(`data:${contentType};base64,${base64Data}`);
  return await b64Res.blob();
}
export default ImageViewer;
