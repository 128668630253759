import { Trans } from '@lingui/macro';
import { DialogActions, DialogContent, Button, DialogTitle, Grid, TableContainer, Table, TableRow, Paper, TableCell, Pagination, TableHead, TableBody } from '@mui/material';
import { TableEmpty } from 'components';
import { useSearch } from 'hooks';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { employeeLaborService } from 'services';
import { PopupController } from 'types/Common';
import { EmployeeLaborType } from 'types/EmployeeLabor';
import { formatNumber } from 'utils/common';

type PopupProps = {
    onClose: () => void;
    employeeId: string;
};
const AttendanceHistoryEmployee = ({ onClose, employeeId }: PopupProps) => {
    const [dataSearch, onSearchChange] = useSearch();
    const { data } = useQuery(
        ['employeeLaborService.fetchEmployeeLaborsHistory', { employeeId: employeeId, ...dataSearch }],
        () => employeeLaborService.fetchEmployeeLaborsHistory({ employeeId: employeeId, ...dataSearch }),
        { keepPreviousData: true },
    );
    const { data: items = [], paginate } = data ?? {}
    const userLocale = useActiveLocale();
    return (
        <>
            <DialogTitle>
                <Trans> {userLocale === 'vi-VN' ? "Lịch sử cập nhật ngày công" : "Update History"}</Trans>
            </DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Trans>Current Advance Term</Trans>
                                </TableCell>
                                <TableCell>
                                    <Trans>{userLocale === 'vi-VN' ? "Số ngày công cập nhật" : "No. of updated labours"}</Trans>
                                </TableCell>
                                <TableCell>
                                    <Trans>{userLocale === 'vi-VN' ? "Ngày cập nhật" : "Date"}</Trans>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{!item.fromDate || !item.toDate ? '' : DateTime.fromISO(item.fromDate).toFormat('dd/MM/yyyy') + " - " + DateTime.fromISO(item.toDate).toFormat('dd/MM/yyyy')}</TableCell>
                                    <TableCell>{formatNumber(item.totalLabor)}</TableCell>
                                    <TableCell>{item.updatedAt ? DateTime.fromISO(item.updatedAt).toFormat('dd/MM/yyyy') : ''}</TableCell>
                                </TableRow>
                            ))}
                            <TableEmpty data={items} />
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='flex justify-center'>
                    <Pagination
                        page={paginate?.page ?? 1}
                        count={paginate?.totalPage}
                        onChange={(event, value) => onSearchChange({ page: value })}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <div className='flex items-center justify-between w-full'>
                    <Button
                        variant='outlined'
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <Trans>Cancel</Trans>
                    </Button>
                </div>
            </DialogActions>
        </>
    );
};

export default AttendanceHistoryEmployee;
