import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { EmailRounded, Lock } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Container, InputAdornment, TextField, Typography } from '@mui/material';
import { InputOTP } from 'components';
import { EpayzFooter } from 'containers';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { epayzRoute } from 'routes';
import { passwordService } from 'services';
import { LoginEpayz } from 'views/Epayz/Login';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState(1);
  const [accessToken, setToken] = useState('');
  const [otp, setOtp] = useState('');

  const { control, handleSubmit } = useForm();

  const { mutate: forgotPassword, isLoading: isLoadingSend } = useMutation(passwordService.forgotPassword, {
    onSuccess: ({ token }) => {
      setToken(token);
      setStep((step) => step + 1);
    },
  });

  const { mutate: resendOtp } = useMutation(passwordService.forgotPassword, {
    onSuccess: ({ token }) => {
      setToken(token);
    },
  });

  const { mutate: confirmOtp, isLoading: isLoadingOtp } = useMutation(passwordService.confirmOtp, {
    onSuccess: () => {
      setStep((step) => step + 1);
    },
  });

  const { mutate: resetPassword, isLoading: isLoadingPassword } = useMutation(passwordService.resetPassword, {
    onSuccess: () => {
      enqueueSnackbar('Update password successfully');
      navigate(epayzRoute.login.path);
    },
  });

  const handleClickSendEmail = () => {
    handleSubmit(({ email }) => {
      forgotPassword({ email });
    })();
  };

  const handleResendOtp = () => {
    handleSubmit(({ email }) => {
      resendOtp({ email });
    })();
  };

  const handleClickSendOtp = () => {
    confirmOtp({
      params: { otp },
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };

  const handleClickSendPassword = () => {
    handleSubmit(({ password, confirmPassword }) => {
      resetPassword({
        params: { password, confirmPassword },
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    })();
  };

  return (
    <>
      <LoginEpayz.Header />
      <Container maxWidth='sm' className='flex flex-col py-32'>
        <Typography className='text-center text-5xl'>Quên mật khẩu</Typography>

        {step === 1 && (
          <Typography className='text-center mt-8 mb-12'>Nhập địa chỉ email của bạn đã đăng ký trên IFZ</Typography>
        )}
        {step === 2 && (
          <Typography className='text-center mt-8 mb-12'>
            Nhập OTP được gửi đến số điện thoại đăng ký của bạn
          </Typography>
        )}
        {step === 3 && <Typography className='text-center mt-8 mb-12'>Nhập mật khẩu mới</Typography>}

        <Container maxWidth='xs'>
          {step === 1 && (
            <Controller
              name='email'
              defaultValue='leminhquang133@gmail.com'
              control={control}
              rules={{ required: t`Email is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant='standard'
                  size='medium'
                  placeholder={t`Email`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <EmailRounded />
                      </InputAdornment>
                    ),
                  }}
                  className='mb-6'
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          )}

          {step === 2 && (
            <InputOTP
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputStyle={{
                marginLeft: '16px',
                border: '1px solid rgba(0, 0, 0, 0.3)',
                width: '48px',
                height: '48px',
                borderRadius: 4,
              }}
              className='mb-6'
            />
          )}

          {step === 3 && (
            <>
              <Controller
                name='password'
                defaultValue=''
                control={control}
                rules={{ required: t`Password is required` }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant='standard'
                    size='medium'
                    placeholder={t`Password`}
                    type='password'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Lock />
                        </InputAdornment>
                      ),
                    }}
                    className='mb-6'
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name='confirmPassword'
                defaultValue=''
                control={control}
                rules={{ required: t`Confirm Password is required` }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant='standard'
                    size='medium'
                    placeholder={t`Confirm Password`}
                    type='password'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Lock />
                        </InputAdornment>
                      ),
                    }}
                    className='mb-6'
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            </>
          )}

          {step === 2 && (
            <span className='italic hover:underline cursor-pointer' onClick={handleResendOtp}>
              <Trans>Resend OTP</Trans>
            </span>
          )}

          <div className='flex justify-center mt-10'>
            {step === 1 && (
              <LoadingButton
                variant='contained'
                size='large'
                className='w-60 bg-primary-main hover:opacity-90'
                loading={isLoadingSend}
                onClick={handleClickSendEmail}
              >
                <Trans>SEND</Trans>
              </LoadingButton>
            )}
            {step === 2 && (
              <LoadingButton
                variant='contained'
                size='large'
                className='w-60 bg-primary-main hover:opacity-90'
                loading={isLoadingOtp}
                onClick={handleClickSendOtp}
              >
                <Trans>NEXT</Trans>
              </LoadingButton>
            )}
            {step === 3 && (
              <LoadingButton
                variant='contained'
                size='large'
                className='w-60 bg-primary-main hover:opacity-90'
                loading={isLoadingPassword}
                onClick={handleClickSendPassword}
              >
                <Trans>CONFIRM</Trans>
              </LoadingButton>
            )}
          </div>

          <div className='flex justify-center mt-6'>
            <Link to={epayzRoute.login.path}>
              <span className='italic hover:underline'>
                {'<'} <Trans>Back</Trans>
              </span>
            </Link>
          </div>
          <LoginEpayz.Contact />
        </Container>
      </Container>

      <EpayzFooter />
    </>
  );
};

export default ForgotPassword;
