import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { usePermission } from 'hooks';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { Grid, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enterpriseService, queryClient } from 'services';
import { InputNumber, InputNumberPhone } from 'components';
import { EnterpriseType, EnterpriseUpdateType } from 'types/Enterprise';

const EnterpriseInfo = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requireUserPC, requreUserPermission, ACCOUNT_ENTERPRISE_UPDATE } = usePermission();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  useEffect(() => {
    Object.entries(enterprise ?? {}).forEach(([key, value]) => {
      if (['firstGrantedDate', 'lastAmendmentDate', 'attorneySigningDate'].includes(key)) {
        if (value) {
          setValue(key, DateTime.fromISO(value as string));
        }
      } else if (value) setValue(key, value);
    });
  }, [enterprise, setValue]);

  const { mutate: updateEnterprise, isLoading } = useMutation(enterpriseService.updateEnterprise, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseService.getEnterpriseById');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      type ValuesType = EnterpriseUpdateType & {
        firstGrantedDate: DateTime;
        lastAmendmentDate: DateTime;
        attorneySigningDate: DateTime;
      };
      const { firstGrantedDate, lastAmendmentDate, attorneySigningDate, ...others } = values as ValuesType;
      if (values.amendmentNo) {
        if ((!values.lastAmendmentDate)) {
          enqueueSnackbar("Ngày cấp không được để trống", { variant: 'error' });
          return;
        } else if (!values.lastAmendmentDate.isValid) {
          enqueueSnackbar("Ngày cấp không đúng định dạng", { variant: 'error' });
          return;
        }
      } else {
        if (values.lastAmendmentDate && !values.lastAmendmentDate.isValid) {
          enqueueSnackbar("Ngày cấp không đúng định dạng", { variant: 'error' });
          return;
        }
      }
      updateEnterprise({
        ...others,
        firstGrantedDate: firstGrantedDate.toISO(),
        lastAmendmentDate: lastAmendmentDate ? lastAmendmentDate.toISO() : undefined,
        attorneySigningDate: attorneySigningDate ? attorneySigningDate.toISO() : undefined,
        id: enterprise?.id!,
      });
    })();
  };

  return (
    <div className='mt-6'>
      <Typography variant='h4' className='mb-4'>
        <Trans>Enterprise Information</Trans>
      </Typography>

      <Grid container columnSpacing={4} rowSpacing={3}>
        <Grid item sm={6}>
          <Controller
            name='name'
            defaultValue=''
            control={control}
            rules={{ required: t`Enterprise Name is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Enterprise Name`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='taxCode'
            defaultValue=''
            control={control}
            rules={{ required: t`Tax Code is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                required
                label={t`Tax Code`}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='shortName'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth label={t`Short Name`} error={invalid} helperText={error?.message} inputProps={{ maxLength: 12 }} />
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name='address'
            defaultValue=''
            control={control}
            rules={{ required: t`Address is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField fullWidth required label={t`Address`} {...field} error={invalid} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='phone'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField fullWidth label={t`Phone`} {...field} error={invalid} helperText={error?.message}
                onKeyPress={(event) => {
                  const keyCode = event.keyCode || event.which;
                  const keyValue = String.fromCharCode(keyCode);
                  if (!/[0-9]/.test(keyValue))
                    event.preventDefault();
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='fax'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField fullWidth label={t`Fax`} {...field} error={invalid} helperText={error?.message} />
            )}
          />
        </Grid>

        <Grid item sm={3}>
          <Controller
            name='ercNumber'
            defaultValue=''
            control={control}
            rules={{ required: t`ERC Number is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`ERC Number`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='firstGrantedDate'
            defaultValue=''
            control={control}
            rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={onChange}
                renderInput={(props) => <TextField {...props} required error={invalid} />}
                label={t`First Granted Date`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='issuanceAgency'
            defaultValue=''
            control={control}
            rules={{ required: t`Issuance Agency is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Issuance Agency`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}></Grid>

        <Grid item sm={3}>
          <Controller
            name='amendmentNo'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Amendment No`}
                InputProps={{
                  inputComponent: InputNumber as any,
                  inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='lastAmendmentDate'
            defaultValue=''
            control={control}
            // rules={{ validate: { isValid: (value: DateTime) => !value || value.isValid } }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={onChange}
                renderInput={(props) => <TextField {...props} error={invalid} />}
                label={t`Last Granted Date`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
        </Grid>
        <Grid item sm={6}></Grid>

        <Grid item sm={3}>
          <Controller
            name='authorizedRepresentative'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Authorized Representative`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='authorizedRepresentativePosition'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Authorized Representative Position`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='attorneyLetterNo'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Attorney Letter No`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='attorneySigningDate'
            defaultValue=''
            control={control}
            rules={{ validate: { isValid: (value: DateTime) => !value || value.isValid } }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={onChange}
                renderInput={(props) => <TextField {...props} error={invalid} />}
                label={t`Attorney Signing Date`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
        </Grid>

        <Grid item sm={3}>
          <Controller
            name='legalRepresentative'
            defaultValue=''
            control={control}
            rules={{ required: t`Legal Representative Name is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Legal Representative`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='legalRepresentativePosition'
            defaultValue=''
            control={control}
            rules={{ required: t`Legal Representative Position is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Legal Representative Position`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={6}></Grid>

        <Grid item sm={3}>
          <Controller
            name='contactName'
            defaultValue=''
            control={control}
            rules={{ required: t`Contact Name is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Contact Name`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='contactDepartment'
            defaultValue=''
            control={control}
            rules={{ required: 'Contact Department is required' }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Contact Department`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='contactPhone'
            defaultValue=''
            control={control}
            rules={{ required: t`Contact Phone is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Contact Phone`}
                error={invalid}
                helperText={error?.message}
                //InputProps={{inputComponent: InputNumberPhone as any,}}
                onKeyPress={(event) => {
                  const keyCode = event.keyCode || event.which;
                  const keyValue = String.fromCharCode(keyCode);
                  if (!/[0-9]/.test(keyValue))
                    event.preventDefault();
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='contactEmail'
            defaultValue=''
            control={control}
            rules={{ required: t`Contact Email is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Contact Email`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
      </Grid>

      {requireUserPC() && requreUserPermission([ACCOUNT_ENTERPRISE_UPDATE]) && (
        <div className='flex justify-end mt-4'>
          <LoadingButton variant='contained' className='w-32' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
      )}
    </div>
  );
};

export default EnterpriseInfo;
