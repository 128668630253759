import { Trans } from '@lingui/macro';
import { Button, Dialog, Typography } from '@mui/material';
import { RevisedStatus, WaitSettlementStatus } from 'constants/transaction';
import { useState } from 'react';
import { TransReviseType, TransType } from 'types/Transaction';
import { TransFeeBank, TransSettlementInfo } from 'views/Transaction/TransSettlement/TransSettlementView/components';
import { usePermission } from 'hooks';
import {
  TransRevisePopup,
  TransReviseViewPopup,
  TransSettlementAddRecordPopup,
  TransTransferOverduePopup,
} from 'views/Transaction/TransSettlement/TransSettlementView/popups';

const TransWaitSettlement = ({ transaction }: { transaction?: TransType }) => {
  const [isOpenRecord, setOpenRecord] = useState(false);
  const [isOpenOverdue, setOpenOverdue] = useState(false);
  const [isOpenRevise, setOpenRevise] = useState(false);
  const [isOpenReviseView, setOpenReviseView] = useState(false);
  const [transRevise, setTransRevise] = useState<TransReviseType>();
  const { requreUserPermission, TRANSACTION_DIEUCHINH, TRANSACTION_GHINHAN, TRANSACTION_CHUYENNOQUAHAN } = usePermission();
  return (
    <>
      <div className='mt-6'>
        <div className='flex justify-between'>
          <div className='flex items-center space-x-3'>
            <Typography variant='h4'>
              <Trans>Transaction Settlement</Trans>
            </Typography>
            {(transaction?.status === WaitSettlementStatus || transaction?.status === RevisedStatus) && requreUserPermission([TRANSACTION_DIEUCHINH]) && (
              <Button variant='contained' onClick={() => setOpenRevise(true)}>
                <Trans>Revise</Trans>
              </Button>
            )}
          </div>
        </div>
        <TransFeeBank transaction={transaction} />
      </div>
      <div className='mt-6'>
        {(transaction?.status === WaitSettlementStatus || transaction?.status === RevisedStatus) && (
          <div className='flex justify-between'>
            <div className='flex items-center space-x-3'>
              { requreUserPermission([TRANSACTION_GHINHAN]) &&
              <Button variant='contained' onClick={() => setOpenRecord(true)}>
                <Trans>Record</Trans>
              </Button>
              }
              { requreUserPermission([TRANSACTION_CHUYENNOQUAHAN]) &&
              <Button variant='outlined' onClick={() => setOpenOverdue(true)}>
                <Trans>Transfer Overdue</Trans>
              </Button>
              }
            </div>
          </div>
        )}
        <TransSettlementInfo transaction={transaction} />
      </div>
      <Dialog fullWidth maxWidth='lg' open={isOpenRecord} onClose={() => setOpenRecord(false)}>
        <TransSettlementAddRecordPopup transaction={transaction!} onClose={() => setOpenRecord(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='lg' open={isOpenOverdue} onClose={() => setOpenOverdue(false)}>
        <TransTransferOverduePopup transaction={transaction!} onClose={() => setOpenOverdue(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='xl' open={isOpenRevise} onClose={() => setOpenRevise(false)}>
        <TransRevisePopup
          transactionId={transaction?.id!}
          salaryPaymentDate={transaction?.salaryPaymentDate}
          onClose={() => setOpenRevise(false)}
          onCreated={(item: TransReviseType) => {
            setTransRevise(item);
            setOpenRevise(false);
            setOpenReviseView(true);
          }}
        />
      </Dialog>
      <Dialog fullWidth maxWidth='lg' open={isOpenReviseView} onClose={() => setOpenReviseView(false)}>
        {transRevise && (
          <TransReviseViewPopup
            salaryPaymentDate={transaction?.salaryPaymentDate!}
            transactionRevise={transRevise!}
            onClose={() => setOpenReviseView(false)}
          />
        )}
      </Dialog>
    </>
  );
};

export default TransWaitSettlement;
