import { Trans } from '@lingui/macro';
import { Container, Typography, Button } from '@mui/material';
import { EpayzLayout } from 'layouts';

const AboutEpayz = () => {
  return (
    <EpayzLayout>
      <div data-aos="fade-up">
        <Container className='py-20' style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <img style={{ width: "500px" }} src={require('assets/epayz/abstract_538_1.png')} />
          </div>
          <div style={{ width: '50%', alignSelf: 'center' }}>
            <div style={{ fontSize: '30px', fontWeight: 600, color: '#7F7F7F' }}><Trans>ABOUT US</Trans></div>
            <div style={{ fontSize: '40px', fontWeight: 600, margin: '30px 0px' }}><Trans>Fintech company<br /> with the unique products</Trans></div>
            <div><Trans>EPZ always provides you with unique products and services to help you proactively<br /> access your own economic resources.</Trans></div>
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="primary" style={{ backgroundColor: "black !important", color: 'white', borderRadius: '20px' }}>
                <Trans>Contact EPZ</Trans>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                  <path d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                </svg>
              </Button>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ background: '#18181C' }}>
        <Container className='py-20' style={{ color: 'white' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <div style={{ fontSize: '25px' }}><Trans>Core Values</Trans></div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ height: '4px', width: '80px', background: 'white', margin: '10px 0px' }}></div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: "70px" }} src={require('assets/epayz/image53.png')} /></div>
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: '40px', justifyContent: 'space-between' }}>
            <div style={{ width: '230px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: "40px" }} src={require('assets/epayz/image50.png')} /></div>
              <div style={{ fontWeight: 600, textAlign: 'center', margin: '10px 0px' }}><Trans>PEOPLE</Trans></div>
              <div style={{ fontSize: '14px', textAlign: 'center' }}><Trans>We do our best for the<br /> development of each other, help<br /> them reach their potential and<br /> achieve their desired goals.</Trans></div>
            </div>
            <div style={{ width: '230px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: "35px", height: '40px' }} src={require('assets/epayz/icon-respect.png')} /></div>
              <div style={{ fontWeight: 600, textAlign: 'center', margin: '10px 0px' }}><Trans>INTEGRITY</Trans></div>
              <div style={{ fontSize: '14px', textAlign: 'center' }}><Trans>We aim for the fairness,<br /> transparency and respect among<br /> parties, which is the foundation for<br /> all EPZ activities.</Trans></div>
            </div>
            <div style={{ width: '230px' }}>
              <div style={{ display: 'flex', justifyContent: 'center', height: '40px', alignItems: 'end' }}><img style={{ width: "35px", height: '30px' }} src={require('assets/epayz/25-254406_white-person-icon-png-people-white-icon-png.png')} /></div>
              <div style={{ fontWeight: 600, textAlign: 'center', margin: '10px 0px' }}><Trans>TEAMMATE</Trans></div>
              <div style={{ fontSize: '14px', textAlign: 'center' }}><Trans>We believe in deep cooperation<br /> and collaboration in a team to<br /> bring to our clients the<br /> outstanding innovation.</Trans></div>
            </div>
            <div style={{ width: '230px' }}>
              <div style={{ display: 'flex', justifyContent: 'center', height: '40px', alignItems: 'end' }}><img style={{ width: "35px", height: "35px" }} src={require('assets/epayz/331-3316045_rezultate-white-muscle-icon-transparent.png')} /></div>
              <div style={{ fontWeight: 600, textAlign: 'center', margin: '10px 0px' }}><Trans>RESPONSIBLE</Trans></div>
              <div style={{ fontSize: '14px', textAlign: 'center' }}><Trans>We believe that we need to<br /> be responsible in control and<br /> maintaining the products that<br /> we make</Trans></div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ background: '#DF7F7F', position: 'relative' }}>
        <Container className='py-20' style={{ position: 'relative', zIndex: 2, display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <div style={{ fontSize: '40px', color: '#DF7F7F' }}><Trans>Our mission</Trans></div>
            <div style={{ fontSize: '15px', fontWeight: 600, marginTop: '40px' }}><Trans>EPZ’s mission is to focus on solving individual financial<br /> needs to give opportunities to each other and then the<br /> whole market to develop, reach out and break all limits to<br /> achieve their own goals.</Trans></div>
            <div style={{ width: '320px', height: '4px', background: '#DF7F7F', margin: '40px 0px' }}></div>
            <div style={{ width: '320px', display: 'flex', justifyContent: 'center' }}><img style={{ width: "200px" }} src={require('assets/epayz/image55.png')} /></div>
          </div>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
            <div>
              <div style={{ width: '320px', display: 'flex', justifyContent: 'center' }}><img style={{ width: "200px" }} src={require('assets/epayz/image56.png')} /></div>
              <div style={{ width: '320px', height: '4px', background: 'white', margin: '40px 0px' }}></div>
              <div style={{ fontSize: '40px', color: 'white', textAlign: 'end' }}><Trans>Our vision</Trans></div>
              <div style={{ fontSize: '15px', fontWeight: 600, marginTop: '30px' }}><Trans>Making EPZ to be the leading company in the<br /> field of salary and brokerage advances in Vietnam<br /> market. We commit to always be a reliable partner<br /> helping customers achieve their goals and<br /> success.</Trans></div>
            </div>
          </div>
        </Container>
        <div style={{ position: 'absolute', top: '0px', left: '0px', width: '55%' }}>
          <img style={{ width: "100%" }} src={require('assets/epayz/backgroungtranghong2.png')} />
        </div>
      </div>
      <div style={{ position: 'relative', zIndex: 2, background: '#DBDBDB' }}>
        <Container className='py-20' style={{ position: 'relative', zIndex: 2 }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ alignSelf: 'end', fontSize: '30px', marginRight: '10px' }}><Trans>Business Sectors of </Trans></div>
            <img style={{ width: "100px" }} src={require('assets/epayz/bo nhan dien thuong hieu EPZ sua-12.png')} />
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px', fontStyle: 'italic' }}><Trans>EPZ is so proud to be the first and exclusive company working in salary and brokerage advance based on debt trading license</Trans></div>
          <div style={{ display: 'flex', marginTop: '30px', justifyContent:'space-between' }}>
            <div style={{ width: '290px' }}>
              <div style={{ display: 'flex', justifyContent: 'center', height: '100px' }}><img src={require('assets/epayz/image80.png')} /></div>
              <div style={{ width: '100%',boxShadow: '1px 1px 5px 5px #DEDEDE', borderRadius: '0px 0px 10px 10px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '65px', background: '#3B3838', borderRadius: '10px 10px 0px 0px' }}>
                  <div style={{ fontSize: '17px', fontWeight: 600, color: 'white' }}><Trans>SALARY ADVANCE</Trans></div>
                </div>
                <div style={{ background: 'white', borderRadius: '0px 0px 10px 10px' }}>
                  <div style={{height:'250px'}}>
                    <div style={{ fontSize: '14px', textAlign: 'center', paddingTop: '30px' }}><Trans>Digital advance service and money<br /> is paid to employee’s bank account<br /> after only a few minutes after<br /> placing order on app.</Trans></div>
                    <div style={{ fontSize: '14px', textAlign: 'center', paddingTop: '40px', fontWeight: 600 }}><Trans>Ensure the welfare for employees<br /> and attract new talents for the<br />  employers</Trans></div>
                  </div>
                  <div style={{height: '70px', display: 'flex', justifyContent: 'center' }}>
                    <div><Button variant="contained" color="primary" style={{ backgroundColor: "black !important", color: 'white', borderRadius: '20px' }}><div style={{ fontSize: '12px' }}><Trans>More</Trans></div></Button></div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '290px' }}>
              <div style={{ display: 'flex', justifyContent: 'center', height: '100px' }}><img src={require('assets/epayz/image81.png')} /></div>
              <div style={{ width: '100%',boxShadow: '1px 1px 5px 5px #DEDEDE', borderRadius: '0px 0px 10px 10px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '65px', background: '#90A3B4', borderRadius: '10px 10px 0px 0px' }}>
                  <div style={{ fontSize: '17px', fontWeight: 600, color: 'white' }}><Trans>BROKERAGE ADVANCE</Trans></div>
                </div>
                <div style={{ background: 'white', borderRadius: '0px 0px 10px 10px' }}>
                  <div style={{height:'250px'}}>
                    <div style={{ fontSize: '14px', textAlign: 'center', paddingTop: '30px' }}><Trans>Provide the service of brokerage<br /> commission advance for individual<br /> brokers working in Securities, Real<br /> Estate, Insurance.</Trans></div>
                    <div style={{ fontSize: '14px', textAlign: 'center', paddingTop: '40px', fontWeight: 600 }}><Trans>Fast approval time, money will be<br /> disbursed with 3 working hours<br /> since applying the order</Trans></div>
                  </div>
                  <div style={{height: '70px', display: 'flex', justifyContent: 'center' }}>
                    <div><Button variant="contained" color="primary" style={{ backgroundColor: "black !important", color: 'white', borderRadius: '20px' }}><div style={{ fontSize: '12px' }}><Trans>More</Trans></div></Button></div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '290px' }}>
              <div style={{ display: 'flex', justifyContent: 'center', height: '100px' }}><img src={require('assets/epayz/image82.png')} /></div>
              <div style={{ width: '100%',boxShadow: '1px 1px 5px 5px #DEDEDE', borderRadius: '0px 0px 10px 10px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '65px', background: '#F57880', borderRadius: '10px 10px 0px 0px' }}>
                  <div style={{ fontSize: '17px', fontWeight: 600, color: 'white' }}><Trans>FINANCIAL INVESTMENT</Trans></div>
                </div>
                <div style={{ background: 'white', borderRadius: '0px 0px 10px 10px' }}>
                  <div style={{height:'250px'}}>
                    <div style={{ fontSize: '14px', textAlign: 'center', paddingTop: '30px' }}><Trans>Offering partners a wide selection of<br /> diversified, safe and attractive<br /> investment channels.</Trans></div>
                    <div style={{ fontSize: '14px', textAlign: 'center', paddingTop: '60px', fontWeight: 600 }}><Trans>Investment yield is higher than<br /> savings at banks and low risk</Trans></div>
                  </div>
                  <div style={{height: '70px', display: 'flex', justifyContent: 'center' }}>
                    <div><Button variant="contained" color="primary" style={{ backgroundColor: "black !important", color: 'white', borderRadius: '20px' }}><div style={{ fontSize: '12px' }}><Trans>More</Trans></div></Button></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div style={{background:'white',width:'100%',position:'absolute',left:'0px',bottom:'0px',height:'55%'}}></div>
      </div>
      <div style={{ position: 'relative', zIndex: 2, background: 'white' }}>
        <Container className='py-20'>
          <div style={{ textAlign: 'center', fontSize: '30px', fontWeight: 600 }}><Trans>We are here to grow with you</Trans></div>
          <div style={{ display: 'flex', marginTop: '40px', justifyContent: 'space-between' }}>
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: "30px" }} src={require('assets/epayz/image60.png')} /></div>
              <div style={{ fontSize: '17px', fontWeight: 600, textAlign: 'center', marginTop: '20px' }}><Trans>Register an account</Trans></div>
              <div style={{ fontSize: '15px', textAlign: 'center', color: '#9D9A9A', marginTop: '20px' }}><Trans>You want to register an account on EPZ platform?</Trans></div>
              <div style={{ fontSize: '15px', color: 'red', textAlign: 'center', marginTop: '20px', textDecoration: 'underline' }}><Trans>Register account</Trans></div>
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: "30px" }} src={require('assets/epayz/image61.jpeg')} /></div>
              <div style={{ fontSize: '17px', fontWeight: 600, textAlign: 'center', marginTop: '20px' }}><Trans>Support Center</Trans></div>
              <div style={{ fontSize: '15px', textAlign: 'center', color: '#9D9A9A', marginTop: '20px' }}><Trans>Do you need support on transaction, platform?</Trans></div>
              <div style={{ fontSize: '15px', color: 'red', textAlign: 'center', marginTop: '20px', textDecoration: 'underline' }}><Trans>Support request</Trans></div>
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}><img style={{ width: "30px" }} src={require('assets/epayz/image62.png')} /></div>
              <div style={{ fontSize: '17px', fontWeight: 600, textAlign: 'center', marginTop: '20px' }}><Trans>Consultants</Trans></div>
              <div style={{ fontSize: '15px', textAlign: 'center', color: '#9D9A9A', marginTop: '20px' }}><Trans>Do you want to know about EPZ’s services?</Trans></div>
              <div style={{ fontSize: '15px', color: 'red', textAlign: 'center', marginTop: '20px', textDecoration: 'underline' }}><Trans>Consulting request</Trans></div>
            </div>
          </div>
        </Container>
      </div>
    </EpayzLayout>
  );
};

export default AboutEpayz;
