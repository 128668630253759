import styled from '@emotion/styled';
import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import useDebounce from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input-rc-17';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { employeeService, transactionService } from 'services';
import { formatNumber } from 'utils/common';
import { TableEmpty } from 'components';
import { TransPromotionType } from 'types/Transaction';
import { DateTime } from 'luxon';
import { getPromotionName } from 'constants/transaction';
import { useParams } from 'react-router-dom';
const Card = styled.div`
  border-radius: 8px;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 8px;
  padding: 16px;
  flex: 1;
`;
function Payroll() {
  const queryParameters = new URLSearchParams(window.location.search)
  const paramId = queryParameters.get("id")
  const [firstParam, onChangefirstParam] = useState(false);
  
  const [selectedEnterprise, setSelectedEnterprise] = useState<number | ''>('');

  const user = useSelector(profileSelector);
  const { data } = useQuery(
    ['transactionService.getTransactionLimit'],
    () => transactionService.getTransactionLimit(),
    { keepPreviousData: true },
  );

  const { data: workingData } = useQuery(
    ['employeeService.fetchEmployeeWorkings', { _id: user.enterprise?.id, size: 1000 }],
    () => employeeService.fetchEmployeeWorkings({ _id: user.enterprise?.id?.toString() || '', size: 1000 }),
    { onSuccess: (data)=>{
      debugger;
      if(paramId && firstParam == false && isNumeric(paramId) == true){
          var check = data.data.filter(function(x){
            return x.id == parseInt(paramId)
          });
          if(check.length > 0){
            var dt = check[0] as any;
            setSelectedEnterprise(parseInt(dt.enterpriseId))
          }
          onChangefirstParam(true);
        }else{
          onChangefirstParam(true);
        }
      }}
  );

  function isNumeric(value : string) {
    return /^-?\d+$/.test(value);
}

  const selectedEnterpriseFullInfo = workingData?.data.find((item) => item.enterprise.id === selectedEnterprise);

  const handleSelectCompany = (e: any) => {
    setSelectedEnterprise(e.target.value);
  };

  const [amount, setAmount] = useState('');
  const debouncedValue = useDebounce(amount, 500);

  const [transaction, setTransaction] = useState<any>();
  const [promotions, setPromotions] = useState<TransPromotionType[]>([]);
  const [promotionId, setPromotionId] = useState(0);
  const [selectPromotionId, setSelectPromotionId] = useState(0);
  const [invalidTrans, setInvalidTrans] = useState(true);

  useEffect(() => {
    if (selectedEnterprise && debouncedValue) {
      setInvalidTrans(true);
      transactionService
        .calculateTransaction({
          amount: Number(debouncedValue),
          enterpriseId: selectedEnterprise,
          promotionId,
        })
        .then((trans) => {
          setTransaction(trans);
          setInvalidTrans(false);
        });
    }
  }, [debouncedValue, selectedEnterprise, promotionId]);

  useEffect(() => {
    console.log(selectedEnterprise)
    if (selectedEnterprise) {
      transactionService
        .getPromotions({
          enterpriseId: selectedEnterprise,
          amount: 0,
        })
        .then((data) => {
          if (data && data.promotions) {
            setPromotions(data.promotions);
          } else {
            setPromotions([]);
          }
        });
    }
  }, [selectedEnterprise]);

  const [creatingTransaction, setCreatingTx] = useState(false);
  const [employeeNotice, setEmployeeNotice] = useState<any>();
  const handleCreateTx = () => {
    setCreatingTx(true);
    transactionService
      .postTransaction({
        amount: Number(debouncedValue),
        enterpriseId: selectedEnterprise,
        promotionId,
      })
      .then((res: any) => {
        setTransaction(res);
        transactionService.getTransactionEmployeeIncomeNotice(res.id).then((r: any) => {
          setEmployeeNotice(r);
        });
      })
      .finally(() => {
        setCreatingTx(false);
      });
  };

  const [openPromotion, setOpenPromotion] = useState(false);
  const [otp, setOtp] = useState('');
  const handleReject = () => {
    setEmployeeNotice(undefined);
    setActiveStep(0);
  };
  const [confirming, setConfiming] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleConfirm = () => {
    setConfiming(true);
    transactionService
      .sentOtp(transaction.id)
      .then((res) => {
        setActiveStep(1);
      })
      .finally(() => {
        setConfiming(false);
      });
  };
  const handleSendOTP = () => {
    setConfiming(true);
    transactionService
      .confirmOtp(transaction.id, otp)
      .then((res) => {
        setActiveStep(3);
      })
      .finally(() => {
        setConfiming(false);
      });
  };

  return (
    <Paper>
      <Dialog open={!!employeeNotice?.data && activeStep === 0} fullWidth maxWidth='lg'>
        <DialogTitle>
          <Trans>TRANSACTION NOTICE</Trans>
        </DialogTitle>
        <DialogContent style={{ height: '90vh' }}>
          {employeeNotice?.data && (
            <embed className='w-full h-full' src={`data:${employeeNotice.contentType};base64,${employeeNotice.data}`} />
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton variant='outlined' onClick={handleReject}>
            <Trans>Reject</Trans>
          </LoadingButton>
          <LoadingButton loading={confirming} variant='contained' onClick={handleConfirm}>
            <Trans>Confirm</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openPromotion} fullWidth maxWidth='md'>
        <DialogTitle>
          <Trans>LIST OF PROMOTION</Trans>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Trans>Select</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Promotion</Trans>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {promotions.map((item, idx) => (
                  <TableRow key={item.id}>
                    <TableCell align='center'>
                      <Checkbox
                        checked={item.id === selectPromotionId}
                        onChange={(event, checked) => {
                          if (checked) setSelectPromotionId(item.id);
                          else setSelectPromotionId(0);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {getPromotionName(
                        item.minimumRequestAmount,
                        item.promotionTransactionCost,
                        item.promotionDiscountRate,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableEmpty data={promotions} />
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <LoadingButton variant='outlined' onClick={() => setOpenPromotion(false)}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton
            variant='contained'
            onClick={() => {
              setPromotionId(selectPromotionId);
              setOpenPromotion(false);
            }}
          >
            <Trans>Apply</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h4'>
          <Trans>Payroll Application</Trans>
        </Typography>
      </div>

      <div className='p-5'>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>
              <Trans>Request</Trans>
            </StepLabel>
          </Step>

          <Step>
            <StepLabel>
              <Trans>Confirmation</Trans>
            </StepLabel>
          </Step>

          <Step>
            <StepLabel>
              <Trans>Result</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 && (
          <>
            <div className='flex justify-between gap-5 mt-4'>
              <Card>
                <div className='text-sm'>
                  <Trans>MAX PAYMENT OF ADVANCE:</Trans>
                </div>
                <div className='font-semibold text-info-main'>{formatNumber(data?.maxAdvanceAmount)}đ</div>
              </Card>

              <Card>
                <div className='text-sm'>
                  <Trans>MAX LABOUR OF ADVANCE:</Trans>
                </div>
                <div className='font-semibold text-info-main'>
                  {data?.maxAdvanceLabor} <Trans>Days</Trans>
                </div>
              </Card>
            </div>

            <div className='flex mt-4 gap-5'>
              <div className='flex-1'>
                <div className='font-medium'>
                  <Trans>Select working Company</Trans>
                </div>
                  <Select
                  className='mt-2 w-full'
                  size='small'
                  value={selectedEnterprise}
                  onChange={handleSelectCompany}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {workingData?.data.map((item) => (
                    <MenuItem key={item.enterprise.id} value={item.enterprise.id}>
                      {item.enterprise.name}
                    </MenuItem>
                  ))}
                </Select>
                
              </div>

              <div className={classNames('flex-1', { 'opacity-30 pointer-events-none': !selectedEnterprise })}>
                <div className='font-medium'>
                  <Trans>Request Amount (VND)</Trans>
                </div>
                <OutlinedInput
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  size='small'
                  className='mt-2 w-full'
                  type='number'
                  endAdornment={<InputAdornment position='end'>đ</InputAdornment>}
                />
              </div>
            </div>

            <div className={classNames('flex mt-4 gap-5', { 'opacity-30 pointer-events-none': !selectedEnterprise })}>
              <div className='flex-1 flex gap-2'>
                <div className='font-medium'>
                  <Trans>Transaction Date</Trans>:
                </div>
                <div className='font-semibold'>{selectedEnterprise && dayjs().format('DD-MM-YYYY')}</div>
              </div>
              <div className='flex-1 flex gap-2'>
                <div className='font-medium'>
                  <Trans>Salary Payment Date</Trans>:
                </div>
                <div className='font-semibold'>
                  {selectedEnterprise &&
                    selectedEnterpriseFullInfo &&
                    selectedEnterpriseFullInfo?.currentSalaryPaymentDate &&
                    DateTime.fromISO(selectedEnterpriseFullInfo?.currentSalaryPaymentDate).toFormat('dd-MM-yyyy')}
                </div>
              </div>
            </div>
            <div className={classNames('flex mt-6 gap-5', { 'opacity-30 pointer-events-none': !selectedEnterprise })}>
              <div className='flex-1 flex gap-2'>
                <div className='font-medium mt-2'>
                  <Trans>Detail</Trans>
                </div>
                <div>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setOpenPromotion(true);
                    }}
                  >
                    <Trans>Promotion</Trans>
                  </Button>
                </div>
              </div>
            </div>

            <div
              className={classNames({
                'opacity-30 pointer-events-none': !selectedEnterprise || !transaction,
              })}
            >
              <div className={classNames('mt-3 border-2 rounded p-3 grid grid-cols-2 gap-4 xl:grid-cols-3', {})}>
                <div className='flex-1 flex gap-2'>
                  <div>
                    <Trans>Corresponding Labours</Trans>:
                  </div>
                  <div className='font-semibold'>{transaction?.correspondingLabor}</div>
                </div>

                <div className='flex-1 flex gap-2'>
                  <div>
                    <Trans>No. of earned labour</Trans>:
                  </div>
                  <div className='font-semibold'>{transaction?.earnedLabor}</div>
                </div>

                <div className='flex-1 flex gap-2'>
                  <div>
                    <Trans>No. of future labour</Trans>:
                  </div>
                  <div className='font-semibold'>{transaction?.futureLabor}</div>
                </div>

                <div className='flex-1 flex gap-2'>
                  <div>
                    <Trans>Accumulated Advanced Labours</Trans>:
                  </div>
                  <div className='font-semibold'>{transaction?.advancedLabor}</div>
                </div>

                <div className='flex-1 flex gap-2'>
                  <div>
                    <Trans>Earned Discount Rate (% day)</Trans>:
                  </div>
                  <div className='font-semibold'>{transaction?.earnedDiscountRate}%</div>
                </div>
                <div className='flex-1 flex gap-2'>
                  <div>
                    <Trans>Future Discount Rate (% day)</Trans>:
                  </div>
                  <div className='font-semibold'>{transaction?.futureDiscountRate}%</div>
                </div>

                <div className='flex-1 flex gap-2'>
                  <div>
                    <Trans>Total Advanced Labours</Trans>:
                  </div>
                  <div className='font-semibold'>
                    {formatNumber((transaction?.correspondingLabor ?? 0) + (transaction?.advancedLabor ?? 0))}
                  </div>
                </div>

                <div className='flex-1 flex gap-2'>
                  <div>
                    <Trans>Earned Discount Amount</Trans>:
                  </div>
                  <div className='font-semibold'>{formatNumber(transaction?.earnedDiscountAmount) || 0}đ</div>
                </div>

                <div className='flex-1 flex gap-2'>
                  <div>
                    <Trans>Future Discount Amount</Trans>:
                  </div>
                  <div className='font-semibold'>{formatNumber(transaction?.futureDiscountAmount) || 0}đ</div>
                </div>

                <div className='flex-1 flex gap-2'>
                  <div>
                    <Trans>Total Transaction Cost</Trans>:
                  </div>
                  <div className='font-semibold'>{formatNumber(transaction?.costAmount) || 0}đ</div>
                </div>

                <div className='flex-1 flex gap-2'>
                  <div>
                    <Trans>Promotion of Transaction Cost</Trans>:
                  </div>
                  <div className='font-semibold'>{formatNumber(transaction?.promotionAmount) || 0}đ</div>
                </div>
              </div>

              <div className='flex font-medium  mt-4 items-center justify-center'>
                <Trans>PAYMENT AMOUNT</Trans>:
                <div className='text-info-main font-semibold ml-2 text-xl'>
                  {formatNumber(transaction?.paymentAmount)}đ
                </div>
              </div>

              <div className='flex justify-center mt-4'>
                <LoadingButton
                  disabled={
                    invalidTrans ||
                    !transaction ||
                    transaction?.requestAmount <= 0 ||
                    !amount ||
                    Number(amount) > (data?.maxAdvanceAmount ?? 0)
                  }
                  variant='contained'
                  size='large'
                  loading={creatingTransaction}
                  onClick={handleCreateTx}
                >
                  <Trans>Request</Trans>
                </LoadingButton>
              </div>
            </div>
          </>
        )}

        {activeStep === 1 && (
          <div>
            <div className='mt-5 text-xl font-semibold text-center text-info-main'>
              <Trans>CONFIRMATION</Trans>
            </div>

            <div className='m-auto mt-6' style={{ maxWidth: '500px' }}>
              <div className='py-3 border-b flex justify-between'>
                <Trans>Company</Trans>
                <div>{transaction.enterprise.name}</div>
              </div>

              <div className='py-3 border-b flex justify-between'>
                <Trans>Account number</Trans>
                <div>{transaction.employee.bankAccountNumber}</div>
              </div>

              <div className='py-3 border-b flex justify-between'>
                <Trans>Bank Name</Trans>
                <div>{transaction.employee.bankName}</div>
              </div>

              <div className='py-3 border-b flex justify-between'>
                <Trans>Account Holder</Trans>
                <div>{transaction.employee.name}</div>
              </div>

              <div className='py-3 border-b flex justify-between'>
                <Trans>Payment Amount</Trans>
                <div>{formatNumber(transaction.paymentAmount)}đ</div>
              </div>
            </div>
            <div className='flex justify-center items-center mt-5 flex-col'>
              <div className='mb-4'>
                <Trans>Please enter the OTP sent to {user.phone} to confirm</Trans>
              </div>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle={{
                  marginLeft: '16px',
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  width: '48px',
                  height: '48px',
                  borderRadius: 4,
                }}
              />
            </div>
            <div className='flex justify-center gap-5 mt-6'>
              <Button onClick={() => setActiveStep(0)} variant='outlined'>
                <Trans>Back</Trans>
              </Button>

              <LoadingButton
                loading={confirming}
                onClick={handleSendOTP}
                variant='contained'
                disabled={otp.length !== 6}
              >
                <Trans>Confirm</Trans>
              </LoadingButton>
            </div>
          </div>
        )}

        {activeStep === 3 && (
          <div className='mt-5 flex justify-center items-center flex-col'>
            <CheckCircleOutlineIcon className='text-9xl' color='success' />
            <div>
              <Trans>SCP has received your request</Trans>{' '}
            </div>

            <Button
              variant='contained'
              onClick={() => {
                setEmployeeNotice(undefined);
                setTransaction(undefined);
                setSelectedEnterprise('');
                setAmount('');
                setActiveStep(0);
              }}
              className='mt-5'
            >
              <Trans>Back to Request page</Trans>
            </Button>
          </div>
        )}
      </div>
    </Paper>
  );
}

export default Payroll;
