import { Trans } from '@lingui/macro';
import { Facebook, LinkedIn } from '@mui/icons-material';
import { AppBar, Container, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { publicRoute } from 'routes';

const FooterStatic = () => {
  return (
    <AppBar component='footer' position='static' color='transparent'>
      <Toolbar>
        <Container className='pt-10 pb-20'>
          <Grid container>
            <Grid item md={4} className='flex items-end gap-3'>
              <img
                src={require('assets/icons/btn-app-store-black.jpg')}
                className='cursor-pointer hover:opacity-70 w-[160px]'
              />
              <img
                src={require('assets/icons/btn-google-play-black.jpg')}
                className='cursor-pointer hover:opacity-70 w-[160px]'
              />
            </Grid>
            <Grid item md={5} className='flex items-end gap-3'>
              <img src={require('assets/images/logo_scp.png')} className='h-12' />
              <Typography className='font-bold -ml-2 mr-3'>
                <Trans>WAGE ADVANCE</Trans>
              </Typography>

              <Typography className='font-black text-primary-main'>
                <Trans>A member of SCP Group</Trans>
              </Typography>
            </Grid>
            <Grid item md={3} className='flex items-end justify-end gap-10 pb-2'>
              <Link to={publicRoute.termsOfUse.path}>
                <Typography className='hover:text-primary-main'>
                  <Trans>Terms of Use</Trans>
                </Typography>
              </Link>
              <Link to={publicRoute.privacyPolicy.path}>
                <Typography className='hover:text-primary-main'>
                  <Trans>Privacy Policy</Trans>
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <div className='flex items-end gap-14 mt-16'>
            <div className='flex items-center gap-2'>
              <Typography className='mr-4'>
                <Trans>Follow SCP</Trans>
              </Typography>
              <IconButton>
                <Facebook fontSize='large' className='text-black' />
              </IconButton>
              <IconButton>
                <LinkedIn fontSize='large' className='text-black' />
              </IconButton>
            </div>
            <div>
              <Typography className='text-primary-main text-2xl'>
                <Trans>SCP TECHNOLOGY SALARY ADVANCE PLATFORM LLC</Trans>
              </Typography>
              <Typography>
                <Trans>
                  Address: 9th Floor, Gelex Tower, 52 Le Dai Hanh Street, Le Dai Hanh Ward, Hai Ba Trung District, Hanoi
                </Trans>
              </Typography>
            </div>
            <div className='px-1'>
              <Typography>
                Hotline: <span className='font-bold'>1900 5656 16</span>
              </Typography>
              <Typography>
                Zalo: <span className='font-bold'>0888 20 17 17</span>
              </Typography>
              <Typography>
                Email: <span className='font-bold'>support.ul@scp.com.vn</span>
              </Typography>
            </div>
          </div>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default FooterStatic;
