import { Trans } from '@lingui/macro';
import { Button, Dialog, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { AttendanceUpdatePopup } from 'views/Attendance/popups';

const AttendanceManagementInfo = () => {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <div>
      <Grid container columnSpacing={3}>
        <Grid item md={12} lg={4} xl={4} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h4' className='mb-4'>
              <Trans>Update Labour as System's Form</Trans>
            </Typography>
            <Button variant='contained' onClick={() => setOpenPopup(true)}>
              <Trans>Update</Trans>
            </Button>
          </Paper>
        </Grid>
        <Grid item md={12} lg={4} xl={4} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h4' className='mb-4'>
              <Trans>Connect Attendance System</Trans>
            </Typography>
            <Button variant='contained' onClick={() => {}}>
              <Trans>Connect</Trans>
            </Button>
          </Paper>
        </Grid>
      </Grid>

      <Dialog fullWidth maxWidth='md' open={openPopup} onClose={() => setOpenPopup(false)}>
        <AttendanceUpdatePopup onClose={() => setOpenPopup(false)} />
      </Dialog>
    </div>
  );
};

export default AttendanceManagementInfo;
