import { t, Trans } from '@lingui/macro';
import { CheckCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { useQuery } from 'react-query';
import { employeeSetupService } from 'services';
import { formatNumber } from 'utils/common';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseEmployeeId: number;
  employeeId: string;
  pasId: number;
};

const FullTimePopupStep1 = ({ pasId, enterpriseEmployeeId, employeeId, onBack, onNext }: PopupProps) => {
  const { data: { bankAccount, coordinationTerms, notInBlacklisted, onboarded } = {} } = useQuery(
    ['employeeSetupService.getEmployeeSetupCondition', { employeeId, enterpriseEmployeeId }],
    () => employeeSetupService.getEmployeeSetupCondition({ employeeId, enterpriseEmployeeId }),
  );

  const {
    data: {
      monthlyAverageSalary,
      typeOfLaborContractEn,
      typeOfLaborContractVi,
      contractAdvanceRatio,
      seniority,
      seniorityAdvanceRatio,
      limitByLaborContractSeniority,
      approvalRatio,
      levelEn,
      levelVi,
      maximumLimit,
      minimumLimit,
      appliedEnterpriseFeeEn,
      employeeSupportDiscountRate,
      advanceLimit,
      earnedDiscountRate,
      futureDiscountRate,
      futureAdvance,
      allowEdit,
      maxLimitPc
    } = {},
  } = useQuery(
    ['employeeSetupService.getPayrollAdvanceSettingEachEnterprise', { employeeId, enterpriseEmployeeId, pasId }],
    () => employeeSetupService.getPayrollAdvanceSettingEachEnterprise({ employeeId, enterpriseEmployeeId, pasId }),
  );
  const userLocale = useActiveLocale();
  const {
    data: {
      employeeType,
      divisionVi,
      divisionEn,
      attendanceClosingDate,
      attendanceStartingDate,
      currentTermAttendanceStartingDate,
      currentTermAttendanceClosingDate,
      salaryPaymentDate,
      currentTermSalaryPaymentDate,
      currentTermNumbOfEarnedLabour,
      currentTermNumbOfDefaultLabour,
      currentTermMaxLaboursOfAdvance,
      attendanceTimeVi,
      attendanceTimeEn,
      fullDayOffVi,
      fullDayOffEn,
      halfFullDayOffVi,
      halfFullDayOffEn,
    } = {},
  } = useQuery(['employeeSetupService.getLabourDetail', { employeeId, enterpriseEmployeeId }], () =>
    employeeSetupService.getLabourDetail({ employeeId, enterpriseEmployeeId }),
  );

  return (
    <>
      <DialogTitle>
        <Trans>CREDIT SETUP FOR FULL-TIME EMPLOYEE</Trans>
      </DialogTitle>
      <DialogContent>
        <div className='p-4'>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item sm={3} className='border p-2'>
              <div className='flex justify-between items-center h-full'>
                <Typography variant='h6'>
                  <Trans>Onboarded</Trans>
                </Typography>
                {onboarded && <CheckCircle color='primary' />}
              </div>
            </Grid>
            <Grid item sm={3} className='border p-2'>
              <div className='flex justify-between items-center h-full'>
                <Typography variant='h6'>
                  <Trans>Bank Account</Trans>
                </Typography>
                {bankAccount && <CheckCircle color='primary' />}
              </div>
            </Grid>
            <Grid item sm={3} className='border p-2'>
              <div className='flex justify-between items-center h-full'>
                <Typography variant='h6'>
                  <Trans>Enterprise has confirmed coordination terms</Trans>
                </Typography>
                {coordinationTerms && <CheckCircle color='primary' />}
              </div>
            </Grid>
            <Grid item sm={3} className='border p-2'>
              <div className='flex justify-between items-center h-full'>
                <Typography variant='h6'>
                  <Trans>Employee is not Blacklisted</Trans>
                </Typography>
                {notInBlacklisted && <CheckCircle color='primary' />}
              </div>
            </Grid>
          </Grid>
        </div>
        <Typography variant='h4'>
          <Trans>Điều kiện ứng lương theo doanh nghiệp</Trans>
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12} lg={8} xl={8}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Monthly Average Salary (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(monthlyAverageSalary) ?? 0} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Type of Labor Contract</Trans>
                    </TableCell>
                    <TableCell>
                    <TextField InputProps={{ readOnly: true }} value={(userLocale === "vi-VN"  ?  typeOfLaborContractVi : typeOfLaborContractEn) ?? ''} />
                    </TableCell>
                    <TableCell>
                      <Trans>Advance Ratio</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={contractAdvanceRatio ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Seniority (Year)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={seniority ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>Advance Ratio</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={seniorityAdvanceRatio ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Limit by Labor Contract & Seniority (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{ readOnly: true }}
                        value={formatNumber(limitByLaborContractSeniority) ?? 0}
                      />
                    </TableCell>
                    <TableCell>
                      <Trans>Approval Ratio</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={approvalRatio ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Level</Trans>
                    </TableCell>
                    <TableCell>
                    <TextField InputProps={{ readOnly: true }} value={(userLocale === "vi-VN" ? levelVi : levelEn) ?? ''} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Minimum Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(minimumLimit) ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>Maximum Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(maximumLimit) ?? 0} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item sm={12} lg={4} xl={4}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Trans>APPROVAL DECISION</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Advance Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>{formatNumber(advanceLimit)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Earned Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>{earnedDiscountRate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Future Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>{futureDiscountRate}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Typography variant='h4'>
          <Trans>Điều kiện ứng lương theo ngày công</Trans>
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12} lg={8} xl={8}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Employee has been eligible for future advance</Trans>
                    </TableCell>
                    <TableCell>
                      {/* <TextField InputProps={{ readOnly: true }} value={appliedEnterpriseFeeEn ?? ''} /> */}
                      {futureAdvance ? t`YES` : t`NO`}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Expected Labours in term</Trans>
                    </TableCell>
                    <TableCell>
                      {currentTermNumbOfDefaultLabour}
                    </TableCell>
                    <TableCell>
                      <Trans>Earned Labours in term</Trans>
                    </TableCell>
                    <TableCell>
                      {currentTermNumbOfEarnedLabour}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Limit as earned labours (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(Math.round((monthlyAverageSalary!*currentTermNumbOfEarnedLabour!)/currentTermNumbOfDefaultLabour!)) ?? 0} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Typography variant='h4'>
          <Trans>Maximum limit setting by PC</Trans>
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12} lg={8} xl={8}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>{t`Maximum limit`}</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(Math.round(maxLimitPc ?? 0))} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button variant='outlined' onClick={onBack}>
            <Trans>Cancel</Trans>
          </Button>
          { allowEdit &&
          <LoadingButton variant='contained' onClick={onNext}>
            <Trans>Edit</Trans>
          </LoadingButton>
          }
        </div>
      </DialogActions>
    </>
  );
};

export default FullTimePopupStep1;