import { useState, useRef } from 'react';
import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Typography, Button, Chip } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { templateService, employeeLaborService, queryClient } from 'services';
import { downloadBase64 } from 'utils/common';
import { useMutation } from 'react-query';
import { UpsertEmployeeLaborPayload } from 'types/EmployeeLabor';
import { ProfileState } from 'reducers/profile';
import { store } from 'reducers';
import AnimatedProgessBar from './AnimatedProgessBar';
import { useActiveLocale } from 'hooks/useActiveLocale';
const { isLoggedIn, accessToken }: ProfileState = store.getState().profile;
type PopupProps = {
  value: string;
  onBack: () => void;
  onNext: () => void;
  setValue: (args: any) => void;
};
const AttendanceUpdatePlatform = ({ value, onBack, onNext, setValue }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const userLocale = useActiveLocale();
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileSelected, setFileSelected] = useState<File | null>();
  const [isRunging, setRunging] = useState(false);
  const [now, setNow] = useState(0);
  function makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  const { mutate: uploadFileSystemGetData } = useMutation(employeeLaborService.uploadFileSystemGetData, {
    onSuccess: (result: UpsertEmployeeLaborPayload[]) => {
      var profile = localStorage.getItem("profile");
      var profileParse = JSON.parse(profile!);
      setRunging(true);
      var dotimport = makeid(6);
      var ip = {
        dot_import: dotimport
      } as UpsertEmployeeLaborPayload;
      createLog(ip);
      if (result.length > 5) {
        var running = false;
        var countImport = 0;
        var count = result.length;
        const interval = setInterval(() => {
          if (!running) {
            running = true;
            var len = (countImport + 5) > count ? count : countImport + 5;

            var lisTemp = [];
            for (var i = countImport; i < len; i++) {
              result[i].dot_import = dotimport;
              lisTemp.push(result[i])
            }
            // uploadFileSystem(lisTemp);

            fetch(`${process.env.REACT_APP_APP_API}/api/v1/employee-labors/import/system`, {
              // fetch("https://demows.vin-hoadon.com" + apiurl.pathguivakyhsm, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${profileParse.accessToken}`
              },
              body: JSON.stringify(lisTemp)
            })
              .then((response) => response.json())
              .then(async (json) => {
                console.log(json);
                if (json.status_code == 500) {
                  enqueueSnackbar(json.error_message, { variant: 'error' });
                  // clearInterval(interval);
                  // setRunging(false);
                  // return;
                }
                countImport = (countImport + 5) > count ? count : countImport + 5;
                setNow((countImport / count) * 100);
                if (countImport >= result.length) {
                  setRunging(false);
                  clearInterval(interval);
                  updateLog(ip)
                  enqueueSnackbar(t`Successful`);
                  queryClient.invalidateQueries('employeeLaborService.fetchEmployeeLabors');
                  onNext();
                }
                running = false;
              })
          }

        })
      } else {
        result.forEach((value) => {
          value.dot_import = dotimport;
        })
        fetch(`${process.env.REACT_APP_APP_API}/api/v1/employee-labors/import/system`, {
          // fetch("https://demows.vin-hoadon.com" + apiurl.pathguivakyhsm, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${profileParse.accessToken}`
          },
          body: JSON.stringify(result)
        })
          .then((response) => response.json())
          .then(async (json) => {
            setRunging(false);
            if (json.status_code == 500) {
              enqueueSnackbar(json.error_message, { variant: 'error' });
              setRunging(false);
              return;
            }
            setNow(100);
            updateLog(ip)
            enqueueSnackbar(t`Successful`);
            queryClient.invalidateQueries('employeeLaborService.fetchEmployeeLabors');
            onNext();
          })
      }

    },
  });
  const { mutate: createLog } = useMutation(employeeLaborService.createLogEmployeeLabor, {
    onSuccess: () => {
      console.log('create log success')
    },
  });
  const { mutate: updateLog } = useMutation(employeeLaborService.updateLogEmployeeLabor, {
    onSuccess: () => {
      console.log('update log success')
    },
  });
  const handleClickSubmit = () => {
    if (fileSelected && value) {
      const formData = new FormData();
      formData.append('file', fileSelected, fileSelected.name);
      uploadFileSystemGetData(formData);
    } else {
      enqueueSnackbar('Please select file', { variant: 'warning' });
    }
  };

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    setFileSelected(fileList[0]);
  };
  return (
    <>
      <DialogTitle>
        <Trans>UPDATE LABOUR BY PLATFORM'S TEMPLATE</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex items-center justify-end space-x-3'>
          <Typography variant='h6'>
            <Trans>Download the template</Trans>
          </Typography>
          <Button
            size='small'
            color='info'
            onClick={() => {
              templateService.downloadFileBase64('import-labor-employee').then((res) => {
                downloadBase64(res);
              });
            }}
          >
            <Trans>WorkDay Information</Trans>
          </Button>
        </div>
        <div className='mt-6'>
          <input ref={inputRef} type='file' hidden onChange={handleChangeFiles} />
          <div className='space-x-3'>
            <LoadingButton variant='outlined' size='small' onClick={() => inputRef.current?.click()}>
              <Trans>Choose Files</Trans>
            </LoadingButton>
            {fileSelected && (
              <Chip
                key={fileSelected?.name}
                variant='outlined'
                label={fileSelected?.name}
                onDelete={() => {
                  setFileSelected(null);
                  if (inputRef.current?.value) {
                    inputRef.current.value = '';
                  }
                }}
              />
            )}
          </div>
        </div>
      </DialogContent>
      {isRunging && <div style={{ textAlign: 'center', }}>
        <AnimatedProgessBar progress={now} />
        <p>{userLocale === "vi-VN" ? "Vui lòng không thao tác trong thời gian hệ thống xác thực dữ liệu" : "Please do not make any actions while validating data"}</p>
      </div>}
      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button
            variant='outlined'
            onClick={() => {
              onBack();
              setValue('');
            }}
          >
            <Trans>Back</Trans>
          </Button>
          <LoadingButton
            loading={isRunging}
            variant='contained'
            size='small'
            startIcon={<Upload />}
            onClick={handleClickSubmit}
          >
            <Trans>Upload</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default AttendanceUpdatePlatform;
