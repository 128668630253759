import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { Add, Delete, Update } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { EnhancedTable, EnhancedTableOptions, InputNumber } from 'components';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { creditScoreService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { CriteriaType, ParameterUpdateType, ScoreCardType } from 'types/CreditScore';
import {
  CriteriaCreatePopup,
  CriteriaScoreCreatePopup,
  CriteriaRemovePopup,
  CriteriaScoreRemovePopup,
  CriteriaUpdatePopup,
} from 'views/GeneralSetup/CreditScore/popups';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { formatNegativeNumber } from 'utils/common';

type PopupProps = PopupController & {
  csId: number;
  prId: number;
};

const ParameterUpdatePopup = ({ csId, prId, onClose }: PopupProps) => {
  const userLocale = useActiveLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  const [isOpenCriteriaCreate, setOpenCriteriaCreate] = useState(false);
  const [isOpenCriteriaRemove, setOpenCriteriaRemove] = useState(false);
  const [chosenCriteria, setChosenCriteria] = useState<CriteriaType>();

  const [isOpenScoreCreate, setOpenScoreCreate] = useState(false);
  const [isOpenScoreRemove, setOpenScoreRemove] = useState(false);
  const [chosenScore, setChosenScore] = useState<ScoreCardType>();

  const [isOpenCriteriaUpdate, setOpenCriteriaUpdate] = useState(false);

  const { data: parameter } = useQuery(
    ['creditScoreService.getParameterById', { csId, id: prId }],
    () => creditScoreService.getParameterById({ csId, id: prId }),
    {
      onSuccess: (parameter) => {
        setValue('proportion', parameter.proportion ?? 0);
      },
    },
  );
  const criterias = parameter?.criterias ?? parameter?.ratios ?? [];
  const isQualitative = parameter?.type === 1;

  const { mutate: updateParameter, isLoading } = useMutation(creditScoreService.updateParameter, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('creditScoreService.getCreditScoreById');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: ParameterUpdateType) => {
      updateParameter({ ...values, csId, id: prId });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Parameter</Trans>
      </DialogTitle>

      <DialogContent>
        <Paper variant='outlined' className='flex items-center gap-3 rounded-t px-3 py-2'>
          <Typography variant='h4' className='flex-1'>
            {userLocale === 'vi-VN' ? parameter?.nameVi : parameter?.nameEn}
          </Typography>
          <Controller
            name='proportion'
            defaultValue={0}
            control={control}
            rules={{ required: true, min: 0, max: 100 }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                error={invalid}
                InputProps={{
                  inputComponent: InputNumber as any,
                  inputProps: {
                    maxLength: 5,
                    className: 'text-right',
                  },
                  endAdornment: '%',
                }}
                className='w-20'
              />
            )}
          />
        </Paper>

        <div className='mt-6'>
          <Button variant='outlined' onClick={() => setOpenCriteriaCreate(true)}>
            <Trans>Add Criteria</Trans>
          </Button>
        </div>
        <TableContainer component={Paper}>
          <EnhancedTable
            title=''
            data={criterias}
            columns={[
              { name: `${userLocale === 'vi-VN' ? 'nameVi' : 'nameEn'}`, label: t`Criteria` },
              {
                name: 'minScore',
                label: 'Score',
                options: {
                  customBodyRender: (value, { rowIndex }) => (
                    <div className='text-center'>
                      {formatNegativeNumber(criterias[rowIndex].minScore)} -{' '}
                      {formatNegativeNumber(criterias[rowIndex].maxScore)}
                    </div>
                  ),
                },
              },
              {
                name: `${userLocale === 'vi-VN' ? 'descriptionVi' : 'descriptionEn'}`,
                label: 'Description',
              },
              {
                name: '',
                label: '',
                options: {
                  customBodyRender: (value, { rowIndex }) => (
                    <div className='text-right space-x-1'>
                      <IconButton
                        size='small'
                        color='primary'
                        onClick={() => {
                          setChosenCriteria(criterias[rowIndex]);
                          setOpenCriteriaUpdate(true);
                        }}
                      >
                        <Update fontSize='small' />
                      </IconButton>
                      <IconButton
                        size='small'
                        color='primary'
                        onClick={() => {
                          setChosenCriteria(criterias[rowIndex]);
                          setOpenScoreCreate(true);
                        }}
                      >
                        <Add fontSize='small' />
                      </IconButton>
                      <IconButton
                        size='small'
                        color='error'
                        onClick={() => {
                          setChosenCriteria(criterias[rowIndex]);
                          setOpenCriteriaRemove(true);
                        }}
                      >
                        <Delete fontSize='small' />
                      </IconButton>
                    </div>
                  ),
                },
              },
            ]}
            options={{
              ...EnhancedTableOptions,
              selectableRows: 'none',
              expandableRows: true,
              renderExpandableRow: (value, { rowIndex }) => {
                const items = criterias[rowIndex].qualitativeScorecards ?? criterias[rowIndex].quantitativeScorecards;
                return (items ?? []).map((item) => (
                  <TableRow key={item.id}>
                    <TableCell></TableCell>
                    <TableCell className={isQualitative ? 'text-left' : 'text-center'}>
                      {isQualitative
                        ? `${userLocale === 'vi-VN' ? item.nameVi : item.nameEn}`
                        : `${formatNegativeNumber(item.minValue)} - ${formatNegativeNumber(item.maxValue)}`}
                    </TableCell>
                    <TableCell className='text-center'>{item.score}</TableCell>
                    <TableCell className='text-center'>
                      {userLocale === 'vi-VN' ? item.descriptionVi : item.descriptionEn}
                    </TableCell>
                    <TableCell className='text-right'>
                      <IconButton
                        size='small'
                        color='error'
                        onClick={() => {
                          setChosenCriteria(criterias[rowIndex]);
                          setChosenScore(item);
                          setOpenScoreRemove(true);
                        }}
                      >
                        <Delete fontSize='small' />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ));
              },
            }}
          />
        </TableContainer>

        <Dialog fullWidth maxWidth='xs' open={isOpenCriteriaCreate} onClose={() => setOpenCriteriaCreate(false)}>
          <CriteriaCreatePopup csId={csId} prId={prId} onClose={() => setOpenCriteriaCreate(false)} />
        </Dialog>
        <Dialog fullWidth maxWidth='xs' open={isOpenCriteriaRemove} onClose={() => setOpenCriteriaRemove(false)}>
          <CriteriaRemovePopup
            csId={csId}
            prId={prId}
            crId={chosenCriteria?.id!}
            onClose={() => setOpenCriteriaRemove(false)}
          />
        </Dialog>
        <Dialog fullWidth maxWidth='md' open={isOpenCriteriaUpdate} onClose={() => setOpenCriteriaUpdate(false)}>
          <CriteriaUpdatePopup
            csId={csId}
            prId={prId}
            crId={chosenCriteria?.id!}
            descriptionEn={chosenCriteria?.descriptionEn!}
            descriptionVi={chosenCriteria?.descriptionVi!}
            onClose={() => setOpenCriteriaUpdate(false)}
          />
        </Dialog>

        <Dialog fullWidth maxWidth='xs' open={isOpenScoreCreate} onClose={() => setOpenScoreCreate(false)}>
          <CriteriaScoreCreatePopup
            csId={csId}
            prId={prId}
            crId={chosenCriteria?.id!}
            type={parameter?.type!}
            onClose={() => setOpenScoreCreate(false)}
          />
        </Dialog>
        <Dialog fullWidth maxWidth='xs' open={isOpenScoreRemove} onClose={() => setOpenScoreRemove(false)}>
          <CriteriaScoreRemovePopup
            csId={csId}
            prId={prId}
            crId={chosenCriteria?.id!}
            id={chosenScore?.id!}
            type={parameter?.type!}
            onClose={() => setOpenScoreRemove(false)}
          />
        </Dialog>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ParameterUpdatePopup;
