import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { Autocomplete, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { categoryService, enterpriseService, queryClient } from 'services';
import { BankAccountCreateType, BankAccountType } from 'types/BankAccount';
import { PopupController } from 'types/Common';
import { EnterpriseType } from 'types/Enterprise';
import { InputNumber, InputNumberPhone } from 'components';

type PopupProps = PopupController & {
  item?: BankAccountType;
  enterprise: EnterpriseType;
};

const BankAccountCreatePopup = ({ item, enterprise, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue, watch } = useForm({ mode: 'onChange' });
  const { bankName } = watch();

  const { data: banks } = useQuery(['categoryService.fetchBanks'], () =>
    categoryService.fetchBanks().then((items) => items.map((item) => ({ ...item, label: item.name }))),
  );
  const { data: branches } = useQuery(['categoryService.fetchBankBranches'], () =>
    categoryService.fetchBankBranches().then((items) => items.map((item) => ({ ...item, label: item.name }))),
  );

  useEffect(() => {
    Object.entries(item ?? {}).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [item, setValue]);

  const { mutate: createBank, isLoading } = useMutation(enterpriseService.createEnterpriseBankAccount, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseService.fetchEnterpriseBanks');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: BankAccountCreateType) => {
      createBank({
        ...values,
        _id: enterprise.id,
        id: item?.id,
        enterpriseId: enterprise.id,
        enterpriseName: enterprise.name,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Add Account</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Controller
              name='bankName'
              defaultValue={null}
              control={control}
              rules={{ required: t`Bank is required` }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <Autocomplete
                  disabled={item?.id ? true : false}
                  value={value}
                  onChange={(event, item) => {
                    onChange({ target: { value: item.name } });
                    setValue('branchName', null);
                  }}
                  options={banks ?? []}
                  disableClearable
                  isOptionEqualToValue={(option, value) => option === value || option.label === value}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      fullWidth
                      required
                      label={t`Bank`}
                      error={invalid}
                      helperText={error?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='branchName'
              defaultValue={null}
              control={control}
              rules={{ required: t`Branch is required` }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <Autocomplete
                  value={value}
                  onChange={(event, item) => onChange({ target: { value: item.name } })}
                  options={branches?.filter((item) => item.bankCode === bankName) ?? []}
                  disableClearable
                  isOptionEqualToValue={(option, value) => option === value || option.label === value}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      fullWidth
                      required
                      label={t`Branch`}
                      error={invalid}
                      helperText={error?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='accountNumber'
              defaultValue=''
              control={control}
              rules={{ required: t`Account Number is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Account Number`}
                  error={invalid}
                  helperText={error?.message}
                  InputProps={{ inputComponent: InputNumberPhone as any, }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default BankAccountCreatePopup;
