import { t, Trans } from '@lingui/macro';
import { Remove } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { usePermission } from 'hooks';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseService, queryClient } from 'services';
import { ProposalType } from 'types/EnterpriseProposal';
import { useSnackbar } from 'notistack';

const EnterpriseProposalInformation = () => {
  const { id: _id } = useParams() as { id: string };
  const { control, handleSubmit, getValues, setValue, clearErrors } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { requreUserPermission, ENTERPRISE_PROPOSAL } = usePermission();
  useQuery(
    ['enterpriseService.getEnterpriseProposal', { _id }],
    () => enterpriseService.getEnterpriseProposal({ _id }),
    {
      onSuccess: (data) => {
        Object.entries(data ?? {}).forEach(([key, value]) => {
          setValue(key, value);
        });
      },
    },
  );

  const { mutate: createEnterpriseProposal, isLoading: isLoadingCreate } = useMutation(
    enterpriseService.createEnterpriseProposal,
    {
      onSuccess: (data, body) => {
        queryClient.invalidateQueries('enterpriseService.fetchEnterpriseProposal');
        Object.keys(getValues()).forEach((key) => {
          setValue(key, '');
          setTimeout(() => clearErrors(key), 0);
        });
      },
    },
  );

  const { mutate: updateEnterpriseProposal, isLoading: isLoadingUpdate } = useMutation(
    enterpriseService.updateEnterpriseProposal,
    {
      onSuccess: (data) => {
        enqueueSnackbar(t`Successful`);
      },
    }
  );

  const handleClickSubmitUpdate = () => {
    handleSubmit((values: ProposalType) => {
      if (values.advanceTermTo <= values.advanceTermFrom) {
        enqueueSnackbar(t`Invalid input information`, { variant: 'error' });
        return;
      }
      if (values.advanceAmountTo <= values.advanceAmountFrom) {
        enqueueSnackbar(t`Invalid input information`, { variant: 'error' });
        return;
      }
      updateEnterpriseProposal({ ...values, _id });
    })();
  };

  return (
    <div>
      <div className='flex items-center justify-between'>
        <Typography variant='h4'>
          <Trans>Proposal Information</Trans>
        </Typography>
        {requreUserPermission([ENTERPRISE_PROPOSAL]) && getValues("totalLimit") && <LoadingButton variant='outlined' loading={isLoadingCreate} onClick={() => createEnterpriseProposal({ _id })}>
          <Trans>Move to History</Trans>
        </LoadingButton>}
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='w-20'>
                <Trans>No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Content</Trans>
              </TableCell>
              <TableCell className='max-w-xs'>
                <Trans>CRM Department's Proposal</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>
                <Trans>Total Enterprise Limit (VND)</Trans>
              </TableCell>
              <TableCell>
                <Controller
                  name='totalLimit'
                  defaultValue=""
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      error={invalid}
                      style={{ width: 492 }}
                      InputProps={{
                        inputComponent: InputNumber as any,
                        inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                      }}
                    />
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2</TableCell>
              <TableCell>
                <Trans>Advance Term (Day)</Trans>
              </TableCell>
              <TableCell className='flex items-center gap-2'>
                <Controller
                  name='advanceTermFrom'
                  defaultValue=""
                  control={control}
                  rules={{
                    validate: (e) => {
                      if (e >= 0) {
                        return true;
                      }
                      return false;
                    }
                  }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField {...field} error={invalid} InputProps={{ inputComponent: InputNumber as any }} />
                  )}
                />
                <Remove />
                <Controller
                  name='advanceTermTo'
                  defaultValue=""
                  control={control}
                  rules={{
                    validate: (e) => {
                      if (e >= 0) {
                        return true;
                      }
                      return false;
                    }
                  }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField {...field} error={invalid} InputProps={{ inputComponent: InputNumber as any }} />
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3</TableCell>
              <TableCell>
                <Trans>Advance Amount (VND)</Trans>
              </TableCell>
              <TableCell className='flex items-center gap-2'>
                <Controller
                  name='advanceAmountFrom'
                  defaultValue=""
                  control={control}
                  rules={{
                    validate: (e) => {
                      if (e >= 0) {
                        return true;
                      }
                      return false;
                    }
                  }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      error={invalid}
                      InputProps={{
                        inputComponent: InputNumber as any,
                        inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                      }}
                    />
                  )}
                />
                <Remove />
                <Controller
                  name='advanceAmountTo'
                  defaultValue=""
                  control={control}
                  rules={{
                    validate: (e) => {
                      if (e >= 0) {
                        return true;
                      }
                      return false;
                    }
                  }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      error={invalid}
                      InputProps={{
                        inputComponent: InputNumber as any,
                        inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                      }}
                    />
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>4</TableCell>
              <TableCell>
                <Trans>Earned Discount Rate (APR)</Trans>
              </TableCell>
              <TableCell>
                <Controller
                  name='earnedDiscountRate'
                  defaultValue=""
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      error={invalid}
                      style={{ width: 492 }}
                      InputProps={{ inputComponent: InputNumber as any }}
                    />
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>5</TableCell>
              <TableCell><Trans>Future Discount Rate (APR)</Trans></TableCell>
              <TableCell>
                <Controller
                  name='futureDiscountRate'
                  defaultValue=""
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      error={invalid}
                      style={{ width: 492 }}
                      InputProps={{ inputComponent: InputNumber as any }}
                    />
                  )}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        {requreUserPermission([ENTERPRISE_PROPOSAL]) && <LoadingButton variant='contained' loading={isLoadingUpdate} className='w-32' onClick={handleClickSubmitUpdate}>
          <Trans>Update</Trans>
        </LoadingButton>}
      </div>
    </div>
  );
};

export default EnterpriseProposalInformation;
