import { PopupController } from 'types/Common';
import {
  GuaranteeConfirmationStep1,
  GuaranteeConfirmationStep2,
  GuaranteeConfirmationStep3,
} from 'views/ClientSetup/Enterprise/popups';
import { NoticeType } from 'types/EnterpriseSetup';
import { usePermission } from 'hooks';
import { EnterpriseTypeSignType, PcTypeSignType } from 'constants/common';

type PopupProps = PopupController & {
  notice: NoticeType;
  stateCurrent: string;
};

const GuaranteeConfirmationPopup = ({ stateCurrent, notice, onClose }: PopupProps) => {
  const { requireRole, requireUserPCManager, requreUserPermission,
    ENTERPRISE_DUYET_TUCHOI_XACNHANBAOLANH, ENTERPRISE_KY_TUCHOI_XACNHANBAOLANH, ENTERPRISE_XACNHAN_XACNHANBAOLANH } = usePermission();
  const signType = requireRole('ENTERPRISE', false) ? EnterpriseTypeSignType : PcTypeSignType;
  return (
    <>
      {stateCurrent === 'WAITING_FOR_APPROVAL' && requreUserPermission([ENTERPRISE_DUYET_TUCHOI_XACNHANBAOLANH]) && (
        <GuaranteeConfirmationStep1 notice={notice} onBack={() => onClose()} onNext={() => onClose()} />
      )}
      {((stateCurrent === 'WAITING_FOR_CONFIRMATION' && requreUserPermission([ENTERPRISE_XACNHAN_XACNHANBAOLANH])) ||
        (stateCurrent === 'WAITING_FOR_SIGNING' && requreUserPermission([ENTERPRISE_KY_TUCHOI_XACNHANBAOLANH]))) && (
          <GuaranteeConfirmationStep2
            signType={signType}
            notice={notice}
            onBack={() => onClose()}
            onNext={() => onClose()}
          />
        )}
      {(!(stateCurrent === 'WAITING_FOR_APPROVAL' && requreUserPermission([ENTERPRISE_DUYET_TUCHOI_XACNHANBAOLANH]))
        && !((stateCurrent === 'WAITING_FOR_CONFIRMATION' && requreUserPermission([ENTERPRISE_XACNHAN_XACNHANBAOLANH])) ||
          (stateCurrent === 'WAITING_FOR_SIGNING' && requreUserPermission([ENTERPRISE_KY_TUCHOI_XACNHANBAOLANH])))) && <GuaranteeConfirmationStep3 notice={notice} onBack={() => onClose()} />}
    </>
  );
};

export default GuaranteeConfirmationPopup;
