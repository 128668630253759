import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { Grid, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { userService } from 'services';
import { ProfileUpdateType } from 'types/User';
import { Trans, t } from '@lingui/macro';
import { queryClient } from 'services';
import { usePermission } from 'hooks';

const UserInformation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { requreUserPermission,ACCOUNT_USERINFO_UPDATE } = usePermission();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });
  const { data: profile } = useQuery(['userService.getProfile'], () => userService.getProfile(), {
    onSuccess: (data) => {
      Object.entries(data ?? {}).forEach(([key, value]) => {
        setValue(key, value);
      });
    },
  });

  const { mutate: updateProfile, isLoading } = useMutation(userService.updateProfile, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('userService.getProfile');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: ProfileUpdateType) => {
      updateProfile({
        ...values,
      });
    })();
  };

  return (
    <div>
      <Typography variant='h4' className='mb-4'>
        <Trans>User Information</Trans>
      </Typography>

      <Grid container columnSpacing={4} rowSpacing={3}>
        <Grid item md={6} lg={4} xl={3}>
          <TextField fullWidth InputProps={{ readOnly: true }} label={t`Name`} value={profile?.fullname ?? ''} />
        </Grid>
        <Grid item md={6} lg={8} xl={9} sx={{ display: { md: 'block', xs: 'none' } }}></Grid>

        <Grid item md={6} lg={4} xl={3}>
          <TextField fullWidth InputProps={{ readOnly: true }} label='Email' value={profile?.email ?? ''} />
        </Grid>
        <Grid item md={6} lg={4} xl={3}>
          <Controller
            name='phone'
            defaultValue=''
            control={control}
            rules={{ required: t`Phone is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth required label={t`Phone`} error={invalid} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item lg={4} xl={6} sx={{ display: { lg: 'block', xs: 'none' } }}></Grid>

        <Grid item md={6} lg={4} xl={3}>
          <TextField
            fullWidth
            InputProps={{ readOnly: true }}
            label={t`Department`}
            value={profile?.department ?? ''}
          />
        </Grid>
        <Grid item md={6} lg={4} xl={3}>
          <TextField fullWidth InputProps={{ readOnly: true }} label={t`Role`} value={profile?.role.name ?? ''} />
        </Grid>
        <Grid item lg={4} xl={6} sx={{ display: { lg: 'block', xs: 'none' } }}></Grid>

        <Grid item md={6} lg={4} xl={3}>
          <Controller
            name='position'
            defaultValue=''
            control={control}
            rules={{ required: t`Position is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Position`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
      </Grid>

      <div className='flex justify-start mt-4'>
        {requreUserPermission([ACCOUNT_USERINFO_UPDATE]) && (<LoadingButton variant='contained' className='w-32' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Update</Trans>
        </LoadingButton>)}
      </div>
    </div>
  );
};

export default UserInformation;
