import { StaticHeader } from 'containers';
import { LoginContent } from 'views/Login';
import { ForgotPasswordForm } from '.';
import { useActiveLocale } from 'hooks/useActiveLocale';

const ForgotPasswordPage = () => {
  const userLocale = useActiveLocale();
  return (
    <div className='App'>
      <StaticHeader />
      <div
        className='h-screen pt-20 flex'
        style={{
          backgroundImage: `url(${
            userLocale === 'vi-VN' ? require('assets/images/bg-login-vi.png') : require('assets/images/bg-login-en.png')
          })`,
        }}
      >
        <div className='w-full flex items-center pl-60 py-20'>
          <ForgotPasswordForm />
        </div>
      </div>
      <LoginContent />
    </div>
  );
};

export default ForgotPasswordPage;
