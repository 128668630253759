import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import {
  Autocomplete,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { transactionOverdueService, enterpriseService, queryClient } from 'services';
import { TableEmpty } from 'components';
import { PopupController } from 'types/Common';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import {
  OverdueTransactionType,
  OverdueNoticeFileType,
  OverdueNoticeRequestType,
  EmployeeType,
} from 'types/TransactionOverdue';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { formatNumber } from 'utils/common';
import PdfViewer from 'components/PdfViewer';

type PopupProps = PopupController & {
  onConfirm: (id: number) => void;
};

const CreateOverdueNoticePopup = ({ onClose, onConfirm }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();
  const [dataSelect, setDataSelect] = useState<OverdueTransactionType[]>([]);
  const [step, setStep] = useState(1);
  const [objKey, setObjectKey] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [overdueDate, setOverdueDate] = useState(DateTime.now().toISO());
  const [transactions, setTransactions] = useState<OverdueTransactionType[]>();
  const [employees, setEmployees] = useState<EmployeeType[]>();

  const { data: bank } = useQuery(
    ['enterpriseService.fetchEnterpriseBanks'],
    () =>
      enterpriseService.fetchEnterpriseBankAccounts({
        _id: 'me',
        page: 1,
        size: 100,
      }),
    { keepPreviousData: true },
  );
  const { data: banks = [] } = bank ?? {};

  useEffect(() => {
    if (overdueDate) {
      transactionOverdueService
        .fetchOverdueEmployees({ overdueDate: overdueDate })
        .then((items) => setEmployees(items.data.map((item) => ({ ...item, label: item.name }))));
    }
    if (selectedEmployee && overdueDate) {
      transactionOverdueService
        .getOverdueTransactions({
          employeeId: parseInt(selectedEmployee),
          overdueDate: overdueDate,
        })
        .then(setTransactions);
    }
  }, [overdueDate, selectedEmployee]);

  const { mutate: getOverdueNoticeFile, isLoading: isLoadingCreate } = useMutation(
    transactionOverdueService.getOverdueNoticeFile,
    {
      onSuccess: (file: OverdueNoticeFileType) => {
        enqueueSnackbar(t`Successful`);
        setObjectKey(file.objectKey);
        setStep(2);
      },
    },
  );

  const { mutate: confirmOverdueNotice, isLoading: isLoadingConfirm } = useMutation(
    transactionOverdueService.confirmOverdueNotice,
    {
      onSuccess: (file: OverdueNoticeFileType) => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('transactionOverdueService.getOverdueTransactionOverdueNotices');
        onConfirm(file.noticeId);
        onClose();
      },
    },
  );

  const handleClickCreate = () => {
    handleSubmit((values: OverdueNoticeRequestType) => {
      type ValuesType = OverdueNoticeRequestType & { overdueDate: DateTime };
      const { overdueDate, ...others } = values as ValuesType;
      getOverdueNoticeFile({
        ...others,
        employeeId: parseInt(selectedEmployee),
        overdueDate: overdueDate.toISO(),
        transIds: dataSelect.map((item) => item.id),
      });
    })();
  };

  const handleClickConfirm = () => {
    handleSubmit((values: OverdueNoticeRequestType) => {
      type ValuesType = OverdueNoticeRequestType & { overdueDate: DateTime };
      const { overdueDate, ...others } = values as ValuesType;
      confirmOverdueNotice({
        ...others,
        employeeId: parseInt(selectedEmployee),
        overdueDate: overdueDate.toISO(),
        transIds: dataSelect.map((item) => item.id),
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Overdue Notice For Employee</Trans>
      </DialogTitle>

      {step === 2 && (
        <>
          <DialogContent>
            <PdfViewer objectKey={objKey} />
          </DialogContent>
          <DialogActions>
            <LoadingButton variant='outlined' onClick={() => setStep(1)} className='absolute left-4'>
              <Trans>Back</Trans>
            </LoadingButton>
            <LoadingButton
              variant='contained'
              disabled={!dataSelect || dataSelect.length === 0}
              onClick={handleClickConfirm}
              loading={isLoadingConfirm}
            >
              <Trans>Confirm</Trans>
            </LoadingButton>
          </DialogActions>
        </>
      )}
      {step === 1 && (
        <>
          <DialogContent>
            <Grid container columnSpacing={2} rowSpacing={3}>
              <Grid item sm={9}>
                <Controller
                  name='employeeId'
                  defaultValue={null}
                  control={control}
                  rules={{ required: t`Field is required` }}
                  render={({ field: { value, onChange: setValue }, fieldState: { invalid, error } }) => (
                    <Autocomplete
                      value={value}
                      onChange={(event, item) => {
                        setValue({ target: { value: item.label } });
                        setSelectedEmployee(item.id);
                      }}
                      options={employees ?? []}
                      disableClearable
                      isOptionEqualToValue={(option, value) => option === value || option.label === value}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          fullWidth
                          required
                          label={t`Employee Name`}
                          error={invalid}
                          helperText={error?.message}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={3}>
                <Controller
                  name='overdueDate'
                  defaultValue={DateTime.now()}
                  control={control}
                  rules={{
                    validate: { isValid: (value: DateTime) => value && value.isValid },
                    required: t`Field is required`,
                  }}
                  render={({ field: { value, onChange: setValue }, fieldState: { invalid, error } }) => (
                    <DesktopDatePicker
                      value={value}
                      onChange={(value) => {
                        setValue(value);
                        setOverdueDate(value.toISO());
                      }}
                      renderInput={(props) => <TextField {...props} required error={invalid} />}
                      label={t`Overdue Starting Date`}
                      inputFormat='dd/MM/yyyy'
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12}>
                <Controller
                  name='bankId'
                  defaultValue=''
                  control={control}
                  rules={{ required: t`Field is required` }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      select
                      required
                      label={t`Receiving Account`}
                      error={invalid}
                      helperText={error?.message}
                    >
                      {banks.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.accountNumber} - {item.bankName} - {item.branchName}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item sm={12}>
                <Controller
                  name='reason'
                  defaultValue=''
                  control={control}
                  rules={{ required: t`Reason for transferring overdue is required` }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label={t`Reason for transferring overdue`}
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        onChange={(event, checked) => {
                          if (checked) setDataSelect(transactions ?? []);
                          else setDataSelect([]);
                        }}
                        checked={dataSelect.length === transactions?.length}
                        indeterminate={dataSelect.length > 0 && dataSelect.length < (transactions!.length ?? 0)}
                      />
                    </TableCell>
                    <TableCell>
                      <Trans>Transaction ID</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Collection Amount (VND)</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(transactions ?? []).map((item, idx) => (
                    <TableRow key={item.id}>
                      <TableCell align='center'>
                        <Checkbox
                          checked={dataSelect.some((next) => next.id === item.id)}
                          onChange={(event, checked) => {
                            if (checked) setDataSelect((nexts) => nexts.concat(item));
                            else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                          }}
                        />
                      </TableCell>
                      <TableCell>{item.transactionCode}</TableCell>
                      <TableCell align='right'>{formatNumber(item.collectionAmount)}</TableCell>
                    </TableRow>
                  ))}
                  <TableEmpty data={transactions} />
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
              <Trans>Cancel</Trans>
            </LoadingButton>
            <LoadingButton
              variant='contained'
              disabled={dataSelect?.length === 0}
              onClick={handleClickCreate}
              loading={isLoadingCreate}
            >
              <Trans>Create</Trans>
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default CreateOverdueNoticePopup;
