import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { categoryService, enterpriseSetupService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { EnterpriseDivisionAssignType } from 'types/EnterpriseSetup';

type PopupProps = PopupController & {
  enterpriseId: string;
  employeeIds: number[];
};

const EmployeeChangeDivisionPopup = ({ enterpriseId, employeeIds, onSuccess, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();

  const { data: workingBlocks } = useQuery(['fetchWorkingBlocks'], () => categoryService.fetchWorkingBlocks());

  const { mutate: updateEmployeeDivision, isLoading } = useMutation(enterpriseSetupService.updateEmployeeDivision, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.fetchEmployees');
      onSuccess?.();
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: EnterpriseDivisionAssignType) => {
      updateEmployeeDivision({
        ...values,
        id: enterpriseId,
        employeeIds,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Change Working Division</Trans>
      </DialogTitle>

      <DialogContent>
        <Controller
          name='blockId'
          defaultValue=''
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField {...field} select fullWidth label={t`Division`} error={invalid}>
              {workingBlocks?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.enContent}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Change</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EmployeeChangeDivisionPopup;
