import { t, Trans } from '@lingui/macro';
import {
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  debounce,
} from '@mui/material';
import { TableEmpty } from 'components';
import { usePermission, useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { DateTime } from 'luxon';
import { useState, useCallback, useEffect } from 'react';
import { getEnterpriseApplyFeeType } from 'constants/enterprise';
import { formatNumber } from 'utils/common';
import SelectPageSize from 'components/SelectPageSize';

const EnterpriseServiceFee = () => {
  const [dataSearch, onSearchChange] = useSearch();
  const { data } = useQuery(['enterpriseSetupService.fetchEnterpriseServiceFees', dataSearch], () =>
    enterpriseSetupService.fetchEnterpriseServiceFees(dataSearch),
  );

  const [searchType, setSearchType] = useState('enterpriseName');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const { requreUserPermission, PHIDICHVUDN_DANHSACH, PHIDICHVUDN_XEM } = usePermission();
  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  const { data: enterprises = [], paginate } = data ?? {};
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Enterprise's service fee</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <div className='flex justify-between items-center'>
          <Typography variant='h4'>
            <Trans>List of Enterprise</Trans>
          </Typography>
          <div className='space-x-3'>
            <TextField
              select
              value={searchType}
              onChange={(event) => {
                setSearchText('');
                onSearchChange({});
                setSearchType(event.target.value);
                debounceChangeValue({ [searchType]: undefined });
              }}
            >
              <MenuItem value='enterpriseName'>
                <Trans>Enterprise Name</Trans>
              </MenuItem>
              <MenuItem value='taxCode'>
                <Trans>Tax Code</Trans>
              </MenuItem>
            </TextField>
            {['enterpriseName', 'taxCode'].includes(searchType) && (
              <TextField
                placeholder={t`Search...`}
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                  debounceChangeValue({ [searchType]: event.target.value });
                }}
              />
            )}
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>No.</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Enterprise Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Tax Code</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Applying Fee</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Service Fee before tax (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Service Fee after tax (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Latest Collection Date</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Detail</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            {requreUserPermission([PHIDICHVUDN_XEM]) && (<TableBody>
              {enterprises.map((item, idx) => (
                <TableRow key={item.id}>
                  <TableCell align='right'>{idx + 1}</TableCell>
                  <TableCell>{item.enterpriseName}</TableCell>
                  <TableCell>{item.taxCode}</TableCell>
                  <TableCell>{getEnterpriseApplyFeeType(item.appliedType)}</TableCell>
                  <TableCell align='right'>{formatNumber(item.serviceFeeBeforeTax)}</TableCell>
                  <TableCell align='right'>{formatNumber(item.serviceFeeAfterTax)}</TableCell>
                  <TableCell align='center'>
                    {item.latestCollectionDate
                      ? DateTime.fromISO(item.latestCollectionDate).toFormat('dd/MM/yyyy')
                      : ''}
                  </TableCell>
                  <TableCell align='center'>
                    {requreUserPermission([PHIDICHVUDN_XEM]) && (<Link to={privateRoute.enterpriseServiceFeeView.url?.(item)!}>
                      <Button size='small' color='info'>
                        <Trans>View</Trans>
                      </Button>
                    </Link>)}
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={enterprises} />
            </TableBody>)}
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => {
              onSearchChange({ page: value });
            }}
          />
        </div>
      </div>
    </Paper>
  );
};

export default EnterpriseServiceFee;
