import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import PdfViewer from 'components/PdfViewer';
import { useState } from 'react';
import { AppendixPayloadType } from 'types/EnterpriseSetup';
import { AppendixObjectDataType } from 'types/EnterpriseSetup';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  objectData: AppendixObjectDataType;
  appendixPayload: AppendixPayloadType;
  setAppendixPayload: (args: any) => void;
  setAppendixCreate: (args: any) => void;
};

const FixedServicePopupStep3 = ({
  objectData,
  appendixPayload,
  setAppendixPayload,
  setAppendixCreate,
  onBack,
  onNext,
}: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [appendixType, setAppendixType] = useState('');

  const { mutate: initAppendix, isLoading } = useMutation(enterpriseSetupService.initAppendix, {
    onSuccess: (data) => {
      enqueueSnackbar(t`Successful`);
      setAppendixCreate(data);
      onNext();
      queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseServiceFeeHistories');
    },
  });

  const handleClickSubmit = () => {
    initAppendix({
      ...appendixPayload,
      appendixType,
    });
    setAppendixPayload({ ...appendixPayload, appendixType });
  };

  return (
    <>
      <DialogTitle>
        <Trans>APPENDIX OF FIXED SERVICE FEE</Trans>
      </DialogTitle>
      <DialogContent>
        <PdfViewer objectKey={objectData?.objectKey} />
        <div className='flex items-center justify-center space-x-6 mt-6'>
          <Button
            variant='contained'
            color={appendixType === 'MANUAL' ? 'primary' : 'inherit'}
            onClick={() => setAppendixType('MANUAL')}
          >
            <Trans>Manual Appendix</Trans>
          </Button>
          <LoadingButton
            variant='contained'
            color={appendixType === 'DIGITAL' ? 'primary' : 'inherit'}
            onClick={() => setAppendixType('DIGITAL')}
          >
            <Trans>Digital Appendix</Trans>
          </LoadingButton>
        </div>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button variant='outlined' color='info' onClick={onBack}>
            <Trans>Back</Trans>
          </Button>
          <LoadingButton variant='contained' disabled={!appendixType} loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Request</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default FixedServicePopupStep3;
