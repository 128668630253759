import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { queryClient, employeeService, userService } from 'services';
import { PopupController } from 'types/Common';
import { UserType } from 'types/User';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';

type PopupProps = PopupController & {
  forCa?: boolean;
  employeeIds: number[];
};

const EmployeeAssignmentPopup = ({ forCa, employeeIds, onSuccess, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [dataSearch, onSearchChange] = useSearch({
    status: 'active',
    role: forCa ? 'CREDIT_OFFICER' : 'CRM,CRM_SENIOR',
    assignEmployeeIds: employeeIds.join(','),
  });
  const [dataSelect, setDataSelect] = useState<UserType[]>([]);

  const { data } = useQuery(['userService.fetchUsers', dataSearch], () => userService.fetchUsers(dataSearch), {
    keepPreviousData: true,
  });
  const { data: items = [], paginate } = data ?? {};

  const { mutate: assignUser, isLoading } = useMutation(
    forCa ? employeeService.assignUserCa : employeeService.assignUserCrm,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('employeeService.fetchEmployees');
        onSuccess?.();
        onClose();
      },
    },
  );

  const handleClickSubmit = () => {
    assignUser({
      employeeIds,
      userId: dataSelect[0].id,
    });
  };

  return (
    <>
      <DialogTitle>
        <Trans>Customer Assignment</Trans>
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Trans>Group</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Position</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='p-1'>
                    <Checkbox
                      checked={dataSelect.some((next) => next.id === item.id)}
                      onChange={(event, checked) => {
                        if (checked) setDataSelect([item]);
                        else setDataSelect([]);
                      }}
                    />
                  </TableCell>
                  <TableCell>{item.group?.name}</TableCell>
                  <TableCell>{item.fullname}</TableCell>
                  <TableCell>{item.position}</TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton
          variant='contained'
          disabled={dataSelect.length === 0}
          loading={isLoading}
          onClick={handleClickSubmit}
        >
          <Trans>Assign</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EmployeeAssignmentPopup;
