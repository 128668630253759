import { Trans } from '@lingui/macro';
import { Button, Dialog, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { EnterpriseType } from 'types/Enterprise';
import { HRUpdatePopup } from 'views/HR/popups';

const HRManagementInfo = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const { data: dataUploaded } = useQuery(
    ['enterpriseSetupService.fetchUpdatedEmployees', { id: enterprise?.id!, size: 1 }],
    () => enterpriseSetupService.fetchEmployees({ id: enterprise?.id!, size: 1 }),
    { keepPreviousData: true },
  );

  const { data: dataAdvanced } = useQuery(
    ['enterpriseSetupService.fetchAdvanceEmployees', { id: enterprise?.id!, size: 1, advanced: 1 }],
    () => enterpriseSetupService.fetchEmployees({ id: enterprise?.id!, size: 1, advanced: 1 }),
    { keepPreviousData: true },
  );

  const { paginate: pU } = dataUploaded ?? {};
  const { paginate: pA } = dataAdvanced ?? {};

  return (
    <div>
      <Grid container columnSpacing={3}>
        <Grid item md={12} lg={4} xl={4} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h4' className='mb-4'>
              <Trans>UPDATE EMPLOYEE INFORMATION</Trans>
            </Typography>
            <Button variant='contained' onClick={() => setOpenPopup(true)}>
              <Trans>Update</Trans>
            </Button>
          </Paper>
        </Grid>
        <Grid item md={12} lg={4} xl={4} className='flex flex-col justify-center items-center'>
          <Typography variant='h4' className='mb-4'>
            <Trans>NO. OF UPLOADED EMPLOYEE</Trans>
          </Typography>
          <Typography>{pU?.totalRecord}</Typography>
        </Grid>
        <Grid item md={12} lg={4} xl={4} className='flex flex-col justify-center items-center'>
          <Typography variant='h4' className='mb-4'>
            <Trans>NO. OF ADVANCED EMPLOYEE</Trans>
          </Typography>
          <Typography>{pA?.totalRecord}</Typography>
        </Grid>
      </Grid>

      <Dialog fullWidth maxWidth='lg' open={openPopup} onClose={() => setOpenPopup(false)}>
        <HRUpdatePopup onClose={() => setOpenPopup(false)} />
      </Dialog>
    </div>
  );
};

export default HRManagementInfo;
