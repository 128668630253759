import { Grid } from '@mui/material';
import { UsingLimit, AttendanceRetail } from 'views/Dashboard/Employee/components';

const DashboardEmployee = () => {
  return (
    <div>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item sm={12}>
          <UsingLimit />
        </Grid>
        <Grid item sm={12}>
          <AttendanceRetail />
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardEmployee;
