import { Trans } from '@lingui/macro';
import { AppBar, Container, Grid, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { publicRoute } from 'routes';

const FooterEpayz = () => {
  return (
    <AppBar component='footer' position='static' color='transparent'>
      <Toolbar className='bg-stone-700 text-white'>
        <Container className='py-10'>
          <Grid container>
            <Grid item md={3}>
              <img src={require('assets/images/Logo_IFZ.png')} className='h-20' />
            </Grid>

            <Grid item md={2.5} className='flex flex-col items-start gap-3'>
              <Link to=''>
                <Typography className='hover:text-white/60'>
                  <Trans>Contact Us</Trans>
                </Typography>
              </Link>
              <Link to=''>
                <Typography className='hover:text-white/60'>
                  <Trans>FAQ</Trans>
                </Typography>
              </Link>
              <Link to={publicRoute.termsOfUse.path}>
                <Typography className='hover:text-white/60'>
                  <Trans>Terms of Use</Trans>
                </Typography>
              </Link>
              <Link to={publicRoute.privacyPolicy.path}>
                <Typography className='hover:text-white/60'>
                  <Trans>Privacy Policy</Trans>
                </Typography>
              </Link>
            </Grid>

            <Grid item md={2.5} className='flex flex-col gap-3 justify-center'>
              <a href='https://apps.apple.com/vn/app/epayz/id1627999955' target='_blank' rel='noreferrer'>
                <img
                  src={require('assets/icons/btn-app-store-white.jpg')}
                  className='cursor-pointer hover:opacity-70 w-[160px] rounded-[7px]'
                />
              </a>
              <img
                src={require('assets/icons/btn-google-play-white.jpg')}
                className='cursor-pointer hover:opacity-70 w-[160px] rounded-[7px]'
              />
            </Grid>

            <Grid item md={4} className='flex flex-col gap-3 justify-center'>
              <Typography variant='h4'>
                <Trans>IFZ DIGITAL PLATFORM Co., Ltd</Trans>
              </Typography>
              <Typography>1/79 Lo Duc Street, Hai Ba Trung District, Hanoi</Typography>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
      <Toolbar className='bg-stone-800 text-white'>
        <Container>
          <Typography>IFZ © 2022 - All rights reserved.</Typography>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default FooterEpayz;
