import { md } from 'node-forge';
import { FileDataType } from 'types/Common';

export const sha256 = (password: string) => {
  const hash = md.sha256.create();
  hash.update(password);
  return hash.digest().toHex();
};

export const formatNumber = (value: number | undefined) => {
  if (value) {
    return value.toLocaleString('vi-VN');
  }
  if (value === 0) {
    return '0';
  }
  return '';
};

export const formatNegativeNumber = (value: number) => {
  if (value < 0) {
    return `(${value * -1})`;
  }
  return value;
};

export const download = async (data: BlobPart, fileName: string, contentType: string) => {
  var url = await base64ToBlob(data).then((blob) => {
    var file = new Blob([blob], { type: contentType });
    return window.URL.createObjectURL(new Blob([file], { type: contentType }))
  });
  const link = document.createElement('a');
  // const url = window.URL.createObjectURL(new Blob([data], { type: contentType }));
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};
async function base64ToBlob(base64Data: any, contentType = "application/json") {
  const b64Res = await fetch(`data:${contentType};base64,${base64Data}`);
  return await b64Res.blob();
}

export const downloadBase64 = (fileData: FileDataType) => {
  const linkSource = `data:${fileData.contentType};base64,${fileData.data}`;
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = '_self';
  downloadLink.download = fileData.name;
  downloadLink.click();
};
