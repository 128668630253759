import { t, Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PdfViewer from 'components/PdfViewer';
import { usePermission } from 'hooks';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { transactionOverdueService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import RejectGuaranteeNoticePopup from './RejectGuaranteeNoticePopup';

type PopupProps = PopupController & {
  noticeId: number;
};

const ApproveGuaranteeNoticePopup = ({ noticeId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requireRole } = usePermission();
  const [isOpenReject, setOpenReject] = useState(false);

  const { data: notice, isSuccess } = useQuery(
    ['transactionOverdueService.getGuaranteeNotice'],
    () => transactionOverdueService.getGuaranteeNotice({ id: noticeId }),
    { keepPreviousData: true },
  );

  const { mutate: approveNotice, isLoading } = useMutation(transactionOverdueService.aprroveGuaranteeNotice, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('transactionOverdueService.getOverdueTransactionGuaranteeNotices');
      onClose();
    },
  });

  const handleClickApprove = () => {
    approveNotice({
      id: noticeId,
    });
  };

  return (
    <>
      <DialogTitle>
        <Trans>Guarantee Implement Notice</Trans>
      </DialogTitle>
      <DialogContent>{isSuccess && <PdfViewer objectKey={notice?.objectKey!} />}</DialogContent>

      <DialogActions className='relative'>
        <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
          <Trans>Cancel</Trans>
        </LoadingButton>
        {(requireRole('ACC_CONTROLLER')) && (
          <div>
            <LoadingButton variant='outlined' color='error' onClick={() => setOpenReject(true)}>
              <Trans>Reject</Trans>
            </LoadingButton>
            <LoadingButton variant='contained' className='w-28' loading={isLoading} onClick={handleClickApprove}>
              <Trans>Approve</Trans>
            </LoadingButton>
          </div>
        )}

      </DialogActions>
      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <RejectGuaranteeNoticePopup
          noticeId={noticeId}
          onClose={() => {
            setOpenReject(false);
            onClose();
          }}
        />
      </Dialog>
    </>
  );
};

export default ApproveGuaranteeNoticePopup;
