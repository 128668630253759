import { Trans, t } from '@lingui/macro';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { categoryService, enterpriseSetupService, queryClient, enterpriseService } from 'services';
import { LaborTermType } from 'types/Category';
import { EnterpriseSettingType, EnterpriseSettingUpdateType } from 'types/EnterpriseSetup';
import { useState, useEffect } from 'react';
import React from "react";
import { usePermission } from 'hooks';

const EnterpriseFullTime = ({ setting }: { setting?: EnterpriseSettingType }) => {
  const { isCaRole } = usePermission();
  const userLocale = useActiveLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();
  const [seniority, setSeniority] = useState(undefined);
  const [seniorityDef, setSeniorityDef] = useState(undefined);
  const [isFocused, setIsFocused] = useState(false);
  const { data: laborTerms } = useQuery(['fetchLaborTerms'], () => categoryService.fetchLaborTerms());

  const { mutate: createFullTimeLabors, isLoading } = useMutation(enterpriseSetupService.createFullTimeLabors, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.getGeneralSettings');
    },
  });
  useEffect(() => {
    enterpriseService.getSeniority({ id: setting?.enterpriseId! }).then(data => {
      if (data != null) {
        var s = JSON.stringify(data);
        setSeniorityDef(JSON.parse(s)["futureSeniority"]);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seniority]);
  const handleClickSubmit = () => {
    handleSubmit(async (values: EnterpriseSettingUpdateType) => {

      enterpriseService.updateSeniority({ id: setting?.enterpriseId!, valueSeniority: seniority! }).then(res => {
        if (res != null && res !== "") {
          enqueueSnackbar(res, { variant: 'error' });
        } else {
          createFullTimeLabors({ ...values, id: setting?.enterpriseId! });
        }
      })
    })();
  };
  async function checkValue(event: any) {
    var value = handleDecimalsOnValue(event.target.value);
    setSeniority(value);
  }
  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)
  function handleDecimalsOnValue(value: any) {
    const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }
  return (
    <div>
      <Typography variant='h4' className='mt-4'>
        <Trans>Eligible Condition for Future Advance</Trans>
      </Typography>

      <div className='flex items-center gap-4'>
        <TableContainer component={Paper} variant='outlined'>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Trans>Type</Trans>
                </TableCell>
                <TableCell colSpan={2}>
                  <Trans>Full Time</Trans>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Trans>Type of Labor Contract</Trans>
                </TableCell>
                <TableCell colSpan={2}>
                  <Controller
                    name='laborTermIds'
                    defaultValue={setting?.laborTermIds}
                    control={control}
                    rules={{ required: seniority }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <Select
                        {...field}
                        multiple
                        fullWidth
                        error={invalid}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected
                              .map((value: number) => laborTerms?.find((item) => item.id === value))
                              .filter((item: any) => item)
                              .map((item: LaborTermType) => (
                                <Chip key={item.id} label={userLocale === 'vi-VN' ? item.viContent : item.enContent} />
                              ))}
                          </Box>
                        )}
                      >
                        {laborTerms?.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Trans>Seniority</Trans>
                </TableCell>
                <TableCell colSpan={2}>
                  {/* <Input fullWidth type='number' value={seniority} onChange={event => setSeniority(event.target.value)} /> */}
                  {
                    (seniority != null && seniority !== "")
                      ?
                      <input type={"text"} onFocus={handleFocus} onBlur={handleBlur} name="seniority" onChange={(event) => { checkValue(event) }} maxLength={8} value={seniority} style={{ width: "100%", height: "40px", borderWidth: 1, borderColor: isFocused ? "black" : "rgb(196 196 196 / 1)", padding: "10px", borderRadius: 5 }} />
                      :
                      <input type={"text"} onFocus={handleFocus} onBlur={handleBlur} name="seniority" onChange={(event) => { checkValue(event) }} maxLength={8} defaultValue={seniorityDef} style={{ width: "100%", height: "40px", borderWidth: 1, borderColor: isFocused ? "black" : "rgb(196 196 196 / 1)", padding: "10px", borderRadius: 5 }} />
                  }
                  {/* <input type={"text"} name="seniority" onChange={(event) => { checkValue(event) }} maxLength={8} value={seniority} defaultValue={seniorityDef} style={{ width: "100%", height: "35px", borderWidth: 1, borderColor: "rgb(224 224 224)" }} /> */}
                  {/* <TextField id="outlined-basic" defaultValue={seniorityDef} value={seniority} inputProps={{ maxLength: 8 }} fullWidth onChange={(event) => {checkValue(event)}} /> */}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {isCaRole() && (<LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit} className='w-32'>
          <Trans>Apply</Trans>
        </LoadingButton>)}
      </div>
    </div>
  );
};

export default EnterpriseFullTime;
