import { t, Trans } from '@lingui/macro';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { TableEmpty } from 'components';
import { getEmployeeType } from 'constants/employee';
import { usePermission } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { employeeSetupService, queryClient } from 'services';
import {
  BlacklistPopup,
  ConditionFullTimePopup,
  ConditionPartTimePopup,
  EKYCInformationPopup,
  LabourFullTimePopup,
  LabourPartTimePopup,
} from 'views/ClientSetup/Employee/popups';
import { formatNumber } from 'utils/common';
import { getEnterpriseStatus } from 'constants/enterprise';
import { HRNoticePopup } from 'views/HR/popups';

const EmployeeInfo = ({ id }: { id: string }) => {
  const [isOpenEKYC, setOpenEKYC] = useState(false);
  const [enterpriseEmployeeId, setEnterpriseEmployeeId] = useState<number>();

  const [isOpenBlacklist, setOpenBlacklist] = useState(false);

  const [isOpenPartLabourDetail, setOpenPartLabourDetail] = useState(false);
  const [isOpenFullLabourDetail, setOpenFullLabourDetail] = useState(false);
  const [isOpenNotice, setOpenNotice] = useState(false);
  const [noticeChoiceKey, setNoticeChoiceKey] = useState('');

  const [isSettingPartLabour, setSettingPartLabour] = useState(false);
  const [isSettingFullLabour, setSettingFullLabour] = useState(false);

  const [enterpriseToChangeStatus, setEnterpriseToChangeStatus] = useState<null | number>(null);

  const { requireUserPCManager, isCaRoles } = usePermission();

  const { data: { employeeName, employeeCode, phone, email, notInBlacklisted } = {} } = useQuery(
    ['employeeSetupService.fetchEmployeeBasicInfo', { id }],
    () => employeeSetupService.fetchEmployeeBasicInfo({ id }),
  );

  const [loading, setLoading] = useState(false);

  const { data: employees } = useQuery(
    ['employeeSetupService.getSalaryCondition', { id }],
    () => employeeSetupService.getSalaryCondition({ id }),
    { keepPreviousData: true },
  );

  const handleChangeStatusUser = () => {
    if (enterpriseToChangeStatus) {
      const employee = employees?.find((item) => item.id === enterpriseToChangeStatus);
      if (!employee) return;

      setLoading(true);
      employeeSetupService
        .updateStatusEmployee({
          employeeId: id,
          enterpriseEmployeeId: enterpriseToChangeStatus,
          state: employee.status === 'LOCKED' ? 'ACTIVATE' : 'LOCKED',
        })
        .then(() => {
          setEnterpriseToChangeStatus(null);
          queryClient.invalidateQueries('employeeSetupService.getSalaryCondition');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between mb-4'>
        <Typography variant='h4'>
          <Trans>Employee Information</Trans>
        </Typography>
        {requireUserPCManager() && (
          <Button
            variant='contained'
            onClick={() => {
              setOpenBlacklist(true);
            }}
          >
            {notInBlacklisted ? <Trans>Add To Blacklist</Trans> : <Trans>Remove from Blacklist</Trans>}
          </Button>
        )}
      </div>
      <div className='mt-6'>
        <Grid container columnSpacing={4} rowSpacing={3}>
          <Grid item sm={3}>
            <TextField fullWidth label={t`Employee Name`} InputProps={{ readOnly: true }} value={employeeName ?? ''} />
          </Grid>
          <Grid item sm={2}>
            <TextField fullWidth label={t`ID`} InputProps={{ readOnly: true }} value={employeeCode ?? ''} />
          </Grid>
          <Grid item sm={2}>
            <TextField fullWidth label={t`Contact Phone`} InputProps={{ readOnly: true }} value={phone ?? ''} />
          </Grid>
          <Grid item sm={3}>
            <TextField fullWidth label={t`Email`} InputProps={{ readOnly: true }} value={email ?? ''} />
          </Grid>
          <Grid item sm={2}>
            <Button
              onClick={() => {
                setOpenEKYC(true);
              }}
              size='small'
              color='info'
            >
              <Trans>eKYC Information</Trans>
            </Button>
          </Grid>
        </Grid>
      </div>

      <div className='mt-6'>
        <Typography variant='h4'>
          <Trans>Wage Advance Setting</Trans>
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Enterprise Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Type</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Advance Limit (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Available Limit (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Earned Discount Rate (APR)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Future Discount Rate (APR)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>No. of available labour</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Labour Detail</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Eligible Employee Notice</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Status</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Activate</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Detail</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.enterpriseName}</TableCell>
                  <TableCell>{getEmployeeType(item.employeeType)}</TableCell>
                  <TableCell>{formatNumber(item.advanceLimit)}</TableCell>
                  <TableCell>{formatNumber(item.availableLimit)}</TableCell>
                  <TableCell>{item.earnedDiscountRate}</TableCell>
                  <TableCell>{item.futureDiscountRate}</TableCell>
                  <TableCell>{item.numbOfAvailableLabour}</TableCell>
                  <TableCell align='center'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        item.employeeType === 'SEASON' ? setOpenPartLabourDetail(true) : setOpenFullLabourDetail(true);
                        setEnterpriseEmployeeId(item.id);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  </TableCell>
                  <TableCell align='center'>
                    {item.status === 'ACTIVATE' && <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpenNotice(true);
                        setNoticeChoiceKey(item.conditionNoticeKey);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>}
                  </TableCell>
                  <TableCell>{getEnterpriseStatus(item.status)}</TableCell>
                  <TableCell>
                    {(() => {
                      return (
                        <Switch
                          disabled={notInBlacklisted && isCaRoles() ? false : true}
                          checked={item.status === 'ACTIVATE'}
                          onClick={() => {
                            setEnterpriseToChangeStatus(item.id);
                          }}
                        />
                      );
                    })()}
                  </TableCell>
                  <TableCell>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        item.employeeType === 'SEASON' ? setSettingPartLabour(true) : setSettingFullLabour(true);
                        setEnterpriseEmployeeId(item.id);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={employees} />
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Dialog fullWidth maxWidth='lg' open={isOpenEKYC} onClose={() => setOpenEKYC(false)}>
        <EKYCInformationPopup onClose={() => setOpenEKYC(false)} />
      </Dialog>

      <Dialog fullWidth maxWidth='sm' open={isOpenBlacklist} onClose={() => setOpenBlacklist(false)}>
        <BlacklistPopup employeeId={id} onClose={() => setOpenBlacklist(false)} notInBlacklisted={notInBlacklisted!} />
      </Dialog>

      <Dialog fullWidth maxWidth='lg' open={isOpenPartLabourDetail} onClose={() => setOpenPartLabourDetail(false)}>
        <LabourPartTimePopup
          employeeId={id}
          onClose={() => setOpenPartLabourDetail(false)}
          enterpriseEmployeeId={enterpriseEmployeeId!}
        />
      </Dialog>

      <Dialog fullWidth maxWidth='lg' open={isOpenFullLabourDetail} onClose={() => setOpenFullLabourDetail(false)}>
        <LabourFullTimePopup
          employeeId={id}
          onClose={() => setOpenFullLabourDetail(false)}
          enterpriseEmployeeId={enterpriseEmployeeId!}
        />
      </Dialog>

      <Dialog fullWidth maxWidth='lg' open={isSettingPartLabour} onClose={() => setSettingPartLabour(false)}>
        <ConditionPartTimePopup
          employeeId={id}
          pasId={0}
          enterpriseEmployeeId={enterpriseEmployeeId!}
          onClose={() => setSettingPartLabour(false)}
        />
      </Dialog>

      <Dialog fullWidth maxWidth='lg' open={isSettingFullLabour} onClose={() => setSettingFullLabour(false)}>
        <ConditionFullTimePopup
          employeeId={id}
          pasId={0}
          enterpriseEmployeeId={enterpriseEmployeeId!}
          onClose={() => setSettingFullLabour(false)}
        />
      </Dialog>

      <Dialog fullWidth maxWidth='lg' open={isOpenNotice} onClose={() => setOpenNotice(false)}>
        <HRNoticePopup conditionNoticeKey={noticeChoiceKey} onClose={() => setOpenNotice(false)} />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth='xs'
        open={!!enterpriseToChangeStatus}
        onClose={() => {
          setEnterpriseToChangeStatus(null);
        }}
      >
        <DialogTitle>
          <Trans>Are you sure?</Trans>
        </DialogTitle>
        <DialogContent>
          <Trans>
            <Trans>
              Do you want to{' '}
              {employees?.find((employee) => employee.id === enterpriseToChangeStatus)?.status !== 'LOCKED'
                ? t`locked`
                : t`activate`}{' '}
              {employees?.find((employee) => employee.id === enterpriseToChangeStatus)?.enterpriseName}?
            </Trans>
          </Trans>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant='outlined'
            onClick={() => {
              setEnterpriseToChangeStatus(null);
            }}
          >
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton loading={loading} variant='contained' onClick={handleChangeStatusUser}>
            <Trans>Update</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmployeeInfo;
