import { t, Trans } from '@lingui/macro';
import { Paper, Tab, Tabs, Typography } from '@mui/material';
import { useTabs } from 'hooks';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { EnterpriseType } from 'types/Enterprise';
import { WorkingType } from 'types/EnterpriseSetup';
import { EmployeeListStopWorking, EmployeeListWorking } from 'views/HR/components';

const HRManagementList = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const { data: working } = useQuery(
    ['enterpriseSetupService.fetchEmployees', { id: enterprise?.id!, workingType: 'WORKING' }],
    () => enterpriseSetupService.fetchEmployees({ id: enterprise?.id!, workingType: 'WORKING' }),
  );
  const { data: stop } = useQuery(
    ['enterpriseSetupService.fetchEmployees', { id: enterprise?.id!, workingType: 'STOP' }],
    () => enterpriseSetupService.fetchEmployees({ id: enterprise?.id!, workingType: 'STOP' }),
  );

  const tabs = [
    {
      code: 'working',
      type: t`WORKING`,
      count: working?.paginate.totalRecord,
      component: <EmployeeListWorking enterpriseId={enterprise?.id} workingType={'WORKING' as WorkingType} />,
    },
    {
      code: 'stop',
      type: t`STOP WORKING`,
      count: stop?.paginate.totalRecord,
      component: <EmployeeListStopWorking enterpriseId={enterprise?.id} workingType={'STOP' as WorkingType} />,
    },
  ].map((item) => ({
    code: item.code,
    label: `${item.type} (${item.count ?? 0})`,
    component: item.component,
  }));
  const [activeTab, onChangeTab] = useTabs(tabs);

  return (
    <Paper className='mt-6'>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>List of Employee</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Tabs value={activeTab} onChange={onChangeTab}>
          {tabs.map((tab) => (
            <Tab key={tab.code} label={tab.label} value={tab.code} />
          ))}
        </Tabs>
        {tabs.map((tab) => (
          <div key={tab.code} hidden={tab.code !== activeTab} className='mt-4'>
            {tab.component}
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default HRManagementList;
