import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enterpriseService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { EnterpriseCreateType } from 'types/Enterprise';

const EnterpriseCreatePopup = ({ onClose }: PopupController) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();

  const { mutate: createEnterprise, isLoading } = useMutation(enterpriseService.createEnterprise, {
    onSuccess: () => {
      enqueueSnackbar(t`Create enterprise successfully`);
      queryClient.invalidateQueries('enterpriseService.fetchEnterprises');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: EnterpriseCreateType) => {
      createEnterprise(values);
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Create Enterprise</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Controller
              name='name'
              defaultValue=''
              control={control}
              rules={{ required: t`Name is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Enterprise Name`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='taxCode'
              defaultValue=''
              control={control}
              rules={{ required: t`Tax Code is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  fullWidth
                  required
                  label={t`Tax Code`}
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='businessSector'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t`Business Sector`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='address'
              defaultValue=''
              control={control}
              rules={{ required: t`Address is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  fullWidth
                  required
                  label={t`Address`}
                  {...field}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='legalRepresentative'
              defaultValue=''
              control={control}
              rules={{ required: t`Legal Representative Name is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Legal Representative`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='legalRepresentativePosition'
              defaultValue=''
              control={control}
              rules={{ required: t`Legal Representative Position is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Legal Representative Position`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='contactName'
              defaultValue=''
              control={control}
              rules={{ required: t`Contact Name is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Contact Name`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='contactDepartment'
              defaultValue=''
              control={control}
              rules={{ required: t`Contact Department is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Contact Department`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='contactPhone'
              defaultValue=''
              control={control}
              rules={{ required: t`Contact Phone is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Contact Phone`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='contactEmail'
              defaultValue=''
              control={control}
              rules={{ required: t`Contact Email is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Contact Email`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EnterpriseCreatePopup;
