import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Dialog, Button } from '@mui/material';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import PdfViewer from 'components/PdfViewer';
import { useState } from 'react';
import { RejectPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import { AppendixObjectDataType } from 'types/EnterpriseSetup';
import { usePermission } from 'hooks';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseId: string;
  appendixId: number;
  objectData: AppendixObjectDataType;
};

const FixedServicePopupStep4 = ({ enterpriseId, appendixId, objectData, onBack, onNext }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenReject, setOpenReject] = useState(false);
  const { requireUserPCManagerOrCALeader } = usePermission();

  const { mutate: approveDigitalAppendix, isLoading } = useMutation(enterpriseSetupService.approveDigitalAppendix, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseServiceFeeHistories');
      onNext();
    },
  });

  const handleClickSubmit = () => {
    approveDigitalAppendix({ enterpriseId, appendixId });
  };

  return (
    <>
      <DialogTitle>
        <Trans>APPENDIX OF FIXED SERVICE FEE</Trans>
      </DialogTitle>
      <DialogContent>
        <PdfViewer objectKey={objectData.objectKey} />
      </DialogContent>

      <DialogActions>
        {requireUserPCManagerOrCALeader() &&
          <div className='flex items-center justify-between w-full'>
            <Button variant='outlined' color='error' onClick={() => setOpenReject(true)}>
              <Trans>Reject</Trans>
            </Button>
            <LoadingButton variant='contained' onClick={handleClickSubmit} loading={isLoading}>
              <Trans>Approve</Trans>
            </LoadingButton>
          
          </div>
        }
      </DialogActions>

      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <RejectPopup
          enterpriseId={enterpriseId}
          appendixId={appendixId}
          onBack={() => {
            setOpenReject(false);
          }}
          onNext={() => {
            setOpenReject(false);
            onBack();
          }}
        />
      </Dialog>
    </>
  );
};

export default FixedServicePopupStep4;
