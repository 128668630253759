import { AppContainer } from 'containers';
import { IS_EPAYZ, IS_SSO } from 'env';
import { LanguageProvider } from 'i18n';
import { default as jwtDecode } from 'jwt-decode';
import { PrivateLayout } from 'layouts';
import { useEffect, useState, useCallback } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from 'reducers';
import { epayzRoute, publicRoute, staticRoute } from 'routes';
import ApiService, {IApiService} from './services/ApiService';
import AuthService, {IAuthService} from './services/AuthService';
import { User } from 'oidc-client';
import { userService } from 'services';
import { openAlert } from 'reducers/notification';
import { t, Trans } from '@lingui/macro';
import { signIn, signOut } from 'reducers/profile';
const authService: IAuthService = new AuthService();
const apiService: IApiService = new ApiService(authService);


const App = () => {
  const [isReady, setIsReady] = useState(false);
  const [api, setApi] = useState();
  useEffect(() => {
    try {  
      if(IS_SSO)
      { 
        const fetchData = async () => {
            var userResponse = await authService.getUser();
            if (userResponse) {
              var profile = localStorage.getItem("profile");
              if(!profile){
                localStorage.clear();
                window.location.reload();
              }
              else{
                var profileParse = JSON.parse(profile);
                jwtDecode(profileParse.accessToken);
                store.dispatch(signIn(profileParse));
              }
          } else {
            authService.login();
          }
        }
        if(window.location.href.indexOf('/signin-callback') == -1){
          fetchData().catch(console.error);
        }
      }
      else
      {
        const profile = JSON.parse(localStorage.getItem('profile') ?? '');
        jwtDecode(profile.accessToken);
        store.dispatch(signIn(profile));
      }
    
    } catch {

    } finally {
      setIsReady(true);
    }
  }, []);


  

  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <LanguageProvider>
          <AppContainer>
            {isReady && (
              <Routes>
                {Object.values(IS_EPAYZ ? epayzRoute : staticRoute).map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
                {Object.values(publicRoute).map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))}
                <Route path='/*' element={<PrivateLayout />} />
              </Routes>
            )}
          </AppContainer>
        </LanguageProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
};

export default App;
