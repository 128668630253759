import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { creditScoreService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { ScoreCardCreateType } from 'types/CreditScore';

type PopupProps = PopupController & {
  csId: number;
  prId: number;
  crId: number;
  type: number;
};

const CriteriaScoreCreatePopup = ({ csId, prId, crId, type, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const isQualitative = type === 1;

  const { mutate: createScore, isLoading } = useMutation(
    isQualitative ? creditScoreService.createScoreQualitative : creditScoreService.createScoreQuantitative,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('creditScoreService.getParameterById');
        onClose();
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit((values: ScoreCardCreateType) => {
      createScore({ ...values, csId, prId, crId });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Add Criteria Score</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          {isQualitative ? (
            <>
              <Grid item sm={12}>
                <Controller
                  name='nameVi'
                  defaultValue=''
                  control={control}
                  rules={{ required: t`Vietnamese Name is required` }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label='Vietnamese Name'
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12}>
                <Controller
                  name='nameEn'
                  defaultValue=''
                  control={control}
                  rules={{ required: t`English Name is required` }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label={t`English Name`}
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item sm={12}>
                <Controller
                  name='minValue'
                  defaultValue=''
                  control={control}
                  rules={{ required: t`Minimun Value is required` }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label={t`Minimun Value`}
                      InputProps={{
                        inputComponent: InputNumber as any,
                        inputProps: {
                          allowNegative: true,
                        },
                      }}
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12}>
                <Controller
                  name='maxValue'
                  defaultValue=''
                  control={control}
                  rules={{ required: t`Maximun Value is required` }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      label={t`Maximun Value`}
                      InputProps={{
                        inputComponent: InputNumber as any,
                        inputProps: {
                          allowNegative: true,
                        },
                      }}
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item sm={12}>
            <Controller
              name='score'
              defaultValue=''
              control={control}
              rules={{ required: t`Score is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Score`}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: {
                      allowNegative: true,
                    },
                  }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CriteriaScoreCreatePopup;
