import { t, Trans } from '@lingui/macro';

import {
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  debounce,
} from '@mui/material';
import { TableEmpty } from 'components';
import { getEmployeeType } from 'constants/employee';
import { useSearch } from 'hooks';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { enterpriseSetupService, categoryService } from 'services';
import { WorkingType } from 'types/EnterpriseSetup';
import { useState, useEffect, useCallback } from 'react';
import SelectPageSize from 'components/SelectPageSize';

const EmployeeListStopWorking = ({
  enterpriseId,
  workingType,
}: {
  enterpriseId?: number;
  workingType: WorkingType;
}) => {
  const [dataSearch, onSearchChange] = useSearch({ id: enterpriseId, workingType });
  const userLocale = useActiveLocale();
  const [searchParams, setSearchParams] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState('name');
  const { data } = useQuery(
    ['enterpriseSetupService.fetchEmployees', dataSearch],
    () => enterpriseSetupService.fetchEmployees(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};
  const { data: employeeLevels } = useQuery(['fetchEmployeeLevels'], () => categoryService.fetchEmployeeLevels());
  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  async function onChangeType(value: any) {
    setSearchType("type")
    var datavalue = { [searchType]: value.target.value === "1" ? "" : value.target.value }
    debounceChangeValue(datavalue)
  }
  async function onChangeTypeLevel(value: any) {
    setSearchType("level_id")
    var datavalue = { [searchType]: value.target.value === "all" ? "" : value.target.value }
    debounceChangeValue(datavalue)
  }
  return (
    <div>
      <div className='flex items-center justify-end'>
        <div className='flex space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value='name'>
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value='ercNumber'>
              <Trans>ID No.</Trans>
            </MenuItem>
            <MenuItem value='type'>
              <Trans>Type</Trans>
            </MenuItem>
            <MenuItem value='level_id'>
              <Trans>Level</Trans>
            </MenuItem>
          </TextField>
          {
            searchType === 'type' ?
              <TextField select defaultValue='1' onChange={(value) => onChangeType(value)}>
                <MenuItem value='1'>
                  <Trans>All</Trans>
                </MenuItem>
                <MenuItem value='SEASON'>
                  <Trans>Part-time</Trans>
                </MenuItem>
                <MenuItem value='FULL'>
                  <Trans>Full-time</Trans>
                </MenuItem>
              </TextField> : searchType === 'level_id'
                ?
                <TextField select defaultValue='all' onChange={(value) => onChangeTypeLevel(value)}>
                  <MenuItem value='all'>
                    <Trans>All</Trans>
                  </MenuItem>
                  {employeeLevels?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                    </MenuItem>
                  ))}
                </TextField>
                :
                <TextField placeholder={t`Search...`}
                  value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                    debounceChangeValue({ [searchType]: event.target.value, languageVi: userLocale === 'vi-VN' ? 'true' : 'false' });
                  }} />
          }
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>ID No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Level</Trans>
              </TableCell>
              <TableCell>
                <Trans>Phone</Trans>
              </TableCell>
              <TableCell>
                <Trans>Type of Labor Contract</Trans>
              </TableCell>
              <TableCell>
                <Trans>Last working day</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.employeeId}>
                <TableCell>{item.employee.name}</TableCell>
                <TableCell>{item.employee.ercNumber}</TableCell>
                <TableCell>{getEmployeeType(item.employeeType)}</TableCell>
                <TableCell>
                  {userLocale === 'vi-VN' ? item.employeeLevel?.viContent : item.employeeLevel?.enContent}
                </TableCell>
                <TableCell>{item.employee.phone}</TableCell>
                <TableCell>{userLocale === 'vi-VN' ? item.laborTerm?.viContent : item.laborTerm?.enContent}</TableCell>
                <TableCell>
                  {item.stopWorkingDate ? DateTime.fromISO(item.stopWorkingDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>
    </div>
  );
};

export default EmployeeListStopWorking;
