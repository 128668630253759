import { t, Trans } from '@lingui/macro';
import { ArrowRight } from '@mui/icons-material';
import { Button, Dialog, Paper, Typography } from '@mui/material';
import { getContractState } from 'constants/contract';
import { usePermission } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseService } from 'services';
import { ContractLastestType, ContractType } from 'types/EnterpriseContract';
import {
  ContractApprovePopup,
  ContractCreatePopup, ContractHistoryDeny,
  ContractHistory, ContractPartnerSignPopup, ContractSignPopup, ContractUploadPopup,
  ContractViewPopup
} from 'views/Enterprise/EnterpriseContract/components';

const EnterpriseContract = () => {
  const { requireRole, requreUserPermission, ENTERPRISE_CONTRACT, ENTERPRISE_CONTRACT_ADD, ENTERPRISE_CONTRACT_APPROVE, ENTERPRISE_CONTRACT_SIGN, ENTERPRISE_CONTRACT_UPLOAD } = usePermission();
  const { id: _id } = useParams() as { id: string };

  const { data } = useQuery(['enterpriseService.getEnterpriseLatest', { _id }], () =>
    enterpriseService.getEnterpriseLatest({ _id }),
  );

  const [isOpen, setOpen] = useState(false);
  const [isOpenCreate, setOpenCreate] = useState(false);
  const [chosenContract, setChosenContract] = useState<ContractType>();
  const { state = 'MISSING' } = chosenContract ?? {};

  const dataContractView = (contract: ContractType | undefined) => {
    var torf = true;
    if (!contract) {
      torf = true;
    }
    else {
      // if(contract?.state == "COMPLETED"){
      //   torf = true;
      // }
      torf = false;
    }
    return torf;
  }
  const dataContractCreate = (contract: ContractType | undefined) => {
    var torf = true;
    if (!requreUserPermission([ENTERPRISE_CONTRACT])) {
      return torf;
    }
    if (!contract) {
      torf = false;
    }
    else {
      if (contract?.state == "COMPLETED") {
        torf = false;
      }
    }
    return torf;
  }
  const dataContractCreateService = (contract: ContractType | undefined, contractCooor: ContractType | undefined) => {
    var torf = true;
    if (!requreUserPermission([ENTERPRISE_CONTRACT])) {
      return torf;
    }
    if (!contract) {
      if (contractCooor?.state == "COMPLETED") {
        torf = false;
      } else {
        torf = true;
      }
    }
    else {
      if (contract?.state == "COMPLETED") {
        torf = false;
      }
    }
    return torf;
  }
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Enterprise Contract</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        {[
          { label: t`Coordination Agreement`, contract: data?.cOORDINATIONAGREEMENT, type: 'COORDINATION_AGREEMENT' },
          { label: t`Service Supply Contract`, contract: data?.sERVICESUPPLY, type: 'SERVICE_SUPPLY' },
        ].map(({ label, contract, type }, index) => (
          <div key={index} className='flex items-center justify-between mb-6'>
            <div className='flex items-center space-x-2'>
              <Typography variant='h4'>
                {index + 1}. {label}
              </Typography>
              <ArrowRight />
              <Typography variant='h5'>{getContractState(contract?.state ?? 'MISSING')}</Typography>
            </div>

            <div className='flex space-x-2'>
              {[
                { key: 'view', label: t`View`, disabled: dataContractView(contract) },
                {
                  key: 'create',
                  label: t`Create`,
                  disabled: type == 'COORDINATION_AGREEMENT' ? dataContractCreate(contract) : dataContractCreateService(contract, data?.cOORDINATIONAGREEMENT),
                },
              ].map((item, index) => (
                <Button
                  key={index}
                  variant='outlined'
                  size='small'
                  disabled={item.disabled}
                  onClick={() => {
                    if (item.key === 'create' && contract?.state === 'COMPLETED') {
                      setOpenCreate(true);
                      setOpen(false);
                    } else {
                      setOpen(true);
                      setOpenCreate(false);
                    }
                    setChosenContract({ ...contract, type } as ContractType | undefined);
                  }}
                >
                  {item.label}
                </Button>
              ))}
            </div>
          </div>
        ))}

        <Dialog fullWidth maxWidth='lg' open={isOpen}>
          {['MISSING'].includes(state) && requreUserPermission([ENTERPRISE_CONTRACT_ADD]) && (
            <ContractCreatePopup contract={chosenContract!} enterpriseId={_id} onClose={() => setOpen(false)} />
          )}
          {['WAITING_DOC'].includes(state) && requreUserPermission([ENTERPRISE_CONTRACT_UPLOAD]) && (
            <ContractUploadPopup contract={chosenContract!} enterpriseId={_id} onClose={() => setOpen(false)} />
          )}
          {['REVIEWING'].includes(state) && requreUserPermission([ENTERPRISE_CONTRACT_APPROVE]) && (
            <ContractApprovePopup contract={chosenContract!} enterpriseId={_id} onClose={() => setOpen(false)} />
          )}
          {['WAITING_SIGN'].includes(state) && requreUserPermission([ENTERPRISE_CONTRACT_SIGN]) && (
            <ContractSignPopup contract={chosenContract!} enterpriseId={_id} onClose={() => setOpen(false)} />
          )}
          {['WAITING_PARTNER'].includes(state) && requreUserPermission([ENTERPRISE_CONTRACT_SIGN]) && (
            <ContractPartnerSignPopup contract={chosenContract!} enterpriseId={_id} onClose={() => setOpen(false)} />
          )}
          {['COMPLETED'].includes(state) && requreUserPermission([ENTERPRISE_CONTRACT]) && (
            <ContractViewPopup contract={chosenContract!} enterpriseId={_id} onClose={() => setOpen(false)} />
          )}
        </Dialog>
        <Dialog fullWidth maxWidth='lg' open={isOpenCreate}>
          {['COMPLETED'].includes(state) && requreUserPermission([ENTERPRISE_CONTRACT_ADD]) && (
            <ContractCreatePopup contract={chosenContract!} enterpriseId={_id} onClose={() => setOpenCreate(false)} />
          )}
        </Dialog>
        <ContractHistoryDeny />
      </div>
    </Paper>
  );
};

export default EnterpriseContract;
