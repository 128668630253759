import { StaticFooter, StaticHeader } from 'containers';

const StaticLayout = ({ children }: any) => {
  return (
    <div className='App'>
      <StaticHeader />
      <div className='min-h-screen pt-20'>{children}</div>
      <StaticFooter />
    </div>
  );
};

export default StaticLayout;
