import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { RejectGuaranteeType } from 'types/EnterpriseSetup';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseId: number;
  noticeId: number;
};

const RejectPopup = ({ enterpriseId, noticeId, onNext, onBack }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: rejectGuaranteeNotice, isLoading } = useMutation(enterpriseSetupService.rejectGuaranteeNotice, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.fetchGuarantee');
      onNext();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit(({ reason }: RejectGuaranteeType) => {
      rejectGuaranteeNotice({
        enterpriseId,
        noticeId,
        reason,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Reason Reject</Trans>
      </DialogTitle>

      <DialogContent>
        <Controller
          name='reason'
          defaultValue=''
          control={control}
          rules={{ required: t`Reason is required` }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              fullWidth
              required
              label={t`Reason`}
              error={invalid}
              helperText={error?.message}
            ></TextField>
          )}
        />
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <LoadingButton variant='outlined' onClick={onBack}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default RejectPopup;
