import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { DesktopDatePicker, LoadingButton, TimePicker } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { employeeService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { EmployeeType } from 'types/Employee';
import { ReportCreateType } from 'types/EmployeeReport';
import { getEnterpriseStatus } from 'constants/enterprise';
import { useEffect, useState } from 'react';
type PopupProps = PopupController & { employee: EmployeeType };

const ReportCreatePopup = ({ employee, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  
const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const [showResults, setShowResults] = useState(false)
  const { mutate: createReport, isLoading } = useMutation(employeeService.createEmployeeReport, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeService.fetchEmployeeReports');
      onClose();
    },
  });
  const onChangeStatus = (event: any) => {
    var value = event.currentTarget.dataset.value;
    if (value && value == "AGREED_TO_MEET") {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }
  const handleClickSubmit = () => {
    handleSubmit((values) => {
      type ValuesType = ReportCreateType & { meetingDate: DateTime; meetingTime: DateTime };
      const { meetingDate, meetingTime, ...others } = values as ValuesType;

      createReport({
        ...others,
        meetingDate: meetingDate ? meetingDate.set({ hour: meetingTime.hour, minute: meetingTime.minute }).toISO() : meetingDate,
        _id: employee.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>Add Report</DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Controller
              name='status'
              defaultValue=''
              control={control}
              rules={{ required: t`Status is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  select
                  required
                  label={t`Status`}
                  error={invalid}
                  helperText={error?.message}
                >
                  {employee.toStatuses?.map((status, index) => (
                    <MenuItem key={index} value={status} onClick={onChangeStatus}>
                      {getEnterpriseStatus(status)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          { showResults && 
          <Grid item sm={7}>
            <Controller
              name='meetingDate'
              defaultValue={DateTime.now()}
              control={control}
              rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDatePicker
                  value={value}
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} required error={invalid} />}
                  label={t`Meeting Date`}
                  inputFormat='dd/MM/yyyy'
                />
              )}
            />
          </Grid>
          }
          { showResults && 
          <Grid item sm={5}>
            <Controller
              name='meetingTime'
              defaultValue={DateTime.now()}
              control={control}
              rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <TimePicker
                  value={value}
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} required error={invalid} />}
                  label={t`Time`}
                />
              )}
            />
          </Grid>
          }
          <Grid item sm={12}>
            <Controller
              name='note'
              defaultValue=''
              control={control}
              rules={{ required: t`Note is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows={2}
                  required
                  label={t`Note`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ReportCreatePopup;
