export { default as Spinner } from './Spinner';
export { default as TableEmpty } from './TableEmpty';
export { default as InputNumber } from './InputNumber';
export { default as InputNumberNegative } from './InputNumberNegative';
export { default as InputNumberPhone } from './InputNumberPhone';
export { default as TooltipLight } from './TooltipLight';
export { default as DigitalSign } from './DigitalSign';
export { default as InfiniteSlider } from 'react-slick';
export { default as CountUp } from 'react-countup';

export { default as InputOTP } from 'react-otp-input-rc-17';
export { default as EnhancedTable } from 'mui-datatables';

export const EnhancedTableOptions = {
  elevation: 0,
  pagination: false,
  search: false,
  download: false,
  print: false,
  filter: false,
  viewColumns: false,
  sort: false,
  fixedSelectColumn: false,
  expandableRows: false,
  expandableRowsHeader: false,
};
