import { Trans } from '@lingui/macro';
import { Grid, Paper, Typography } from '@mui/material';
import { TransSummaryType } from 'types/Transaction';
import { formatNumber } from 'utils/common';

const TransSummary = ({ summary }: { summary?: TransSummaryType }) => {
  return (
    <Paper className='bg-[#f2f2f2] p-4'>
      <Grid container columnSpacing={3}>
        <Grid item md={6} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>No. of Transaction</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.noOfTransaction)}</Typography>
          </Paper>
        </Grid>
        <Grid item md={6} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Total Request Amount</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalRequestAmount)} VND</Typography>
          </Paper>
        </Grid>
        <Grid item md={6} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>No. of Overdue</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.noOfOverdue)}</Typography>
          </Paper>
        </Grid>
        <Grid item md={6} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Total Overdue Unpaid Amount</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalOverdueUnpaidAmount)} VND</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TransSummary;
