import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { queryClient, userService } from 'services';
import { PopupController } from 'types/Common';
import { UserType } from 'types/User';

type PopupProps = PopupController & {
  user: UserType;
};

const UserRemovePopup = ({ user, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: removeUser, isLoading } = useMutation(userService.removeUser, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('userService.fetchUsers');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    removeUser({ id: user.id });
  };

  return (
    <>
      <DialogTitle>
        <Trans>Remove User</Trans>
      </DialogTitle>

      <DialogContent>
        <Typography>
          <Trans>Are your sure you want to remove user {user.fullname}?</Trans>
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' color='error' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Remove</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default UserRemovePopup;
