import { useState, useRef } from 'react';
import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Chip,
} from '@mui/material';
import { PopupController } from 'types/Common';
import { Upload, ArrowRight } from '@mui/icons-material';
import { enterpriseService, templateService, queryClient } from 'services';
import { downloadBase64 } from 'utils/common';
import { useMutation } from 'react-query';
type PopupProps = PopupController & {
  onNext: () => void;
};

const EnterpriseCreateSelectPopup = ({ onNext, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const [fileSelected, setFileSelected] = useState<File>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const { mutate: createEnterprise, isLoading } = useMutation(enterpriseService.importEnterprises, {
    onSuccess: () => {
      enqueueSnackbar(t`Create enterprise successfully`);
      queryClient.invalidateQueries('enterpriseService.fetchEnterprises');
      onClose();
    },
  });
  const handleClickSubmit = () => {
    var extension = ["xls", "xlsx"];
    if (fileSelected) {
      const ext = fileSelected.name.split(".").pop()?.toString();
      if (ext) {
        if (extension.includes(ext.toLowerCase())) {
          const formData = new FormData();
          formData.append('file', fileSelected, fileSelected.name);
          createEnterprise(formData);
          onClose();
        } else {
          enqueueSnackbar(t`Please import file format xlsx`, { variant: 'warning' });
        }
      }



    } else {
      enqueueSnackbar(t`Please select file or choose template`, { variant: 'warning' });
    }
  };

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    setFileSelected(fileList[0]);
  };
  return (
    <>
      <DialogTitle>
        <Trans>Add Enterprise</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex items-center justify-end space-x-3'>
          <Typography variant='h6'>
            <Trans>Download the samples</Trans>
          </Typography>
          <Button
            size='small'
            color='info'
            onClick={() => {
              templateService.downloadFileBase64('import-enterprise').then((res) => {
                downloadBase64(res);
              });
            }}
          >
            <Trans>Enterprise</Trans>
          </Button>
        </div>
        <RadioGroup name='employee-type' value={value} onChange={handleChange}>
          <Grid container columnSpacing={2} className='my-6'>
            <Grid item sm={12} md={6}>
              <div className='border flex justify-center items-center py-4 rounded-lg'>
                <FormControlLabel value='import' control={<Radio />} label={t`Update by excel`} />
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div className='border flex justify-center items-center py-4 rounded-lg'>
                <FormControlLabel value='manual' control={<Radio />} label={t`Manual Update`} />
              </div>
            </Grid>
          </Grid>
        </RadioGroup>
        <div className='flex justify-between'>
          <input ref={inputRef} type='file' hidden onChange={handleChangeFiles} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
          <div className='space-x-3'>
            <LoadingButton variant='outlined' size='small' onClick={() => inputRef.current?.click()}>
              <Trans>Choose Files</Trans>
            </LoadingButton>
            {fileSelected && (
              <Chip
                key={fileSelected?.name}
                variant='outlined'
                label={fileSelected?.name}
                onDelete={() => setFileSelected(undefined)}
              />
            )}
          </div>

          {value === 'import' && fileSelected && (
            <LoadingButton variant='contained' size='small' startIcon={<Upload />} onClick={handleClickSubmit}>
              <Trans>Upload</Trans>
            </LoadingButton>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        {value === 'manual' && (
          <LoadingButton variant='contained' startIcon={<ArrowRight />} onClick={onNext}>
            <Trans>Next</Trans>
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default EnterpriseCreateSelectPopup;
