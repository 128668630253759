import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { CheckCircleRounded } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { InputNumber, TableEmpty } from 'components';
import { usePermission, useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { promotionService, queryClient } from 'services';
import { PromotionEnterpriseType, PromotionUpdateType, EmployeeOfEnterpriseType } from 'types/Promotion';
import { EmployeeListPopup } from './components';
import SelectPageSize from 'components/SelectPageSize';

const PromotionView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { requireRole, requreUserPermission, GENERALSETTING_PROMOTION_DELETE, GENERALSETTING_PROMOTION_UPDATE, GENERALSETTING_PROMOTION_CREATE } = usePermission();
  const { id } = useParams() as { id: string };
  const isCreate = id === 'create';

  const { control, handleSubmit, setValue, getValues } = useForm({ mode: 'onChange' });

  const [dataSearch, onSearchChange] = useSearch({ id: isCreate ? '0' : id });
  const [isOpen, setOpen] = useState(false);
  const [isUpdate, setUpdate] = useState(true);
  const [chosenEnterprise, setChosenEnterprise] = useState<PromotionEnterpriseType>();

  const [dataSelect, setDataSelect] = useState<EmployeeOfEnterpriseType[]>([]);
  const [dataSelected, setDataSelected] = useState<PromotionEnterpriseType[]>([]);

  useQuery(['promotionService.getPromotionById', { id }], () => promotionService.getPromotionById({ id }), {
    enabled: !isCreate,
    onSuccess: (data) => {
      Object.entries(data).forEach(([key, value]) => {
        if (['startingDate', 'closingDate'].includes(key)) {
          setValue(key, DateTime.fromISO(value as string));
        } else setValue(key, value);
        setUpdate(key === 'status' && ['WAITING_FOR_APPLY', 'APPLIED'].includes(value.toString()));
      });
    },
  });

  const { data } = useQuery(
    ['promotionService.fetchPromotionEnterprises', dataSearch],
    () => promotionService.fetchPromotionEnterprises(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { mutate: createPromotion, isLoading } = useMutation(
    isCreate ? promotionService.createPromotion : promotionService.updatePromotion,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('promotionService.fetchPromotionEnterprises');
        setDataSelect([]);
      },
    },
  );

  const { mutate: removePromotionEnterprises, isLoading: isLoadingDelete } = useMutation(
    promotionService.removePromotionEnterprises,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('promotionService.fetchPromotionEnterprises');
        setDataSelect([]);
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      type ValuesType = PromotionUpdateType & {
        startingDate: DateTime;
        closingDate: DateTime;
      };
      const { startingDate, closingDate, ...others } = values as ValuesType;
      createPromotion({
        ...others,
        startingDate: startingDate.toISO(),
        closingDate: closingDate.toISO(),
        enterprises: dataSelect,
      });
    })();
  };

  const handleClickDelete = () => {
    removePromotionEnterprises({
      id,
      enterpriseIds: dataSelected.filter((item) => item.totalPromotionEmployee > 0).map((item) => item.id),
    });
  };

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Promotion Information</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Typography variant='h4' className='mb-4'>
          <Trans>Promotion Information</Trans>
        </Typography>
        <Grid container columnSpacing={4} rowSpacing={3}>
          <Grid item sm={6}>
            <Controller
              name='promotionName'
              defaultValue=''
              control={control}
              rules={{ required: t`Promotion Name is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Promotion Name`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name='startingDate'
              defaultValue=''
              control={control}
              rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDatePicker
                  value={value}
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} required error={invalid} />}
                  label={t`Start Date`}
                  inputFormat='dd/MM/yyyy'
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name='closingDate'
              defaultValue=''
              control={control}
              rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDatePicker
                  value={value}
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} required error={invalid} />}
                  label={t`End Date`}
                  inputFormat='dd/MM/yyyy'
                />
              )}
            />
          </Grid>
        </Grid>

        <Typography variant='h4' className='mt-6'>
          <Trans>Promotion Recipients</Trans>
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    onChange={(event, checked) => {
                      if (checked) setDataSelected(items);
                      else setDataSelected([]);
                    }}
                    checked={
                      dataSelected.filter((item) => item.totalPromotionEmployee > 0).length ===
                      items.filter((item) => item.totalPromotionEmployee > 0).length
                    }
                    indeterminate={
                      dataSelected.length > 0 &&
                      dataSelected.length < items.filter((item) => item.totalPromotionEmployee > 0).length
                    }
                  />
                </TableCell>
                <TableCell>
                  <Trans>Enterprise Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Promotion</Trans>
                </TableCell>
                <TableCell className='text-center'>
                  <Trans>Number of Working Employee</Trans>
                </TableCell>
                <TableCell className='text-center'>
                  <Trans>Number of Promotion Employee</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align='center' className='p-1'>
                    {item.totalPromotionEmployee > 0 && (
                      <Checkbox
                        checked={dataSelected.some((next) => next.id === item.id)}
                        onChange={(event, checked) => {
                          if (checked) setDataSelected((nexts) => nexts.concat(item));
                          else setDataSelected((nexts) => nexts.filter((next) => next.id !== item.id));
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>{item.enterpriseName}</TableCell>
                  <TableCell className='text-center'>
                    {item.totalPromotionEmployee > 0 && <CheckCircleRounded color='success' />}
                  </TableCell>
                  <TableCell className='text-center'>{item.totalEmployee}</TableCell>
                  <TableCell className='text-center'>
                    {item.totalPromotionEmployee > 0 ? item.totalPromotionEmployee : ''}
                  </TableCell>
                  <TableCell className='text-right'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpen(true);
                        setChosenEnterprise(item);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>

        <Dialog fullWidth maxWidth='md' open={isOpen} onClose={() => setOpen(false)}>
          <EmployeeListPopup
            promotionId={isCreate ? '0' : id}
            enterpriseId={chosenEnterprise?.id!}
            setDataSelect={setDataSelect}
            dataSelect={dataSelect}
            numberApplied={getValues("numberAppliedTimes")}
            onClose={() => setOpen(false)}
          />
        </Dialog>

        <Typography variant='h4' className='mb-4'>
          <Trans>Promotion Setting</Trans>
        </Typography>
        <Grid container columnSpacing={4} rowSpacing={3}>
          <Grid item sm={3}>
            <Controller
              name='minimumRequestAmount'
              defaultValue=''
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t`Minimum of Request Amount (VND)`}
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name='promotionDiscountRate'
              defaultValue=''
              control={control}
              rules={{ required: true, min: 0, max: 100 }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t`Promotion of Discount Rate (%)`}
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: { maxLength: 5 },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name='promotionTransactionCost'
              defaultValue=''
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t`Promotion of Transaction Cost`}
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: { allowNegative: true, thousandSeparator: '.', decimalSeparator: ',' },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name='numberAppliedTimes'
              defaultValue=''
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t`Number of applied times for each employee`}
                  error={invalid}
                  InputProps={{ inputComponent: InputNumber as any }}
                />
              )}
            />
          </Grid>
        </Grid>

        <div className='flex justify-center mt-4'>
          {requireRole('MASTER') && isUpdate && (
            <>
              {!isCreate && requreUserPermission([GENERALSETTING_PROMOTION_DELETE]) && (
                <LoadingButton
                  variant='contained'
                  color='error'
                  className='w-32 mr-1'
                  loading={isLoadingDelete}
                  onClick={handleClickDelete}
                >
                  <Trans>Delete</Trans>
                </LoadingButton>
              )}
              <LoadingButton variant='contained' className='w-32' loading={isLoading} onClick={handleClickSubmit}>
                {isCreate ? requreUserPermission([GENERALSETTING_PROMOTION_CREATE]) && (<Trans>Create</Trans>) : requreUserPermission([GENERALSETTING_PROMOTION_UPDATE]) && (<Trans>Update</Trans>)}
              </LoadingButton>
            </>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default PromotionView;
