import { EpayzHeader, EpayzFooter } from 'containers';

const EpayzLayout = ({ children }: any) => {
  return (
    <div className='App'>
      <EpayzHeader />
      <div className='min-h-screen pt-[108px]'>{children}</div>
      <EpayzFooter />
    </div>
  );
};

export default EpayzLayout;
