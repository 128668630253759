import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { employeeService, queryClient } from 'services';
import { AppraisalCreateType } from 'types/EmployeeAppraisal';
import { getProfileType, getAppraisalType } from 'constants/enterprise';
import { TableEmpty } from 'components';

export const PROFILE_TYPES = [
  { value: 1, label: t`One` },
  { value: 2, label: t`Two` },
];

export const APPRAISAL_TYPES = [
  { value: 1, label: t`One` },
  { value: 2, label: t`Two` },
  { value: 3, label: t`Three` },
];

const EmployeeChecklistAppraisal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const userLocale = useActiveLocale();
  const { id: _id } = useParams() as { id: string };
  const { control, handleSubmit, getValues, setValue } = useForm();

  const { data } = useQuery(['employeeService.getEmployeeAppraisal', { _id }], () =>
    employeeService.getEmployeeAppraisal({ _id }),
  );
  const { groups = [] } = data ?? {};

  const { mutate: createEmployeeAppraisal, isLoading: isLoadingCreate } = useMutation(
    employeeService.createEmployeeAppraisal,
    {
      onSuccess: (data, body) => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('employeeService.fetchEmployeeAppraisal');
        Object.keys(getValues()).forEach((key) => {
          setValue(key, '');
        });
      },
    },
  );

  const { mutate: updateEmployeeAppraisal, isLoading: isLoadingUpdate } = useMutation(
    employeeService.updateEmployeeAppraisal,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
      },
    },
  );

  const handleClickSubmitUpdate = () => {
    handleSubmit((values) => {
      const array = Object.keys(values)
        .map((item) => Number(item.split('-')[1]))
        .filter((item, index, array) => array.indexOf(item) === index)
        .reduce(
          (array: AppraisalCreateType[], id) =>
            array.concat({
              appraisalId: id,
              appraisalValue: values[`appraisal-${id}`],
              profileValue: values[`profile-${id}`],
            }),
          [],
        );
      updateEmployeeAppraisal({ _id, values: array });
    })();
  };

  return (
    <div className='mt-6'>
      <div className='flex items-center justify-between mb-4'>
        <Typography variant='h4'>
          <Trans>Checklist for Employee Onboarding</Trans>
        </Typography>
        <LoadingButton variant='outlined' loading={isLoadingCreate} onClick={() => createEmployeeAppraisal({ _id })}>
          <Trans>Move to History</Trans>
        </LoadingButton>
      </div>

      {groups.map((group) => (
        <Accordion key={group.id} className='mb-4'>
          <AccordionSummary>
            <Typography>
              {group.groupNumber}. {userLocale === 'vi-VN' ? group.viContent : group.enContent}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} variant='outlined' className='-my-1'>
              <Table>
                <TableBody>
                  {group.items.map((item) => (
                    <TableRow key={item.appraisalId}>
                      <TableCell className='w-2/3'>
                        {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                      </TableCell>
                      <TableCell>
                        {item.profileType ? (
                          <Controller
                            name={`profile-${item.appraisalId}`}
                            defaultValue={item.profileValue}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <FormGroup className='flex-row flex-nowrap'>
                                {PROFILE_TYPES.map((choice, index) => (
                                  <FormControlLabel
                                    key={index}
                                    label={getProfileType(choice.value)}
                                    control={
                                      <Checkbox
                                        checked={choice.value === value}
                                        onChange={(event, checked) => {
                                          if (checked) onChange({ target: { value: choice.value } });
                                        }}
                                      />
                                    }
                                  />
                                ))}
                              </FormGroup>
                            )}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      <TableCell>
                        {item.appraisalType ? (
                          <Controller
                            name={`appraisal-${item.appraisalId}`}
                            defaultValue={item.appraisalValue}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <FormGroup className='flex-row flex-nowrap'>
                                {APPRAISAL_TYPES.map((choice, index) => (
                                  <FormControlLabel
                                    key={index}
                                    label={getAppraisalType(choice.value)}
                                    control={
                                      <Checkbox
                                        checked={choice.value === value}
                                        onChange={(event, checked) => {
                                          if (checked) onChange({ target: { value: choice.value } });
                                        }}
                                      />
                                    }
                                  />
                                ))}
                              </FormGroup>
                            )}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableEmpty data={group.items} />
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}

      <div className='flex justify-center'>
        <LoadingButton variant='contained' loading={isLoadingUpdate} className='w-32' onClick={handleClickSubmitUpdate}>
          <Trans>Update</Trans>
        </LoadingButton>
      </div>
    </div>
  );
};

export default EmployeeChecklistAppraisal;
