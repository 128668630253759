import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enterpriseService, fileService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { ContractApproveType, ContractType } from 'types/EnterpriseContract';
import { ObjectType } from 'types/Object';
import ContractRejectPopup from './ContractRejectPopup';

type PopupProps = PopupController & {
  contract: ContractType;
  enterpriseId: string;
};

const ContractUploadPopup = ({ contract, enterpriseId, onClose }: PopupProps) => {
  const [isOpenReject, setOpenReject] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const [objects, setObjects] = useState<ObjectType[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const { mutate: uploadFiles, isLoading: isLoadingUpload } = useMutation(fileService.uploadFiles, {
    onSuccess: (data) => {
      setObjects(data.objects);
    },
  });

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    // Array.from(event.target.files!).forEach((file) => {
    //   console.log(file);

    // });
    if (!event.target.files) return;
    if (event.target.files![0].type != "application/pdf") {
      enqueueSnackbar(`Chỉ cho phép tải lên file hợp đồng định dạng PDF`, { variant: 'warning' });
      return;
    }
    formData.append('files', event.target.files![0]);
    uploadFiles(formData);
  };

  const { mutate: approveContract, isLoading: isLoadingApprove } = useMutation(
    enterpriseService.approveEnterpriseContract,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseService.getEnterpriseLatest');
        queryClient.invalidateQueries('enterpriseService.fetchEnterpriseContract');
        onClose();
      },
    },
  );

  const handleClickApprove = () => {
    handleSubmit((values) => {
      type ValuesType = ContractApproveType & { signDate: DateTime };
      const { signDate, ...others } = values as ValuesType;
      approveContract({
        ...others,
        _id: enterpriseId,
        id: contract.id!,
        objectId: objects[0]?.id,
        signDate: signDate.toISO(),
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Enterprise Contract</Trans>
      </DialogTitle>

      <DialogContent>
        <Stepper activeStep={1}>
          <Step>
            <StepLabel>
              <Trans>Creation</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>View</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Completion</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <div className='flex items-end space-x-3 h-10'>
              <input ref={inputRef} type='file' hidden onChange={handleChangeFiles} accept="application/pdf" />
              <LoadingButton
                variant='outlined'
                size='small'
                loading={isLoadingUpload}
                onClick={() => inputRef.current?.click()}
              >
                <Trans>Choose File</Trans>
              </LoadingButton>

              {objects.map((file) => (
                <Chip
                  key={file.id}
                  variant='outlined'
                  label={file.originalName}
                  onDelete={() => setObjects((items) => items.filter((item) => item.id !== file.id))}
                />
              ))}
            </div>
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='contractNo'
              defaultValue=''
              control={control}
              rules={{ required: t`Contract No is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField fullWidth required label={t`Contract No`} {...field} error={invalid} />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='signDate'
              defaultValue={DateTime.now()}
              control={control}
              rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDatePicker
                  value={value}
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} required error={invalid} />}
                  label={t`Sign Date`}
                  inputFormat='dd/MM/yyyy'
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className='relative' style={{ height: '55px' }}>
        <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
          <Trans>Close</Trans>
        </LoadingButton>

        <LoadingButton variant='outlined' color='error' onClick={() => setOpenReject(true)}>
          <Trans>Cancel Contract</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-28' loading={isLoadingApprove} onClick={handleClickApprove}>
          <Trans>Request</Trans>
        </LoadingButton>
      </DialogActions>
      <Dialog fullWidth maxWidth='md' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <ContractRejectPopup
          contract={contract}
          enterpriseId={enterpriseId}
          onClose={() => {
            setOpenReject(false);
            onClose();
          }}
        />
      </Dialog>
    </>
  );
};

export default ContractUploadPopup;
