import { Trans } from '@lingui/macro';
import { Container, Typography, Button } from '@mui/material';
import { EpayzLayout } from 'layouts';
import { useActiveLocale } from 'hooks/useActiveLocale';
import './index.css';

const Partners = () => {
  const userLocale = useActiveLocale();
  return (
    <EpayzLayout>
      <div data-aos="fade-up" style={{ backgroundImage: `url(./5562066_2882064_2.png)`, width: '100%', height: '700px', backgroundRepeat: 'round', backgroundSize: 'cover' }}>
        <Container className='py-20'>
          <div style={{ fontSize: '40px', fontWeight: 600, marginTop: '55px' }}><Trans>EPZ’s Partners</Trans></div>
          <div style={{ fontSize: '15px' }}><Trans>We find and provide our Partners as individuals and corporates with the most<br /> professional products, services to win the attractive investment opportunities,<br /> high profitability and no risk.</Trans></div>
        </Container>
      </div>
      <div>
        <Container className='py-20'>
          <div style={{ fontSize: '40px', fontWeight: 600, textAlign: 'center' }}><Trans>Products for Partners</Trans></div>
          <div style={{ marginTop: '30px', display: 'flex' }}>
            <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
              <div> <img style={{ width: "100px" }} src={require('assets/epayz/time-money-long-term-investment-fast-micro-loan-dollar-sign-clock-credit-payment-installment-cash-advance-pension-savings-146254786.jpg')} /></div>
              <div style={{ width: '5px', background: '#F57880', height: '100px', margin: '0px 25px' }}></div>
              <div style={{ fontSize: '25px', fontWeight: 600, alignSelf: 'center' }}><Trans>Financial Investment</Trans></div>
            </div>
            <div style={{ width: '70%', display: 'flex', alignItems: 'center', boxShadow: '1px 1px 5px 5px #DEDEDE', borderRadius: '5px', height: '150px' }}>
              <div style={{ width: '80%' }}>
                <ul style={{ listStyleType: 'square', padding: '30px', fontSize: '15px' }}>
                  <li style={{ paddingLeft: '10px' }}><Trans>Partners choose to invest by transaction or by fixed term of EPZ.</Trans></li>
                  <li style={{ paddingLeft: '10px' }}><Trans>Investment term and Interest rate is public listed on platform by EPZ for partners to choose.</Trans></li>
                </ul>
              </div>
              <div style={{ width: '20%', display: 'flex', justifyContent: 'center' }}><Button variant="contained" color="primary" style={{ backgroundColor: "black !important", color: 'white', borderRadius: '20px' }}><Trans>Contact EPZ</Trans></Button></div>
            </div>
          </div>
          <div style={{ marginTop: '30px', display: 'flex' }}>
            <div style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
              <div> <img style={{ width: "100px" }} src={require('assets/epayz/imagesdoitac.png')} /></div>
              <div style={{ width: '5px', background: '#F57880', height: '100px', margin: '0px 25px' }}></div>
              <div style={{ fontSize: '25px', fontWeight: 600, alignSelf: 'center' }}>{userLocale === 'vi-VN' ? <Trans>Hợp tác Giới thiệu<br /> Phát triển Đối tác</Trans> : <Trans>Referral Coordination</Trans>}</div>
            </div>
            <div style={{ width: '70%', display: 'flex', alignItems: 'center', boxShadow: '1px 1px 5px 5px #DEDEDE', borderRadius: '5px', height: '150px' }}>
              <div style={{ width: '80%' }}>
                <ul style={{ listStyleType: 'square', padding: '30px', fontSize: '15px' }}>
                  <li style={{ paddingLeft: '10px' }}><Trans>Referring other partners to use EPZ’s services.</Trans></li>
                  <li style={{ paddingLeft: '10px' }}><Trans>Receiving referral commission by quarter / by month as partner’s choice.</Trans></li>
                  <li style={{ paddingLeft: '10px' }}><Trans>Commissions are calculated by transactions which have been done by referred<br /> partners throughout the cooperation time.</Trans></li>
                </ul>
              </div>
              <div style={{ width: '20%', display: 'flex', justifyContent: 'center' }}><Button variant="contained" color="primary" style={{ backgroundColor: "black !important", color: 'white', borderRadius: '20px' }}><Trans>Contact EPZ</Trans></Button></div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ position: 'relative' }}>
        <Container className='py-20' style={{ position: 'relative', zIndex: 2, display: 'flex' }}>
          <div style={{ width: '30%', fontSize: '40px', fontWeight: 600, color: 'white' }}>
            {userLocale === 'vi-VN' ? <Trans>Lợi ích của<br /> Đối tác</Trans> : <Trans>Benefits of<br /> Partners</Trans>}
          </div>
          <div style={{ width: '70%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <div>
                <div style={{ display: 'flex', justifyContent: 'center', height: '140px', alignItems: 'end' }}><img style={{ width: "130px", height: "104px" }} src={require('assets/epayz/383-3834350_interest-rates-mutual-funds-money-vector-hd-png.png')} /></div>
                <div style={{ fontWeight: 600, fontSize: '20px', marginTop: '10px', textAlign: 'center' }}>{userLocale === 'vi-VN' ? <Trans>Tỷ suất Lợi nhuận<br /> hấp dẫn</Trans> : <Trans>Attractive Profit Rate</Trans>}</div>
              </div>
              <div>
                <div style={{ display: 'flex', justifyContent: 'center', height: '140px', alignItems: 'end' }}><img style={{ width: "130px", height: '130px' }} src={require('assets/epayz/battay.png')} /></div>
                <div style={{ fontWeight: 600, fontSize: '20px', marginTop: '10px', textAlign: 'center' }}><Trans>Seize the market and find the<br /> opportunities to develop</Trans></div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
              <div>
                <div style={{ display: 'flex', justifyContent: 'center', height: '140px', alignItems: 'end' }}><img style={{ width: "130px", height: "104px" }} src={require('assets/epayz/lovepik-money-money-banknote-icon-free-vector-illustration-png-image_401496451_wh1200-removebg-preview.png')} /></div>
                <div style={{ fontWeight: 600, fontSize: '20px', marginTop: '10px', textAlign: 'center' }}>{userLocale === 'vi-VN' ? <Trans>Sinh lời trên nguồn tiền<br /> nhàn rỗi</Trans> : <Trans>Profit on the idle money</Trans>}</div>
              </div>
              <div>
                <div style={{ display: 'flex', justifyContent: 'center', height: '140px', alignItems: 'end' }}><img style={{ width: "160px" }} src={require('assets/epayz/12953631_Data_security_28.jpg')} /></div>
                <div style={{ fontWeight: 600, fontSize: '20px', marginTop: '10px', textAlign: 'center' }}><Trans>User information is<br /> absolutely confidential</Trans></div>
              </div>
              <div>
                <div style={{ display: 'flex', justifyContent: 'center', height: '140px', alignItems: 'end' }}><img style={{ width: "130px", height: '130px' }} src={require('assets/epayz/online-banking-business-vector-graphics-mobile-banking-png-favpng-tLEVZcUeiQdNK2FXnjR9CFFN0-removebg-preview.png')} /></div>
                <div style={{ fontWeight: 600, fontSize: '20px', marginTop: '10px', textAlign: 'center' }}><Trans>100% working on<br /> electronic platform</Trans></div>
              </div>
            </div>
          </div>
        </Container>
        <img style={{ width: "35%", position: 'absolute', top: '0px', left: '0px' }} src={require('assets/epayz/Intro-organic-shape.png')} />
      </div>
      <div style={{ background: '#3B3838' }}>
        <Container className='py-20'>
          <div style={{ fontSize: '35px', fontWeight: 600, color: 'white', textAlign: 'center' }}><Trans>Steps to be an EPZ’s Partner</Trans></div>
          <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
            <div style={{ border: '1px solid white', color: 'white', width: '210px', height: '350px', borderRadius: '15px' }}>
              <div style={{ height: '235px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img style={{ width: "40px", height: "40px", margin: '20px' }} src={require('assets/epayz/127-1271822_select-your-storage-select-white-icon-png.png')} />
                </div>
                <div style={{ textAlign: "center", margin: '15px 0px', fontWeight: 600, fontSize: '17px' }}><Trans>Choice</Trans></div>
                <div style={{ textAlign: "center", fontSize: '13px' }}><Trans>EPZ consults products and offers<br /> cooperation details to help you<br /> select the optimal plan</Trans></div>
              </div>
              <div style={{ height: "25px", background: 'white',position:'relative' }}><div style={{position:'absolute',top:'0px',right:'0px',height:'25px',background:'white',width:'750px'}}></div></div>
              <div style={{ fontSize: '22px', height: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Trans>STEP 1</Trans></div>
            </div>
            <div style={{ width: '100px' }}>
              <div style={{ height: '236px' }}></div>
              <div style={{ background: 'white', height: '25px', width: '55px', borderRadius: '0px 25px 0px 0px' }}></div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '55px' }}>
                <div style={{ width: '20px', height: '20px', background: 'white' }}>
                  <div style={{ width: '20px', height: '20px', background: '#3B3838', borderRadius: '0px 5px 0px 0px' }}></div>
                </div>
                <div style={{ background: 'white', height: '48px', width: '25px' }}></div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '55px' }}>
                <div className='arrow-down'></div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '55px' }}>
                <div style={{ height: '20px', background: 'white' }}><div className='arrow-down-2'></div></div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '75px' }}>
                <div style={{ background: 'white', height: '44px', width: '25px' }}></div>
                <div style={{ height:'44px',display:'flex',alignItems:'end'}}>
                  <div style={{ width: '20px', height: '20px', background: 'white' }}>
                    <div style={{ width: '20px', height: '20px', background: '#3B3838', borderRadius: '0px 0px 0px 5px' }}></div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <div style={{ background: 'white', height: '26px', width: '70px', borderRadius: '0px 0px 0px 25px' }}></div>
              </div>
            </div>
            <div>
              <div style={{ height: '150px' }}></div>
              <div style={{ border: '1px solid white', color: 'white', width: '210px', height: '350px', borderRadius: '15px' }}>
                <div style={{ height: '235px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img style={{ width: "40px", height: "40px", margin: '20px' }} src={require('assets/epayz/88-885453_login-white-on-clear-user-icon.png')} />
                  </div>
                  <div style={{ textAlign: "center", margin: '15px 0px', fontWeight: 600, fontSize: '17px' }}><Trans>Registration</Trans></div>
                  <div style={{ textAlign: "center", fontSize: '13px' }}><Trans>Based on your provided<br /> information, EPZ sends you an<br /> email to register the partner<br /> account on EPZ platform</Trans></div>
                </div>
                <div style={{ height: "25px", background: 'white' }}></div>
                <div style={{ fontSize: '22px', textAlign: 'center', height: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Trans>STEP 2</Trans></div>
              </div>
            </div>
            <div style={{ width: '100px' }}>
              <div style={{ height: '236px' }}></div>
              <div style={{display:'flex',justifyContent:'end'}}>
                <div style={{ background: 'white', height: '25px', width: '70px', borderRadius: '25px 0px 0px 0px' }}></div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '75px' }}>
                <div style={{ background: 'white', height: '56px', width: '25px' }}></div>
                <div style={{ width: '20px', height: '20px', background: 'white' }}>
                  <div style={{ width: '20px', height: '20px', background: '#3B3838', borderRadius: '5px 0px 0px 0px' }}></div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '55px' }}>
              <div style={{ height: '12px',background:'white' }}><div className='arrow-up-2'></div></div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '55px' }}>
                <div className='arrow-up'></div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '55px' }}>
              <div style={{ height:'44px',display:'flex',alignItems:'end'}}>
                  <div style={{ width: '20px', height: '20px', background: 'white' }}>
                    <div style={{ width: '20px', height: '20px', background: '#3B3838', borderRadius: '0px 0px 5px 0px' }}></div>
                  </div>
                </div>
                <div style={{ background: 'white', height: '44px', width: '25px' }}></div>
              </div>
              <div>
                <div style={{ background: 'white', height: '26px', width: '55px', borderRadius: '0px 0px 25px 0px' }}></div>
              </div>
            </div>
            <div style={{ border: '1px solid white', color: 'white', width: '210px', height: '350px', borderRadius: '15px' }}>
              <div style={{ height: '235px' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img style={{ width: "40px", height: "40px", margin: '20px' }} src={require('assets/epayz/21-215619_checkmark-in-circle-clip-white-check-mark-symbol.png')} />
                </div>
                <div style={{ textAlign: "center", margin: '15px 0px', fontWeight: 600, fontSize: '17px' }}><Trans>Confirmation</Trans></div>
                <div style={{ textAlign: "center", fontSize: '13px' }}><Trans>Both of us decide the cooperation<br /> product and criteria, conditions<br /> proposed by EPZ on Platform</Trans></div>
              </div>
              <div style={{ height: "25px", background: 'white' }}></div>
              <div style={{ fontSize: '22px', textAlign: 'center', height: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Trans>STEP 3</Trans></div>
            </div>
            <div style={{ width: '100px' }}>
              <div style={{ height: '236px' }}></div>
              <div style={{ background: 'white', height: '25px', width: '55px', borderRadius: '0px 25px 0px 0px' }}></div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '55px' }}>
                <div style={{ width: '20px', height: '20px', background: 'white' }}>
                  <div style={{ width: '20px', height: '20px', background: '#3B3838', borderRadius: '0px 5px 0px 0px' }}></div>
                </div>
                <div style={{ background: 'white', height: '48px', width: '25px' }}></div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '55px' }}>
                <div className='arrow-down'></div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '55px' }}>
                <div style={{ height: '20px', background: 'white' }}><div className='arrow-down-2'></div></div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', width: '75px' }}>
                <div style={{ background: 'white', height: '44px', width: '25px' }}></div>
                <div style={{ height:'44px',display:'flex',alignItems:'end'}}>
                  <div style={{ width: '20px', height: '20px', background: 'white' }}>
                    <div style={{ width: '20px', height: '20px', background: '#3B3838', borderRadius: '0px 0px 0px 5px' }}></div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <div style={{ background: 'white', height: '26px', width: '70px', borderRadius: '0px 0px 0px 25px' }}></div>
              </div>
            </div>
            <div>
              <div style={{ height: '150px' }}></div>
              <div style={{ border: '1px solid white', color: 'white', width: '210px', height: '350px', borderRadius: '15px' }}>
                <div style={{ height: '235px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img style={{ width: "40px", height: "40px", margin: '20px' }} src={require('assets/epayz/document-icon-30.png')} />
                  </div>
                  <div style={{ textAlign: "center", margin: '15px 0px', fontWeight: 600, fontSize: '17px' }}><Trans>Cooperation</Trans></div>
                  <div style={{ textAlign: "center", fontSize: '13px' }}><Trans>Both of us sign the cooperation<br /> agreements (manual or digtial<br /> sign depending on your choice)</Trans></div>
                </div>
                <div style={{ height: "25px", background: 'white',position:'relative' }}><div style={{position:'absolute',top:'0px',left:'0px',height:'25px',background:'white',width:'750px'}}></div></div>
                <div style={{ fontSize: '22px', textAlign: 'center', height: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Trans>STEP 4</Trans></div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container style={{ display: 'flex', marginTop: '50px' }}>
          <div style={{ width: '60%' }}>
            <div style={{ fontSize: '35px', fontWeight: 600 }}><Trans>Who are EPZ’s Partners?</Trans></div>
            <div style={{ fontSize: '15px', margin: '15px 0px', color: '#767171' }}><Trans>We always welcome all individual and corporate partners operating in the Vietnamese market.</Trans></div>
            <ul style={{ listStyleImage: 'url(dautich.png)', marginLeft: '20px', lineHeight: '26px', fontSize: '15px', fontWeight: 600 }}>
              <li><Trans>Funds or Financial Institutions with idle funds are looking to invest in the potential Fintech<br /> solutions.</Trans></li>
              <li><Trans>Individuals with idle money want to invest with attractive and stable profit.</Trans></li>
              <li><Trans>Individuals having partnership with big companies in Vietnam</Trans></li>
            </ul>
            <div style={{ fontSize: '15px', margin: '15px 0px', color: '#767171' }}><Trans>Besides, the Partner needs to have a Vietnamese bank account because the transaction between EPZ and<br /> the Partner is made entirely in VND.</Trans></div>
          </div>
          <div style={{ width: '40%' }}>
            <img style={{ width: "100%" }} src={require('assets/epayz/28694979_leadership_set_1.png')} />
          </div>
        </Container>
      </div>
    </EpayzLayout>
  );
};

export default Partners;
