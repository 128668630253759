import { t, Trans } from '@lingui/macro';
import { CheckCircle } from '@mui/icons-material';

import {
  Button,
  Checkbox,
  debounce,
  Dialog,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';
import { getEnterpriseStatus } from 'constants/enterprise';
import { usePermission, useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { enterpriseService } from 'services';
import { EnterpriseType } from 'types/Enterprise';
import { EnterpriseMeetingCalendar, EnterpriseMeetingSummary } from 'views/Enterprise/EnterpriseList/components';
import {
  EnterpriseAssignmentPopup,
  EnterpriseCreatePopup,
  EnterpriseCreateSelectPopup,
} from 'views/Enterprise/EnterpriseList/popups';
const EnterpriseAll = () => {
  const { requireUserPCManager, requireUserPCManagerOrCRMLeader, requreUserPermission, ENTERPRISE_CREATE, ENTERPRISE_VIEW, ENTERPRISE_ASSIGN } = usePermission();
  const [searchType, setSearchType] = useState('name');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});

  const [dataSearch, onSearchChange] = useSearch();
  const [dataSelect, setDataSelect] = useState<EnterpriseType[]>([]);

  const [isOpenAssign, setOpenAssign] = useState(false);
  const [isOpenCreate, setOpenCreate] = useState(false);
  const [isOpenManual, setOpenManual] = useState(false);

  const { data } = useQuery(
    ['enterpriseService.fetchEnterprises', dataSearch],
    () => enterpriseService.fetchEnterprises(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { data: statuses } = useQuery(['enterpriseService.fetchStatuses'], () => enterpriseService.fetchStatuses(), {
    keepPreviousData: true,
  });

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  return (
    <div>
      <Grid container columnSpacing={4} rowSpacing={1} className='mb-6'>
        <Grid item xl={2}>
          <EnterpriseMeetingSummary />
        </Grid>
        <Grid item sm={12} xl={10}>
          <EnterpriseMeetingCalendar />
        </Grid>
      </Grid>

      <div className='flex justify-between'>
        <div className='space-x-3'>

          <>
            {requreUserPermission([ENTERPRISE_CREATE]) && (
              <Button variant='contained' onClick={() => setOpenCreate(true)}>
                <Trans>Create Enterprise</Trans>
              </Button>
            )}
            {requreUserPermission([ENTERPRISE_ASSIGN]) &&
              <Button variant='contained' disabled={dataSelect.length === 0} onClick={() => setOpenAssign(true)}>
                <Trans>Assignment</Trans>
              </Button>
            }
          </>

        </div>
        <div className='space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value='name'>
              <Trans>Enterprise Name</Trans>
            </MenuItem>
            <MenuItem value='taxCode'>
              <Trans>Tax Code</Trans>
            </MenuItem>
            <MenuItem value='locationName'>
              <Trans>Location</Trans>
            </MenuItem>
            <MenuItem value='seniorCrmName'>
              <Trans>Senior CRM</Trans>
            </MenuItem>
            <MenuItem value='status'>
              <Trans>Status</Trans>
            </MenuItem>
            <MenuItem value='autoTransaction'>
              <Trans>Automatic Transaction</Trans>
            </MenuItem>
          </TextField>
          {['name', 'taxCode', 'locationName', 'seniorCrmName'].includes(searchType) && (
            <TextField
              placeholder={t`Search...`}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
            />
          )}
          {['status'].includes(searchType) && (
            <TextField
              select
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
            >
              <MenuItem value=''>
                <Trans>All</Trans>
              </MenuItem>
              {(statuses ?? []).map((item) => (
                <MenuItem key={item} value={item}>
                  {getEnterpriseStatus(item)}
                </MenuItem>
              ))}
            </TextField>
          )}
          {['autoTransaction'].includes(searchType) && <TextField
            select
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              debounceChangeValue({ [searchType]: event.target.value });
            }}
          >
            <MenuItem value=''>
              <Trans>All</Trans>
            </MenuItem>
            <MenuItem value='1'>
              <Trans>YES</Trans>
            </MenuItem>
            <MenuItem value='0'>
              <Trans>NO</Trans>
            </MenuItem>
          </TextField>}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {requireUserPCManager() &&
                <TableCell>
                  <Checkbox
                    onChange={(event, checked) => {
                      if (checked) setDataSelect(items);
                      else setDataSelect([]);
                    }}
                    checked={dataSelect.length === items.length}
                    indeterminate={dataSelect.length > 0 && dataSelect.length < items.length}
                  />
                </TableCell>
              }
              <TableCell>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Tax Code</Trans>
              </TableCell>
              <TableCell>
                <Trans>Automatic Transaction</Trans>
              </TableCell>
              <TableCell>
                <Trans>Location</Trans>
              </TableCell>
              <TableCell>
                <Trans>Last Contact Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Senior CRM</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (

              <TableRow key={item.id}>
                {requireUserPCManager() &&
                  <TableCell align='center' className='p-1'>
                    <Checkbox
                      checked={dataSelect.some((next) => next.id === item.id)}
                      onChange={(event, checked) => {
                        if (checked) setDataSelect((nexts) => nexts.concat(item));
                        else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                      }}
                    />
                  </TableCell>}
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.taxCode}</TableCell>
                <TableCell className='text-center'>{item.autoTransaction ? <CheckCircle color='primary' /> : ""}</TableCell>
                <TableCell>{item.locationName}</TableCell>
                <TableCell>
                  {item.lastContactDate ? DateTime.fromISO(item.lastContactDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{getEnterpriseStatus(item.status)}</TableCell>
                <TableCell>{item.seniorUser?.fullname}</TableCell>
                <TableCell className='text-right'>
                  <Link to={privateRoute.enterpriseClientView.url?.(item)!}>
                    <Button size='small' color='info'>
                      <Trans>View</Trans>
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ size: size });
            setDataSelect([]);
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
            setDataSelect([]);
          }}
        />
      </div>

      <Dialog fullWidth maxWidth='md' open={isOpenAssign} onClose={() => setOpenAssign(false)}>
        <EnterpriseAssignmentPopup
          enterpriseIds={dataSelect.map((item) => item.id)}
          onSuccess={() => setDataSelect([])}
          onClose={() => setOpenAssign(false)}
        />
      </Dialog>

      <Dialog fullWidth maxWidth='md' open={isOpenManual} onClose={() => setOpenManual(false)}>
        <EnterpriseCreatePopup onClose={() => setOpenManual(false)} />
      </Dialog>

      <Dialog fullWidth maxWidth='sm' open={isOpenCreate} onClose={() => setOpenCreate(false)}>
        <EnterpriseCreateSelectPopup
          onNext={() => {
            setOpenCreate(false);
            setOpenManual(true);
          }}
          onClose={() => {
            setOpenCreate(false);
            setOpenManual(false);
          }}
        />
      </Dialog>
    </div >
  );
};

export default EnterpriseAll;
