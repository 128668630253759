import { Trans } from '@lingui/macro';
import { Delete, Edit } from '@mui/icons-material';
import {
  Button,
  Dialog,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';
import { usePermission, useSearch } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseSetupService } from 'services';
import { EnterpriseType } from 'types/Enterprise';
import { AdvanceSeniorityType } from 'types/EnterpriseSetupAdvance';
import { AdvanceSeniorityCreatePopup, AdvanceSeniorityRemovePopup } from 'views/ClientSetup/Enterprise/popups';

const EmployeeReportTable = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const { isCaRole } = usePermission();
  const { id } = useParams() as { id: string };

  const [dataSearch, onSearchChange] = useSearch({ id });
  const [isOpenCreate, setOpenCreate] = useState(false);
  const [isOpenRemove, setOpenRemove] = useState(false);
  const [chosenUpdate, setChosenUpdate] = useState<AdvanceSeniorityType>();

  const { data } = useQuery(
    ['enterpriseSetupService.fetchAdvanceSenioritys', dataSearch],
    () => enterpriseSetupService.fetchAdvanceSenioritys(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  return (
    <div>
      <div className='flex justify-between'>
        <Typography variant='h4'>
          <Trans>Advance Ratio by Seniority</Trans>
        </Typography>
        {isCaRole() && (<Button
          variant='outlined'
          size='small'
          onClick={() => {
            setOpenCreate(true);
            setChosenUpdate(undefined);
          }}
        >
          <Trans>Add</Trans>
        </Button>)}
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Minimum years of work</Trans>
              </TableCell>
              <TableCell>
                <Trans>Maximum years of work</Trans>
              </TableCell>
              <TableCell>
                <Trans>Advance Ratio</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell className='text-center'>{item.minWorkYear}</TableCell>
                <TableCell className='text-center'>{item.maxWorkYear}</TableCell>
                <TableCell className='text-center'>{item.rate} %</TableCell>
                <TableCell className='text-right space-x-1 whitespace-nowrap'>
                  {isCaRole() && (<><IconButton
                    size='small'
                    color='error'
                    onClick={() => {
                      setOpenRemove(true);
                      setChosenUpdate(item);
                    }}
                  >
                    <Delete fontSize='small' />
                  </IconButton>
                    <IconButton
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpenCreate(true);
                        setChosenUpdate(item);
                      }}
                    >
                      <Edit fontSize='small' />
                    </IconButton></>)}
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='xs' open={isOpenCreate} onClose={() => setOpenCreate(false)}>
        <AdvanceSeniorityCreatePopup
          id={enterprise?.id!}
          advanceSeniority={chosenUpdate}
          onClose={() => setOpenCreate(false)}
        />
      </Dialog>
      <Dialog fullWidth maxWidth='xs' open={isOpenRemove} onClose={() => setOpenRemove(false)}>
        <AdvanceSeniorityRemovePopup
          id={enterprise?.id!}
          advanceSeniority={chosenUpdate!}
          onClose={() => setOpenRemove(false)}
        />
      </Dialog>
    </div>
  );
};

export default EmployeeReportTable;
