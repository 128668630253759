import { Trans, t } from '@lingui/macro';
import { DesktopDatePicker } from '@mui/lab';
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Checkbox,
  Dialog,
} from '@mui/material';
import { TableEmpty } from 'components';
import { useSearch } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { DateTime } from 'luxon';
import { getEnterpriseApplyFeeType } from 'constants/enterprise';
import { formatNumber } from 'utils/common';
import { useState } from 'react';
import { EnterpriseWaitingPaymentType } from 'types/EnterpriseSetup';
import { WaitingPaymentCollectFeePopup, MoveLatePaymentPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import { useSnackbar } from 'notistack';
import { usePermission } from 'hooks';
import SelectPageSize from 'components/SelectPageSize';
const EnterpriseWaitingPaymentList = ({ id }: { id: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, watch, clearErrors } = useForm({ mode: 'onChange' });
  const [dataSearch, onSearchChange] = useSearch();

  const [dataSelect, setDataSelect] = useState<EnterpriseWaitingPaymentType[]>([]);
  const [openCollectFee, setOpenCollectFee] = useState(false);
  const [openMoveLatePayment, setOpenMoveLatePayment] = useState(false);
  const { requreUserPermission, PHIDICHVUDN_CHITIET_GHINHAN, PHIDICHVUDN_CHITIET_QUAHAN } = usePermission();
  const { data } = useQuery(
    ['enterpriseSetupService.getEnterpriseWaitingPaymentData', { enterpriseId: id, ...dataSearch }],
    () => enterpriseSetupService.getEnterpriseWaitingPaymentData({ enterpriseId: id, ...dataSearch }),
    { keepPreviousData: true },
  );

  const { data: items = [], paginate } = data ?? {};

  const [fromDate, setFromDate] = useState(null as DateTime | null);
  const [toDate, setToDate] = useState(null as DateTime | null);

  return (
    <>
      <div className='flex items-center justify-between'>
        <div className='space-x-4'>
          {requreUserPermission([PHIDICHVUDN_CHITIET_GHINHAN]) && <Button
            variant='contained'
            onClick={() => {
              if (dataSelect.length === 0) enqueueSnackbar('Please select at least one', { variant: 'warning' });
              else setOpenCollectFee(true);
            }}
          >
            <Trans>Collect fee</Trans>
          </Button>}
          {requreUserPermission([PHIDICHVUDN_CHITIET_QUAHAN]) && <Button
            variant='contained'
            onClick={() => {
              if (dataSelect.length === 1) setOpenMoveLatePayment(true);
              else enqueueSnackbar('Please select only one', { variant: 'warning' });
            }}
          >
            <Trans>Late Payment</Trans>
          </Button>}
        </div>
        <div className='space-x-4'>
          <DesktopDatePicker
            value={fromDate}
            onChange={(value) => {
              setFromDate(value);

              onSearchChange({ fromDate: value?.toISO() });

            }}
            renderInput={(props) => <TextField {...props} />}
            label={t`From Date`}
            inputFormat='dd/MM/yyyy'
          />
          <DesktopDatePicker
            value={toDate}
            onChange={(value) => {
              setToDate(value);

              onSearchChange({ toDate: value?.toISO() });

            }}
            renderInput={(props) => <TextField {...props} />}
            label={t`To Date`}
            inputFormat='dd/MM/yyyy'
          />
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onChange={(event, checked) => {
                    if (checked) setDataSelect(items);
                    else setDataSelect([]);
                  }}
                  checked={dataSelect.length === items.length}
                  indeterminate={dataSelect.length > 0 && dataSelect.length < items.length}
                />
              </TableCell>
              <TableCell>
                <Trans>Expected Payment Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Category</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transaction ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Service Fee before tax (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Service Fee after tax (VND)</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item, index) => (
              <TableRow key={index}>
                <TableCell align='center'>
                  <Checkbox
                    checked={dataSelect.some((next) => next.id === item.id)}
                    onChange={(event, checked) => {
                      if (checked) setDataSelect((nexts) => nexts.concat(item));
                      else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                    }}
                  />
                </TableCell>
                <TableCell>
                  {item.expectedPaymentDate ? DateTime.fromISO(item.expectedPaymentDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>
                  <Trans>{getEnterpriseApplyFeeType(item.category)}</Trans>
                </TableCell>
                <TableCell>{item.transactionCode}</TableCell>
                <TableCell align='right'>{formatNumber(item.serviceFeeBeforeTax)}</TableCell>
                <TableCell align='right'>{formatNumber(item.serviceFeeAfterTax)}</TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='sm' open={openCollectFee} onClose={() => setOpenCollectFee(false)}>
        <WaitingPaymentCollectFeePopup
          enterpriseId={id}
          setDataSelect={setDataSelect}
          dataSelect={dataSelect}
          onClose={() => setOpenCollectFee(false)}
        />
      </Dialog>

      <Dialog fullWidth maxWidth='sm' open={openMoveLatePayment} onClose={() => setOpenMoveLatePayment(false)}>
        <MoveLatePaymentPopup
          enterpriseId={id}
          setDataSelect={setDataSelect}
          dataSelect={dataSelect}
          onClose={() => setOpenMoveLatePayment(false)}
        />
      </Dialog>
    </>
  );
};

export default EnterpriseWaitingPaymentList;
