import { Trans, t } from '@lingui/macro';
import { usePermission } from 'hooks';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { privateRoute } from 'routes';
import { creditScoreService } from 'services';
import { ExpireDate, Qualitative, Quantitative, RatingCategory } from './components';
import { CreditScoreRemovePopup } from './popups';

const CreditScoreView = () => {
  const { requireRole,requreUserPermission, DIEMTINDUNG_CHITIET_THEMTIEUCHI, DIEMTINDUNG_CHITIET_XOA, DIEMTINDUNG_CHITIET_LUU } = usePermission();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id: _id } = useParams() as { id: string };

  const [isOpenRemove, setOpenRemove] = useState(false);

  const { data: creditScore, isFetched } = useQuery(['creditScoreService.getCreditScoreById', { id: _id }], () =>
    creditScoreService.getCreditScoreById({ id: _id }),
  );

  const { mutate: completeCreditScore, isLoading } = useMutation(creditScoreService.completeCreditScore, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
    },
  });

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Credit Score Information</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        {isFetched && (
          <Grid container columnSpacing={4} rowSpacing={3}>
            <Grid item sm={12} className='flex items-center gap-3'>
              <ExpireDate creditScore={creditScore} />
            </Grid>
            <Grid item sm={6}>
              <Qualitative creditScore={creditScore} />
            </Grid>
            <Grid item sm={6}>
              <Quantitative creditScore={creditScore} />
            </Grid>
          </Grid>
        )}
        <RatingCategory creditScore={creditScore} />

        <div className='flex justify-center gap-3'>
          <Button variant='outlined' className='w-32' onClick={() => navigate(privateRoute.creditScoreSetup.path)}>
            <Trans>Back</Trans>
          </Button>
          {requireRole('CA_SENIOR') && (
            <>
              {requreUserPermission([DIEMTINDUNG_CHITIET_XOA]) && (<Button variant='contained' className='w-32' color='error' onClick={() => setOpenRemove(true)}>
                <Trans>Remove</Trans>
              </Button>)}
              {requreUserPermission([DIEMTINDUNG_CHITIET_LUU]) && (<LoadingButton
                variant='contained'
                className='w-32'
                loading={isLoading}
                onClick={() => completeCreditScore({ id: _id })}
              >
                <Trans>Complete</Trans>
              </LoadingButton>)}
            </>
          )}
        </div>

        <Dialog fullWidth maxWidth='xs' open={isOpenRemove} onClose={() => setOpenRemove(false)}>
          <CreditScoreRemovePopup id={_id} onClose={() => setOpenRemove(false)} />
        </Dialog>
      </div>
    </Paper>
  );
};

export default CreditScoreView;
