import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { employeeService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { ContractType } from 'types/EmployeeContract';
import { EmployeeViewInfo } from 'views/Employee/EmployeeView/components';

type PopupProps = PopupController & {
  contract: ContractType;
  employeeId: string;
};

const ContractCreatePopup = ({ contract, employeeId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isCreate, setIsCreate] = useState(false);
  const [enterpriseId, setEnterpriseId] = useState<number | string>('');
  const { data: employee, isSuccess: isSuccessLoadEmployee } = useQuery(
    ['employeeService.getEmployeeById', { id: employeeId }],
    () => employeeService.getEmployeeById({ id: employeeId }),
  );

  const { data: dataFile, isSuccess: isSuccessLoadFile } = useQuery(
    ['employeeService.generateEmployeeContract', { id: employeeId, type: contract?.type }],
    () => employeeService.generateEmployeeContract({ id: employeeId, type: contract?.type }),
  );
  const { data } = useQuery(
    ['employeeService.fetchEmployeeWorkings', { _id: employeeId }],
    () => employeeService.fetchEmployeeWorkings({ _id: employeeId, size: 100 }),
    {
      onSuccess: ({ data: items }) => {
        if (items[0]) {
          setEnterpriseId(items[0].enterprise.id);
        }
      },
    },
  );
  const { mutate: createContract, isLoading: isLoadingCreate } = useMutation(employeeService.createEmployeeContract, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeService.getEmployeeLatest');
      queryClient.invalidateQueries('employeeService.fetchEmployeeContract');
      onClose();
    },
  });

  const handleClickRequest = () => {
    createContract({
      _id: employeeId,
      enterpriseId: enterpriseId!,
      type: contract.type!,
      digital: true
    });
  };

  return (
    <>
      <DialogTitle>
        <Trans>Employee Contract</Trans>
      </DialogTitle>

      <DialogContent>
        <Stepper activeStep={0}>
          <Step>
            <StepLabel>
              <Trans>Creation</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>View</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Completion</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        {!isCreate && isSuccessLoadEmployee && (
          <div>
            <Typography align='center' className='mb-2'>
              <Trans>Please check Employee's information before pressing "Create" button</Trans>
            </Typography>
            <EmployeeViewInfo employee={employee} />
          </div>
        )}
        {isCreate && (
          <div className='border' style={{ height: 'calc(-180px + 80vh)' }}>
            {isSuccessLoadFile && (
              <object
                data={'data:application/pdf;base64,' + dataFile?.data!}
                type='application/pdf'
                className='w-full h-full'
              ></object>
            )}
          </div>
        )}
      </DialogContent>

      <DialogActions className='relative'>
        <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
          <Trans>Close</Trans>
        </LoadingButton>

        {isCreate ? (
          <LoadingButton variant='contained' className='w-28' loading={isLoadingCreate} onClick={handleClickRequest}>
            <Trans>Request</Trans>
          </LoadingButton>
        ) : (
          <LoadingButton variant='contained' className='w-28' onClick={() => setIsCreate(true)}>
            <Trans>Create</Trans>
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default ContractCreatePopup;
