import { t, Trans } from '@lingui/macro';

import { Paper, Tab, Tabs, Typography } from '@mui/material';
import { useTabs } from 'hooks';
import { useQuery } from 'react-query';
import { transactionOverdueService } from 'services';
import { TransCompleted, TransOverdue } from 'views/Transaction/TransOverdue/TransOverdueList/components';

const TransOverdueList = () => {
  const { data: overdue } = useQuery(
    ['transactionOverdueService.fetchOverdueTransactions', {}],
    () => transactionOverdueService.fetchOverdueTransactions({ }),
  );
  const { data: completed } = useQuery(
    ['transactionOverdueService.fetchCompletedTransactions', {}],
    () => transactionOverdueService.fetchCompletedTransactions({}),
  );

  const tabs = [
    {
      code: 'overdue',
      label: t`OVERDUE (${overdue?.paginate.totalRecord ?? 0})`,
      component: <TransOverdue />,
    },
    { code: 'overdueCompleted', label: t`OVERDUE COMPLETED (${completed?.paginate.totalRecord ?? 0})`, component: <TransCompleted /> },
  ];
  const [activeTab, onChangeTab] = useTabs(tabs);

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Overdue Management</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Tabs value={activeTab} onChange={onChangeTab}>
          {tabs.map((tab) => (
            <Tab key={tab.code} label={tab.label} value={tab.code} />
          ))}
        </Tabs>
        {tabs.map((tab) => (
          <div key={tab.code} hidden={tab.code !== activeTab} className='mt-4'>
            {tab.component}
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default TransOverdueList;
