import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { TableEmpty } from 'components';
import { getContractMethod, getContractName } from 'constants/contract';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseService } from 'services';
import { ContractType } from 'types/EnterpriseContract';
import { ContractViewPopup } from 'views/Enterprise/EnterpriseContract/components';

const ContractHistoryDeny = () => {
  const { id: _id } = useParams() as { id: string };

  const { data } = useQuery(['enterpriseService.fetchEnterpriseContract', { _id }], () =>
    enterpriseService.fetchEnterpriseContract({ _id, states: 'OUTDATED,REJECT' }),
  );
  const { data: items = [] } = data ?? {};

  const [isOpen, setOpen] = useState(false);
  const [chosenContract, setChosenContract] = useState<ContractType>();

  return (
    <div className='mt-6'>
      <Typography variant='h4'>
        <Trans>Contract History</Trans>
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Contract Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Contract Form</Trans>
              </TableCell>
              <TableCell>
                <Trans>Contract No</Trans>
              </TableCell>
              <TableCell>
                <Trans>Signing Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Rejection / Cancellation Reason</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{getContractName(item.type)}</TableCell>
                <TableCell>{getContractMethod(item.digital)}</TableCell>
                <TableCell>{item.contractNo}</TableCell>
                <TableCell>{item.signedAt ? DateTime.fromISO(item.signedAt).toFormat('dd/MM/yyyy') : ''}</TableCell>
                <TableCell>{item.rejectReason}</TableCell>
                <TableCell className='text-right'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpen(true);
                      setChosenContract(item);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        fullWidth
        maxWidth='lg'
        PaperProps={{
          sx: {
            minHeight: '80vh',
            maxHeight: '80vh',
          },
        }}
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <ContractViewPopup contract={chosenContract!} enterpriseId={_id} onClose={() => setOpen(false)} />
      </Dialog>
    </div>
  );
};

export default ContractHistoryDeny;
