import { Trans } from '@lingui/macro';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import { formatNumber } from 'utils/common';

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
];

const COLORS = ['#b91c1c', '#404040'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CompanyListWorking = () => {
  return (
    <Grid container columnSpacing={4} rowSpacing={3}>
      <Grid item sm={6}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell>
                <Trans>Company Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Labour Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Advance Limit (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Max Labor of Advance</Trans>
              </TableCell>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Company A</TableCell>
                <TableCell>Full-time</TableCell>
                <TableCell>{formatNumber(10000000)}</TableCell>
                <TableCell>{formatNumber(10000000)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Company B</TableCell>
                <TableCell>Part-time</TableCell>
                <TableCell>{formatNumber(10000000)}</TableCell>
                <TableCell>{formatNumber(10000000)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className='text-center'>
                  Sum
                </TableCell>
                <TableCell>{formatNumber(10000000)}</TableCell>
                <TableCell>{formatNumber(10000000)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item sm={6}>
        <div className='flex items-center justify-around border rounded-lg py-4'>
          <div>
            <Typography variant='h5' className='text-center'>
              Salary Advance Limit
            </Typography>
            <PieChart width={250} height={250}>
              <Pie
                data={data}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
                legendType='square'
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend
                payload={data.map((item, index) => ({
                  id: item.name,
                  type: 'square',
                  value: `${item.name} ${item.value}`,
                  color: COLORS[index % COLORS.length],
                }))}
              />
            </PieChart>
          </div>
          <div>
            <Typography variant='h5' className='text-center'>
              Workdays Available
            </Typography>
            <PieChart width={250} height={250}>
              <Pie
                data={data}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
                legendType='square'
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend
                payload={data.map((item, index) => ({
                  id: item.name,
                  type: 'square',
                  value: `${item.name} ${item.value}`,
                  color: COLORS[index % COLORS.length],
                }))}
              />
            </PieChart>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default CompanyListWorking;
