import { Trans } from '@lingui/macro';
import { useStyles } from './TransInfo';
import { TableBody, Table, TableCell, TableContainer, TableRow, Paper, Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { TransType } from 'types/Transaction';
import { formatNumber } from 'utils/common';
import { getPromotionName } from 'constants/transaction';

const TransInfo = ({ transaction }: { transaction?: TransType }) => {
  const classes = useStyles();

  return (
    <>
      <div className='mt-6'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Transaction Details Spreadsheet</Trans>
          </Typography>
          {transaction?.promotionId && (
            <div className='bg-blue-200 p-2'>
              {getPromotionName(
                transaction?.promotionMinimumRequestAmount,
                transaction?.promotionOrgAmount,
                transaction?.promotionDiscountRate,
              )}
            </div>
          )}
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>Request Amount (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.requestAmount)}</TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Corresponding Labours</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.correspondingLabor)}</TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Available Labours</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.availableLabor)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>No. of Earned Labour</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.earnedLabor)}</TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>No. of Future Labour</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.futureLabor)}</TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Applied Term (Day)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.appliedTerm)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>Earned Discount Rate (APR)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>
                  {formatNumber(transaction?.appliedDiscountRate ?? 0)}
                </TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Future Discount Rate (APR)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>
                  {formatNumber(transaction?.appliedFutureDiscountRate ?? 0)}
                </TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Promotion of Transaction Cost</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.promotionAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>Earned Discount Amount (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.earnedDiscountAmount)}</TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Future Discount Amount (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.futureDiscountAmount)}</TableCell>
                <TableCell className={classes.cellTitleBold}>
                  <Trans>Total Transaction Cost Enterprise supported (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.enterpriseCost)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>Collection Amount from Employee (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.collectionAmount)}</TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Debt Purchasing Price (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>
                  {formatNumber(transaction?.debtPurchasingPriceAmount)}
                </TableCell>
                <TableCell className={classes.cellTitleBold}>
                  <Trans>Total Transaction Cost Employee pays (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.costAmount)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className='mt-6'>
        <Grid container columnSpacing={4} rowSpacing={3}>
          <Grid item md={12} lg={6} xl={6}>
            <div className='flex items-center space-x-3'>
              <Typography variant='h4'>
                <Trans>Program For Key Employee</Trans>
              </Typography>
            </div>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>Enterprise Supported Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.keyEnterpriseSupportAmount)}
                    </TableCell>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>Corresponding Labours</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.keyCorrespondingLabor)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>No. of Earned Labour</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>{formatNumber(transaction?.keyEarnedLabor)}</TableCell>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>Earned Discount Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.keyEarnedDiscountAmount)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>No. of Future Labour</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>{formatNumber(transaction?.keyFutureLabor)}</TableCell>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>Future Discount Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.keyFutureDiscountAmount)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>Total Transaction Cost (VND)</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.keyTransactionCost)}
                    </TableCell>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>% of Enterprise support</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.keyEnterpriseSupportRate)} %
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={12} lg={6} xl={6}>
            <div className='flex items-center space-x-3'>
              <Typography variant='h4'>
                <Trans>Program For Regular Employee</Trans>
              </Typography>
            </div>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>Enterprise Supported Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.regularEnterpriseSupportAmount)}
                    </TableCell>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>Corresponding Labours</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.regularCorrespondingLabor)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>No. of Earned Labour</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.regularEarnedLabor)}
                    </TableCell>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>Earned Discount Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.regularEarnedDiscountAmount)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>No. of Future Labour</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.regularFutureLabor)}
                    </TableCell>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>Future Discount Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.regularFutureDiscountAmount)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>Total Transaction Cost (VND)</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.regularTransactionCost)}
                    </TableCell>
                    <TableCell className={classes.cellTitle4}>
                      <Trans>% of Enterprise support</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue4}>
                      {formatNumber(transaction?.regularEnterpriseSupportRate)} %
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TransInfo;
