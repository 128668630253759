import { Trans } from '@lingui/macro';
import { Container, Typography, Button, Grid, Dialog, Paper, Divider } from '@mui/material';
import { StaticLayout } from 'layouts';
import { useActiveLocale } from 'hooks/useActiveLocale';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContactPopup from './ContactPopup';
import { Fragment, useState, useEffect } from 'react';
import CountUp from 'react-countup';

const Enterprise = () => {
  const userLocale = useActiveLocale();
  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [bankCode, setBankCode] = useState('');
  function submitContact() {
    setOpenSelectBank(true)
  }
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);
  return (
    <StaticLayout>
      {/* <Container className='py-20'>
        <Typography className='font-black text-[3rem]'><Trans>Enterprise</Trans></Typography>
      </Container> */}
      <div>
        <Container className='py-20'>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '40%', margin: "30px 0px", position: 'relative', paddingRight: 100 }}>
              <div style={{ fontSize: 30, fontWeight: 700, marginBottom: 45, marginTop: 45 }}><Trans>A unique financial benefit for your employees</Trans></div>
              <div style={{ lineHeight: "25px", marginBottom: 15 }}><Trans>Partner with us to help enhance the financial well-being for your employees, boosting productivity and engagement in work.</Trans></div>
              <div style={{ lineHeight: "25px", marginBottom: 15 }}><Trans>Together with SCP, you can create an ideal and flexible working environment to improve the quality of human resources.</Trans></div>
              <Button onClick={submitContact} variant='contained' size='large' style={{ backgroundColor: '#0FB7AD', position: 'absolute', left: 0 }} className='h-14 rounded-lg mx-auto'>
                <Trans>Liên hệ nhận tư vấn </Trans><ArrowForwardIcon></ArrowForwardIcon>
              </Button>
            </div>
            <div style={{ width: '60%' }}>
              <img data-aos="zoom-in" style={{ width: '100%' }} src={require('assets/images/pic20.png')} />
            </div>
          </div>
        </Container>
      </div>
      <div style={{ backgroundColor: '#D4ECF6' }}>
        <Container className='py-20'>
          <div style={{ fontSize: 35, fontWeight: 700, textAlign: 'center', color: '#1C2B4D' }}><Trans>WHAT IS YOUR COMPANY’S BENEFITS ?</Trans></div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" style={{ position: 'relative', backgroundColor: 'white', borderRadius: "10px", height: '111px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px', marginLeft: 20, marginRight: 20 }}>
                <img style={{ position: 'absolute', left: "20px", top: '-25px' }} src={require('assets/images/dn1.png')} />
                <div style={{ textAlign: 'center', fontSize: 17 }}>
                  <Trans><span style={{ color: '#28A0D4', fontWeight: 700, }}>Increase engagement</span> & Reduce resignation rate of your employees</Trans>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="1000" data-aos-anchor-placement="top-bottom" style={{ position: 'relative', backgroundColor: 'white', borderRadius: "10px", height: '111px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px', marginLeft: 20, marginRight: 20 }}>
                <img style={{ position: 'absolute', left: "20px", top: '-25px' }} src={require('assets/images/dn2.png')} />
                <div style={{ textAlign: 'center', fontSize: 17 }}>
                  <Trans><span style={{ color: '#28A0D4', fontWeight: 700, }}>Reduce the effort</span> of handling salary advance request of HR Department</Trans>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="2000" data-aos-anchor-placement="top-bottom" style={{ position: 'relative', backgroundColor: 'white', borderRadius: "10px", height: '111px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px', marginLeft: 20, marginRight: 20 }}>
                <img style={{ position: 'absolute', left: "20px", top: '-25px' }} src={require('assets/images/dn3.png')} />
                <div style={{ textAlign: 'center', fontSize: 17, padding: "0px 15px" }}>
                  <Trans>Reduce financial burden to help employees work more <span style={{ color: '#28A0D4', fontWeight: 700, }}>focus and efficient</span></Trans>
                </div>
              </div>
            </div>
            <div style={{ width: '50%' }}>
              <div data-aos="fade-up" data-aos-delay="500" data-aos-anchor-placement="top-bottom" style={{ position: 'relative', backgroundColor: 'white', borderRadius: "10px", height: '111px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '60px', marginLeft: 20, marginRight: 20 }}>
                <img style={{ position: 'absolute', left: "20px", top: '-25px' }} src={require('assets/images/dn4.png')} />
                <div style={{ textAlign: 'center', fontSize: 17 }}>
                  <Trans>Modern financial welfare policy, easy to attract <span style={{ color: '#28A0D4', fontWeight: 700, }}>new talents</span> in employee market</Trans>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="1500" data-aos-anchor-placement="top-bottom" style={{ position: 'relative', backgroundColor: 'white', borderRadius: "10px", height: '111px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px', marginLeft: 20, marginRight: 20 }}>
                <img style={{ position: 'absolute', left: "20px", top: '-25px' }} src={require('assets/images/dn5.png')} />
                <div style={{ textAlign: 'center', fontSize: 17, padding: "0px 15px" }}>
                  <Trans>Enhance the health, happiness and <span style={{ color: '#28A0D4', fontWeight: 700, }}>satisfaction of employees</span> in the interests of meeting their real needs</Trans>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="2500" data-aos-anchor-placement="top-bottom" style={{ position: 'relative', backgroundColor: 'white', borderRadius: "10px", height: '111px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px', marginLeft: 20, marginRight: 20 }}>
                <img style={{ position: 'absolute', left: "20px", top: '-25px' }} src={require('assets/images/dn6.png')} />
                <div style={{ textAlign: 'center', fontSize: 17 }}>
                  <Trans><span style={{ color: '#28A0D4', fontWeight: 700, }}>Digitalize</span> the payroll process and labor management</Trans>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container className='py-20'>
          <div style={{ fontSize: 35, fontWeight: 700, color: '#1C2B4D', textAlign: 'center' }}><Trans>ATTRACT AND RECRUIT THE BEST TALENTS</Trans></div>
          <div style={{ textAlign: 'center' }}><Trans>This is the real reason why our clients love us. Numbers talk by themselves.</Trans></div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '25%', margin: 20 }}>
              <div className="progress-bar A" style={{ margin: 'auto' }}>
                <progress id='A' value="81" max="100" style={{ visibility: 'hidden', height: '0', width: '0' }}>81%</progress>
              </div>
              <div style={{ color: "#3B3838", textAlign: 'center', padding: "30px 40px" }}><Trans>of employees want more choice around pay – <b>attract great talent.</b></Trans></div>
            </div>
            <div style={{ width: '25%', margin: 20 }}>
              <div className="progress-bar B" style={{ margin: 'auto' }}>
                <progress id='B' value="77" max="100" style={{ visibility: 'hidden', height: '0', width: '0' }}>77%</progress>
              </div>
              <div style={{ color: "#3B3838", textAlign: 'center', padding: "30px 40px" }}><Trans>of SCP users are more focus at work since SCP service was introduced – <b>reduces sick days.</b></Trans></div>
            </div>
            <div style={{ width: '25%', margin: 20 }}>
              <div className="progress-bar C" style={{ margin: 'auto' }}>
                <progress id='C' value="72" max="100" style={{ visibility: 'hidden', height: '0', width: '0' }}>72%</progress>
              </div>
              <div style={{ color: "#3B3838", textAlign: 'center', padding: "30px 40px" }}><Trans>of SCP users feel comfortable in balancing their finance when using SCP service - <b>satisfies with benefits</b></Trans></div>
            </div>
            <div style={{ width: '25%', margin: 20 }}>
              <div className="progress-bar D" style={{ margin: 'auto' }}>
                <progress id='D' value="54" max="100" style={{ visibility: 'hidden', height: '0', width: '0' }}>54%</progress>
              </div>
              <div style={{ color: "#3B3838", textAlign: 'center', padding: "30px 40px" }}><Trans>of employees want to work for a long time while using SCP service - <b>reduces resignation rate</b></Trans></div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ backgroundColor: '#A6A6A6', position: 'relative' }}>
        {/* <img style={{position:'absolute',bottom:0,left:'18%'}} src={require('assets/images/dn11.png')} /> */}
        <Container style={{ paddingTop: '5rem' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '40%' }}>
              <div data-aos="zoom-out-right" style={{ fontSize: 39, fontWeight: 700, color: 'white', textAlign: 'center' }}><Trans>SCP’S ADVANTAGES</Trans></div>
              <div style={{ display: "flex", justifyContent: "center" }}><img style={{}} src={require('assets/images/dn11.png')} /></div>
            </div>
            <div style={{ width: '60%' }}>
              <div style={{ display: "flex" }}>
                <div style={{ width: '33%', backgroundColor: 'white', borderRadius: "10px", margin: "20px" }}>
                  <div data-aos="flip-left" style={{ display: 'flex', justifyContent: 'center', margin: 17 }}><img style={{}} src={require('assets/images/dn12.png')} /></div>
                  <div style={{ textAlign: 'center', fontSize: "24px", fontWeight: 700, color: "#203864", marginBottom: "15px" }}><Trans>Convenient</Trans></div>
                  <div style={{ textAlign: 'center', fontSize: 15, color: "#3B3838", padding: "0px 20px 20px 20px", }}><Trans>Do not change the attendance and payroll process</Trans></div>
                </div>
                <div style={{ width: '33%', backgroundColor: 'white', borderRadius: "10px", margin: "20px" }}>
                  <div data-aos="flip-left" style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 21 }}><img style={{}} src={require('assets/images/dn13.png')} /></div>
                  <div style={{ textAlign: 'center', fontSize: "24px", fontWeight: 700, color: "#203864", marginBottom: "15px" }}><Trans>Flexible</Trans></div>
                  <div style={{ textAlign: 'center', fontSize: 15, color: "#3B3838", padding: "0px 20px 20px 20px", }}><Trans>Multiple options for deducting salary to pay SCP</Trans></div>
                </div>
                <div style={{ width: '33%', backgroundColor: 'white', borderRadius: "10px", margin: "20px" }}>
                  <div data-aos="flip-left" style={{ display: 'flex', justifyContent: 'center', margin: 13 }}><img style={{}} src={require('assets/images/dn14.png')} /></div>
                  <div style={{ textAlign: 'center', fontSize: "24px", fontWeight: 700, color: "#203864", marginBottom: "15px" }}><Trans>Optimal</Trans></div>
                  <div style={{ textAlign: 'center', fontSize: 15, color: "#3B3838", padding: "0px 20px 20px 20px", }}><Trans>No costs for businesses (could share fees with employees)</Trans></div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: '33%', backgroundColor: 'white', borderRadius: "10px", margin: "20px" }}>
                  <div data-aos="flip-left" style={{ display: 'flex', justifyContent: 'center', margin: 20 }}><img style={{}} src={require('assets/images/dn15.png')} /></div>
                  <div style={{ textAlign: 'center', fontSize: "24px", fontWeight: 700, color: "#203864", marginBottom: "15px" }}><Trans>Exclusive</Trans></div>
                  <div style={{ textAlign: 'center', fontSize: 15, color: "#3B3838", padding: "0px 20px 20px 20px", }}><Trans>Advance both earned labors and future labors</Trans></div>
                </div>
                <div style={{ width: '33%', backgroundColor: 'white', borderRadius: "10px", margin: "20px" }}>
                  <div data-aos="flip-left" style={{ display: 'flex', justifyContent: 'center', margin: 16 }}><img style={{}} src={require('assets/images/dn16.png')} /></div>
                  <div style={{ textAlign: 'center', fontSize: "24px", fontWeight: 700, color: "#203864", marginBottom: "15px" }}><Trans>Transparent</Trans></div>
                  <div style={{ textAlign: 'center', fontSize: 15, color: "#3B3838", padding: "0px 20px 20px 20px", }}><Trans>Transparent legal operation and secured user information</Trans></div>
                </div>
                <div style={{ width: '33%', backgroundColor: 'white', borderRadius: "10px", margin: "20px" }}>
                  <div data-aos="flip-left" style={{ display: 'flex', justifyContent: 'center', margin: 19 }}><img style={{}} src={require('assets/images/dn17.png')} /></div>
                  <div style={{ textAlign: 'center', fontSize: "24px", fontWeight: 700, color: "#203864", marginBottom: "15px" }}><Trans>Easy</Trans></div>
                  <div style={{ textAlign: 'center', fontSize: 15, color: "#3B3838", padding: "0px 20px 20px 20px", }}><Trans>Simple platform 100% online, connect API to attendance system</Trans></div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container className='py-20'>
          <div style={{ textAlign: 'center', fontSize: 35, fontWeight: 700, color: '#1C2B4D', marginBottom: "20px" }}><Trans>COOPERATION STEPS</Trans></div>
          <div style={{ display: 'flex' }}>
            <div data-aos="flip-up" style={{ width: '25%', margin: 25, borderRadius: 10, borderWidth: 1, borderColor: '#0FB7AD' }}>
              <div style={{ display: 'flex', justifyContent: 'center', margin: 25 }}><img style={{ width: '150px' }} src={require('assets/images/dn18.jpg')} /></div>
              <div style={{ textAlign: 'center', fontSize: "20px", color: "#203864", marginBottom: "25px", fontWeight: 600 }}><Trans>Cooperation Agreement</Trans></div>
              <div style={{ textAlign: 'center', padding: "0px 35px 40px 35px", }}><Trans>SCP and the enterprise negotiate on a salary advance program for employees</Trans></div>
            </div>
            <div data-aos="flip-up" data-aos-delay="1000" style={{ width: '25%', margin: 25, borderRadius: 10, borderWidth: 1, borderColor: '#0FB7AD' }}>
              <div style={{ display: 'flex', justifyContent: 'center', margin: 25 }}><img style={{ width: '150px' }} src={require('assets/images/dn19.jpg')} /></div>
              <div style={{ textAlign: 'center', fontSize: "20px", color: "#203864", marginBottom: "25px", fontWeight: 600 }}><Trans>Sign & Onboard</Trans></div>
              <div style={{ textAlign: 'center', padding: "0px 35px 40px 35px", }}><Trans>Sign the Cooperation Agreement and get an account to using SCP platform</Trans></div>
            </div>
            <div data-aos="flip-up" data-aos-delay="2000" style={{ width: '25%', margin: 25, borderRadius: 10, borderWidth: 1, borderColor: '#0FB7AD' }}>
              <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}><img style={{ width: '160px' }} src={require('assets/images/dn20.jpg')} /></div>
              <div style={{ textAlign: 'center', fontSize: "20px", color: "#203864", marginBottom: "25px", fontWeight: 600 }}><Trans>Information Update</Trans></div>
              <div style={{ textAlign: 'center', padding: "0px 35px 40px 35px", }}><Trans>Update employee information and connect API to attendance system for labor confirmation</Trans></div>
            </div>
            <div data-aos="flip-up" data-aos-delay="3000" style={{ width: '25%', margin: 25, borderRadius: 10, borderWidth: 1, borderColor: '#0FB7AD' }}>
              <div style={{ display: 'flex', justifyContent: 'center', margin: 15 }}><img style={{ width: '170px' }} src={require('assets/images/dn21.jpg')} /></div>
              <div style={{ textAlign: 'center', fontSize: "20px", color: "#203864", marginBottom: "25px", fontWeight: 600 }}><Trans>Salary Payment</Trans></div>
              <div style={{ textAlign: 'center', padding: "0px 35px 40px 35px", }}><Trans>The enterprise pays SCP the advanced amount or pays 100% to the employees, SCP will collect automatically</Trans></div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
            <Button onClick={submitContact} variant='contained' size='large' style={{ backgroundColor: '#0FB7AD' }} className='h-14 rounded-lg mx-auto'>
              <Trans>Contact SCP now !</Trans>
            </Button>
          </div>
        </Container>
      </div>
      <Dialog fullWidth maxWidth='xl' open={openSelectBank} onClose={() => setOpenSelectBank(false)}>
        <ContactPopup
          onNext={(code: string) => {
            setBankCode(code);
            setOpenExport(true);
          }}
          onClose={() => {
            setOpenSelectBank(false);
          }}
        />
      </Dialog>
    </StaticLayout>
  );
};

export default Enterprise;
