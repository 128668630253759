import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { ArrowRight } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';
import { usePermission } from 'hooks';
import { getPaymentMethodStatus } from 'constants/enterprise';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseService, queryClient } from 'services';

const EnterprisePaymentMethod = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { requireRole, requireUserPCManagerOrCRMLeader, getEnterpriseId } = usePermission();
  const { id: _id } = useParams() as { id: string };

  const [type, setType] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { data: paymentMethod } = useQuery(
    ['enterpriseService.getEnterprisePaymentMethod', { _id: _id ?? getEnterpriseId() }],
    () => enterpriseService.getEnterprisePaymentMethod({ _id: _id ?? getEnterpriseId() }),
    {
      onSuccess: (data) => {
        setType(data.type);
      },
    },
  );

  const handleClickSubmit = () => {
    setIsLoading(true);
    if (requireRole('ENTERPRISE', false) && paymentMethod?.status! === 'WAITING_FOR_CONFIRMATION') {
      enterpriseService.confirmEnterprisePaymentMethod().finally(() => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseService.getEnterprisePaymentMethod');
        setIsLoading(false);
      });
    } else {
      enterpriseService.updateEnterprisePaymentMethod({ _id: _id ?? getEnterpriseId(), type }).finally(() => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseService.getEnterprisePaymentMethod');
        setIsLoading(false);
      });
    }
  };

  return (
    <div className='mt-6'>
      <div className='flex items-center space-x-2'>
        <Typography variant='h4'>
          <Trans>Form of Payment at Maturity</Trans>
        </Typography>
        <ArrowRight />
        <Typography variant='h5'>{getPaymentMethodStatus(paymentMethod?.status!)}</Typography>
      </div>

      <FormControl className='mt-3 ml-3'>
        {[
          { value: 'FULL', label: t`100% Payment to Employee's Designated Bank Account` },
          { value: 'PART', label: t`Pay the advanced amount to Platform Company & Pay the rest to Employee` },
        ].map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={item.value === type}
                onChange={(event) => {
                  if (event.target.checked) setType(item.value);
                }}
              />
            }
            label={item.label}
          />
        ))}
      </FormControl>

      {(requireUserPCManagerOrCRMLeader() ||
        (requireRole('ENTERPRISE', false) && paymentMethod?.status! === 'WAITING_FOR_CONFIRMATION')) && (
        <div className='flex justify-end'>
          <LoadingButton variant='contained' className='w-32' loading={isLoading} onClick={handleClickSubmit}>
            {requireUserPCManagerOrCRMLeader() ? <Trans>Update</Trans> : <Trans>Confirm</Trans>}
          </LoadingButton>
        </div>
      )}
    </div>
  );
};

export default EnterprisePaymentMethod;
