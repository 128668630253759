import { t, Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  debounce,
} from '@mui/material';
import { TableEmpty } from 'components';
import { getEmployeeType } from 'constants/employee';
import { useSearch } from 'hooks';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';
import { employeeService, enterpriseSetupService, queryClient, categoryService } from 'services';
import { EmployeeType, WorkingType } from 'types/EnterpriseSetup';
import { formatNumber } from 'utils/common';
import { ContractType } from 'types/EmployeeContract';
import ContractViewPopup from 'views/Employee/EmployeeContract/components/ContractViewPopup';
import SelectPageSize from 'components/SelectPageSize';


const EmployeeListWorking = ({ enterpriseId, workingType }: { enterpriseId?: number; workingType: WorkingType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const [dataSearch, onSearchChange] = useSearch({ id: enterpriseId, workingType });
  const userLocale = useActiveLocale();
  const [dataSelect, setDataSelect] = useState<EmployeeType[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [chosenContract, setChosenContract] = useState<ContractType>();
  const [searchParams, setSearchParams] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState('name');
  const { data } = useQuery(
    ['enterpriseSetupService.fetchEmployees', dataSearch],
    () => enterpriseSetupService.fetchEmployees(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};
  const calculateSeniorYear = (startWorkingDate: string) => {
    if (startWorkingDate === '') {
      return 0;
    }
    const start = DateTime.fromISO(startWorkingDate);
    const now = DateTime.local();
    const diff = now.diff(start, 'years');
    const years = diff.toObject().years;
    return formatNumber(Math.round((years! + Number.EPSILON) * 100) / 100);
  };
  const { data: employeeLevels } = useQuery(['fetchEmployeeLevels'], () => categoryService.fetchEmployeeLevels());
  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  async function onChangeType(value: any) {
    setSearchType("type")
    var datavalue = { [searchType]: value.target.value === "1" ? "" : value.target.value }
    debounceChangeValue(datavalue)
  }
  async function onChangeTypeLevel(value: any) {
    setSearchType("level_id")
    var datavalue = { [searchType]: value.target.value === "all" ? "" : value.target.value }
    debounceChangeValue(datavalue)
  }

  const { mutate: changeKeys, isLoading: isLoadingKey } = useMutation(employeeService.changeKeyEmployee, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.fetchEmployees');
      setOpenConfirm(false);
    },
  });

  const handleChangeKey = () => {
    const employeeIds = dataSelect.map((item) => item.employeeId);
    if (employeeIds) {
      changeKeys({ employeeIds, isKey: 0 });
    }
  };
  const viewCommitment = (data: EmployeeType) => {
    employeeService.fetchCommitmentEmployee({ id: data.employeeId, entId: data.enterprise.id }).then(async res => {
      setChosenContract(res);
      console.log(chosenContract);
      if (res.id > 0) {
        setOpen(true);
      }
    })
  }
  return (
    <div>
      <div className='flex justify-between'>
        <div className='space-x-3'>
          <Button variant='contained' disabled={dataSelect.length === 0} onClick={() => setOpenConfirm(true)}>
            <Trans>Switch to Regular Employee</Trans>
          </Button>
        </div>
        <div className='flex space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value='name'>
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value='ercNumber'>
              <Trans>ID No.</Trans>
            </MenuItem>
            <MenuItem value='type'>
              <Trans>Type</Trans>
            </MenuItem>
            <MenuItem value='level_id'>
              <Trans>Level</Trans>
            </MenuItem>
          </TextField>
          {
            searchType === 'type' ?
              <TextField select defaultValue='1' onChange={(value) => onChangeType(value)}>
                <MenuItem value='1'>
                  <Trans>All</Trans>
                </MenuItem>
                <MenuItem value='SEASON'>
                  <Trans>Part-time</Trans>
                </MenuItem>
                <MenuItem value='FULL'>
                  <Trans>Full-time</Trans>
                </MenuItem>
              </TextField> : searchType === 'level_id'
                ?
                <TextField select defaultValue='all' onChange={(value) => onChangeTypeLevel(value)}>
                  <MenuItem value='all'>
                    <Trans>All</Trans>
                  </MenuItem>
                  {employeeLevels?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                    </MenuItem>
                  ))}
                </TextField>
                :
                <TextField placeholder={t`Search...`}
                  value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                    debounceChangeValue({ [searchType]: event.target.value, languageVi: userLocale === 'vi-VN' ? 'true' : 'false' });
                  }} />
          }

        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onChange={(event, checked) => {
                    if (checked) setDataSelect(items);
                    else setDataSelect([]);
                  }}
                  checked={dataSelect.length === items.length}
                  indeterminate={dataSelect.length > 0 && dataSelect.length < items.length}
                />
              </TableCell>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>ID No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Employee</Trans>
              </TableCell>
              <TableCell>
                <Trans>Level</Trans>
              </TableCell>
              <TableCell>
                <Trans>Position</Trans>
              </TableCell>
              <TableCell>
                <Trans>Phone</Trans>
              </TableCell>
              <TableCell>
                <Trans>Type of Labor Contract</Trans>
              </TableCell>
              <TableCell>
                <Trans>Seniority (Year)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Monthly Average Salary (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Commitment</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.employeeId}>
                <TableCell align='center' className='p-1'>
                  {item.isKey === 1 && (
                    <Checkbox
                      checked={dataSelect.some((next) => next.employeeId === item.employeeId)}
                      onChange={(event, checked) => {
                        if (checked) setDataSelect((nexts) => nexts.concat(item));
                        else setDataSelect((nexts) => nexts.filter((next) => next.employeeId !== item.employeeId));
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>{item.employee.name}</TableCell>
                <TableCell>{item.employee.ercNumber}</TableCell>
                <TableCell>{getEmployeeType(item.employeeType)}</TableCell>
                <TableCell>{item.isKey === 1 ? t`Key Employee` : t`Regular Employee`}</TableCell>
                <TableCell>
                  {userLocale === 'vi-VN' ? item.employeeLevel?.viContent : item.employeeLevel?.enContent}
                </TableCell>
                <TableCell>{item.position}</TableCell>
                <TableCell>{item.employee.phone}</TableCell>
                <TableCell>{userLocale === 'vi-VN' ? item.laborTerm?.viContent : item.laborTerm?.enContent}</TableCell>
                <TableCell>{calculateSeniorYear(item.startWorkingDate)}</TableCell>
                <TableCell align='right'>{formatNumber(item.salaryAverage)}</TableCell>
                <TableCell align='center'>{item.detailSetting ? DateTime.fromISO(item.salaryDate).toFormat("dd/MM/yyyy") : item.salaryPaymentDate}</TableCell>
                <TableCell>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      //setOpen(true);
                      //setChosenContract(item);
                      viewCommitment(item);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>
      <Dialog
        fullWidth
        maxWidth='xs'
        open={isOpenConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
      >
        <DialogTitle>
          <Trans>Are you sure?</Trans>
        </DialogTitle>
        <DialogActions>
          <LoadingButton
            variant='outlined'
            onClick={() => {
              setOpenConfirm(false);
            }}
          >
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton loading={isLoadingKey} variant='contained' onClick={handleChangeKey}>
            <Trans>Update</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth='lg' open={isOpen} onClose={() => setOpen(false)}>
        <ContractViewPopup contract={chosenContract!} employeeId='0' onClose={() => setOpen(false)} />
      </Dialog>
      {/* {chosenContract && (
        <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
          <ContractRejectPopup
            contract={chosenContract!}
            employeeId='0'
            onClose={() => {
              setOpenReject(false);
            }}
          />
        </Dialog>
      )} */}
    </div>
  );
};

export default EmployeeListWorking;
