import { Trans } from '@lingui/macro';
import { Container, Typography } from '@mui/material';
import { EpayzLayout } from 'layouts';

const LimitedOffer = () => {
  return (
    <EpayzLayout>
      <Container className='py-20'>
        <Typography className='font-black text-[3rem]'>
          <Trans>Limited Offer</Trans>
        </Typography>
      </Container>
    </EpayzLayout>
  );
};

export default LimitedOffer;
