import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { TableEmpty } from 'components';
import { getAppraisalType, getProfileType } from 'constants/enterprise';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { useQuery } from 'react-query';
import { employeeService } from 'services';
import { PopupController } from 'types/Common';
import { AppraisalHistoryType } from 'types/EmployeeAppraisal';
import { APPRAISAL_TYPES, PROFILE_TYPES } from '../components/EmployeeChecklistAppraisal';

type PopupProps = PopupController & {
  appraisal: AppraisalHistoryType;
};

const EmployeeAppraisalPopup = ({ appraisal, onClose }: PopupProps) => {
  const { data } = useQuery(['employeeService.getAppraisalById', { id: appraisal.id }], () =>
    employeeService.getAppraisalById({ id: appraisal.id }),
  );
  const { groups = [] } = data ?? {};

  const userLocale = useActiveLocale();

  return (
    <>
      <DialogTitle>
        <Trans>Appraisal View</Trans>
      </DialogTitle>

      <DialogContent>
        {groups.map((group) => (
          <Accordion key={group.id} className='mb-4'>
            <AccordionSummary>
              <Typography>{userLocale === 'vi-VN' ? group.viContent : group.enContent}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper} variant='outlined' className='-my-1'>
                <Table>
                  <TableBody>
                    {group.items.map((item) => (
                      <TableRow key={item.appraisalId}>
                        <TableCell>{userLocale === 'vi-VN' ? item.viContent : item.enContent}</TableCell>
                        <TableCell>
                          {item.profileType ? (
                            getProfileType(PROFILE_TYPES.find((choice) => choice.value === item.profileValue)?.value)
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell>
                          {item.appraisalValue ? (
                            getAppraisalType(
                              APPRAISAL_TYPES.find((choice) => choice.value === item.appraisalValue)?.value,
                            )
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableEmpty data={group.items} />
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Close</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EmployeeAppraisalPopup;
