import { SnackbarProvider } from 'notistack';
import { QueryClientProvider } from 'react-query';
import { AppTheme } from 'containers';
import { queryClient } from 'services';
import { IS_EPAYZ } from 'env';

type ContainerType = {
  children: React.ReactNode;
};

const Container = ({ children }: ContainerType) => {
  return (
    <SnackbarProvider
      preventDuplicate={false}
      autoHideDuration={3000}
      variant='success'
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <QueryClientProvider client={queryClient}>
        <AppTheme>
          <div className={IS_EPAYZ ? 'epayz' : 'admin'}>{children}</div>
        </AppTheme>
      </QueryClientProvider>
    </SnackbarProvider>
  );
};

export default Container;
