import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseService, queryClient } from 'services';
import { AppraisalCreateType } from 'types/EnterpriseAppraisal';
import { getProfileType, getAppraisalType } from 'constants/enterprise';
import { TableEmpty } from 'components';
import { usePermission } from 'hooks';
import { $CombinedState } from '@reduxjs/toolkit';
import { useState, useEffect } from 'react';
export const PROFILE_TYPES = [
  { value: 1, label: t`Hard` },
  { value: 2, label: t`Soft` },
];

export const APPRAISAL_TYPES = [
  { value: 1, label: t`One` },
  { value: 2, label: t`Two` },
  { value: 3, label: t`Three` },
];

const EnterpriseChecklistAppraisal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const userLocale = useActiveLocale();
  const { id: _id } = useParams() as { id: string };
  const { control, handleSubmit, getValues, setValue } = useForm();
  const { requreUserPermission, ENTERPRISE_CHUYENVAOLICHSU, canActionAssignCheckList } = usePermission();
  const { data } = useQuery(['enterpriseService.getEnterpriseAppraisal', { _id }], () =>
    enterpriseService.getEnterpriseAppraisal({ _id }),
  );
  const [showNutChuyenVaoLichSu, setValueNutCVLS] = useState(false);
  const [runOne, setRunOne] = useState(false);
  const { groups = [] } = data ?? {};
  const showChuyenVaoLichSu = () => {
    if (groups.length == 0) {
      return setValueNutCVLS(false);
    }
    else {
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].items) {
          for (let j = 0; j < groups[i].items.length; j++) {
            if (groups[i].items[j].checkHaveValue != true && groups[i].items[j].appraisalType != 0) {
              setRunOne(true);
              return setValueNutCVLS(false);
            }
          }
        }
      }
    }
    setRunOne(true);
    return setValueNutCVLS(true);
  }
  useEffect(() => {
    console.log(runOne)
    if (runOne == false) {
      showChuyenVaoLichSu();
    }
  });
  //showChuyenVaoLichSu();
  const { mutate: createEnterpriseAppraisal, isLoading: isLoadingCreate } = useMutation(
    enterpriseService.createEnterpriseAppraisal,
    {
      onSuccess: (data, body) => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseService.fetchEnterpriseAppraisal');
        Object.keys(getValues()).forEach((key) => {
          setValue(key, '');
        });
        setValueNutCVLS(false);
      },
    },
  );

  const { mutate: updateEnterpriseAppraisal, isLoading: isLoadingUpdate } = useMutation(
    enterpriseService.updateEnterpriseAppraisal,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        setValueNutCVLS(true);
      },
    },
  );

  const handleClickSubmitUpdate = () => {
    handleSubmit(async (values) => {
      const array = Object.keys(values)
        .map((item) => Number(item.split('-')[1]))
        .filter((item, index, array) => array.indexOf(item) === index)
        .reduce(
          (array: AppraisalCreateType[], id) =>
            array.concat({
              appraisalId: id,
              appraisalValue: values[`appraisal-${id}`],
              profileValue: values[`profile-${id}`],
            }),
          [],
        );
      var numberArray = array.findIndex((item, i) => {
        if (item.appraisalValue == null) {
          return i;
        }
      })
      if (numberArray >= 0) {
        enqueueSnackbar((userLocale == "vi-VN" ? "Vui lòng tick đủ danh mục thẩm định" : "Please tick all checklist for Enterprise Onboarding"), { variant: 'error' });
      } else {
        updateEnterpriseAppraisal({ _id, values: array });
      }
    })();
  };

  return (
    <div className='mt-6'>
      <div className='flex items-center justify-between mb-4'>
        <Typography variant='h4'>
          <Trans>Checklist for Enterprise Onboarding</Trans>
        </Typography>
        {canActionAssignCheckList() && requreUserPermission([ENTERPRISE_CHUYENVAOLICHSU]) && showNutChuyenVaoLichSu &&
          <LoadingButton variant='outlined' loading={isLoadingCreate} onClick={() => createEnterpriseAppraisal({ _id })}>
            <Trans>Move to History</Trans>
          </LoadingButton>
        }
      </div>

      {groups.map((group) => (
        <Accordion key={group.id} className='mb-4'>
          <AccordionSummary>
            <Typography>
              {group.groupNumber}. {userLocale === 'vi-VN' ? group.viContent : group.enContent}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} variant='outlined' className='-my-1'>
              <Table>
                <TableBody>
                  {group.items.map((item) => (
                    <TableRow key={item.appraisalId}>
                      <TableCell className='w-[55%]'>
                        {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                      </TableCell>
                      <TableCell>
                        {item.profileType ? (
                          <Controller
                            name={`profile-${item.appraisalId}`}
                            defaultValue={item.profileValue}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <FormGroup className='flex-row flex-nowrap'>
                                {PROFILE_TYPES.map((choice, index) => (
                                  <FormControlLabel
                                    key={index}
                                    label={getProfileType(choice.value)}
                                    control={
                                      <Checkbox
                                        checked={choice.value === value}
                                        onChange={(event, checked) => {
                                          if (checked) onChange({ target: { value: choice.value } });
                                        }}
                                      />
                                    }
                                  />
                                ))}
                              </FormGroup>
                            )}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                      <TableCell>
                        {item.appraisalType ? (
                          <Controller
                            name={`appraisal-${item.appraisalId}`}
                            defaultValue={item.appraisalValue}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <FormGroup className='flex-row flex-nowrap'>
                                {APPRAISAL_TYPES.map((choice, index) => (
                                  <FormControlLabel
                                    key={index}
                                    label={getAppraisalType(choice.value)}
                                    control={
                                      <Checkbox
                                        checked={choice.value === value}
                                        onChange={(event, checked) => {
                                          if (checked) onChange({ target: { value: choice.value } });
                                        }}
                                      />
                                    }
                                  />
                                ))}
                              </FormGroup>
                            )}
                          />
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableEmpty data={group.items} />
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}

      <div className='flex justify-center'>
        {canActionAssignCheckList() && <LoadingButton variant='contained' loading={isLoadingUpdate} className='w-32' onClick={handleClickSubmitUpdate}>
          <Trans>Update</Trans>
        </LoadingButton>}
      </div>
    </div>
  );
};

export default EnterpriseChecklistAppraisal;
