import { Trans, t } from '@lingui/macro';
import { Box, Grid, MenuItem, Select, Typography, TextField } from '@mui/material';
import { formatNumber } from 'utils/common';
import { DesktopDatePicker } from '@mui/lab';
import { DateTime } from 'luxon';

const AttendanceRetail = () => {
  return (
    <Grid container columnSpacing={4} rowSpacing={3}>
      <Grid item sm={3}>
        <Select className='my-4' size='small' fullWidth displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
          <MenuItem>
            <Trans>Company A</Trans>
          </MenuItem>
          <MenuItem>
            <Trans>Company B</Trans>
          </MenuItem>
        </Select>
        <Box
          sx={{
            padding: 2,
            backgroundColor: '#ccc',
          }}
        >
          <div className='flex items-center justify-between'>
            <Typography variant='subtitle1'>Sum 1</Typography>
            <div>{formatNumber(1000)}</div>
          </div>
          <div className='flex items-center justify-between'>
            <Typography variant='subtitle1'>Sum 2</Typography>
            <div>{formatNumber(1000)}</div>
          </div>
        </Box>
      </Grid>
      <Grid item sm={9}>
        <div className='mt-4'>
          <DesktopDatePicker
            value={DateTime.now()}
            onChange={() => {}}
            renderInput={(props) => <TextField {...props} />}
            label={t`Month`}
            inputFormat='MM/yyyy'
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default AttendanceRetail;
