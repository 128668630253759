import { Grid } from '@mui/material';
import { UsingLimit, PaymentSchedule, UpdateInformation } from 'views/Dashboard/Enterprise/components';

const DashboardEnterprise = () => {
  return (
    <div>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item sm={12}>
          <UsingLimit />
        </Grid>
        <Grid item sm={12}>
          <PaymentSchedule />
        </Grid>
        <Grid item sm={12}>
          <UpdateInformation />
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardEnterprise;
