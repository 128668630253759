import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { Grid, TextField, Typography, Button, Switch, Dialog } from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { DateTime } from 'luxon';
import { getEnterpriseApplyFeeType } from 'constants/enterprise';
import { formatNumber } from 'utils/common';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { InputNumber } from 'components';
import { UpdateEnterpriseServiceTaxFeeType } from 'types/EnterpriseSetup';
import { useState } from 'react';
import { EnterpriseSettingFeePopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import { usePermission } from 'hooks';

const EnterpriseApplyFee = ({ id }: { id: string }) => {
  const { requireRole, requreUserPermission, PHIDICHVUDN_CHITIET_CAIDAT, PHIDICHVUDN_CHITIET_THUE_LUU } = usePermission();
  const { control, handleSubmit, setValue, getValues } = useForm({ mode: 'onChange' });
  const { enqueueSnackbar } = useSnackbar();

  const { data: { id: appendixId, expire, active, processing, appliedType, endValidityTime, fixedFee } = {} } =
    useQuery(
      ['enterpriseSetupService.getEnterpriseServiceFeeApplyInfo', { id }],
      () => enterpriseSetupService.getEnterpriseServiceFeeApplyInfo({ id }),
      {
        onSuccess: (data) => {
          Object.entries(data ?? {}).forEach(([key, value]) => {
            setValue(key, value);
          });
        },
      },
    );

  const { mutate: updateServiceTaxFee, isLoading } = useMutation(enterpriseSetupService.updateServiceTaxFee, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.getEnterpriseServiceFeeApplyInfo');
      queryClient.invalidateQueries('enterpriseSetupService.getEnterpriseWaitingPaymentData');
    },
  });

  const { mutate: activateAppendix } = useMutation(enterpriseSetupService.activateAppendix, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.getEnterpriseServiceFeeApplyInfo');
    },
  });

  const handleActivate = () => {
    activateAppendix({ appendixId: appendixId!, enterpriseId: id, status: !active });
  };

  const handleClickSubmit = () => {
    handleSubmit((values: UpdateEnterpriseServiceTaxFeeType) => {
      updateServiceTaxFee({ ...values, id });
    })();
  };
  const [openSettingPopup, setOpenSettingPopup] = useState(false);
  return (
    <div>
      <div className='flex items-center justify-between mb-4'>
        <Typography variant='h4'>
          <Trans>Applying Fee</Trans>
        </Typography>
        {requreUserPermission([PHIDICHVUDN_CHITIET_CAIDAT]) && parseInt(getValues("serviceTaxForEnterprise")) >= 0 && (<Button disabled={processing} variant='contained' onClick={() => setOpenSettingPopup(true)}>
          <Trans>Setup</Trans>
        </Button>)}
      </div>

      <Grid container columnSpacing={4} rowSpacing={3}>
        <Grid item sm={3}>
          <TextField
            fullWidth
            label={t`Applied Fee`}
            InputProps={{ readOnly: true }}
            value={getEnterpriseApplyFeeType(appliedType)}
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            fullWidth
            label={t`Fixed Service Fee (VND)`}
            InputProps={{ readOnly: true }}
            value={formatNumber(fixedFee) ?? 0}
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            fullWidth
            label={t`Expire Date`}
            InputProps={{ readOnly: true }}
            value={endValidityTime ? DateTime.fromISO(endValidityTime).toFormat('dd/MM/yyyy') : ''}
          />
        </Grid>
        <Grid item sm={3}>
          <Switch
            disabled={expire || !appendixId || !requireRole('CA_SENIOR')}
            checked={active}
            onClick={() => {
              handleActivate();
            }}
          />
        </Grid>
      </Grid>

      <div className='my-4'>
        <Typography variant='h4'>
          <Trans>Service tax</Trans>
        </Typography>
        <div className='flex items-center space-x-3 mt-4'>
          <Typography variant='h4'>
            <Trans>Tax</Trans>
          </Typography>
          <Controller
            name='serviceTaxForEnterprise'
            defaultValue=''
            control={control}
            rules={{ min: 0 }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                error={invalid}
                InputProps={{
                  inputComponent: InputNumber as any,
                  inputProps: {
                    maxLength: 5,
                  },
                  endAdornment: '%',
                }}
              />
            )}
          />
          {requreUserPermission([PHIDICHVUDN_CHITIET_CAIDAT]) && (<LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Save</Trans>
          </LoadingButton>)}
        </div>
      </div>

      <Dialog fullWidth maxWidth='lg' open={openSettingPopup} onClose={() => setOpenSettingPopup(false)}>
        <EnterpriseSettingFeePopup enterpriseId={id} onClose={() => setOpenSettingPopup(false)} />
      </Dialog>
    </div>
  );
};

export default EnterpriseApplyFee;
