import { Trans } from '@lingui/macro';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { Edit } from '@mui/icons-material';
import {
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { EnterpriseType } from 'types/Enterprise';
import { AdvanceLaborType } from 'types/EnterpriseSetupAdvance';
import { AdvanceLaborCreatePopup } from 'views/ClientSetup/Enterprise/popups';
import { usePermission } from 'hooks';

const EnterpriseAdvanceLabor = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const { isCaRole } = usePermission();
  const userLocale = useActiveLocale();
  const [isOpen, setOpen] = useState(false);
  const [chosenUpdate, setChosenUpdate] = useState<AdvanceLaborType>();

  const { data } = useQuery(['enterpriseSetupService.fetchAdvanceLabors'], () =>
    enterpriseSetupService.fetchAdvanceLabors({ id: enterprise?.id! }),
  );
  const { items = [] } = data ?? {};

  return (
    <div>
      <div className='flex justify-between'>
        <Typography variant='h4'>
          <Trans>Advance Ratio by Labor Contract</Trans>
        </Typography>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Term of Labor Contract</Trans>
              </TableCell>
              <TableCell>
                <Trans>Advance Ratio</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.laborTermId}>
                <TableCell>{userLocale === 'vi-VN' ? item.laborTerm.viContent : item.laborTerm.enContent}</TableCell>
                <TableCell className='text-center'>{item.rate ?? 0} %</TableCell>
                <TableCell className='text-right'>
                  {isCaRole() && (<IconButton
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpen(true);
                      setChosenUpdate(item);
                    }}
                  >
                    <Edit fontSize='small' />
                  </IconButton>)}
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog fullWidth maxWidth='xs' open={isOpen} onClose={() => setOpen(false)}>
        <AdvanceLaborCreatePopup id={enterprise?.id!} advanceLabor={chosenUpdate!} onClose={() => setOpen(false)} />
      </Dialog>
    </div>
  );
};

export default EnterpriseAdvanceLabor;
