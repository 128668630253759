import { t, Trans } from '@lingui/macro';
import { Button, Container, Dialog, Divider, Grid, Paper, Typography } from '@mui/material';
import { CountUp, InfiniteSlider } from 'components';
import { StaticFooter } from 'containers';
import { Fragment, useState, useEffect } from 'react';
import { ContactPopup } from 'views/Static';

const LoginContent = () => {
  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [bankCode, setBankCode] = useState('');
  function submitContact() {
    setOpenSelectBank(true)
  }
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);
  return (
    <>
      <Container className='py-20'>
        <Typography className='font-black text-[3rem] mb-4'>
          <Trans>Paying Salary on Time</Trans>
          <br />
          <Trans>Isn't Enough!</Trans>
        </Typography>

        <Typography className='mb-10'>
          <Trans>
            Entering the new modern era, the financial health of each employee also needs to be taken care of. When
            employees see that they are valued, they will be committed and dedicated.
          </Trans>
          <br />
          <Trans>
            With the support of SCP, employees will be given the freedom to choose how and when to receive their salary,
            helping to build a healthy personal financial foundation.
          </Trans>
        </Typography>

        <Grid container columnSpacing={8}>
          {[
            { value: 34, content: t`HR staff complain about receiving too many requests for salary advance` },
            { value: 56, content: t`Employees run out of money after 20 days of receiving salary` },
            { value: 89, content: t`Employees love to work for companies that have a program of salary advance` },
          ].map((item, index) => (
            <Grid item md={4} key={index}>
              <Paper variant='outlined' className='rounded-lg border-2 h-full p-8'>
                <Typography color='textSecondary' className='text-[5rem] text-center'>
                  <CountUp end={item.value} />%
                </Typography>
                <Divider className='m-8 mt-4 border-b-2' />
                <Typography className='text-lg'>{item.content}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>

      <div className='bg-blue-200'>
        <Container className='py-10'>
          <Typography className='text-[2rem] text-white mb-10'>
            <Trans>PARTNERS TRUST IN</Trans> <span className='text-black'>SCP</span>
          </Typography>
          <InfiniteSlider autoplay arrows slidesToShow={3}>
            <div className='w-full h-[160px] p-[20px] flex justify-center items-center'>
              <img src={require('assets/images/static-partner-1.png')} />
            </div>
            <div className='w-full h-[160px] flex justify-center items-center'>
              <img src={require('assets/images/static-partner-2.png')} />
            </div>
            <div className='w-full h-[160px] flex justify-center items-center'>
              <img src={require('assets/images/static-partner-3.png')} />
            </div>
            <div className='w-full h-[160px] p-[20px] flex justify-center items-center'>
              <img src={require('assets/images/static-partner-1.png')} />
            </div>
            <div className='w-full h-[160px] flex justify-center items-center'>
              <img src={require('assets/images/static-partner-2.png')} />
            </div>
            <div className='w-full h-[160px] flex justify-center items-center'>
              <img src={require('assets/images/static-partner-3.png')} />
            </div>
          </InfiniteSlider>
        </Container>
      </div>
      <Container className='py-20'>
        <Typography
          className='font-black text-[2rem] text-center mb-12'
          data-aos='fade-up'
          data-aos-anchor-placement='top-bottom'
        >
          <Trans><span className='text-blue-400'>SCP - outstanding </span>SALARY ADVANCE</Trans>
          <br />
          <Trans>for business</Trans>
        </Typography>
        <Grid container columnSpacing={8}>
          {[
            {
              icon: require('assets/images/static-salary-1.png'),
              content: t`Zero cost to your company (sharing cost if you wish)`,
            },
            {
              icon: require('assets/images/static-salary-2.png'),
              content: t`Attract new talents & engage your workforce`,
            },
            {
              icon: require('assets/images/static-salary-3.png'),
              content: t`Connect API with attendance & management software`,
            },
            {
              icon: require('assets/images/static-salary-4.png'),
              content: t`Not affect the current salary calculation and payment process`,
            },
          ].map((item, index) => (
            <Grid item md={3} key={index} className='flex flex-col items-center gap-10' data-aos='fade-up'>
              <div className='h-[220px] text-center'>
                <img src={item.icon} />
              </div>
              <Typography variant='subtitle1' className='text-center'>
                {item.content}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>

      <div className='bg-blue-100'>
        <Container className='relative py-20'>
          <Grid container columnSpacing={8}>
            {[
              {
                icon: require('assets/images/static-enterprise-1.png'),
                title: t`Download app and login`,
                content: t`Download the SCP app from the Google Play or Apple Store and register your Account information`,
                top: 240,
              },
              {
                icon: require('assets/images/static-enterprise-2.png'),
                title: t`eKYC verification`,
                content: t`Verify eKYC information only 1 time to activate your Account`,
                top: 120,
              },
              {
                icon: require('assets/images/static-enterprise-3.png'),
                title: t`Apply Salary Advance`,
                content: t`Enter the requested salary amount and confirm the transaction. Your request will be immediately sent and processed`,
                top: 0,
              },
            ].map((item, index) => (
              <Grid item md={4} key={index} style={{ marginTop: item.top }}>
                <div className='flex flex-col gap-3' data-aos='fade-up'>
                  <img src={item.icon} className='h-[160px] mr-auto' />
                  <div className='w-12 h-12 rounded-full bg-info-main flex items-center justify-center'>
                    <Typography variant='h4' color='white'>
                      {index + 1}
                    </Typography>
                  </div>
                  <Typography variant='h4'>{item.title}</Typography>
                  <Typography>{item.content}</Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>

      <Container className='flex overflow-hidden'>
        <div className='flex-1 py-20'>
          {[
            {
              icon: require('assets/images/static-employee-1.png'),
              content: t`Get early salary paid 24/7 even weekend, holidays`,
            },
            {
              icon: require('assets/images/static-employee-2.png'),
              content: t`Lower cost than other traditional consumer loan channels`,
            },
            {
              icon: require('assets/images/static-employee-3.png'),
              content: t`Receive money immediately after applying on platform`,
            },
            {
              icon: require('assets/images/static-employee-4.png'),
              content: t`Simple working on app`,
            },
          ].map((item, index) => (
            <div className='flex items-center gap-3 mb-14' key={index} data-aos='fade-right'>
              <div className='w-20 flex justify-center'>
                <img src={item.icon} />
              </div>
              <Typography variant='h4'>{item.content}</Typography>
            </div>
          ))}
        </div>
        <img src={require('assets/images/bg-employee.png')} data-aos='fade-left' />
      </Container>

      <div style={{ background: `url(${require('assets/images/bg-investment.png')}) no-repeat` }}>
        <Container className='py-20'>
          <Grid container columnSpacing={8}>
            <Grid item md={7}>
              <img src={require('assets/images/static-investment-1.png')} data-aos='fade-right' />
            </Grid>
            <Grid item md={5} className='flex flex-col items-start justify-end pb-10'>
              <Typography className='font-black text-[3rem] text-white mb-4'>
                <Trans>Get your salary now!</Trans>
              </Typography>
              <Typography className='text-white mb-10'>
                <Trans>Contact SCP immediately to become a changemaker and financially proactive</Trans>
              </Typography>
              <Button onClick={submitContact} variant='contained' size='large' color='info' className='h-14 rounded-lg mx-auto'>
                <Trans>Contact SCP</Trans>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Dialog fullWidth maxWidth='xl' open={openSelectBank} onClose={() => setOpenSelectBank(false)}>
        <ContactPopup
          onNext={(code: string) => {
            setBankCode(code);
            setOpenExport(true);
          }}
          onClose={() => {
            setOpenSelectBank(false);
          }}
        />
      </Dialog>
      <StaticFooter />
    </>
  );
};

export default LoginContent;
