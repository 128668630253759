import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import {
  Autocomplete,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { transactionOverdueService, enterpriseService, queryClient } from 'services';
import { TableEmpty } from 'components';
import { PopupController } from 'types/Common';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import {
  GuaranteeTransactionType,
  GuaranteeNoticeFileType,
  GuaranteeNoticeRequestType,
} from 'types/TransactionOverdue';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { formatNumber } from 'utils/common';
import PdfViewer from 'components/PdfViewer';

type PopupProps = PopupController & {
  onConfirm: (id: number) => void;
};

const CreateGuaranteeNoticePopup = ({ onClose, onConfirm }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();
  const [dataSelect, setDataSelect] = useState<GuaranteeTransactionType[]>([]);
  const [step, setStep] = useState(1);
  const [objKey, setObjectKey] = useState('');
  const [selectedEnterprise, setSelectedEnterprise] = useState('');
  const [paymentDate, setPaymentDate] = useState(DateTime.now().toISO());
  // const [bank, setBank] = useState<BankAccountPaginateType>();
  const [transactions, setTransactions] = useState<GuaranteeTransactionType[]>();
  const [checkall, changecheckall] = useState(false);
  const { data: enterprises } = useQuery(['transactionOverdueService.fetchOverdueGuaranteeEnterprises'], () =>
    transactionOverdueService
      .fetchOverdueGuaranteeEnterprises()
      .then((items) => items.data.map((item) => ({ ...item, label: item.enterpriseName }))),
  );
  // const { data: banks = [] } = bank ?? {};
  const { data: bank } = useQuery(
    ['enterpriseService.fetchEnterpriseBanks'],
    () =>
      enterpriseService.fetchEnterpriseBankAccounts({
        _id: 'me',
        page: 1,
        size: 100,
      }),
    { keepPreviousData: true },
  );
  const { data: banks = [] } = bank ?? {};
  useEffect(() => {
    // if (selectedEnterprise) {
    //   enterpriseService
    //     .fetchEnterpriseBankAccounts({
    //       _id: selectedEnterprise,
    //       page: 1,
    //       size: 100,
    //     })
    //     .then(setBank);
    // }
    if (selectedEnterprise && paymentDate) {
      transactionOverdueService
        .getGuaranteeTransactions({
          enterpriseId: parseInt(selectedEnterprise),
          paymentDate: paymentDate,
        })
        .then(setTransactions);
    }
  }, [paymentDate, selectedEnterprise]);

  const { mutate: getGuaranteeNoticeFile, isLoading: isLoadingCreate } = useMutation(
    transactionOverdueService.getGuaranteeNoticeFile,
    {
      onSuccess: (file: GuaranteeNoticeFileType) => {
        enqueueSnackbar(t`Successful`);
        setObjectKey(file.objectKey);
        setStep(2);
      },
    },
  );

  const { mutate: confirmGuaranteeNotice, isLoading: isLoadingConfirm } = useMutation(
    transactionOverdueService.confirmGuaranteeNotice,
    {
      onSuccess: (file: GuaranteeNoticeFileType) => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('transactionOverdueService.getOverdueTransactionGuaranteeNotices');
        onConfirm(file.noticeId);
        onClose();
      },
    },
  );

  const handleClickCreate = () => {
    handleSubmit((values: GuaranteeNoticeRequestType) => {
      type ValuesType = GuaranteeNoticeRequestType & { paymentDate: DateTime };
      const { paymentDate, ...others } = values as ValuesType;
      getGuaranteeNoticeFile({
        ...others,
        enterpriseId: parseInt(selectedEnterprise),
        paymentDate: paymentDate.toISO(),
        transIds: dataSelect.map((item) => item.id),
      });
    })();
  };

  const handleClickConfirm = () => {
    handleSubmit((values: GuaranteeNoticeRequestType) => {
      type ValuesType = GuaranteeNoticeRequestType & { paymentDate: DateTime };
      const { paymentDate, ...others } = values as ValuesType;
      confirmGuaranteeNotice({
        ...others,
        enterpriseId: parseInt(selectedEnterprise),
        paymentDate: paymentDate.toISO(),
        transIds: dataSelect.map((item) => item.id),
      });
    })();
  };

  function selectAll(){
    var lstid = [];
    for(var i = 0; i < (transactions ?? []).length; i++){
      lstid.push((transactions ?? [])[i]);
    }
    setDataSelect(lstid);
  }
  function deselectAll(){
    setDataSelect([]);
  }
  useEffect(() => {
    if((transactions ?? []).length > 0) {
      if((transactions ?? []).length == dataSelect.length) {
        changecheckall(true);
      }else{
        changecheckall(false);
      }
    }else{
      changecheckall(false);
    }
  }, [dataSelect]);

  return (
    <>
      <DialogTitle>
        <Trans>Guarantee Implement Notice</Trans>
      </DialogTitle>

      {step === 2 && (
        <>
          <DialogContent>
            <PdfViewer objectKey={objKey} />
          </DialogContent>
          <DialogActions>
            <LoadingButton variant='outlined' onClick={() => setStep(1)} className='absolute left-4'>
              <Trans>Back</Trans>
            </LoadingButton>
            <LoadingButton
              variant='contained'
              disabled={!dataSelect || dataSelect.length === 0}
              onClick={handleClickConfirm}
              loading={isLoadingConfirm}
            >
              <Trans>Confirm</Trans>
            </LoadingButton>
          </DialogActions>
        </>
      )}
      {step === 1 && (
        <>
          <DialogContent>
            <Grid container columnSpacing={2} rowSpacing={3}>
              <Grid item sm={9}>
                <Controller
                  name='enterpriseId'
                  defaultValue={null}
                  control={control}
                  rules={{ required: t`Field is required` }}
                  render={({ field: { value, onChange: setValue }, fieldState: { invalid, error } }) => (
                    <Autocomplete
                      value={value}
                      onChange={(event, item) => {
                        setValue({ target: { value: item.label } });
                        setSelectedEnterprise(item.id);
                      }}
                      options={enterprises ?? []}
                      disableClearable
                      isOptionEqualToValue={(option, value) => option === value || option.label === value}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          fullWidth
                          required
                          label={t`Enterprise Name`}
                          error={invalid}
                          helperText={error?.message}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={3}>
                <Controller
                  name='paymentDate'
                  defaultValue={DateTime.now()}
                  control={control}
                  rules={{
                    validate: { isValid: (value: DateTime) => value && value.isValid },
                    required: t`Field is required`,
                  }}
                  render={({ field: { value, onChange: setValue }, fieldState: { invalid, error } }) => (
                    <DesktopDatePicker
                      value={value}
                      onChange={(value) => {
                        setValue(value);
                        setPaymentDate(value.toISO());
                      }}
                      renderInput={(props) => <TextField {...props} required error={invalid} />}
                      label={t`Payment Date`}
                      inputFormat='dd/MM/yyyy'
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12}>
                <Controller
                  name='bankId'
                  defaultValue=''
                  control={control}
                  rules={{ required: t`Field is required` }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      select
                      required
                      label={t`Receiving Account`}
                      error={invalid}
                      helperText={error?.message}
                    >
                      {banks.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.accountNumber} - {item.bankName} - {item.branchName}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                    <Checkbox
                      checked={ checkall }
                      onChange={(event, checked) => {
                        if(checked == true){
                          selectAll();
                        }else{
                          deselectAll();
                        }
                      }}
                    />
                    </TableCell>
                    <TableCell>
                      <Trans>Transaction ID</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Employee Name</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Penalty (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Collection Amount (VND)</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(transactions ?? []).map((item, idx) => (
                    <TableRow key={item.id}>
                      <TableCell align='center'>
                        <Checkbox
                          checked={dataSelect.some((next) => next.id === item.id)}
                          onChange={(event, checked) => {
                            if (checked) setDataSelect((nexts) => nexts.concat(item));
                            else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                          }}
                        />
                      </TableCell>
                      <TableCell>{item.transactionCode}</TableCell>
                      <TableCell>{item.employeeName}</TableCell>
                      <TableCell align='right'>{formatNumber(item.penalty)}</TableCell>
                      <TableCell align='right'>{formatNumber(item.collectionAmount)}</TableCell>
                    </TableRow>
                  ))}
                  <TableEmpty data={transactions} />
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
              <Trans>Cancel</Trans>
            </LoadingButton>
            <LoadingButton
              variant='contained'
              disabled={dataSelect?.length === 0}
              onClick={handleClickCreate}
              loading={isLoadingCreate}
            >
              <Trans>Create</Trans>
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default CreateGuaranteeNoticePopup;
