import { Trans } from '@lingui/macro';
import { Grid, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  EnterpriseFeeDetail,
  EnterpriseApplyFee,
  EnterpriseHistorySettingFee,
  EnterpriseServiceFeeDetail,
} from 'views/Enterprise/EnterpriseServiceFee';

const EnterpriseServiceFeeView = () => {
  const { id } = useParams() as { id: string };

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>ENTERPRISE'S FEE DETAILS</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid item sm={12}>
            <EnterpriseFeeDetail id={id} />
          </Grid>
          <Grid item sm={12}>
            <EnterpriseApplyFee id={id} />
          </Grid>
          <Grid item sm={12}>
            <EnterpriseHistorySettingFee id={id} />
          </Grid>
          <Grid item sm={12}>
            <EnterpriseServiceFeeDetail id={id} />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default EnterpriseServiceFeeView;
