import { Trans } from '@lingui/macro';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TableBody, Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TransType } from 'types/Transaction';
import { getPaymentMethod } from 'constants/transaction';

export const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  cellTitle: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '20%',
  },
  cellValue: {
    width: '30%',
  },
});
const TransFeeBank = ({ transaction }: { transaction?: TransType }) => {
  const classes = useStyles();
  return (
    <Grid container columnSpacing={2} rowSpacing={3} className='mt-2'>
      <Grid item sm={4} className='flex items-center'>
        <Trans>Payment Form of Enterprise at Maturity</Trans>
      </Grid>
      <Grid item sm={8} className='flex'>
        <div className='bg-blue-200 p-2'>{getPaymentMethod(transaction?.enterpriseFeeType)}</div>
      </Grid>
      <Grid item sm={2} className='mt-6'>
        <Trans>Designated Bank Account</Trans>
      </Grid>
      <Grid item sm={10}>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>Bank Name</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{transaction?.designatedBank?.name}</TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Branch Name</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{transaction?.designatedBankBranch?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>Account Holder</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{transaction?.designatedBankAccountHolder}</TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Account Number</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{transaction?.designatedBankAccountNumber}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TransFeeBank;
