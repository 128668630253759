import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Dialog, Button } from '@mui/material';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import PdfViewer from 'components/PdfViewer';
import { useState } from 'react';
import { RejectPopup } from 'views/ClientSetup/Enterprise/popups';
import { NoticeType } from 'types/EnterpriseSetup';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  notice: NoticeType;
};

const GuaranteeConfirmationStep1 = ({ notice, onBack, onNext }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenReject, setOpenReject] = useState(false);

  const { mutate: approveGuaranteeNotice, isLoading } = useMutation(enterpriseSetupService.approveGuaranteeNotice, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      onNext();
      queryClient.invalidateQueries('enterpriseSetupService.fetchGuarantee');
    },
  });

  const handleClickSubmit = () => {
    approveGuaranteeNotice({
      enterpriseId: notice.enterpriseId,
      noticeId: notice.id,
    });
  };

  return (
    <>
      <DialogTitle>
        <Trans>GUARANTEE CONFIRMATION NOTICE</Trans>
      </DialogTitle>
      <DialogContent>
        <PdfViewer objectKey={notice.objectKey} />
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button variant='outlined' color='error' onClick={() => setOpenReject(true)}>
            <Trans>Reject</Trans>
          </Button>
          <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Approve</Trans>
          </LoadingButton>
        </div>
      </DialogActions>

      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <RejectPopup
          enterpriseId={notice.enterpriseId}
          noticeId={notice.id}
          onBack={() => {
            setOpenReject(false);
          }}
          onNext={() => {
            setOpenReject(false);
            onBack();
          }}
        />
      </Dialog>
    </>
  );
};

export default GuaranteeConfirmationStep1;
