import { client } from './axios';
import { BankType, BankBranchType, WorkingType, WeekDayType, LaborTermType, EmployeeLevelType, ServerTimeType } from 'types/Category';

const fetchBanks = (): Promise<BankType[]> => client.get(`/api/v1/categories/bank`);
const fetchBankBranches = (): Promise<BankBranchType[]> => client.get(`/api/v1/categories/bank_branch`);

const fetchWorkingTypes = (): Promise<WorkingType[]> => client.get(`/api/v1/categories/working_type`);
const fetchWorkingBlocks = (): Promise<WorkingType[]> => client.get(`/api/v1/categories/working_block`);
const fetchWeekDays = (): Promise<WeekDayType[]> => client.get(`/api/v1/categories/day`);
const fetchLaborTerms = (): Promise<LaborTermType[]> => client.get(`/api/v1/categories/labor-term`);
const fetchEmployeeLevels = (): Promise<EmployeeLevelType[]> => client.get(`/api/v1/categories/employee-level`);
const getServerTime = (): Promise<ServerTimeType> => client.get(`/api/v1/categories/current-time`);

export default {
  fetchBanks,
  fetchBankBranches,

  fetchWorkingTypes,
  fetchWorkingBlocks,
  fetchWeekDays,
  fetchLaborTerms,
  fetchEmployeeLevels,
  getServerTime,
};
