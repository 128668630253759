import { Trans, t } from '@lingui/macro';
import { store } from 'reducers';
import { openAlert } from 'reducers/notification';

import { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  BottomNavigation,
  BottomNavigationAction,
  TextField,
  MenuItem,
} from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { categoryService, fileService } from 'services';
import { useMutation, useQuery } from 'react-query';
import { PopupController, FileDataType, SignCoordinate } from 'types/Common';
import { ObjectBas64Type } from 'types/Object';
import { SignedDataType } from 'types/DigitalSign';
import { VNPT_LICENSE } from 'env';

type PopupProps = PopupController & {
  documents: FileDataType[];
  signType: string; // Doi tuong ki, lay gia tri la type trong FileDataType no se la pc_sign, partner_sign, ...
  onSigned: (signedObjects: SignedDataType[]) => void;
};

type CertType = {
  id: string;
  subjectCN: string;
  notAfter: string;
  serial: string;
};

type SignResponse = {
  message: string;
  code: any;
  content: string;
};

const DigitalSign = ({ documents, signType, onSigned, onClose }: PopupProps) => {
  const VnptPlugin = (window as any).vnpt_plugin;
  const PdfSigner = (window as any).PdfSigner;
  const [plugin, setPlugin] = useState(false);
  const [license, setLicense] = useState(false);
  const [usb, setUsb] = useState(false);
  const [signing, setSigning] = useState(false);
  const [currentCertId, setCurrentCertId] = useState('');
  const [certs, setCerts] = useState<CertType[]>([]);

  const currentCert = certs.find((item) => item.id === currentCertId);

  const { data: serverTime } = useQuery(['categoryService.getServerTime'], () => categoryService.getServerTime());

  const { mutate: uploadBase64Files, isLoading: isLoadingUpload } = useMutation(fileService.uploadBase64Files, {
    onSuccess: (data) => {
      let signedObjects: SignedDataType[] = [];
      Array.from(data.objects!).forEach((o) => {
        Array.from(documents!).forEach((d) => {
          if (o.clientKey === (d.key ?? d.name)) {
            signedObjects.push({ key: o.clientKey, objectId: o.id });
            return;
          }
        });
      });
      onSigned(signedObjects);
      onClose();
    },
  });

  const handleSigned = (signedDocuments: FileDataType[]) => {
    let base64Objects: ObjectBas64Type[] = [];
    Array.from(signedDocuments).forEach((doc) => {
      base64Objects.push({ data: doc.data, key: doc.key ?? doc.name, fileName: 'signed_' + doc.name });
    });
    uploadBase64Files({ files: base64Objects });
  };

  useEffect(() => {
    VnptPlugin.checkPlugin().then((plugin: any) => {
      setPlugin(true);
      setVnptLicense().then((license) => {
        const data = JSON.parse(license);
        setLicense(data.code === 1);

        checkUsbToken().then((usb) => {
          if (usb) {
            setUsb(true);
          } else {
            setUsb(false);
          }
          getCerts().then((resCerts: CertType[]) => {
            if (resCerts) {
              setCerts(resCerts);
              setCurrentCertId(resCerts[0].id);
            }
          });
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const isPluginReady = async () => {
    const data = await VnptPlugin.checkPlugin();
    return data === '1';
  };

  async function setVnptLicense() {
    while (true) {
      if (await isPluginReady()) break;
      await sleep(1000);
    }
    console.log(VNPT_LICENSE);
    return await VnptPlugin.setLicenseKey(VNPT_LICENSE);
  }

  async function checkUsbToken() {
    const data = await VnptPlugin.CheckToken();
    return Number(data);
  }

  async function getCerts() {
    while (true) {
      let res = await VnptPlugin.GetAllCertificates();
      res = JSON.parse(res);
      if (!res || !Array.isArray(res)) {
        await sleep(1000);
        continue;
      }
      let result: CertType[] = [];
      res.forEach((e: any, idx: number) => {
        result.push({ id: idx.toString(), ...JSON.parse(e) });
      });
      return result;
    }
  }

  function createSignOption(setting: SignCoordinate) {
    let option = new PdfSigner();
    const llx = Math.round(setting.x0);
    const lly = 832 - Math.round(setting.y0);
    option.llx = llx;
    option.lly = lly;
    option.urx = llx + 230;
    option.ury = lly + 70;
    option.page = setting.pageNumber;
    option.SigTextSize = 9;
    option.SetImageBackground = false;
    option.SigColorRGB = '0,0,102';
    option.SigVisible = true;
    option.SignType = 0;
    option.SigningTime = serverTime?.time;
    option.SigSignerVisible = true;
    option.SigEmailVisible = true;
    option.SigPositionVisible = false;
    option.SigSigningTimeVisible = true;
    option.ValidationOption = false;
    return option;
  }

  function getSignMessage(data: any): SignResponse {
    let jsOb: any;
    if (typeof JSON.parse(data).code !== 'undefined') {
      jsOb = JSON.parse(data);
    } else {
      jsOb = JSON.parse(JSON.parse(data)[0]);
    }
    var err = '';
    switch (jsOb.code) {
      case 0:
        err = 'Ký thành công';
        break;
      case 1:
        err = 'Dữ liệu đầu vào không đúng định dạng';
        break;
      case 2:
        err = 'Không lấy được thông tin chứng thư số';
        break;
      case 3:
        err = 'Có lỗi trong quá trình ký số';
        break;
      case 4:
        err = 'Chứng thư số không có khóa bí mật';
        break;
      case 5:
        err = 'Lỗi không xác định';
        break;
      case 6:
        err = 'Ký pdf: không tìm thấy tham số số trang cần ký';
        break;
      case 7:
        err = 'Ký pdf: trang đặt chữ ký không tồn tại';
        break;
      case 8:
        err = 'Ký xml: không tìm thấy thẻ ký số';
        break;
      case 9:
        err = 'Ký pdf: không tìm thấy id của thẻ ký số';
        break;
      case 10:
        err = 'Dữ liệu ký đã chứa một hoặc nhiều chữ ký không hợp lệ';
        break;
      case 11:
        err = 'Người dùng hủy bỏ';
        break;
      case 13:
        err = 'Dữ liệu ký rỗng';
        break;
      case 14:
        err = 'Chứng thư hết hạn';
        break;
      default:
        err = 'Lỗi không xác định';
        break;
    }
    return {
      message: err,
      code: jsOb.code,
      content: jsOb.data,
    };
  }

  async function _sign(base64: string, setting: SignCoordinate) {
    const option = createSignOption(setting);
    const response = await VnptPlugin.signArrData([base64], 'pdf', option, currentCert?.serial, false);
    return getSignMessage(response);
  }

  async function checkValidDate(){
    const response = await VnptPlugin.CheckValidTime(currentCert?.serial);
    console.log(response);
    return response;
  }

  async function sign() {
    if (documents.length === 0) {
      return;
    }

    if (!currentCertId) {
      return;
    }

    if (signing) {
      return;
    }
    if(await checkValidDate() == "2"){
      store.dispatch(
        openAlert({
          message: "Chứng thư hết hạn",
          code: 400,
          variant: 'error',
        }),
      );
      return;
    }

    setSigning(true);
    let signedDocuments: FileDataType[] = [];
    for (let i = 0; i < documents.length; i++) {
      let doc = documents[i];
      for (let j = 0; j < doc.signatures.length; j++) {
        const signature = doc.signatures[j];
        if (signature.type === signType) {
          console.log(signature);
          for (let k = 0; k < signature.coordinates.length; k++) {
            const res = await _sign(doc.data, signature.coordinates[k]);
            if (res.code !== 0) {
              console.warn(res.message);
              store.dispatch(
                openAlert({
                  message: res.message,
                  code: 400,
                  variant: 'error',
                }),
              );
              setSigning(false);
              return;
            }
            doc.data = res.content;
          }
          break;
        }
      }
      signedDocuments.push(doc);
    }

    handleSigned(signedDocuments);
    setSigning(false);
  }

  function renderCheckStatus(status: boolean) {
    if (status) {
      return <CheckCircleOutline fontSize='large' color='success' />;
    }
    return <CloseIcon fontSize='large' color='error' />;
  }
  return (
    <>
      <DialogTitle>
        <Trans>Digital Sign</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <BottomNavigation showLabels>
              <BottomNavigationAction label={t`Plugin`} icon={renderCheckStatus(plugin)} />
              <BottomNavigationAction label={t`License`} icon={renderCheckStatus(license)} />
              <BottomNavigationAction label={t`USB Token`} icon={renderCheckStatus(usb)} />
            </BottomNavigation>
          </Grid>
          <Grid item sm={12}>
            <TextField
              fullWidth
              select
              value={currentCertId}
              onChange={(event) => setCurrentCertId(event.target.value)}
            >
              {certs.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.subjectCN}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={12}>
            <TextField fullWidth InputProps={{ readOnly: true }} label={t`Subject CN`} value={currentCert?.subjectCN} />
          </Grid>
          <Grid item sm={12}>
            <TextField
              fullWidth
              InputProps={{ readOnly: true }}
              label={t`Expired Date`}
              value={currentCert?.notAfter}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField fullWidth InputProps={{ readOnly: true }} label={t`Serial`} value={currentCert?.serial} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} className='absolute left-4'>
          <Trans>Cancel</Trans>
        </Button>
        <LoadingButton variant='contained' loading={signing || isLoadingUpload} onClick={sign}>
          <Trans>Sign</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DigitalSign;
