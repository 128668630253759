import { t, Trans } from '@lingui/macro';

import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import {
  Button,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TableEmpty } from 'components';
import {
  getPaymentRecipient,
  getTransactionStatus,
  OverdueCompletedStatus,
  WaitCollectionApprovalStatus,
  OverdueStatus,
  PaidStatus,
  PayingStatus,
  RejectedStatus,
  RevisedStatus,
  SettledStatus,
  WaitSettlementApprovalStatus,
  WaitSettlementStatus,
} from 'constants/transaction';
import { useSearch, usePermission } from 'hooks';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { transactionService } from 'services';
import { TransParamsType } from 'types/Transaction';
import { downloadBase64, formatNumber } from 'utils/common';
import TransHisListSummary from './TransHisListSummary';
import SelectPageSize from 'components/SelectPageSize';

const TransHisList = () => {
  const { requireRole } = usePermission();
  const [isLoadingExport, setLoadingExport] = useState(false);
  const STATUSES = [
    { value: ' ', label: t`All` },
    { value: `${PayingStatus}`, label: t`Paying` },
    { value: `${PaidStatus}`, label: t`Paid` },
    { value: `${WaitSettlementStatus}`, label: t`Waiting for settlement` },
    { value: `${RevisedStatus}`, label: t`Revised` },
    { value: `${WaitSettlementApprovalStatus}`, label: t`Waiting for Approval` },
    { value: `${SettledStatus}`, label: t`Settled` },
    { value: `${OverdueStatus},`, label: t`Overdue` },
    { value: `${WaitCollectionApprovalStatus},`, label: t`Waiting for Collection Approval` },
    { value: `${OverdueCompletedStatus}`, label: t`Overdue Completed` },
  ];
  const FEE_TYPES = [
    { value: ' ', label: t`All` },
    {
      value: 'FULL',
      label: t`Employee`,
    },
    { value: 'PART', label: t`Payroll Company` },
  ];
  const [dataSearch, onSearchChange] = useSearch({ sum: 1, calcOverdue: 1, checkDraft: "true" });
  const [searchParams, setSearchParams] = useState({});

  const { data } = useQuery(
    ['transactionService.fetchTransactions', dataSearch],
    () => transactionService.fetchTransactions(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  const { control, handleSubmit } = useForm();
  const handleClickSearch = () => {
    handleSubmit((values: TransParamsType) => {
      type ValuesType = TransParamsType & {
        salaryDateFrom: DateTime;
        salaryDateTo: DateTime;
      };
      const { salaryDateFrom, salaryDateTo, ...others } = values as ValuesType;
      setSearchParams({
        ...others,
        salaryDateFrom: salaryDateFrom ? salaryDateFrom.toISO() : '',
        salaryDateTo: salaryDateTo ? salaryDateTo.toISO() : '',
      });
    })();
  };

  const exportReport = () => {
    setLoadingExport(true);
    transactionService
      .exportTransPcHisList({ ...dataSearch, export: 1 })
      .then(downloadBase64)
      .finally(() => {
        setLoadingExport(false);
      });
  };
  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      <Grid item sm={3}>
        <Controller
          name='statuses'
          defaultValue=' '
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField {...field} fullWidth select label={t`Status`} error={invalid} helperText={error?.message}>
              {STATUSES.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>

      <Grid item sm={3}>
        <Controller
          name='salaryDateFrom'
          defaultValue=''
          control={control}
          rules={{
            validate: { isValid: (value: DateTime) => !value || value.isValid },
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <DesktopDatePicker
              value={value}
              onChange={onChange}
              renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
              label={t`Maturity Date From`}
              inputFormat='dd/MM/yyyy'
            />
          )}
        />
      </Grid>
      <Grid item sm={3}>
        <Controller
          name='salaryDateTo'
          defaultValue=''
          control={control}
          rules={{
            validate: { isValid: (value: DateTime) => !value || value.isValid },
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <DesktopDatePicker
              value={value}
              onChange={onChange}
              renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
              label={t`Maturity Date To`}
              inputFormat='dd/MM/yyyy'
            />
          )}
        />
      </Grid>
      <Grid item sm={3}>
        <Controller
          name='enterpriseFeeType'
          defaultValue=' '
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              fullWidth
              select
              label={t`Recipient of Due Payment`}
              error={invalid}
              helperText={error?.message}
            >
              {FEE_TYPES.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item sm={3}>
        <Controller
          name='transactionCode'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField {...field} fullWidth label={t`Transaction ID`} error={invalid} helperText={error?.message} />
          )}
        />
      </Grid>
      <Grid item sm={3}>
        <Controller
          name='enterpriseName'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField {...field} fullWidth label={t`Enterprise Name`} error={invalid} helperText={error?.message} />
          )}
        />
      </Grid>
      <Grid item sm={3}>
        <Controller
          name='employeeName'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField {...field} fullWidth label={t`Employee Name`} error={invalid} helperText={error?.message} />
          )}
        />
      </Grid>
      <Grid item sm={3} className='flex items-center space-x-3'>
        <LoadingButton variant='contained' onClick={handleClickSearch}>
          <Trans>Search</Trans>
        </LoadingButton>
        {(<LoadingButton variant='contained' loading={isLoadingExport} onClick={exportReport}>
          <Trans>Export Data</Trans>
        </LoadingButton>)}
      </Grid>
      <Grid item sm={12}>
        <TransHisListSummary summary={data?.summary} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Status</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Maturity Date</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Recipient of Due Payment</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Transaction ID</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Enterprise Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Employee Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Request Amount (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Payment Amount (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Transaction Cost (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Total Collection Amount from Employee (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Overdue Unpaid Amount (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Detail</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{getTransactionStatus(item.status)}</TableCell>
                  <TableCell align='center'>
                    {item.salaryPaymentDate ? DateTime.fromISO(item.salaryPaymentDate).toFormat('dd/MM/yyyy') : ''}
                  </TableCell>
                  <TableCell>{getPaymentRecipient(item.enterpriseFeeType)}</TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell>{item.enterprise?.name}</TableCell>
                  <TableCell>{item.employee?.name}</TableCell>
                  <TableCell className='text-right'>{formatNumber(item.requestAmount)}</TableCell>
                  <TableCell className='text-right'>{formatNumber(item.paymentAmount)}</TableCell>
                  <TableCell className='text-right'>{formatNumber(item.costAmount)}</TableCell>
                  <TableCell className='text-right'>{formatNumber(item.collectionAmount)}</TableCell>
                  <TableCell className='text-right'>{formatNumber(item.overdueUnpaidAmount)}</TableCell>
                  <TableCell align='center'>
                    {[PayingStatus, PaidStatus].includes(item.status) && (
                      <Link to={privateRoute.transactionPayingView.url?.(item)!}>
                        <Button size='small' color='info'>
                          <Trans>View</Trans>
                        </Button>
                      </Link>
                    )}
                    {[RejectedStatus].includes(item.status) && (
                      <Link to={privateRoute.transactionRejectView.url?.(item)!}>
                        <Button size='small' color='info'>
                          <Trans>View</Trans>
                        </Button>
                      </Link>
                    )}
                    {[WaitSettlementStatus, SettledStatus, RevisedStatus, WaitSettlementApprovalStatus].includes(
                      item.status,
                    ) && (
                        <Link to={privateRoute.transactionWaitSettlementView.url?.(item)!}>
                          <Button size='small' color='info'>
                            <Trans>View</Trans>
                          </Button>
                        </Link>
                      )}
                    {[OverdueStatus, WaitCollectionApprovalStatus, OverdueCompletedStatus].includes(item.status) && (
                      <Link to={privateRoute.transactionOverdueView.url?.(item)!}>
                        <Button size='small' color='info'>
                          <Trans>View</Trans>
                        </Button>
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
            <SelectPageSize
              size={paginate?.size ?? 10}
              onChange={(size) => {
                onSearchChange({ ...dataSearch, size: size });
              }}
            />
            <Pagination
              page={paginate?.page ?? 1}
              count={paginate?.totalPage}
              onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
            />
          </div>
      </Grid>
    </Grid>
  );
};

export default TransHisList;
