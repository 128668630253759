import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { useSnackbar } from 'notistack';

type PopupProps = {
  enterpriseId: string;
  setAppendixCreate: (args: any) => void;
  onBack: () => void;
  onNext: () => void;
};

const SingleServicePopupStep2 = ({ enterpriseId, setAppendixCreate, onBack, onNext }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: { serviceTax } = {} } = useQuery(
    ['enterpriseSetupService.getEnterpriseServiceFeeCategoryInfo', { id: enterpriseId }],
    () =>
      enterpriseSetupService.getEnterpriseServiceFeeCategoryInfo({
        id: enterpriseId,
        appliedFee: 'SINGLE_SERVICE_FEE',
      }),
  );

  const { mutate: saveSingleServiceFee, isLoading } = useMutation(enterpriseSetupService.saveSingleServiceFee, {
    onSuccess: (data) => {
      setAppendixCreate(data);
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseServiceFeeHistories');
      onNext();
    },
  });

  const handleSave = () => {
    saveSingleServiceFee({ id: enterpriseId });
  };

  return (
    <>
      <DialogTitle>
        <Trans>ENTERPRISE'S SERVICE FEE SETTING</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Applied Fee</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Single Service Fee</Trans>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>VAT</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true, endAdornment: '%' }} value={serviceTax ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Payment Term</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>within 03 Working Days from receiving Notice of Single Fee Payment on Platform</Trans>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button variant='outlined' onClick={onBack}>
            <Trans>Back</Trans>
          </Button>
          <LoadingButton variant='contained' onClick={handleSave} loading={isLoading}>
            <Trans>Save</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default SingleServicePopupStep2;
