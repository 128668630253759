import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Dialog } from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import { enterpriseSetupService, fileService, queryClient } from 'services';
import PdfViewer from 'components/PdfViewer';
import { useState } from 'react';
import { RejectPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import { AppendixObjectDataType } from 'types/EnterpriseSetup';
import { DigitalSign } from 'components';
import { EnterpriseTypeSignType, PcTypeSignType } from 'constants/common';
import { SignedDataType } from 'types/DigitalSign';
import { usePermission } from 'hooks';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseId: string;
  objectData: AppendixObjectDataType;
  appendixId: number;
};

const FixedServicePopupStep5 = ({ enterpriseId, appendixId, objectData, onBack, onNext }: PopupProps) => {
  const { requireRole, requireUserPCManager, signable, rolesignablenew, requreUserPermission, PHIDICHVUDN_HOPDONG_KY, PHIDICHVUDN_HOPDONG_TUCHOI } = usePermission();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenReject, setOpenReject] = useState(false);
  const [isOpenSign, setOpenSign] = useState(false);
  const signType = requireRole('ENTERPRISE', false) ? EnterpriseTypeSignType : PcTypeSignType;

  const { mutate: verifyDigitalAppendix, isLoading: isLoadingVerify } = useMutation(
    enterpriseSetupService.verifyDigitalAppendix,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseServiceFeeHistories');
        onNext();
      },
    },
  );

  const { data: dataFile, isSuccess: isSuccessLoadFile } = useQuery(
    ['fileService.downloadSignatureFile', { key: objectData.objectKey }],
    () => fileService.downloadSignatureFile(objectData.objectKey),
  );

  const handleSignSuccess = (signedObjects: SignedDataType[]) => {
    verifyDigitalAppendix({
      enterpriseId,
      appendixId,
      objectId: signedObjects[0].objectId,
    });
  };

  return (
    <>
      <DialogTitle>
        <Trans>APPENDIX OF FIXED SERVICE FEE</Trans>
      </DialogTitle>
      <DialogContent>
        <PdfViewer objectKey={objectData.objectKey} />
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          {(
            <>
              {(requireRole("ENTERPRISE") || requireUserPCManager()) && requreUserPermission([PHIDICHVUDN_HOPDONG_TUCHOI]) && (<LoadingButton variant='outlined' color='error' onClick={() => setOpenReject(true)}>
                <Trans>Reject</Trans>
              </LoadingButton>)}
              {(requireRole("ENTERPRISE") || requireUserPCManager()) && requreUserPermission([PHIDICHVUDN_HOPDONG_KY]) && (<LoadingButton variant='contained' loading={isLoadingVerify} onClick={() => setOpenSign(true)}>
                <Trans>Sign</Trans>
              </LoadingButton>)}
            </>
          )}
        </div>
      </DialogActions>

      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <RejectPopup
          enterpriseId={enterpriseId}
          appendixId={appendixId}
          onBack={() => {
            setOpenReject(false);
          }}
          onNext={() => {
            setOpenReject(false);
            onBack();
          }}
        />
      </Dialog>
      <Dialog fullWidth maxWidth='md' open={isOpenSign} onClose={() => setOpenSign(false)}>
        <DigitalSign
          onClose={() => setOpenSign(false)}
          documents={[{ ...dataFile!, key: 'service_fee' }]}
          signType={signType}
          onSigned={(signedObjects) => handleSignSuccess(signedObjects)}
        />
      </Dialog>
    </>
  );
};

export default FixedServicePopupStep5;
