import { Trans, t } from '@lingui/macro';
import { Paper, Tab, Tabs, Typography } from '@mui/material';
import { useTabs } from 'hooks';
import { useQuery } from 'react-query';
import { transactionService } from 'services';
import { PayingStatus, PaidStatus } from 'constants/transaction';
import { TransPaying, TransPaid } from 'views/Transaction/TransPaying/TransPayingList/components';

const PayingList = () => {
  const { data: paying } = useQuery(['transactionService.fetchTransactions', { statuses: PayingStatus }], () =>
    transactionService.fetchTransactions({ statuses: PayingStatus }),
  );
  const { data: paid } = useQuery(['transactionService.fetchTransactions', { statuses: PaidStatus }], () =>
    transactionService.fetchTransactions({ statuses: PaidStatus }),
  );

  const tabs = [
    { code: 'paying', label: t`PAYING (${paying?.paginate.totalRecord ?? 0})`, component: <TransPaying /> },
    { code: 'paid', label: t`PAID (${paid?.paginate.totalRecord ?? 0})`, component: <TransPaid /> },
  ];
  const [activeTab, onChangeTab] = useTabs(tabs);

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Transaction Payment</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Tabs value={activeTab} onChange={onChangeTab}>
          {tabs.map((tab) => (
            <Tab key={tab.code} label={tab.label} value={tab.code} />
          ))}
        </Tabs>
        {tabs.map((tab) => (
          <div key={tab.code} hidden={tab.code !== activeTab} className='mt-4'>
            {tab.component}
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default PayingList;
