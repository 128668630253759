import { Trans } from '@lingui/macro';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Button, ButtonGroup, Grid, Typography, Paper } from '@mui/material';
import { getDay } from 'constants/common';
import { DateTime } from 'luxon';
import { Fragment, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { enterpriseService } from 'services';
import { formatNumber } from 'utils/common';

const PaymentSchedule = () => {
  const [pivot, setPivot] = useState(0);
  const pivotWeek = DateTime.now().plus({ week: pivot });
  const workingDays = Array.from({ length: 5 }).map((_, index) => pivotWeek.startOf('week').plus({ day: index }));

  const weekSearch = useMemo(() => {
    return {
      dateFrom: pivotWeek.startOf('week').toISO(),
      dateTo: pivotWeek.endOf('week').toISO(),
    };
  }, [pivotWeek]);

  const { data } = useQuery(['enterpriseService.fetchEnterprisePaymentSalarySchedules', { ...weekSearch }], () =>
    enterpriseService.getPaymentSchedule({ ...weekSearch }),
  );


  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h4'>
          <Trans>PAYMENT SCHEME OF SALARY & FEE</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Grid container columnSpacing={4} rowSpacing={1} className='mb-6'>
          <Grid item sm={12} xl={10}>
            <div className='flex items-start'>
              <Grid container className='text-center'>
                <Grid item sm={2} className='relative bg-primary-light text-white border p-2'>
                  <div>
                    <Trans>WEEK</Trans> {pivotWeek.weekNumber}
                  </div>
                  <div>{pivotWeek.year}</div>
                </Grid>
                {workingDays.map((today, index) => (
                  <Grid item key={index} sm={2} className='bg-primary-light text-white border p-2'>
                    <div>{getDay(today.weekdayLong.toUpperCase())}</div>
                    <div>{today.toFormat('dd/MM/yyyy')}</div>
                  </Grid>
                ))}

                <Grid item sm={2} className='border p-2'>
                  <ButtonGroup variant='contained' size='small'>
                    <Button onClick={() => setPivot((v) => v - 1)}>
                      <NavigateBefore />
                    </Button>
                    <Button disabled={pivot === 0} onClick={() => setPivot(0)}>
                      <Trans>TODAY</Trans>
                    </Button>
                    <Button onClick={() => setPivot((v) => v + 1)}>
                      <NavigateNext />
                    </Button>
                  </ButtonGroup>
                </Grid>
                {workingDays.map((today, index) => {
                  const { schedules: items = [] } = data ?? {};
                  const schedule = items.find((item) =>
                    DateTime.fromISO(item.date).startOf('day').equals(today),
                  );
                  return (
                    <Grid item key={index} sm={2} className='border p-2'>
                      <Typography variant='h4' className='mb-2'>
                        {formatNumber(schedule?.paymentAmount)}
                      </Typography>
                      <Grid container>
                        {schedule?.enterprises.map((client, index) => {
                          return client.type === 'FEE' ?
                            (
                              <Fragment key={index}>
                                <Grid item sm={12} className='bg-primary-light text-white border-b p-1'>
                                  <div className='font-bold'><Trans>Pay [PC]</Trans></div>
                                  <div>{formatNumber(client.payableAmount)}</div>
                                </Grid>
                              </Fragment>
                            ) : (
                              <Fragment key={index}>
                                {client.partAmount > 0 && (<Grid item sm={12} className='bg-gray-300 border-b p-1'>
                                  <div className='font-bold'><Trans>Pay [PC]</Trans></div>
                                  <div>{formatNumber(client.partAmount)}</div>
                                </Grid>)}
                                {client.fullAmount > 0 && (<Grid item sm={12} className='bg-gray-300 border-b p-1'>
                                  <div className='font-bold'><Trans>Pay Employee</Trans></div>
                                  <div>{formatNumber(client.fullAmount)}</div>
                                </Grid>)}
                              </Fragment>
                            )
                        })}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Grid>
          <Grid item sm={12} xl={2}>
            <Grid container className='text-center ml-2'>
              <Grid item sm={12} className='bg-primary-light text-white border p-2'>
                <Trans>TOTAL SALARY PAYABLE FOR THE WEEK (VND) </Trans>
              </Grid>
              <Grid item sm={12} className='border p-2'>
                <Typography variant='h4' className='mb-2'>
                  {formatNumber(data?.totalSalaryPayable)}
                </Typography>
              </Grid>
              <Grid item sm={12} className='bg-primary-light text-white border p-2'>
                <Trans>TOTAL FEE PAYABLE FOR THE WEEK (VND) </Trans>
              </Grid>
              <Grid item sm={12} className='border p-2'>
                <Typography variant='h4' className='mb-2'>
                  {formatNumber(data?.totalFeePayable)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className='text-center ml-2 mt-2'>
              <Grid item sm={6}>
                <Button className='mb-1 bg-gray-300' style={{ minWidth: '10px' }} />  <Trans>Salary</Trans>
              </Grid>
              <Grid item sm={6}>
                <Button className='mb-1 bg-primary-light' style={{ minWidth: '10px' }} />  <Trans>Fee</Trans>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default PaymentSchedule;
