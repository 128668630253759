import { UploadFileType } from 'types/Object';
import { EmployeeLaborPaginateType, EmployeeLaborUpdateType, UpsertEmployeeLaborPayload } from 'types/EmployeeLabor';
import { clientUpload, client } from './axios';
import { EmployeeSetupParamsType } from 'types/EmployeeSetup';


  const uploadFileSystemGetData = (body: FormData): Promise<UpsertEmployeeLaborPayload[]> =>
  clientUpload.post(`/api/v1/employee-labors/import/system/get-data`, body);
const uploadFileEnterprise = (body: FormData): Promise<UploadFileType> =>
  clientUpload.post(`/api/v1/employee-labors/import/enterprise`, body);

  
const uploadFileSystem = (body: UpsertEmployeeLaborPayload[]): Promise<UploadFileType> =>
  client.post(`/api/v1/employee-labors/import/system`, body);
const createLogEmployeeLabor = (body: UpsertEmployeeLaborPayload) => client.post(`/api/v1/employee-labors/create-log`, body);
const updateLogEmployeeLabor = (body: UpsertEmployeeLaborPayload) => client.post(`/api/v1/employee-labors/update-log`, body);
const addOrUpdateEmployeeLabor = (body: EmployeeLaborUpdateType) => client.post(`/api/v1/employee-labors`, body);
const fetchEmployeeLabors = (params?: EmployeeSetupParamsType): Promise<EmployeeLaborPaginateType> =>
  client.get(`/api/v1/employee-labors`, { params });
const fetchEmployeeLaborsHistory = (params?: EmployeeSetupParamsType): Promise<EmployeeLaborPaginateType> =>
  client.get(`/api/v1/employee-labors/history-employee/${params?.employeeId}`, { params });
export default {
  uploadFileSystem,
  uploadFileEnterprise,
  addOrUpdateEmployeeLabor,
  fetchEmployeeLabors,
  fetchEmployeeLaborsHistory,
  uploadFileSystemGetData,
  createLogEmployeeLabor,
  updateLogEmployeeLabor
};
