import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { AttendanceManagementInfo, AttendanceWorkingBlocks, EmployeeList } from 'views/Attendance/components';

const AttendanceManagement = () => {
  const { enterprise } = useSelector(profileSelector);
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Attendance Management</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Paper className='bg-[#f2f2f2] p-4'>
          <AttendanceManagementInfo />
        </Paper>
        <div className='mt-4'>
          <AttendanceWorkingBlocks enterprise={enterprise} />
          <EmployeeList enterprise={enterprise} />
        </div>
      </div>
    </Paper>
  );
};

export default AttendanceManagement;
