import { t, Trans } from '@lingui/macro';
import { Remove } from '@mui/icons-material';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { InputNumber } from 'components';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { enterpriseService, enterpriseSetupService, queryClient } from 'services';
import { CreditScoreType } from 'types/CreditScore';
import { ProposalType } from 'types/EnterpriseProposal';
import { CreditScoreApproveBody, CreditScoreCreateType } from 'types/EnterpriseSetup';

type PopupProps = {
  enterpriseId: number;
  creditScore: CreditScoreType;
  creditScoreCreate: CreditScoreCreateType;
  proposalRequest?: ProposalType;
  conditionId?: number;
  onCreate: (args: any) => void;
  onBack: () => void;
  onNext: () => void;
};

const BusinessCreditScoreStep3 = ({
  enterpriseId,
  creditScore,
  creditScoreCreate,
  proposalRequest,
  conditionId,
  onCreate,
  onBack,
  onNext,
}: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  const { data: proposal } = useQuery(['enterpriseService.getEnterpriseProposal', { id: enterpriseId }], () =>
    enterpriseService.getEnterpriseProposal({ _id: '' + enterpriseId }),
  );

  useQuery(
    ['enterpriseSetupService.getConditionById', { enId: enterpriseId, id: conditionId! }],
    () => enterpriseSetupService.getConditionById({ enId: enterpriseId, id: conditionId! }),
    {
      enabled: !!conditionId,
      onSuccess: (data) => {
        Object.entries(data ?? {}).forEach(([key, value]) => {
          setValue(key, value);
        });
      },
    },
  );

  const { mutate: updateCreditScoreApprove, isLoading: isLoadingApprove } = useMutation(
    enterpriseSetupService.updateCreditScoreApprove,
    {
      onSuccess: (data) => {
        enqueueSnackbar(t`Successful`);
        onCreate(data);
        onNext();
        queryClient.invalidateQueries('enterpriseSetupService.fetchConditionSetting');
        queryClient.invalidateQueries('enterpriseSetupService.getConditionLastest');
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      type ValuesType = CreditScoreApproveBody & { expireDate: DateTime };
      const { expireDate, ...others } = values as ValuesType;

      updateCreditScoreApprove({
        ...others,
        enId: enterpriseId,
        csId: creditScoreCreate.id,
        expireDate: expireDate.toISO(),
      });
    })();
  };

  return (
    <>
      <DialogContent>
        <div className='flex mb-4'>
          <TextField
            label={t`Business Sector`}
            value={creditScore.industryEn}
            InputProps={{ readOnly: true }}
            className='w-[240px]'
          />
        </div>

        <Stepper activeStep={3} className='mb-6'>
          <Step>
            <StepLabel>
              <Trans>Credit Scoring</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Input Assessment Information</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Assessment Result</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Approval Decision By Investment Committee</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        <Grid container className='border w-[320px] mb-6'>
          <Grid item md={7} className='font-bold px-3 py-1'>
            <Trans>CREDIT SCORE</Trans>
          </Grid>
          <Grid item md={5} className='font-bold text-center px-3 py-1 bg-gray-200'>
            {creditScoreCreate.totalScore}
          </Grid>
          <Grid item md={7} className='font-bold px-3 py-1'>
            <Trans>RATING</Trans>
          </Grid>
          <Grid item md={5} className='font-bold text-center px-3 py-1 bg-gray-200 text-red-700'>
            {creditScoreCreate.ratingCategory.ratingType}
          </Grid>
        </Grid>

        <Grid container columnSpacing={4}>
          <Grid item md={6}>
            <Typography variant='h4'>
              <Trans>Proposal Content</Trans>
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className='w-20'>
                      <Trans>No.</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Content</Trans>
                    </TableCell>
                    <TableCell className='max-w-xs'>
                      <Trans>CRM Department's Proposal</Trans>
                    </TableCell>
                    <TableCell className='max-w-xs'>
                      <Trans>Platform's Proposal</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>
                      <Trans>Total Enterprise Limit (VND)</Trans>
                    </TableCell>
                    <TableCell className='text-center'>
                      <div className='px-3 py-2'>{proposal?.totalLimit.toLocaleString()}</div>
                    </TableCell>
                    <TableCell className='text-center font-bold'>
                      {proposalRequest?.totalLimit.toLocaleString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>
                      <Trans>Advance Term (Day)</Trans>
                    </TableCell>
                    <TableCell className='text-center'>
                      <div className='px-3 py-2'>
                        {proposal?.advanceTermFrom.toLocaleString()} - {proposal?.advanceTermTo.toLocaleString()}
                      </div>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>3</TableCell>
                    <TableCell>
                      <Trans>Advance Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className='text-center'>
                      <div className='px-3 py-2'>
                        {proposal?.advanceAmountFrom.toLocaleString()} - {proposal?.advanceAmountTo.toLocaleString()}
                      </div>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>4</TableCell>
                    <TableCell>
                      <Trans>Earned Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell className='text-center'>
                      <div className='px-3 py-2'>{proposal?.earnedDiscountRate}%</div>
                    </TableCell>
                    <TableCell className='text-center font-bold'>{proposalRequest?.earnedDiscountRate}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>5</TableCell>
                    <TableCell>
                      <Trans>Future Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell className='text-center'>
                      <div className='px-3 py-2'>{proposal?.futureDiscountRate}%</div>
                    </TableCell>
                    <TableCell className='text-center font-bold'>{proposalRequest?.futureDiscountRate}%</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item md={6}>
            <Typography variant='h4'>
              <Trans>Approval Decision of CA Department</Trans>
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className='w-10'>
                      <Trans>No</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Content</Trans>
                    </TableCell>
                    <TableCell className='w-64'>
                      <Trans>Approval Decision</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className='h-12'>
                    <TableCell colSpan={3} className='font-bold'>
                      <Trans>Advance Conditions</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>
                      <Trans>Total Enterprise Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='totalLimit'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: !!conditionId,
                              inputProps: {
                                thousandSeparator: ',',
                                className: 'text-right',
                              },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>
                      <Trans>Expire Date</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='expireDate'
                        defaultValue={DateTime.now()}
                        control={control}
                        rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                          <DesktopDatePicker
                            value={value}
                            onChange={onChange}
                            renderInput={(props) => <TextField {...props} error={invalid} />}
                            inputFormat='dd/MM/yyyy'
                            disabled={!!conditionId}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>3</TableCell>
                    <TableCell>
                      <Trans>Advance Term (Day)</Trans>
                    </TableCell>
                    <TableCell className='flex items-center gap-2 -ml-20'>
                      <Controller
                        name='minAdvanceTerm'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: !!conditionId,
                              inputProps: { className: 'text-right' },
                            }}
                          />
                        )}
                      />
                      <Remove fontSize='small' />
                      <Controller
                        name='maxAdvanceTerm'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: !!conditionId,
                              inputProps: { className: 'text-right' },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>4</TableCell>
                    <TableCell>
                      <Trans>Advance Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className='flex items-center gap-2 -ml-20'>
                      <Controller
                        name='minAdvanceAmount'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: !!conditionId,
                              inputProps: {
                                thousandSeparator: ',',
                                className: 'text-right',
                              },
                            }}
                          />
                        )}
                      />
                      <Remove fontSize='small' />
                      <Controller
                        name='maxAdvanceAmount'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: !!conditionId,
                              inputProps: {
                                thousandSeparator: ',',
                                className: 'text-right',
                              },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>5</TableCell>
                    <TableCell>
                      <Trans>Earned Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='earnedDiscountRate'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: !!conditionId,
                              inputProps: {
                                maxLength: 5,
                                className: 'text-right',
                              },
                              endAdornment: '%',
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>6</TableCell>
                    <TableCell>
                      <Trans>Future Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='futureDiscountRate'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: !!conditionId,
                              inputProps: {
                                maxLength: 5,
                                className: 'text-right',
                              },
                              endAdornment: '%',
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>7</TableCell>
                    <TableCell>
                      <Trans>Promotion</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='promotion'
                        defaultValue=''
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField InputProps={{ readOnly: !!conditionId }} {...field} />
                        )}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className='h-12'>
                    <TableCell colSpan={3} className='font-bold'>
                      <Trans>Employee Conditions</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>
                      <Trans>Labor Contract eligible for future advance</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='laborContract'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField InputProps={{ readOnly: !!conditionId }} {...field} error={invalid} />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>
                      <Trans>Other Proposal</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='otherProposal'
                        defaultValue=''
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField InputProps={{ readOnly: !!conditionId }} {...field} />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className='relative'>
        <LoadingButton variant='outlined' onClick={() => onBack()} className='absolute left-4 w-28'>
          <Trans>Back</Trans>
        </LoadingButton>

        <LoadingButton
          variant='contained'
          className='w-28'
          loading={isLoadingApprove}
          onClick={!!conditionId ? onNext : handleClickSubmit}
        >
          {!!conditionId ? <Trans>Next</Trans> : <Trans>Request</Trans>}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default BusinessCreditScoreStep3;
