import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Paper, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { creditScoreService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { CriteriaRatioUpdateType } from 'types/CreditScore';

type PopupProps = PopupController & {
  csId: number;
  prId: number;
  crId: number;
  descriptionEn: string;
  descriptionVi: string;
};

const CriteriaUpdatePopup = ({ csId, prId, crId, descriptionEn, descriptionVi, onClose }: PopupProps) => {
  const userLocale = useActiveLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: updateCriteria, isLoading } = useMutation(creditScoreService.updateCriteria, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('creditScoreService.getParameterById');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: CriteriaRatioUpdateType) => {
      updateCriteria({ ...values, csId, prId, id: crId });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Change Description</Trans>
      </DialogTitle>

      <DialogContent>
        <Paper variant='outlined' className='flex items-center gap-3 rounded-t px-3 py-2'>
          <Controller
            name={userLocale === 'vi-VN' ? 'descriptionVi' : 'descriptionEn'}
            defaultValue={userLocale === 'vi-VN' ? descriptionVi : descriptionEn}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => <TextField fullWidth {...field} error={invalid} />}
          />
        </Paper>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CriteriaUpdatePopup;
