import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { APP_API, AUTH_API } from 'env';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { stringify } from 'qs';
import { store } from 'reducers';
import { openAlert } from 'reducers/notification';
import { ProfileState, signIn, signOut } from 'reducers/profile';
import { getErrorMessage } from 'utils/errorMessage';
import $ from "jquery";
const beforeRequest = (config: AxiosRequestConfig) => {
  const { isLoggedIn, accessToken }: ProfileState = store.getState().profile;
  if (isLoggedIn) {
    Object.assign(config.headers, { Authorization: `Bearer ${accessToken}` });
  }
  if (config.data instanceof FormData) {
    Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' });
  }
  return config;
};

const OnError = async (error: AxiosError) => {
  const { response, config } = error;
  if (response) {
    const { status, data } = response;
    console.log($(".eyrhqyn0").find("div").eq(0).find("img").attr("src")?.indexOf("flag-vn"))
    if (status === 401) {
      try {
        const { refreshToken }: ProfileState = store.getState().profile;
        const { data } = await clientAuth.post(`/api/v1/users/refresh`, { refreshToken });
        store.dispatch(signIn(data));
        return axios(beforeRequest(config)).then(({ data }) => data);
      } catch {
        store.dispatch(signOut());
        return Promise.reject(error);
      }
    } else {
      console.warn(data.errorMessage);
      store.dispatch(
        openAlert({
          message: getErrorMessage(data.errorMessageVn ? ($(".eyrhqyn0").find("div").eq(0).find("img").attr("src")?.indexOf("flag-vn") == -1 ? data.errorMessageEn : data.errorMessageVn) : (data.errorMessage ? data.errorMessage : data.error_message)),
          code: data.statusCode,
          variant: 'error',
        }),
      );
    }
  }
  return Promise.reject(error);
};
const client = axios.create({ baseURL: APP_API });
client.interceptors.request.use(beforeRequest);
client.interceptors.response.use(({ data }) => data, OnError);

const clientAuth = axios.create({ baseURL: AUTH_API });
clientAuth.interceptors.response.use(({ data }) => data);

[client, clientAuth].forEach((client) => {
  client.defaults.paramsSerializer = (params) => stringify(decamelizeKeys(params), { arrayFormat: 'brackets' });
  client.defaults.transformRequest = [(data) => decamelizeKeys(data), ...(axios.defaults.transformRequest as [])];
  client.defaults.transformResponse = [...(axios.defaults.transformResponse as []), (data) => camelizeKeys(data)];
});

const clientUpload = axios.create({ baseURL: APP_API });
clientUpload.interceptors.request.use(beforeRequest);
clientUpload.interceptors.response.use(({ data }) => data, OnError);
clientUpload.defaults.transformResponse = [...(axios.defaults.transformResponse as []), (data) => camelizeKeys(data)];

const clientDownload = axios.create({ baseURL: APP_API });
clientDownload.interceptors.request.use(beforeRequest);
clientDownload.interceptors.response.use(({ data }) => data, OnError);

export { client, clientUpload, clientDownload, clientAuth };
