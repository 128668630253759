import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { enterpriseService, groupService, queryClient, userService, departmentRolesService } from 'services';
import { PopupController } from 'types/Common';
import { DepartmentType, UserCreateType } from 'types/User';
import { useActiveLocale } from 'hooks/useActiveLocale';

type PopupProps = PopupController & {
  department: DepartmentType;
};

const UserCreatePopup = ({ department, onClose }: PopupProps) => {
  const DEPARTMENT_TYPES = [
    { label: t`Manager`, value: 'MANAGER' },
    { label: t`CRM`, value: 'CRM' },
    { label: t`CA`, value: 'CA' },
    { label: t`Accounting`, value: 'ACCOUNTING' },
  ];
  const userLocale = useActiveLocale();
  // const DEPARTMENT_ROLES = {
  //   MANAGER: [
  //     { label: t`Master`, value: 'MASTER' },
  //     { label: t`Sub Master`, value: 'SUB_MASTER' },
  //     { label: t`Supporter`, value: 'SUPPORTER' },
  //   ],
  //   CRM: [
  //     { label: t`CRM Senior`, value: 'CRM_SENIOR' },
  //     { label: t`CRM`, value: 'CRM' },
  //   ],
  //   CA: [
  //     { label: t`CA Senior`, value: 'CA_SENIOR' },
  //     { label: t`Credit Officer`, value: 'CREDIT_OFFICER' },
  //   ],
  //   ACCOUNTING: [
  //     { label: t`Accountant Manager`, value: 'ACC_CONTROLLER' },
  //     { label: t`Accountant`, value: 'ACCOUNTANT' },
  //   ],
  // };
  // const { data: departmentroles} = useQuery(['departmentRolesService.getProfile'], () => departmentRolesService.getListRoles(), {
  //   onSuccess: (data) => {
  //     Object.entries(data ?? {}).forEach(([key, value]) => {

  //     });
  //   },
  // });
  const { data: departmentroles } = useQuery(['departmentRolesService.getProfile', { department }], () =>
    departmentRolesService.getListRoles({ code: department }),
  );

  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();

  const { data: enterprise } = useQuery(['enterpriseService.getEnterpriseById', { id: 'me' }], () =>
    enterpriseService.getEnterpriseById({ id: 'me' }),
  );

  const { data: group } = useQuery(['groupService.fetchGroups', { department }], () =>
    groupService.fetchGroups({ department, size: 100 }),
  );

  const { mutate: createUser, isLoading } = useMutation(userService.createUser, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('userService.fetchUsers');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: UserCreateType) => {
      createUser({
        ...values,
        department: department,
        enterpriseId: enterprise?.id!,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Add User</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Controller
              name='department'
              defaultValue={DEPARTMENT_TYPES.find((item) => item.value === department)?.label}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth label={t`Department`} InputProps={{ readOnly: true }} />
              )}
            />
          </Grid>
          {['CA', 'CRM'].includes(department) && (
            <Grid item sm={12}>
              <Controller
                name='groupId'
                defaultValue=''
                control={control}
                rules={{ required: t`Group is required` }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    required
                    label={t`Group`}
                    error={invalid}
                    helperText={error?.message}
                  >
                    {group?.data.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          )}
          <Grid item sm={12}>
            <Controller
              name='fullname'
              defaultValue=''
              control={control}
              rules={{ required: t`Name is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth required label={t`Name`} error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='role'
              defaultValue=''
              control={control}
              rules={{ required: t`Role is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  select
                  required
                  label={t`Role`}
                  error={invalid}
                  helperText={error?.message}
                >
                  {departmentroles?.map((item) => (
                    <MenuItem key={item.roleCode} value={item.roleCode}>
                      {userLocale === "vi-VN" ? item.roleNameVi : item.roleNameEn}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='position'
              defaultValue=''
              control={control}
              rules={{ required: t`Position is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Position`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='email'
              defaultValue=''
              control={control}
              rules={{
                required: t`Email is required`,
                validate: {
                  isValid: (value) => value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) || t`Email is not valid`,
                },
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth required label={t`Email`} error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='phone'
              defaultValue=''
              control={control}
              rules={{ required: t`Phone is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth required label={t`Phone`} error={invalid} helperText={error?.message} />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default UserCreatePopup;
