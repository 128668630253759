import { Trans, t } from '@lingui/macro';
import { Button, Paper, Typography, Tabs, Tab, Dialog } from '@mui/material';
import {
  EnterpriseWaitingPaymentList,
  EnterprisePaidPaymentList,
  EnterpriseLatePaymentList,
} from 'views/Enterprise/EnterpriseServiceFee';
import { useTabs } from 'hooks';
import { formatNumber } from 'utils/common';
import { enterpriseSetupService } from 'services';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { NoticeListPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';

const EnterpriseServiceFeeDetail = ({ id }: { id: string }) => {
  const { data: enterpriseWaitingPayment } = useQuery(
    ['enterpriseSetupService.getEnterpriseWaitingPaymentData', { enterpriseId: id }],
    () => enterpriseSetupService.getEnterpriseWaitingPaymentData({ enterpriseId: id }),
    { keepPreviousData: true },
  );

  const { data: enterpriseLatePayment } = useQuery(
    ['enterpriseSetupService.getEnterpriseLatePaymentData', { enterpriseId: id }],
    () => enterpriseSetupService.getEnterpriseLatePaymentData({ enterpriseId: id }),
    { keepPreviousData: true },
  );

  const { data: enterprisePaidPayment } = useQuery(
    ['enterpriseSetupService.getEnterprisePaidPaymentData', { enterpriseId: id }],
    () => enterpriseSetupService.getEnterprisePaidPaymentData({ enterpriseId: id }),
    { keepPreviousData: true },
  );

  const tabs = [
    {
      code: 'waiting',
      type: t`WAITING FOR PAYMENT`,
      component: <EnterpriseWaitingPaymentList id={id} />,
      total: enterpriseWaitingPayment?.total,
    },
    {
      code: 'paid',
      type: t`PAID FEE`,
      component: <EnterprisePaidPaymentList id={id} />,
      total: enterprisePaidPayment?.total,
    },
    {
      code: 'stop',
      type: t`LATE PAYMENT`,
      component: <EnterpriseLatePaymentList id={id} />,
      total: enterpriseLatePayment?.sumPayment,
    },
  ].map((item) => ({
    code: item.code,
    label: (
      <div className='flex items-center flex-col space-y-4 justify-center'>
        <div>{item.type}</div>
        <div>{formatNumber(item.total)} VND</div>
      </div>
    ),
    component: item.component,
  }));
  const [activeTab, onChangeTab] = useTabs(tabs);

  const [openNoticeListPopup, setOpenNoticeListPopup] = useState(false);

  return (
    <div>
      <div className='flex items-center justify-between mb-4'>
        <Typography variant='h4'>
          <Trans>Detail of Service Fee</Trans>
        </Typography>
        <Button variant='contained' onClick={() => setOpenNoticeListPopup(true)}>
          <Trans>Notice of single fee payment</Trans>
        </Button>
      </div>

      <Paper className='mt-6'>
        <div className='p-3'>
          <Tabs value={activeTab} onChange={onChangeTab}>
            {tabs.map((tab) => (
              <Tab key={tab.code} label={tab.label} value={tab.code} />
            ))}
          </Tabs>
          {tabs.map((tab) => (
            <div key={tab.code} hidden={tab.code !== activeTab} className='mt-4'>
              {tab.component}
            </div>
          ))}
        </div>
      </Paper>

      <Dialog fullWidth maxWidth='md' open={openNoticeListPopup} onClose={() => setOpenNoticeListPopup(false)}>
        <NoticeListPopup id={id} onClose={() => setOpenNoticeListPopup(false)} />
      </Dialog>
    </div>
  );
};

export default EnterpriseServiceFeeDetail;
