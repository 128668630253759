import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, Button, DialogTitle, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/lab';
import { DateTime } from 'luxon';
import { InputNumber } from 'components';
import { employeeLaborService, queryClient } from 'services';
import { useMutation } from 'react-query';
import { EmployeeLaborUpdateType } from 'types/EmployeeLabor';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  setValue: (args: any) => void;
};

const AttendanceUpdateEmployee = ({ onBack, onNext, setValue }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: addOrUpdateEmployeeLabor, isLoading } = useMutation(employeeLaborService.addOrUpdateEmployeeLabor, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeLaborService.fetchEmployeeLabors');
      onNext();
    }
  });

  const handleClickSubmit = () => {
    handleSubmit((values: EmployeeLaborUpdateType) => {
      addOrUpdateEmployeeLabor({
        ...values,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>UPDATE LABOUR BY SINGLE EMPLOYEE</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContent>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item sm={12}>
              <DesktopDatePicker
                value={DateTime.now()}
                onChange={() => {}}
                renderInput={(props) => <TextField {...props} />}
                label={t`Update Day`}
                inputFormat='dd/MM/yyyy'
                disabled
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                name='employeeCode'
                defaultValue=''
                control={control}
                rules={{ required: t`ID No. is required` }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label={t`Staff ID`}
                    error={invalid}
                    helperText={error?.message}
                  ></TextField>
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                name='employeeName'
                defaultValue=''
                control={control}
                rules={{ required: t`Employee Name is required` }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label={t`Employee Name`}
                    error={invalid}
                    helperText={error?.message}
                  ></TextField>
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                name='totalLabor'
                defaultValue=''
                control={control}
                rules={{
                  required: t`No. of Earned Labour is required`,
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    label={t`No. of Earned Labour`}
                    error={invalid}
                    helperText={error?.message}
                    InputProps={{
                      inputComponent: InputNumber as any,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button
            variant='outlined'
            onClick={() => {
              onBack();
              setValue('');
            }}
          >
            <Trans>Back</Trans>
          </Button>
          <LoadingButton loading={isLoading} variant='contained' onClick={handleClickSubmit}>
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default AttendanceUpdateEmployee;
