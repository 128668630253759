import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { Delete, Edit, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { InputNumber, TableEmpty } from 'components';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { creditScoreService, queryClient } from 'services';
import { CreditScoreType, ParameterType } from 'types/CreditScore';
import {
  ParameterCreatePopup,
  ParameterRemovePopup,
  ParameterUpdatePopup,
} from 'views/GeneralSetup/CreditScore/popups';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { usePermission } from 'hooks';

const Quantitative = ({ creditScore }: { creditScore?: CreditScoreType }) => {
  const { requireRole, requreUserPermission, DIEMTINDUNG_CHITIET_THEMTIEUCHI } = usePermission();
  const userLocale = useActiveLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const [isOpenCreate, setOpenCreate] = useState(false);
  const [isOpenView, setOpenView] = useState(false);
  const [isOpenRemove, setOpenRemove] = useState(false);
  const [chosenParameter, setChosenParameter] = useState<ParameterType>();

  const { mutate: updateCreditScore, isLoading } = useMutation(creditScoreService.updateCreditScore, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('creditScoreService.getCreditScoreById');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      const { proportion } = values as { proportion: number };
      updateCreditScore({
        id: creditScore?.id!,
        expireDate: creditScore?.expireDate!,
        qualitativeProportion: 100 - proportion,
      });
    })();
  };
  return (
    <div>
      <Paper variant='outlined' className='flex items-center gap-3 rounded-t px-3 py-2'>
        <Typography variant='h4' className='flex-1'>
          <Trans>Quantitative Proportion</Trans>
        </Typography>
        <Controller
          name='proportion'
          defaultValue={creditScore?.quantitativeProportion ?? 0}
          control={control}
          rules={{ required: true, max: 100 }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              error={invalid}
              InputProps={{
                inputComponent: InputNumber as any,
                inputProps: {
                  maxLength: 5,
                  className: 'text-right',
                  allowNegative: true,
                },
                endAdornment: '%',
              }}
              className='w-20'
            />
          )}
        />
        {requireRole('CA_SENIOR') && (
          <LoadingButton
            variant='outlined'
            sx={{ padding: 0.5, minWidth: 5 }}
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            <Save />
          </LoadingButton>
        )}
      </Paper>
      {requireRole('CA_SENIOR') && requreUserPermission([DIEMTINDUNG_CHITIET_THEMTIEUCHI]) && (
        <div className='mt-6'>
          <Button variant='outlined' onClick={() => setOpenCreate(true)}>
            <Trans>
              <Trans>Add Parameter</Trans>
            </Trans>
          </Button>
        </div>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Parameter</Trans>
              </TableCell>
              <TableCell>
                <Trans>Proportion</Trans>
              </TableCell>
              {requireRole('CA_SENIOR') && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {creditScore?.quantitative?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{userLocale === 'vi-VN' ? item.nameVi : item.nameEn}</TableCell>
                <TableCell className='text-center'>{item.proportion} %</TableCell>
                {requireRole('CA_SENIOR') && (
                  <TableCell className='text-right space-x-1'>
                    <IconButton
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpenView(true);
                        setChosenParameter(item);
                      }}
                    >
                      <Edit fontSize='small' />
                    </IconButton>
                    <IconButton
                      size='small'
                      color='error'
                      onClick={() => {
                        setOpenRemove(true);
                        setChosenParameter(item);
                      }}
                    >
                      <Delete fontSize='small' />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
            <TableEmpty data={creditScore?.quantitative} />
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog fullWidth maxWidth='xs' open={isOpenCreate} onClose={() => setOpenCreate(false)}>
        <ParameterCreatePopup csId={creditScore?.id!} type={2} onClose={() => setOpenCreate(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='lg' open={isOpenView} onClose={() => setOpenView(false)}>
        <ParameterUpdatePopup csId={creditScore?.id!} prId={chosenParameter?.id!} onClose={() => setOpenView(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='xs' open={isOpenRemove} onClose={() => setOpenRemove(false)}>
        <ParameterRemovePopup
          csId={creditScore?.id!}
          prId={chosenParameter?.id!}
          onClose={() => setOpenRemove(false)}
        />
      </Dialog>
    </div>
  );
};

export default Quantitative;
