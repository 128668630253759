import { PieChart, Pie, Cell, Legend } from 'recharts';
import { formatNumber } from 'utils/common';
import { enterpriseService } from 'services';
import { t } from '@lingui/macro';
import { useQuery } from 'react-query';

const UsingLimit = () => {
  const COLORS = ['#b91c1c', '#404040'];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const { data } = useQuery(['enterpriseService.fetchEnterprises'], () =>
    enterpriseService.fetchEnterprises({ sum: '1' }).then((list) => {
      if (list && list.data.length > 0) {
        return [
          { name: t`Available Limit`, value: list.data[0].totalAvailableLimit },
          { name: t`Total Limit`, value: list.data[0].totalLimit },
        ];
      }
      return [];
    }),
  );
  const items = data ?? [];

  return (
    <div className='flex justify-center border rounded-lg mt-2 py-4'>
      <PieChart width={250} height={250}>
        <Pie
          data={items}
          cx='50%'
          cy='50%'
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill='#8884d8'
          dataKey='value'
          legendType='square'
        >
          {items.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          payload={items.map((item, index) => ({
            id: item.name,
            type: 'square',
            value: `${item.name} ${formatNumber(item.value)}`,
            color: COLORS[index % COLORS.length],
          }))}
        />
      </PieChart>
    </div>
  );
};

export default UsingLimit;
