import { t,Trans } from '@lingui/macro';
import { DialogTitle, DialogContent, DialogActions, Dialog } from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import { enterpriseSetupService, fileService, queryClient } from 'services';
import { LoadingButton } from '@mui/lab';
import { EnterpriseServiceFeeNoticeType } from 'types/EnterpriseSetup';
import PdfViewer from 'components/PdfViewer';
import { NoticeRejectPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import { useState } from 'react';
import { SignedDataType } from 'types/DigitalSign';
import { DigitalSign } from 'components';
import { usePermission } from 'hooks';
import { EnterpriseTypeSignType, PcTypeSignType } from 'constants/common';
import { useSnackbar } from 'notistack';

type PopupProps = {
  enterpriseId: string;
  noticeCreate: EnterpriseServiceFeeNoticeType;
  onNext: () => void;
};

const NoticeCreatePopupStep3 = ({ enterpriseId, noticeCreate, onNext }: PopupProps) => {
  const [isOpenReject, setOpenReject] = useState(false);
  const [isOpenSign, setOpenSign] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { requireRole } = usePermission();

  const signType = requireRole('ENTERPRISE', false) ? EnterpriseTypeSignType : PcTypeSignType;

  const { mutate: signEnterpriseFeeNotice, isLoading } = useMutation(enterpriseSetupService.signEnterpriseFeeNotice, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseFeeNotices');
      onNext();
    },
  });

  const { data: dataFile, isSuccess: isSuccessLoadFile } = useQuery(
    ['fileService.downloadSignatureFile', { key: noticeCreate?.object?.key }],
    () => fileService.downloadSignatureFile(noticeCreate?.object?.key),
  );

  const handleSignSuccess = (signedObjects: SignedDataType[]) => {
    signEnterpriseFeeNotice({
      enterpriseId,
      noticeId: noticeCreate?.id,
      objectId: signedObjects[0].objectId,
    });
  };

  return (
    <>
      <DialogTitle>
        <Trans>NOTICE OF SINGLE FEE PAYMENT</Trans>
      </DialogTitle>

      <DialogContent>
        <PdfViewer objectKey={noticeCreate?.object.key} />
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          {isSuccessLoadFile && (
            <>
              <LoadingButton variant='outlined' color='error' onClick={() => setOpenReject(true)}>
                <Trans>Reject</Trans>
              </LoadingButton>
              <LoadingButton variant='contained' loading={isLoading} onClick={() => setOpenSign(true)}>
                <Trans>Sign</Trans>
              </LoadingButton>
            </>
          )}
        </div>
      </DialogActions>

      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <NoticeRejectPopup
          enterpriseId={enterpriseId}
          noticeId={noticeCreate?.id}
          onBack={() => setOpenReject(false)}
          onNext={() => {
            setOpenReject(false);
            onNext();
          }}
        />
      </Dialog>

      <Dialog fullWidth maxWidth='md' open={isOpenSign} onClose={() => setOpenSign(false)}>
        <DigitalSign
          onClose={() => setOpenSign(false)}
          documents={[{ ...dataFile!, key: 'notice' }]}
          signType={signType}
          onSigned={(signedObjects) => handleSignSuccess(signedObjects)}
        />
      </Dialog>
    </>
  );
};

export default NoticeCreatePopupStep3;
