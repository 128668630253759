import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'reducers';
import { RoleType } from 'types/Role';
import { EnterpriseType } from 'types/Enterprise';
import AuthService, {IAuthService} from '../services/AuthService';
import { IS_SSO } from 'env';
export type ProfileState = {
  id?: number;
  allowDs?: boolean;
  isLoggedIn?: boolean;
  accessToken?: string;
  refreshToken?: string;
  permission?: string[];
  role?: RoleType;
  enterprise?: EnterpriseType;
  phone?: string;
  position?: string;
  fullname?: string;
};
const authService: IAuthService = new AuthService();
export const profileSlice = createSlice({
  name: 'profile',
  initialState: {} as ProfileState,
  reducers: {
    signIn: (state, { payload: profile }) => {
      profile = { ...state, ...profile };
      localStorage.setItem('profile', JSON.stringify(profile));
      return { ...profile, isLoggedIn: true };
    },
    signOut: () => {
      localStorage.removeItem('profile');
      if(IS_SSO){
        localStorage.clear();
        authService.logout();
      }
      return { isLoggedIn: false };
    },
  },
});

export const { signIn, signOut } = profileSlice.actions;

export const profileSelector = ({ profile }: RootState) => profile;

export default profileSlice.reducer;
