import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { creditScoreService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { CriteriaCreateType } from 'types/CreditScore';

type PopupProps = PopupController & {
  csId: number;
  prId: number;
};

const CriteriaCreatePopup = ({ csId, prId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: createCriteria, isLoading } = useMutation(creditScoreService.createCriteria, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('creditScoreService.getParameterById');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: CriteriaCreateType) => {
      createCriteria({
        ...values,
        csId,
        prId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Add Criteria</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Controller
              name='nameVi'
              defaultValue=''
              control={control}
              rules={{ required: t`Vietnamese Name is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Vietnamese Name`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='descriptionVi'
              defaultValue=''
              control={control}
              rules={{ required: t`Vietnamese Description is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  multiline
                  minRows={2}
                  label={t`Vietnamese Description`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='nameEn'
              defaultValue=''
              control={control}
              rules={{ required: t`English Name is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`English Name`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='descriptionEn'
              defaultValue=''
              control={control}
              rules={{ required: t`English Description is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  multiline
                  minRows={2}
                  label={t`English Description`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CriteriaCreatePopup;
