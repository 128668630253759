import { Trans } from '@lingui/macro';
import { TableBody, Table, TableCell, TableContainer, TableRow, Paper, Typography } from '@mui/material';
import { formatNumber } from 'utils/common';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { TransOverdueTrackingType } from 'types/TransactionOverdue';

export const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  cellTitle: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '21%',
  },
  cellValue: {
    width: '12%',
  },
});

const TransOverdueTracking = ({ overdueTracking }: { overdueTracking?: TransOverdueTrackingType }) => {
  const classes = useStyles();

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Overdue Tracking</Trans>
          </Typography>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                <Trans>Overdue Transfering Amount (VND)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {formatNumber(overdueTracking?.overdueTransferringAmount)}
              </TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Overdue Starting Date</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {overdueTracking?.overdueStartingDate
                  ? DateTime.fromISO(overdueTracking?.overdueStartingDate).toFormat('dd/MM/yyyy')
                  : ''}
              </TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Penal Rate of Overdue Type 1 (APR)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {formatNumber(overdueTracking?.penalRateOfOverdueType1)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                <Trans>Type of Overdue Changeover Time (Day)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {formatNumber(overdueTracking?.typeOfOverdueChangeoverTime)}
              </TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Collection Cost for Employee (%)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {formatNumber(overdueTracking?.collectionCostForEmployee)}
              </TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Penal Rate of Overdue Type 2 (%)</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {formatNumber(overdueTracking?.penalRateOfOverdueType2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TransOverdueTracking;
