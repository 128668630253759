import { t, Trans } from '@lingui/macro';
import { CheckCircle, ErrorOutlineOutlined } from '@mui/icons-material';

import {
  Button,
  Checkbox,
  debounce,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { TableEmpty, TooltipLight } from 'components';
import SelectPageSize from 'components/SelectPageSize';
import { getLegalStatus } from 'constants/enterprise';
import { usePermission, useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { enterpriseService } from 'services';
import { EnterpriseType } from 'types/Enterprise';
import { formatNumber } from 'utils/common';
import { EnterpriseAssignmentPopup } from 'views/Enterprise/EnterpriseList/popups';

const EnterpriseListSetup = () => {
  const { requireUserPCManager, requreUserPermission, ENTERPRISE_ASSIGN } = usePermission();
  const [searchType, setSearchType] = useState('name');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const [dataSearch, onSearchChange] = useSearch({ sum: 1 });
  const [dataSelect, setDataSelect] = useState<EnterpriseType[]>([]);

  const [isOpenAssign, setOpenAssign] = useState(false);

  const { data } = useQuery(
    ['enterpriseService.fetchEnterprises', dataSearch],
    () => enterpriseService.fetchEnterprises(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Enterprise Setup</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <div className='flex justify-between'>
          <div className='flex items-center space-x-3'>
            <Typography variant='h4'>
              <Trans>List of Enterprise</Trans>
            </Typography>
            {requireUserPCManager() && requreUserPermission([ENTERPRISE_ASSIGN]) && (
              <Button variant='contained' disabled={dataSelect.length === 0} onClick={() => setOpenAssign(true)}>
                <Trans>Assignment</Trans>
              </Button>
            )}
          </div>
          <div className='space-x-3'>
            <TextField
              select
              value={searchType}
              onChange={(event) => {
                setSearchText('');
                onSearchChange({});
                setSearchType(event.target.value);
                debounceChangeValue({ [searchType]: '' });
              }}
            >
              <MenuItem value='name'>
                <Trans>Enterprise Name</Trans>
              </MenuItem>
              <MenuItem value='taxCode'>
                <Trans>Tax Code</Trans>
              </MenuItem>
              <MenuItem value='seniorCaName'>
                <Trans>CA Team Lead</Trans>
              </MenuItem>
              <MenuItem value='legalStatus'>
                <Trans>Document Status</Trans>
              </MenuItem>
              <MenuItem value='autoTransaction'>
                <Trans>Automatic Transaction</Trans>
              </MenuItem>
            </TextField>
            {['name', 'taxCode', 'seniorCaName'].includes(searchType) && (
              <TextField
                placeholder={t`Search...`}
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                  debounceChangeValue({ [searchType]: event.target.value });
                }}
              />
            )}
            {['legalStatus'].includes(searchType) && (
              <TextField
                select
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                  debounceChangeValue({ [searchType]: event.target.value });
                }}
              >
                <MenuItem value=''>
                  <Trans>All</Trans>
                </MenuItem>
                <MenuItem value='1'>
                  <Trans>Missing</Trans>
                </MenuItem>
                <MenuItem value='2'>
                  <Trans>Complete</Trans>
                </MenuItem>
              </TextField>
            )}
            {['autoTransaction'].includes(searchType) && <TextField
              select
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
            >
              <MenuItem value=''>
                <Trans>All</Trans>
              </MenuItem>
              <MenuItem value='1'>
                <Trans>YES</Trans>
              </MenuItem>
              <MenuItem value='0'>
                <Trans>NO</Trans>
              </MenuItem>
            </TextField>}
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {requireUserPCManager() && <TableCell>
                  <Checkbox
                    onChange={(event, checked) => {
                      if (checked) setDataSelect(items);
                      else setDataSelect([]);
                    }}
                    checked={dataSelect.length === items.length}
                    indeterminate={dataSelect.length > 0 && dataSelect.length < items.length}
                  />
                </TableCell>
                }
                <TableCell>
                  <Trans>Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Tax Code</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Automatic Transaction</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Total Advance Limit (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Total Available Limit (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Discount Rate (APR)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Document Status</Trans>
                </TableCell>
                <TableCell>
                  <Trans>CA Senior</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  {requireUserPCManager() &&
                    <TableCell align='center' className='p-1'>
                      <Checkbox
                        checked={dataSelect.some((next) => next.id === item.id)}
                        onChange={(event, checked) => {
                          if (checked) setDataSelect((nexts) => nexts.concat(item));
                          else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                        }}
                      />
                    </TableCell>
                  }
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.taxCode}</TableCell>
                  <TableCell className='text-center'>{item.autoTransaction ? <CheckCircle color='primary' /> : ""}</TableCell>
                  <TableCell>{formatNumber(item.totalLimit)}</TableCell>
                  <TableCell>{formatNumber(item.totalAvailableLimit)}</TableCell>
                  <TableCell>
                    {formatNumber(item.earnedDiscountRate)}% - {formatNumber(item.futureDiscountRate)}%
                  </TableCell>
                  <TableCell>
                    <div className='flex items-center justify-between'>
                      <Typography>{getLegalStatus(item.documentStatus as 1 | 2)}</Typography>
                      {item.documentStatus !== 2 && (
                        <TooltipLight
                          title={
                            <Grid container className='w-72'>
                              <Grid item sm={4} className='border p-2'>
                                <Trans>Note</Trans>
                              </Grid>
                              <Grid item sm={8} className='border p-2'>
                                {item.documentNote}
                              </Grid>
                              <Grid item sm={4} className='border p-2'>
                                <Trans>Deadline</Trans>
                              </Grid>
                              <Grid item sm={8} className='border p-2'>
                                {item.documentDeadline
                                  ? DateTime.fromISO(item.documentDeadline).toFormat('dd/MM/yyyy')
                                  : ''}
                              </Grid>
                            </Grid>
                          }
                        >
                          <IconButton>
                            <ErrorOutlineOutlined />
                          </IconButton>
                        </TooltipLight>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{item.caManagerUser?.fullname}</TableCell>
                  <TableCell className='text-right'>
                    <Link to={privateRoute.enterpriseSetupView.url?.(item)!}>
                      <Button size='small' color='info'>
                        <Trans>View</Trans>
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ size: size });
              setDataSelect([]);
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => {
              onSearchChange({ page: value });
              setDataSelect([]);
            }}
          />
        </div>

        <Dialog fullWidth maxWidth='md' open={isOpenAssign} onClose={() => setOpenAssign(false)}>
          <EnterpriseAssignmentPopup
            forCa={true}
            enterpriseIds={dataSelect.map((item) => item.id)}
            onSuccess={() => setDataSelect([])}
            onClose={() => setOpenAssign(false)}
          />
        </Dialog>
      </div>
    </Paper>
  );
};

export default EnterpriseListSetup;
