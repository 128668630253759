import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Dialog } from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import { enterpriseSetupService, queryClient, fileService } from 'services';
import PdfViewer from 'components/PdfViewer';
import { useState } from 'react';
import { RejectPopup } from 'views/ClientSetup/Enterprise/popups';
import { NoticeType } from 'types/EnterpriseSetup';
import { DigitalSign } from 'components';
import { SignedDataType } from 'types/DigitalSign';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  notice: NoticeType;
  signType: string;
};

const GuaranteeConfirmationStep2 = ({ signType, notice, onBack, onNext }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenReject, setOpenReject] = useState(false);
  const [isOpenSign, setOpenSign] = useState(false);

  const { mutate: verifyGuaranteeNotice, isLoading: isLoadingVerify } = useMutation(
    enterpriseSetupService.verifyGuaranteeNotice,
    {
      onSuccess: () => {
        debugger;
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseSetupService.fetchGuarantee');
        onNext();
      },
    },
  );

  const { data: dataFile, isSuccess: isSuccessLoadFile } = useQuery(
    ['fileService.downloadSignatureFile', { key: notice.objectKey }],
    () => fileService.downloadSignatureFile(notice.objectKey),
  );

  const handleSignSuccess = (signedObjects: SignedDataType[]) => {
    verifyGuaranteeNotice({
      enterpriseId: notice.enterpriseId,
      noticeId: notice.id,
      objectId: signedObjects[0].objectId,
    });
  };

  return (
    <>
      <DialogTitle>
        <Trans>GUARANTEE CONFIRMATION NOTICE</Trans>
      </DialogTitle>
      <DialogContent>
        <PdfViewer objectKey={notice.objectKey} />
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          {isSuccessLoadFile && (
            <>
              <LoadingButton variant='outlined' color='error' onClick={() => setOpenReject(true)}>
                <Trans>Reject</Trans>
              </LoadingButton>
              <LoadingButton variant='contained' loading={isLoadingVerify} onClick={() => setOpenSign(true)}>
                <Trans>Sign</Trans>
              </LoadingButton>
            </>
          )}
        </div>
      </DialogActions>

      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <RejectPopup
          enterpriseId={notice.enterpriseId}
          noticeId={notice.id}
          onBack={() => {
            setOpenReject(false);
          }}
          onNext={() => {
            setOpenReject(false);
            onBack();
          }}
        />
      </Dialog>
      <Dialog fullWidth maxWidth='md' open={isOpenSign} onClose={() => setOpenSign(false)}>
        <DigitalSign
          onClose={() => setOpenSign(false)}
          documents={[{ ...dataFile!, key: 'guarantee' }]}
          signType={signType}
          onSigned={(signedObjects) => handleSignSuccess(signedObjects)}
        />
      </Dialog>
    </>
  );
};

export default GuaranteeConfirmationStep2;
