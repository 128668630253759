import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import { usePermission, useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { promotionService, queryClient } from 'services';
import { formatNumber } from 'utils/common';
import SelectPageSize from 'components/SelectPageSize';

const PromotionList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { requireUserPCManager,
    requreUserPermission,
    GENERALSETTING_PROMOTION_VIEW,
    GENERALSETTING_PROMOTION_DELETE,
    GENERALSETTING_PROMOTION_LIST,
    GENERALSETTING_PROMOTION_CREATE
  } = usePermission();
  const [dataSearch, onSearchChange] = useSearch();
  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const [selectId, setSelectId] = useState(0);

  const { data } = useQuery(
    ['promotionService.fetchPromotions', dataSearch],
    () => promotionService.fetchPromotions(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { mutate: removePromotion, isLoading: isLoadingConfirm } = useMutation(promotionService.removePromotion, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('promotionService.fetchPromotionEnterprises');
    },
  });

  const handleRemovePromotion = () => {
    removePromotion({
      id: selectId,
    });
  };

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Promotion Setup</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <div className='flex items-center gap-3'>
          <Typography variant='h4'>
            <Trans>Promotion List</Trans>
          </Typography>
          {requireUserPCManager() && requreUserPermission([GENERALSETTING_PROMOTION_CREATE]) && (
            <Link to={privateRoute.promotionSetupView.url?.({ id: 'create' })!}>
              <LoadingButton variant='contained'>
                <Trans>Create</Trans>
              </LoadingButton>
            </Link>
          )}
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Created At</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Promotion Name</Trans>
                </TableCell>
                <TableCell className='text-center'>
                  <Trans>Applied Term</Trans>
                </TableCell>
                <TableCell className='text-center'>
                  <Trans>Minimum of Request Amount</Trans>
                </TableCell>
                <TableCell className='text-center'>
                  <Trans>Promotion of Discount Rate (%)</Trans>
                </TableCell>
                <TableCell className='text-center'>
                  <Trans>Promotion of Transaction Cost</Trans>
                </TableCell>
                <TableCell className='text-center'>
                  <Trans>Number of Applied Times</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Status</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {requreUserPermission([GENERALSETTING_PROMOTION_LIST]) && (<TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='text-center'>
                    {DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>{item.promotionName}</TableCell>
                  <TableCell className='text-center'>
                    {DateTime.fromISO(item.startingDate).toFormat('dd/MM/yyyy')} -{' '}
                    {DateTime.fromISO(item.closingDate).toFormat('dd/MM/yyyy')}
                  </TableCell>
                  <TableCell className='text-center'>{formatNumber(item.minimumRequestAmount)}</TableCell>
                  <TableCell className='text-center'>{item.promotionDiscountRate} %</TableCell>
                  <TableCell className='text-center'>{formatNumber(item.promotionTransactionCost)}</TableCell>
                  <TableCell className='text-center'>{item.numberAppliedTimes}</TableCell>
                  <TableCell className='text-center'>
                    {item.status === 'WAITING_FOR_APPLY' ? (
                      <Trans>Waiting for apply</Trans>
                    ) : item.status === 'APPLIED' ? (
                      <Trans>Applied</Trans>
                    ) : item.status === 'EXPIRED' ? (
                      <Trans>Expired</Trans>
                    ) : (
                      <Trans>Deleted</Trans>
                    )}
                  </TableCell>
                  <TableCell align='center' className='space-x-1'>
                    {requreUserPermission([GENERALSETTING_PROMOTION_VIEW]) && (<Link to={privateRoute.promotionSetupView.url?.(item)!}>
                      <Button size='small' color='info'>
                        <Trans>View</Trans>
                      </Button>
                    </Link>)}
                    {['WAITING_FOR_APPLY'].includes(item.status) && requreUserPermission([GENERALSETTING_PROMOTION_DELETE]) && (
                      <Button
                        size='small'
                        color='error'
                        onClick={() => {
                          setSelectId(item.id);
                          setOpenConfirm(true);
                        }}
                      >
                        <Trans>Delete</Trans>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>)}
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth='xs'
        open={isOpenConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
      >
        <DialogTitle>
          <Trans>Are you sure?</Trans>
        </DialogTitle>
        <DialogActions>
          <LoadingButton
            variant='outlined'
            onClick={() => {
              setOpenConfirm(false);
            }}
          >
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton loading={isLoadingConfirm} color='error' variant='contained' onClick={handleRemovePromotion}>
            <Trans>Update</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default PromotionList;
