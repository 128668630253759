import { Trans } from '@lingui/macro';
import styled from '@emotion/styled/macro';
import { LogoutOutlined } from '@mui/icons-material';
import { AppBar, IconButton, ListItemButton, Toolbar } from '@mui/material';
import ENFlag from 'assets/icons/flag-en.svg';
import VNFlag from 'assets/icons/flag-vn.svg';
import Logo from 'assets/images/logo_scp.png';
import LogoEpayz from 'assets/images/Logo_IFZ.png';
import { IS_EPAYZ } from 'env';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { store } from 'reducers';
import { signOut } from 'reducers/profile';
import { setUserLocale } from 'reducers/user-settings';
import { privateRoute } from 'routes';

export const Dropdown = styled.div`
  display: none;
  position: absolute;
  background: white;
  color: rgba(0, 0, 0, 0.87);
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.36));
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 8px;
  padding: 12px 4px;
  width: max-content;
  top: 36px;

  left: 50%;
  transform: translate(-50%, 0);
`;
export const DropdownIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;

  transition: transform 300ms;
`;

export const HoverDropdown = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  font-size: 1rem;
  width: fit-content;
  padding: 8px 12px;
  font-weight: 500;

  :hover {
    ${Dropdown} {
      display: flex;
      flex-direction: column;
    }

    ${DropdownIcon} {
      transform: rotate(-180deg);
    }
  }
`;

const Header = () => {
  const dispatch = useDispatch();
  const userLocale = useActiveLocale();

  return (
    <AppBar elevation={0} classes={{ colorPrimary: 'bg-primary-main' }}>
      <Toolbar>
        <Link to={privateRoute.home.path} className='flex items-end gap-2'>
          <img alt='logo' src={IS_EPAYZ ? LogoEpayz : Logo} className='h-12' />
          {!IS_EPAYZ && (
            <span className='font-bold'>
              <Trans>WAGE ADVANCE</Trans>
            </span>
          )}
        </Link>
        <div className='flex-1' />
        <div className='flex items-center w-max'>
          <HoverDropdown>
            <div className='flex items-center gap-1.5'>
              <img src={userLocale === 'vi-VN' ? VNFlag : ENFlag} alt='' width='20px' />
              {userLocale === 'vi-VN' ? 'Việt Nam' : 'English'}
              <DropdownIcon />
            </div>
            <Dropdown>
              <ListItemButton
                selected={userLocale === 'vi-VN'}
                className='flex gap-1.5'
                onClick={() => store.dispatch(setUserLocale('vi-VN'))}
              >
                <img src={VNFlag} alt='' width='20px' />
                Việt Nam
              </ListItemButton>
              <ListItemButton
                selected={userLocale === 'en-US'}
                className='flex gap-1.5'
                onClick={() => store.dispatch(setUserLocale('en-US'))}
              >
                <img src={ENFlag} alt='' width='20px' />
                English
              </ListItemButton>
            </Dropdown>
          </HoverDropdown>
          <IconButton onClick={() => dispatch(signOut())}>
            <LogoutOutlined className='text-white' />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
