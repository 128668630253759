import { Grid } from '@mui/material';
import { TopCalendar, BusinessResult, TopGroup } from 'views/Dashboard/Main/components';

const DashboardMain = () => {
  return (
    <div>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item sm={12}>
          <TopCalendar limit={0} />
        </Grid>
        <Grid item sm={12}>
          <BusinessResult />
        </Grid>
        <Grid item sm={12}>
          <TopGroup />
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardMain;
