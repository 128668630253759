import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { usePermission } from 'hooks';
import { TableEmpty } from 'components';
import { useState } from 'react';
import { CreditScoreType, RatingCategoryType } from 'types/CreditScore';
import { RatingCreatePopup } from 'views/GeneralSetup/CreditScore/popups';

const EnterpriseBankAccount = ({ creditScore }: { creditScore?: CreditScoreType }) => {
  const { requireRole,requreUserPermission,DIEMTINDUNG_CHITIET_THEMTIEUCHI } = usePermission();
  const [isOpen, setOpen] = useState(false);
  const [chosenUpdate, setChosenUpdate] = useState<RatingCategoryType>();

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Limit Adjustment Ratio By Rating (%)</Trans>
          </Typography>
          {requireRole('CA_SENIOR') && requreUserPermission([DIEMTINDUNG_CHITIET_THEMTIEUCHI]) &&  (
            <Button
              variant='outlined'
              size='small'
              onClick={() => {
                setOpen(true);
                setChosenUpdate(undefined);
              }}
            >
              <Trans>Add Rating</Trans>
            </Button>
          )}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Rating</Trans>
              </TableCell>
              <TableCell>
                <Trans>Score Range</Trans>
              </TableCell>
              <TableCell>
                <Trans>Limit Adjustment Ratio by Industry</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creditScore?.ratingCategories?.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell className='text-center'>{item.ratingType}</TableCell>
                <TableCell className='text-center'>
                  {item.minScore} - {item.maxScore}
                </TableCell>
                <TableCell className='text-center'>{item.adjustmentRatio} %</TableCell>
                <TableCell className='text-right'>
                  {requireRole('CA_SENIOR') && (
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpen(true);
                        setChosenUpdate(item);
                      }}
                    >
                      <Trans>Update</Trans>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={creditScore?.ratingCategories} />
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog fullWidth maxWidth='xs' open={isOpen} onClose={() => setOpen(false)}>
        <RatingCreatePopup item={chosenUpdate} creditScore={creditScore!} onClose={() => setOpen(false)} />
      </Dialog>
    </div>
  );
};

export default EnterpriseBankAccount;
