import { t } from '@lingui/macro';
export const PcTypeSignType = "pc_sign";
export const EnterpriseTypeSignType = "ent_sign";

export const getGender = (status?: string) => {
    if (!status) {
        return '';
    }
    const GENDER_MAP: any = {
        MALE: t`Male`,
        FEMALE: t`Female`,
    };
    return GENDER_MAP[status!];
};

export const getDepartment = (code?: string) => {
    if (!code) {
        return '';
    }
    const DATA_MAP: any = {
        MANAGER: t`MANAGEMENT DEPARTMENT`,
        CRM: t`CRM DEPARTMENT`,
        CA: t`CA DEPARTMENT`,
        ACCOUNTING: t`ACCOUNTING DEPARTMENT`,
    };
    return DATA_MAP[code!];
};

export const getDepartmentName = (code?: string) => {
    if (!code) {
        return '';
    }
    const DATA_MAP: any = {
        MANAGER: t`MANAGER`,
        CRM: t`CRM`,
        CA: t`CA`,
        ACCOUNTING: t`ACCOUNTING`,
    };
    return DATA_MAP[code!];
};

export const getDay = (day?: string) => {
    if (!day) {
        return '';
    }
    const DATA_MAP: any = {
        MONDAY: t`MONDAY`,
        TUESDAY: t`TUESDAY`,
        WEDNESDAY: t`WEDNESDAY`,
        THURSDAY: t`THURSDAY`,
        FRIDAY: t`FRIDAY`,
        SATURDAY: t`SATURDAY`,
        SUNDAY: t`SUNDAY`,
    };
    return DATA_MAP[day!];
};
