import { t, Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';
import { usePermission, useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { EnterpriseType } from 'types/Enterprise';
import { formatNumber } from 'utils/common';
import {
  BusinessConditionReview,
  BusinessCreditScorePopup,
  BusinessTrialCondition,
} from 'views/ClientSetup/Enterprise/popups';
const EnterpriseAdvanceLabor = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const { isCaRole } = usePermission();
  const [isOpenCredit, setOpenCredit] = useState(false);
  const [isOpenTrial, setOpenTrial] = useState(false);
  const [isOpenCondition, setOpenCondition] = useState(false);
  const [conditionId, setConditionId] = useState(1);
  const { requreUserPermission, ENTERPRISE_DIEMTINDUNG, ENTERPRISE_DATDIEUKIENDUNGTHU } = usePermission();
  const [dataSearch, onSearchChange] = useSearch();

  const { data } = useQuery(
    ['enterpriseSetupService.fetchConditionSetting', { id: enterprise?.id, ...dataSearch }],
    () => enterpriseSetupService.fetchConditionSetting({ id: enterprise?.id, ...dataSearch }),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { data: condition } = useQuery(['enterpriseSetupService.getConditionLastest', { id: enterprise?.id }], () =>
    enterpriseSetupService.getConditionLastest({ id: enterprise?.id! }),
  );

  return (
    <div>
      <div className='flex justify-between'>
        <Typography variant='h4'>
          <Trans>Business Credit Setup</Trans>
        </Typography>
        {isCaRole() && <div className='flex gap-3'>
          <Button variant='contained' onClick={() => setOpenCredit(true)}>
            <Trans>Credit Scoring Setup</Trans>
          </Button>
          {requreUserPermission([ENTERPRISE_DATDIEUKIENDUNGTHU]) && <Button variant='contained' onClick={() => setOpenTrial(true)}>
            <Trans>Set Trial Conditions</Trans>
          </Button>}
        </div>}
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Rating</Trans>
              </TableCell>
              <TableCell>
                <Trans>Credit Score</Trans>
              </TableCell>
              <TableCell>
                <Trans>Total Limit (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Earned Discount Rate (APR)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Future Discount Rate (APR)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Expire Date</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='center'>{condition?.ratingType}</TableCell>
              <TableCell align='center'>{condition?.totalScore}</TableCell>
              <TableCell align='center'>{formatNumber(condition?.totalLimit)}</TableCell>
              <TableCell align='center'>{condition?.earnedDiscountRate}%</TableCell>
              <TableCell align='center'>{condition?.futureDiscountRate}%</TableCell>
              <TableCell align='center'>
                {condition?.expireDate ? DateTime.fromISO(condition.expireDate).toFormat('dd/MM/yyyy') : ''}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant='h4'>
        <Trans>Setting History</Trans>
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Execution Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Executor</Trans>
              </TableCell>
              <TableCell>
                <Trans>Credit Score</Trans>
              </TableCell>
              <TableCell>
                <Trans>Rating</Trans>
              </TableCell>
              <TableCell>
                <Trans>Approver</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell align='center'>{DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')}</TableCell>
                <TableCell align='center'>
                  {item.type === 1 && t`Credit Scoring`}
                  {item.type === 2 && t`Trial Condition`}
                </TableCell>
                <TableCell align='center'>{item.createdName}</TableCell>
                <TableCell align='center'>{item.totalScore}</TableCell>
                <TableCell align='center'>{item.ratingType}</TableCell>
                <TableCell align='center'>{item.approvedName}</TableCell>
                <TableCell align='center'>
                  {item.status === 'approved' && t`Approved`}
                  {item.status === 'reject' && t`Rejected`}
                  {item.status === 'pending' && t`Pending`}
                </TableCell>
                <TableCell align='center'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setConditionId(item.id);
                      setOpenCondition(true);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='lg' open={isOpenCredit} onClose={() => setOpenCredit(false)}>
        <BusinessCreditScorePopup id={enterprise?.id!} onClose={() => setOpenCredit(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='xl' open={isOpenTrial} onClose={() => setOpenTrial(false)}>
        <BusinessTrialCondition id={enterprise?.id!} onClose={() => setOpenTrial(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='lg' open={isOpenCondition} onClose={() => setOpenCondition(false)}>
        <BusinessConditionReview
          isStep={false}
          enterpriseId={enterprise?.id!}
          conditionId={conditionId}
          onClose={() => setOpenCondition(false)}
        />
      </Dialog>
    </div>
  );
};

export default EnterpriseAdvanceLabor;
