import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { categoryService, enterpriseSetupService, queryClient } from 'services';
import { EnterpriseType } from 'types/Enterprise';
import { DateTime } from 'luxon';
import { BlockType } from 'types/EnterpriseSetupAdvance';
import { useEffect, useState } from 'react';
const AttendanceWorkingBlocks = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const userLocale = useActiveLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue, getValues } = useForm();
  const [isRender, setRender] = useState(false);
  const { data: workingTypes } = useQuery(['fetchWorkingTypes'], () => categoryService.fetchWorkingTypes());
  const { data: weekDays } = useQuery(['fetchWeekDays'], () => categoryService.fetchWeekDays());
  const secondWeekDays = weekDays ? [...weekDays] : [];
  secondWeekDays.splice(0, 0, { id: 0, isOffDefault: 0, enContent: 'Select', viContent: 'Chọn' });

  const id = enterprise?.id.toString()! ?? 0;
  useEffect(() => {
    console.log("render");
  }, [isRender])
  const { data } = useQuery(
    ['enterpriseSetupService.fetchWorkingBlocks', { id }],
    () => enterpriseSetupService.fetchWorkingBlocks({ id }),
    {
      onSuccess: ({ blocks }) => {
        blocks.forEach((block) => {
          setValue(block.workingBlockId + '-startDay', block.startDay ?? '');
          setValue(block.workingBlockId + '-endDay', block.endDay ?? '');
          setValue(block.workingBlockId + '-salaryDate', block.salaryDate ?? '');
          setValue(block.workingBlockId + '-workingTypeId', block.workingTypeId ?? '');
          setValue(block.workingBlockId + '-firstDayOffId', block.firstDayOffId ?? '');
          setValue(block.workingBlockId + '-secondDayOffId', block.secondDayOffId ?? '');
          setValue(block.workingBlockId + '-note', block.note ?? '');
          setValue(block.workingBlockId + '-detailSetting', block.detailSetting ?? false);
          setValue(block.workingBlockId + '-setAdvanceFrom', block.setAdvanceFrom ? DateTime.fromISO(block.setAdvanceFrom) : "");
          setValue(block.workingBlockId + '-setAdvanceTo', block.setAdvanceTo ? DateTime.fromISO(block.setAdvanceTo) : "");
          setValue(block.workingBlockId + '-setStartingDate', block.setStartingDate ? DateTime.fromISO(block.setStartingDate) : "");
          setValue(block.workingBlockId + '-setClosingDate', block.setClosingDate ? DateTime.fromISO(block.setClosingDate) : "");
          setValue(block.workingBlockId + '-setSalaryDate', block.setSalaryDate ? DateTime.fromISO(block.setSalaryDate) : "");
        });
      },
    },
  );
  const { blocks = [], editable = false } = data ?? {};

  const { mutate: createWorkingBlocks, isLoading } = useMutation(enterpriseSetupService.createWorkingBlocks, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.fetchWorkingBlocks');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      var blockInputs = [] as any[];
      blocks.filter((item: BlockType) => item.workingTypeId && item.firstDayOffId).forEach((item: BlockType) => {
        let obj = {
          startDay: values[item.workingBlockId + '-startDay'] as number,
          endDay: values[item.workingBlockId + '-endDay'] as number,
          firstDayOffId: values[item.workingBlockId + '-firstDayOffId'] as number,
          secondDayOffId: values[item.workingBlockId + '-secondDayOffId'] as number,
          workingBlockId: item.workingBlockId,
          workingTypeId: values[item.workingBlockId + '-workingTypeId'] as number,
          note: values[item.workingBlockId + '-note'] as string,
          detailSetting: values[item.workingBlockId + '-detailSetting'] as boolean,
          setAdvanceFrom: "",
          setAdvanceTo: "",
          setStartingDate: "",
          setClosingDate: "",
          setSalaryDate: ""
        }
        let setAdvanceFrom = (values[item.workingBlockId + '-setAdvanceFrom'] as DateTime);
        obj.setAdvanceFrom = setAdvanceFrom ? setAdvanceFrom.isValid ? setAdvanceFrom.toISO() : setAdvanceFrom.toString() : "";
        let setAdvanceTo = (values[item.workingBlockId + '-setAdvanceTo'] as DateTime);
        obj.setAdvanceTo = setAdvanceTo ? setAdvanceTo.isValid ? setAdvanceTo.toISO() : setAdvanceTo.toString() : "";
        let setStartingDate = (values[item.workingBlockId + '-setStartingDate'] as DateTime);
        obj.setStartingDate = setStartingDate ? setStartingDate.isValid ? setStartingDate.toISO() : setStartingDate.toString() : "";
        let setClosingDate = (values[item.workingBlockId + '-setClosingDate'] as DateTime);
        obj.setClosingDate = setClosingDate ? setClosingDate.isValid ? setClosingDate.toISO() : setClosingDate.toString() : "";
        let setSalaryDate = (values[item.workingBlockId + '-setSalaryDate'] as DateTime);
        obj.setSalaryDate = setSalaryDate ? setSalaryDate.isValid ? setSalaryDate.toISO() : setSalaryDate.toString() : "";
        blockInputs.push(obj);
      });
      createWorkingBlocks({
        id,
        blocks: blockInputs
      });
    })();
  };
  const settingDetail = (block: BlockType) => {
    setValue(block.workingBlockId + '-detailSetting', !getValues(block.workingBlockId + '-detailSetting'));
    setValue(block.workingBlockId + '-setAdvanceFrom', "");
    setValue(block.workingBlockId + '-setAdvanceTo', "");
    setValue(block.workingBlockId + '-setStartingDate', "");
    setValue(block.workingBlockId + '-setClosingDate', "");
    setValue(block.workingBlockId + '-setSalaryDate', "");
    setRender(!isRender);
  }
  return (
    <div>
      <Typography variant='h4' className='mb-4'>
        <Trans>Management of Employee's Attendance</Trans>
      </Typography>
      <Grid container columnSpacing={4}>
        {[0, 3]
          .map((start) => blocks.slice(start, start + 3))
          .map((array, slide) => (
            <Grid item md={6} key={slide}>
              <Grid container rowSpacing={2}>
                {array.map((block, index) => (
                  <Grid item md={12} key={index}>
                    <div className='flex'>
                      <Typography variant='h5' className='mb-2' style={{ width: 180 }}>
                        {userLocale === 'vi-VN' ? block.workingBlock.viContent : block.workingBlock.enContent}
                      </Typography>
                      <Controller
                        name={block.workingBlockId + '-note'}
                        defaultValue=''
                        control={control}
                        render={({ field }) => <TextField {...field} fullWidth label={t`Note`} />}
                      />
                    </div>
                    <Paper variant='outlined' className='p-3 mt-2'>
                      <Grid container columnSpacing={2} rowSpacing={3}>
                        <Grid item sm={4}>
                          <Controller
                            name={block.workingBlockId + '-startDay'}
                            defaultValue=''
                            control={control}
                            rules={{ required: slide === 0 }}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label={t`Attendance Starting Date`}
                                error={invalid}
                                InputProps={{ inputComponent: InputNumber as any }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <Controller
                            name={block.workingBlockId + '-endDay'}
                            defaultValue=''
                            control={control}
                            rules={{ required: slide === 0 }}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label={t`Attendance Closing Date`}
                                error={invalid}
                                InputProps={{ inputComponent: InputNumber as any }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <Controller
                            name={block.workingBlockId + '-salaryDate'}
                            defaultValue=''
                            control={control}
                            rules={{ required: slide === 0 }}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label={t`Salary Payment Date`}
                                error={invalid}
                                InputProps={{ inputComponent: InputNumber as any }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <Controller
                            name={block.workingBlockId + '-workingTypeId'}
                            defaultValue=''
                            control={control}
                            rules={{ required: slide === 0 }}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField {...field} select fullWidth label={t`Attendance Time`} error={invalid}>
                                {workingTypes?.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <Controller
                            name={block.workingBlockId + '-firstDayOffId'}
                            defaultValue=''
                            control={control}
                            rules={{ required: slide === 0 }}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField {...field} select fullWidth label={t`(1) Full day off`} error={invalid}>
                                {weekDays?.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          <Controller
                            name={block.workingBlockId + '-secondDayOffId'}
                            defaultValue=''
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField {...field} select fullWidth label={t`(2) Half/Full day off`} error={invalid}>
                                {secondWeekDays?.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item sm={4}>
                          {getValues(block.workingBlockId + '-detailSetting') && <Typography variant='h5' className='mb-2' style={{ width: 180 }}>
                            Cài đặt chi tiết
                          </Typography>}
                        </Grid>
                        {getValues(block.workingBlockId + '-detailSetting') && <Grid item sm={4}>
                          <Controller
                            name={block.workingBlockId + '-setAdvanceFrom'}
                            defaultValue=''
                            control={control}
                            rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
                            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                              <DesktopDatePicker
                                value={value}
                                onChange={onChange}
                                renderInput={(props) => <TextField {...props} required={getValues(block.workingBlockId + '-detailSetting')} error={invalid} />}
                                label={t`Advance from`}
                                inputFormat='dd/MM/yyyy'
                              />
                            )}
                          />
                        </Grid>}
                        {getValues(block.workingBlockId + '-detailSetting') && <Grid item sm={4}>
                          <Controller
                            name={block.workingBlockId + '-setAdvanceTo'}
                            defaultValue=''
                            control={control}
                            rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
                            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                              <DesktopDatePicker
                                value={value}
                                onChange={onChange}
                                renderInput={(props) => <TextField {...props} required={getValues(block.workingBlockId + '-detailSetting')} error={invalid} />}
                                label={t`Advance to`}
                                inputFormat='dd/MM/yyyy'
                              />
                            )}
                          />
                        </Grid>}
                        {getValues(block.workingBlockId + '-detailSetting') && <Grid item sm={4}>
                          <Controller
                            name={block.workingBlockId + '-setStartingDate'}
                            defaultValue=''
                            control={control}
                            rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
                            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                              <DesktopDatePicker
                                value={value}
                                onChange={onChange}
                                renderInput={(props) => <TextField {...props} required={getValues(block.workingBlockId + '-detailSetting')} error={invalid} />}
                                label={t`Attendance Starting Date`}
                                inputFormat='dd/MM/yyyy'
                              />
                            )}
                          />
                        </Grid>}
                        {getValues(block.workingBlockId + '-detailSetting') && <Grid item sm={4}>
                          <Controller
                            name={block.workingBlockId + '-setClosingDate'}
                            defaultValue=''
                            control={control}
                            rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
                            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                              <DesktopDatePicker
                                value={value}
                                onChange={onChange}
                                renderInput={(props) => <TextField {...props} required={getValues(block.workingBlockId + '-detailSetting')} error={invalid} />}
                                label={t`Attendance Closing Date`}
                                inputFormat='dd/MM/yyyy'
                              />
                            )}
                          />
                        </Grid>}
                        {getValues(block.workingBlockId + '-detailSetting') && <Grid item sm={4}>
                          <Controller
                            name={block.workingBlockId + '-setSalaryDate'}
                            defaultValue=''
                            control={control}
                            rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
                            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                              <DesktopDatePicker
                                value={value}
                                onChange={onChange}
                                renderInput={(props) => <TextField {...props} required={getValues(block.workingBlockId + '-detailSetting')} error={invalid} />}
                                label={t`Salary Payment Date`}
                                inputFormat='dd/MM/yyyy'
                              />
                            )}
                          />
                        </Grid>}
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
      </Grid>
      {editable && (
        <div className='flex justify-end mt-4'>
          <LoadingButton variant='contained' className='w-32' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
      )}
    </div>
  );
};

export default AttendanceWorkingBlocks;
