import * as React from 'react';
import AuthService, {IAuthService} from './services/AuthService';
import { useNavigate } from 'react-router-dom';
import { store } from 'reducers';
import { signIn, signOut } from 'reducers/profile';
import { userService } from 'services';
import { openAlert } from 'reducers/notification';
import { t, Trans } from '@lingui/macro';
const authService: IAuthService = new AuthService();
const SigninCallback = () => {
    const navigate = useNavigate();
    React.useEffect(() => {
        (async () => { 
            try {
                console.log("Signing in...");
                await authService.signinCallback();

                var userResponse = await authService.getUser();
                console.log(userResponse)
                if (userResponse) {
                    store.dispatch(signIn({ accessToken: userResponse.access_token, refreshToken: userResponse.refresh_token}));
                    userService.getProfile().then((profile) => {
                    if (!profile) {
                    store.dispatch(
                        openAlert({
                        message: t`Username or password is incorrect`,
                        code: 400,
                        variant: 'error',
                        }),
                    );
                    store.dispatch(signOut());
                    return;
                    }
                    store.dispatch(signIn(profile));
                });
                }
            }
            catch(e) {
                console.error(e);
            }
            finally {
                navigate('/');
            }
        })();
    });
  
    return <div>
                <img src={require('assets/images/download.gif')} style={{ width: '100%', height: 'calc(100vh)' }} />
            </div>;
  };
  
  export default SigninCallback;
