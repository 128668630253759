import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { RejectPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';

type PopupProps = {
  enterpriseId: string;
  appendixId: number;
  onNext: () => void;
  onBack: () => void;
};

const SingleServicePopupStep3 = ({ appendixId, enterpriseId, onNext, onBack }: PopupProps) => {
  const { data: { serviceTax } = {} } = useQuery(
    ['enterpriseSetupService.getEnterpriseServiceFeeCategoryInfo', { id: enterpriseId }],
    () =>
      enterpriseSetupService.getEnterpriseServiceFeeCategoryInfo({
        id: enterpriseId,
        appliedFee: 'SINGLE_SERVICE_FEE',
      }),
  );

  const { enqueueSnackbar } = useSnackbar();
  const [isOpenReject, setOpenReject] = useState(false);

  const { mutate: completeManualAppendix, isLoading: isLoadingVerify } = useMutation(
    enterpriseSetupService.completeManualAppendix,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseServiceFeeHistories');
        onNext();
      },
    },
  );

  const handleSignSuccess = () => {
    completeManualAppendix({
      enterpriseId,
      appendixId,
    });
  };

  return (
    <>
      <DialogTitle>
        <Trans>ENTERPRISE'S SERVICE FEE SETTING</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Applied Fee</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Single Service Fee</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>VAT</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={serviceTax ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Payment Term</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>within 03 Working Days from receiving Notice of Single Fee Payment on Platform</Trans>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <LoadingButton variant='outlined' color='error' onClick={() => setOpenReject(true)}>
            <Trans>Reject</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' loading={isLoadingVerify} onClick={handleSignSuccess}>
            <Trans>Confirm</Trans>
          </LoadingButton>
        </div>
      </DialogActions>

      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <RejectPopup
          enterpriseId={enterpriseId}
          appendixId={appendixId}
          onBack={() => {
            setOpenReject(false);
          }}
          onNext={() => {
            setOpenReject(false);
            onBack();
          }}
        />
      </Dialog>
    </>
  );
};

export default SingleServicePopupStep3;
